import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

interface NivoThemeValuesProps {
    map: Record<string, unknown>;
    keyPrefix: string;
}

export const NivoThemeValues = ({ map, keyPrefix }: NivoThemeValuesProps) => {
    return Object.keys(map)
        .filter((key: string) => key !== 'theme')
        .map((key: string) => (
            <Accordion key={keyPrefix + '-' + key}>
                <AccordionSummary>{key}</AccordionSummary>
                <AccordionDetails>
                    <pre>{JSON.stringify(map[key], null, 2)}</pre>
                </AccordionDetails>
            </Accordion>
        ));
};
