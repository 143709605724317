import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const drawerRecipe = sva({
    slots: ['root', 'backdrop', 'header', 'content', 'defaultHeader'],
    base: {
        root: {
            overflow: 'auto',
            boxShadow: '[0 0 15px rgba(0, 0, 0, 0.5)]',
            transition: '[transform 0.3s ease]',
            top: '[0]',
            left: '[0]',
            backgroundColor: 'background.reversed',
            color: 'text.primary.reversed',
            _dark: {
                backgroundColor: 'cream.100',
                color: 'text.primary.reversed',
            },
        },
        backdrop: {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '[100%]',
            height: '[100%]',
            background: '[rgba(0, 0, 0, 0.5)]',
            transition: '[opacity 0.3s ease, visibility 0.3s ease]',
            opacity: '[0]',
            visibility: 'hidden',
            pointerEvents: 'none',
            willChange: 'opacity',
        },
        header: {
            padding: '[5px]',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        content: {
            padding: '[5px]',
        },
        defaultHeader: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
    variants: {
        placement: {
            left: {
                root: {
                    top: '[0]',
                    left: '[0]',
                    width: '[320px]',
                    height: '[100%]',
                    transform: '[translateX(-100%)]',
                    lg: {
                        width: '[320px]',
                        height: '[100%]',
                    },
                    md: {
                        width: '[320px]',
                        height: '[100%]',
                    },
                    sm: {
                        width: '[100%]',
                        height: '[100%]',
                    },
                },
            },
            right: {
                root: {
                    top: '[0]',
                    right: '[0]',
                    left: '[unset]',
                    width: '[320px]',
                    height: '[100%]',
                    transform: '[translateX(100%)]',
                    lg: {
                        width: '[320px]',
                        height: '[100%]',
                    },
                    md: {
                        width: '[320px]',
                        height: '[100%]',
                    },
                    sm: {
                        width: '[100%]',
                        height: '[100%]',
                    },
                },
            },
            top: {
                root: {
                    top: '[0]',
                    left: '[0]',
                    right: '[0]',
                    width: '[100%]',
                    height: '[320px]',
                    transform: '[translateY(-100%)]',
                    lg: {
                        width: '[100%]',
                        height: '[320px]',
                    },
                    md: {
                        width: '[100%]',
                        height: '[320px]',
                    },
                    sm: {
                        height: '[100%]',
                    },
                },
            },
            bottom: {
                root: {
                    bottom: '[0]',
                    left: '[0]',
                    right: '[unset]',
                    top: '[unset]',
                    width: '[100%]',
                    height: '[320px]',
                    transform: '[translateY(100%)]',
                    lg: {
                        width: '[100%]',
                        height: '[320px]',
                    },
                    md: {
                        width: '[100%]',
                        height: '[320px]',
                    },
                    sm: {
                        height: '[100%]',
                    },
                },
            },
        },
        isOpen: {
            true: {
                root: {
                    transform: 'translate(0)',
                    zIndex: 'drawer',
                },
                backdrop: {
                    visibility: 'visible',
                    opacity: '1',
                    pointerEvents: 'auto',
                    zIndex: 'backdrop',
                    transition: '[opacity 0.3s ease, visibility 0s 0.3s]',
                },
            },
            false: {},
        },
        backdrop: {
            true: {
                backdrop: {},
            },
            false: {
                backdrop: {
                    display: 'none',
                },
            },
        },
        variant: {
            temporary: {
                root: {
                    position: 'fixed',
                },
            },
            persistent: {
                root: {
                    // default left styles
                    position: 'relative',
                    transform: 'none',
                    width: '[20%]',
                    height: '[100%]',
                    transition: '[transform 0.3s ease]',
                },
                backdrop: {
                    display: 'none',
                },
            },
            permanent: {
                root: {
                    position: 'relative',
                    transform: 'none',
                    width: '[20%]',
                    height: '[100%]',
                },
                backdrop: {
                    display: 'none',
                },
            },
        },
    },
    compoundVariants: [
        {
            placement: 'right',
            variant: 'persistent',
            css: {
                root: {
                    order: '999', // realign flex item to the right
                },
            },
        },
        {
            placement: 'left',
            isOpen: true,
            css: {
                root: {
                    animation: 'slideInLeft 250ms ease-out forwards',
                },
            },
        },
        {
            placement: 'right',
            isOpen: true,
            css: {
                root: {
                    animation: 'slideInRight 250ms ease-out forwards',
                },
            },
        },
        {
            placement: 'top',
            isOpen: true,
            css: {
                root: {
                    animation: 'slideInTop 250ms ease-out forwards',
                },
            },
        },
        {
            placement: 'bottom',
            isOpen: true,
            css: {
                root: {
                    animation: 'slideInBottom 250ms ease-out forwards',
                },
            },
        },
    ],
    defaultVariants: {
        placement: 'left',
        isOpen: false,
        backdrop: true,
        variant: 'temporary',
    },
});

type DrawerRecipeProps = RecipeVariantProps<typeof drawerRecipe>;

export { drawerRecipe, drawerRecipe as default };
export type { DrawerRecipeProps };
