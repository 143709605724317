
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const linksGroup: ReactNode[] = [
    <div>
        <varnishUi.Label>Link Header 1</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Privacy Policy</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Terms of Service</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Contact Us</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">About Us</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Help Center</varnishUi.Link></li>
        </ul>
    </div>,
    <div>
        <varnishUi.Label>Link Header 2</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Data Protection Policy</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">User Agreement</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Get in Touch</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Our Story</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Support Center</varnishUi.Link></li>
        </ul>
    </div>,
    <div>
        <varnishUi.Label>Link Header 3</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Confidentiality Agreement</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Service Terms</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Reach Out</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Who We Are</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Assistance Hub</varnishUi.Link></li>
        </ul>
    </div>,
    <div>
        <varnishUi.Label>Link Header 4</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Privacy Commitment</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Terms and Conditions</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Contact Support</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Learn About Us</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">FAQ Section</varnishUi.Link></li>
        </ul>
    </div>
];

const className = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const linksGroupClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R5.value};
    \`;
}

const copyRightClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
    \`;
}

render(
    <varnishUi.Footer linksGroup={linksGroup} className={emotionCss.cx(className())} linksGroupClassName={emotionCss.cx(linksGroupClassName())} copyRightClassName={emotionCss.cx(copyRightClassName())} />
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;