
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const searchClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
    \`;
}

const actionClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
    \`;
}

render(
     <varnishUi.Header
            logo={<varnishUi.Icons.Spark size="large" />}
            search={<varnishUi.Input variant="outlined" placeholder="search" />}
            action={<varnishUi.Button variant="text">Sign In</varnishUi.Button>}
            navItem={
                <>
                <varnishUi.Link href="https://allenai.org/" color="primary">
                    Ai2 Link
                </varnishUi.Link>
                <varnishUi.Link
                    href="https://varnish.apps.allenai.org/components/varnish/welcome"
                    color="primary">
                    Varnish
                </varnishUi.Link>
                </>
            }
            search={<varnishUi.Input variant="outlined" placeholder="search" size="sm" />}
            action={<varnishUi.Button variant="text" size="sm">Sign In</varnishUi.Button>}
            icon={<varnishUi.Icons.Spark size="large" />}
            className={emotionCss.cx(className())}
            searchClassName={emotionCss.cx(searchClassName())}
            actionClassName={emotionCss.cx(actionClassName())}
        />
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;