
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To customize link colors, apply the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.Link href="https://allenai.org/">Default Link</varnishUi.Link>
        <varnishUi.Link color="primary" href="https://allenai.org/">
            Primary Link
        </varnishUi.Link>
        <varnishUi.Link color="secondary" href="https://allenai.org/">
            Secondary Link
        </varnishUi.Link>
        <varnishUi.Link color="tertiary" href="https://allenai.org/">
            Tertiary Link
        </varnishUi.Link>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;