
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Search'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Header offers a search slot.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
const { logos } = varnishComponents;

render(
    <varnishUi.Header
        logo={<varnishUi.Icons.Spark size="large" />}
        search={<varnishUi.Input variant="outlined" placeholder="search" size="sm" />}
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;