import { PropsWithChildren, ReactNode } from 'react';
import {
    ColumnResizer as AriaColumnResizer,
    ColumnResizerProps as AriaColumnResizerProps,
} from 'react-aria-components';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';

type ColumnResizerProps = {
    children?: ReactNode;
    className?: string;
} & AriaColumnResizerProps;

const ColumnResizer = ({ children, className, ...rest }: PropsWithChildren<ColumnResizerProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaColumnResizer
            {...localProps}
            className={cx(recipeClassNames.columnResizer, className)}>
            {children}
        </AriaColumnResizer>
    );
};

export { ColumnResizer };
export type { ColumnResizerProps };
