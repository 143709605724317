
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';

const containerClassName = () => {
    return emotionCss.css\`
        border: 2px dashed aqua;
        background-color: #9080A0;
    \`;
};

const messageClassName = () => {
    return emotionCss.css\`
        border: 2px dashed yellow;
        text-transform: uppercase;
    \`;
};

const titleClassName = () => {
    return emotionCss.css\`
        border: 2px dashed orange;
        color: white;
    \`;
};

render(
    <varnishUi.Stack direction="row" spacing={25}>
        <varnishUi.Alert
            severity="success"
            variant="contained"
            className={containerClassName()}
            messageClassName={messageClassName()}
            title="Custom styled title"
            titleClassName={titleClassName()}>
            Mission accomplished
        </varnishUi.Alert>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;