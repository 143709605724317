import { darkCategoricalColor } from '@allenai/varnish2/theme';
import { dictionaryToArray } from '@allenai/varnish2/utils';

import { ChartingGrid, ColorValues, ColorGroupDict } from './ColorValues';

const darkCategoricalColorGroup: ColorGroupDict = {
    darkCategorical: dictionaryToArray(darkCategoricalColor),
};

export function DarkCategoricalColorValues() {
    return (
        <ChartingGrid id="darkCategoricalColor">
            <ColorValues
                colorGroupDict={darkCategoricalColorGroup}
                themeProp="darkCategoricalColor"
            />
        </ChartingGrid>
    );
}
