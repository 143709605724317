import {
    Key,
    Table as AriaTable,
    TableProps as AriaTableProps,
    TableBodyProps as AriaTableBodyProps,
    useDragAndDrop,
    isFileDropItem,
    DIRECTORY_DRAG_TYPE,
    isDirectoryDropItem,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';
import { PropsWithChildren, ReactNode } from 'react';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';
import focusRing from '@/components/shared/focusRing.styles';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { Row } from './Row';
import { Cell } from './Cell';
import { Column } from './Column';
import Checkbox from '../checkbox/Checkbox';
import { ColumnResizer } from './ColumnResizer';
import Button from '../button/Button';

type TableHeaderItem = {
    id: string;
    name: string;
    isRowHeader?: boolean;
    width?: number;
    allowsSorting?: boolean;
    allowsResizing?: boolean;
};

// Generic TableRowItem for type safety
type TableRowItem<T extends Record<string, unknown>> = T & { id: Key };

type TableProps<T extends Record<string, unknown>> = {
    className?: string;
    tableRowClassName?: string;
    tableHeaderClassName?: string;
    columnClassName?: string;
    rowClassName?: string;
    cellClassName?: string;
    checkboxClassName?: string;
    dragButtonClassname?: string;
    tableBodyClassName?: string;
    headers?: TableHeaderItem[];
    dataItems?: TableRowItem<T>[];
    renderEmptyState?: (props: AriaTableBodyProps<object>) => ReactNode;
    children?: ReactNode;
} & AriaTableProps;

const Table = <T extends Record<string, unknown>>({
    className,
    tableRowClassName,
    tableHeaderClassName,
    tableBodyClassName,
    columnClassName,
    rowClassName,
    cellClassName,
    checkboxClassName,
    dragButtonClassname,
    headers,
    dataItems,
    renderEmptyState,
    children,
    ...rest
}: PropsWithChildren<TableProps<T>>) => {
    const {
        dragAndDropHooks,
        disabledKeys,
        sortDescriptor,
        selectedKeys,
        defaultSelectedKeys,
        disabledBehavior,
        selectionBehavior,
        selectionMode,
        onSortChange,
        ...localProps
    } = rest;

    const [variantProps, remainingProps] = tableRecipe.splitVariantProps(localProps);
    const recipeClassNames = tableRecipe(variantProps);
    const hasHeaders = headers !== undefined && headers.length > 0;
    const hasDataItems = dataItems !== undefined && dataItems.length >= 0;
    const direction = sortDescriptor !== undefined ? sortDescriptor.direction : 'ascending';

    return (
        <AriaTable
            {...remainingProps}
            disabledKeys={disabledKeys}
            sortDescriptor={sortDescriptor}
            selectedKeys={selectedKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            disabledBehavior={disabledBehavior}
            selectionBehavior={selectionBehavior}
            selectionMode={selectionMode}
            onSortChange={onSortChange}
            dragAndDropHooks={dragAndDropHooks}
            className={cx(recipeClassNames.root, className)}>
            {hasHeaders && hasDataItems ? (
                <>
                    <TableHeader
                        columns={headers}
                        className={cx(recipeClassNames.tableHeader, tableHeaderClassName)}>
                        {dragAndDropHooks ? <Column /> : null}
                        {selectionMode && selectionMode !== 'none' ? (
                            <Column className={cx(recipeClassNames.column, columnClassName)}>
                                <Checkbox
                                    slot="selection"
                                    className={cx(
                                        css(focusRing),
                                        recipeClassNames.checkbox,
                                        checkboxClassName
                                    )}
                                />
                            </Column>
                        ) : null}
                        {headers.map((column) => (
                            <Column
                                key={column.id}
                                id={column.id}
                                allowsSorting={column.allowsSorting}
                                sortDirection={direction}
                                isRowHeader={column.isRowHeader}
                                width={column.width}
                                className={cx(recipeClassNames.column, columnClassName)}>
                                {column.allowsResizing ? (
                                    <div className="flex-wrapper">
                                        <span tabIndex={-1} className="column-name">
                                            {column.name}
                                        </span>
                                        <ColumnResizer />
                                    </div>
                                ) : (
                                    column.name
                                )}
                            </Column>
                        ))}
                    </TableHeader>

                    <TableBody
                        items={dataItems}
                        className={cx(recipeClassNames.tableBody, tableBodyClassName)}
                        renderEmptyState={renderEmptyState}>
                        {dataItems.length > 0 &&
                            dataItems.map((item) => (
                                <Row
                                    key={item.id}
                                    id={item.id}
                                    columns={headers}
                                    className={cx(recipeClassNames.row, rowClassName)}
                                    href={item.url ? (item.url as string) : undefined}
                                    target={item.url ? '_blank' : undefined}
                                    isDisabled={
                                        item.isDisabled ? (item.isDisabled as boolean) : undefined
                                    }>
                                    {dragAndDropHooks && (
                                        <Cell className={cx(recipeClassNames.cell, cellClassName)}>
                                            <Button
                                                slot="drag"
                                                className={cx(
                                                    css(focusRing),
                                                    recipeClassNames.dragButton,
                                                    dragButtonClassname
                                                )}>
                                                ≡
                                            </Button>
                                        </Cell>
                                    )}
                                    {selectionMode && selectionMode !== 'none' && (
                                        <Cell className={cx(recipeClassNames.cell, cellClassName)}>
                                            <Checkbox
                                                slot="selection"
                                                className={cx(
                                                    css(focusRing),
                                                    recipeClassNames.checkbox,
                                                    checkboxClassName
                                                )}
                                            />
                                        </Cell>
                                    )}
                                    {headers.map((column) => (
                                        <Cell
                                            key={column.id}
                                            className={cx(recipeClassNames.cell, cellClassName)}>
                                            {item[column.id] as ReactNode}
                                        </Cell>
                                    ))}
                                </Row>
                            ))}
                    </TableBody>
                </>
            ) : (
                children
            )}
        </AriaTable>
    );
};

export { Table, useDragAndDrop, isFileDropItem, DIRECTORY_DRAG_TYPE, isDirectoryDropItem };
export type { TableProps, TableHeaderItem, TableRowItem };
