import {
    ListBoxItem as AriaListBoxItem,
    ListBoxItemProps as AriaListBoxItemProps,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { useContext } from 'react';

import { cx } from '@/utils/cx';

import selectRecipe, { SelectRecipeProps } from '@/components/select/select.styles';
import focusRing from '@/components/shared/focusRing.styles';
import { SelectVariantContext } from '@/components/select/Select';

type SelectListBoxItemProps = {
    icon?: React.ReactNode;
    text?: string;
    label?: string;
    className?: string;
    listBoxItemIconClassName?: string;
    listBoxItemText?: string;
    children?: React.ReactNode;
} & AriaListBoxItemProps &
    SelectRecipeProps;

const SelectListBoxItem = ({
    icon,
    text,
    children,
    className,
    listBoxItemIconClassName,
    listBoxItemText,
    ...rest
}: SelectListBoxItemProps) => {
    const [variantProps, localProps] = selectRecipe.splitVariantProps(rest);

    const groupProps = useContext(SelectVariantContext);
    const recipeClassNames = selectRecipe.raw({ ...groupProps, ...variantProps });

    return (
        <AriaListBoxItem
            {...localProps}
            className={cx(css(recipeClassNames.listBoxItem, focusRing), className)}>
            <div
                aria-hidden
                data-empty-icon={icon === undefined} // prop is used by button to tell if it should leave space for icon
                className={cx(css(recipeClassNames.listBoxItemIcon), listBoxItemIconClassName)}>
                {icon}
            </div>
            <span className={cx(css(recipeClassNames.listBoxItemText), listBoxItemText)}>
                {text}
            </span>
            {children}
        </AriaListBoxItem>
    );
};

export { SelectListBoxItem, SelectListBoxItem as default };
export type { SelectListBoxItemProps };
