
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish',
  type: 'Patterns',
  title: 'Testing',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Testing`}</h2>
    <h3>{`End-to-end testing`}</h3>
    <p>{`We recommend using `}<a parentName="p" {...{
        "href": "https://playwright.dev/"
      }}>{`Playwright`}</a>{` for end-to-end tests. Playwright is developed and maintained by Microsoft, and allows you
to automate browser actions and interaction with various browsers (Firefox, Chromium, Webkit). You can also set up Playwright to work with
`}<a parentName="p" {...{
        "href": "https://www.browserstack.com/"
      }}>{`Browserstack`}</a>{`, which makes it easy to test your website across platforms and mobile devices.
Get started with Playwright `}<a parentName="p" {...{
        "href": "https://playwright.dev/docs/intro"
      }}>{`here`}</a>{`.`}</p>
    <h3>{`Unit testing`}</h3>
    <h4>{`Jest`}</h4>
    <p>{`We recommend using `}<a parentName="p" {...{
        "href": "https://jestjs.io/"
      }}>{`jest`}</a>{` for unit testing. You can find their getting started guide here: `}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/getting-started"
      }}>{`https://jestjs.io/docs/getting-started`}</a>{`. If you're using Typescript, make sure you follow the instructions for setting jest up with Typescript here: `}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/getting-started#using-typescript"
      }}>{`https://jestjs.io/docs/getting-started#using-typescript`}</a>{`. If you're using NextJS, follow the instructions here: `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/app/building-your-application/testing/jest"
      }}>{`https://nextjs.org/docs/app/building-your-application/testing/jest`}</a></p>
    <p>{`If you want to mock something, jest has built-in mocking functionality: `}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/mock-functions"
      }}>{`https://jestjs.io/docs/mock-functions`}</a>{`. We recommend mocking third-party dependencies and things that may make the test unstable, like time or random numbers.`}</p>
    <h4>{`React Testing Library`}</h4>
    <p>{`If you're testing a React application, we recommend React Testing Library. Their introduction is a good way to get acquianted with it: `}<a parentName="p" {...{
        "href": "https://testing-library.com/docs/react-testing-library/intro/"
      }}>{`https://testing-library.com/docs/react-testing-library/intro/`}</a>{`. If you want to make some user interactions like typing, pointing, or copying easier, add Testing Library's user-event library: `}<a parentName="p" {...{
        "href": "https://testing-library.com/docs/user-event/intro"
      }}>{`https://testing-library.com/docs/user-event/intro`}</a>{`.`}</p>
    <p>{`If you've used something like Enzyme before RTL may take some adjustment. React Testing Library discourages testing component internals in favor of testing your UI as close as you can to how a user would use it. This allows you to be more confident that your components are usable and accessible. To see more about how this can apply to your tests, see their "Priority" section in their docs: `}<a parentName="p" {...{
        "href": "https://testing-library.com/docs/queries/about#priority"
      }}>{`https://testing-library.com/docs/queries/about#priority`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;