
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Extending the Theme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can add properties to the theme, so that you can use them in custom
styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import varnishComponents from '@allenai/varnish2/components';
import ThemeMui from '@allenai/varnish2/theme';

const extendedTheme = {
    color: {
        Freeze: new ThemeMui.Color('Mr. Freeze', '#bbccff', false),
    },
};

const CoolH1 = styled('h1')\`
    color: \${({ theme }) => theme.color.Freeze};
\`;

render(
    <varnishComponents.VarnishApp theme={extendedTheme}>
        <CoolH1>🥶 Brrrr....</CoolH1>
    </varnishComponents.VarnishApp>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;