
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To adjust the color of checkboxes, use the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop on the group.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={8}>
        <varnishUi.CheckboxGroup color="primary" defaultValue={['baseball']} label="Primary">
            <varnishUi.Checkbox value="soccer">Soccer</varnishUi.Checkbox>
            <varnishUi.Checkbox value="baseball">Baseball</varnishUi.Checkbox>
            <varnishUi.Checkbox value="basketball">Basketball</varnishUi.Checkbox>
        </varnishUi.CheckboxGroup>

        <varnishUi.CheckboxGroup color="secondary" defaultValue={['soccer']} label="Secondary">
            <varnishUi.Checkbox value="soccer">Soccer</varnishUi.Checkbox>
            <varnishUi.Checkbox value="baseball">Baseball</varnishUi.Checkbox>
            <varnishUi.Checkbox value="basketball">Basketball</varnishUi.Checkbox>
        </varnishUi.CheckboxGroup>

        <varnishUi.CheckboxGroup color="tertiary" defaultValue={['basketball']} label="Tertiary">
            <varnishUi.Checkbox value="soccer">Soccer</varnishUi.Checkbox>
            <varnishUi.Checkbox value="baseball">Baseball</varnishUi.Checkbox>
            <varnishUi.Checkbox value="basketball">Basketball</varnishUi.Checkbox>
        </varnishUi.CheckboxGroup>

        <varnishUi.CheckboxGroup color="teal" defaultValue={['baseball']} label="Teal">
            <varnishUi.Checkbox value="soccer">Soccer</varnishUi.Checkbox>
            <varnishUi.Checkbox value="baseball">Baseball</varnishUi.Checkbox>
            <varnishUi.Checkbox value="basketball">Basketball</varnishUi.Checkbox>
        </varnishUi.CheckboxGroup>
        <PadDiv>
            <varnishUi.CheckboxGroup color="cream" defaultValue={['basketball']} label="Cream">
                <varnishUi.Checkbox value="soccer">Soccer</varnishUi.Checkbox>
                <varnishUi.Checkbox value="baseball">Baseball</varnishUi.Checkbox>
                <varnishUi.Checkbox value="basketball">Basketball</varnishUi.Checkbox>
            </varnishUi.CheckboxGroup>
        </PadDiv>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;