
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'FileInputButton'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`If you only want a FileInputButton without the drag&drop capability, you can just reference the `}<inlineCode parentName="p">{`<FileInputButton />`}</inlineCode>{` component.`}</p>
    <p>{`Below is a default button, an `}<inlineCode parentName="p">{`iconOnly`}</inlineCode>{` button, a custom icon button, and a button with textOnly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const PaperClipIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-paperclip">
        <path d="M13.234 20.252 21 12.3" />
        <path d="m16 6-8.414 8.586a2 2 0 0 0 0 2.828 2 2 0 0 0 2.828 0l8.414-8.586a4 4 0 0 0 0-5.656 4 4 0 0 0-5.656 0l-8.415 8.585a6 6 0 1 0 8.486 8.486" />
    </svg>
);

render(
    <varnishUi.Stack direction="row" align="center" spacing={16}>
        <varnishUi.FileInputButton />
        <varnishUi.FileInputButton iconOnly={true} />
        <varnishUi.FileInputButton iconOnly={true} icon={PaperClipIcon} />
        <varnishUi.FileInputButton icon={false} buttonText="Feed me a file" />
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;