
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Custom Header'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can use the header components individually for a more customized
appearance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import varnishComponents from '@allenai/varnish2/components';
import { styled } from '@mui/material';

const { Header, logos } = varnishComponents;

const Tagline = {
    Container: styled('span')\`
        display: flex;
        align-items: center;
        gap: 1ch;
    \`,
    Text: styled('em')\`
        color: \${({ theme }) => theme.color.N2.toString()};
        font-size: 24px;
    \`,
};

function Demo() {
    const customBanner = (
        <Header.AI2Banner>
            <Tagline.Container>
                <Tagline.Text>A service of</Tagline.Text>
                <logos.AI2Logo size="sm" color="white" />
            </Tagline.Container>
        </Header.AI2Banner>
    );

    return (
        <div style={{ width: '100%' }}>
            <Header customBanner={customBanner} bannerAlwaysVisible>
                <Header.Columns columns="auto">
                    <logos.AI2Logo />
                </Header.Columns>
            </Header>
        </div>
    );
}

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;