
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can use the following classNames to style: className (root), chipClassName, labelClassName, descriptionClassName.`}</p>
    <p>{`These classNames must have css inserted by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const className = () => {
    return emotionCss.css\`
        border: 2px dashed red;
    \`;
};

const chipClassName = () => {
    return emotionCss.css\`
        border: 2px dashed orange;
        background: orange;
    \`;
};

const descriptionClassName = () => {
    return emotionCss.css\`
        border: 2px dashed yellow;
    \`;
};

const labelClassName = () => {
    return emotionCss.css\`
        border: 2px dashed green;
    \`;
};

const defaultItems = [
    { id: '1', text: 'Mario' },
    { id: '2', text: 'Luigi' },
    { id: '3', text: 'Peach' },
    { id: '5', text: 'Yoshi' },
    { id: '6', text: 'Toad' },
];

const Demo = () => {
    const [items, setItems] = React.useState(defaultItems);

    return (
        <varnishUi.Stack spacing={16}>
            <varnishUi.ChipGroup
                label="Label: Pick a MarioKart character"
                description="Description: Toad is the best choice tbh"
                className={emotionCss.cx(className())}
                chipClassName={emotionCss.cx(chipClassName())}
                labelClassName={emotionCss.cx(labelClassName())}
                descriptionClassName={emotionCss.cx(descriptionClassName())}
                items={items} />
        </varnishUi.Stack>
    );
};

render(<Demo />);

`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;