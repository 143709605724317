
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-theme',
  type: 'Components',
  title: 'Icons',
  cols: 1,
  iconSrc: './logos-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const IconGrid = makeShortcode("IconGrid");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Icons`}</h2>
    <p>{`Varnish-Theme has the ability to export icons. Here are your options for importing them:`}</p>
    <ol>
      <li parentName="ol">{`You can import the .svg file directly.`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`import spark from '@allenai/varnish-theme/icons/spark.svg';
<img src={spark}>
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`You can import the generated base64 string.`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`import assets_icons from '@allenai/varnish-theme/assets_icons.json';
const base64String = assets_icons.spark;
<img src={\`<img src="data:image/svg+xml;base64,\${base64String}\`} />
`}</code></pre>
    <p>{`Archetype recommends widths of 22px for square icons, and 24px for circular icons.`}</p>
    <h2>{`List of Icons`}</h2>
    <IconGrid mdxType="IconGrid" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;