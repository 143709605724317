
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Required'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To make required, pass in prop `}<inlineCode parentName="p">{`isRequired`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const exampleItems = ['Cat', 'Dog', 'Alligator', 'Llama', 'Lizard', 'Frog'];
render(
    <form>
        <varnishUi.Stack spacing={16}>
            <varnishUi.Select
                items={exampleItems}
                isRequired
                placeholder="Please select an animal"
            />
            <varnishUi.Button variant="contained" type="submit">
                Submit
            </varnishUi.Button>
        </varnishUi.Stack>
    </form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;