
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Custom Content With AI2 Privacy and Terms of Use Links'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Footer with custom content using AI2's default privacy and terms of use links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import varnishComponents from '@allenai/varnish2/components';
import ThemeMui from '@allenai/varnish2/theme';

const Demo = () => {
    return (
        <div style={{ width: '100%' }}>
            <varnishComponents.Footer backgroundColor={ThemeMui.color.G4}>
                Custom footer content - 🦶🏻 🦶🏼 🦶🏽 🦶🏾 🦶🏿
                <varnishComponents.FooterLinkSeparator />
                <varnishComponents.PrivacyLink />
                <varnishComponents.FooterLinkSeparator />
                <varnishComponents.TermsOfUseLink />
            </varnishComponents.Footer>
        </div>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;