
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Offset & Cross Offset'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The tooltip offers offset to create more space between the element and its anchor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing="16">
        <varnishUi.PopoverTrigger>
            <varnishUi.Button>Offset vertical (main axis)</varnishUi.Button>
            <varnishUi.Popover heading="heading" offset={50}>
                <p>Content</p>
            </varnishUi.Popover>
        </varnishUi.PopoverTrigger>
        <varnishUi.PopoverTrigger>
            <varnishUi.Button>Offset horizontal (cross axis)</varnishUi.Button>
            <varnishUi.Popover heading="heading" crossOffset={50}>
                <p>Lorum ipsum sit amet dolar</p>
            </varnishUi.Popover>
        </varnishUi.PopoverTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;