
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`CodeBlock by default needs code as a string and a coding language you want to render.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const blockCode = \`
  def get_metrics(self, reset: bool = False) -> Dict[str, float]
  \`;

render(<varnishUi.CodeBlock value={blockCode} language="python" />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;