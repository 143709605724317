import * as Default from './demo/default.mdx';
import * as Delete from './demo/delete.mdx';
import * as Sizes from './demo/sizes.mdx';
import * as Shapes from './demo/shapes.mdx';
import * as Colors from './demo/colors.mdx';
import * as Variants from './demo/variants.mdx';
import * as Select from './demo/select.mdx';
import * as StylingCSS from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const ChipExamples = [
    Default,
    Variants,
    Delete,
    Sizes,
    Shapes,
    Colors,
    Select,
    StylingCSS,
    StylingStyled,
];
