import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const fileInputRecipe = sva({
    slots: ['root', 'button', 'icon', 'text'],
    base: {
        root: {}, // this is the div provided by DropZone.
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1',
            width: '[fit-content]',
            boxSizing: 'border-box',
            fontFamily: 'body',
            margin: '0',
            border: '[1px solid]',
            borderRadius: 'sm',
            _hover: {
                _notDisabled: {
                    borderColor: 'green.100',
                },
            },
            _disabled: {
                cursor: 'not-allowed',
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
            },

            // default light styles
            backgroundColor: '[white]',
            borderColor: '[white]',
            color: 'text.primary',
            _dark: {
                backgroundColor: 'background',
                borderColor: 'background',
                color: 'text.primary',
                _disabled: {
                    cursor: 'not-allowed',
                    backgroundColor: 'gray.20',
                    borderColor: 'gray.40',
                },
            },
        },
        icon: {
            _groupDisabled: {
                color: 'dark-teal.100',
                stroke: 'gray.80',
            },
        },
        text: {
            _groupDisabled: {
                color: 'gray.80',
            },
        },
    },
    // Variants
    variants: {
        iconOnly: {
            false: {},
            true: {
                button: {
                    padding: '[0]',
                    border: '[none]',
                    cursor: 'pointer',
                    backgroundColor: '[transparent]',
                    _dark: {
                        backgroundColor: '[transparent]',
                    },
                },
            },
        },
        variant: {
            contained: {}, // default
            outlined: {
                button: {
                    border: '[1px solid]',
                    borderColor: 'dark-teal.20',
                    _dark: {
                        borderColor:
                            '[color-mix(in srgb, {colors.cream.100} 20%, {colors.background})]',
                        color: 'text.primary',
                    },
                },
            },
        },
        size: {
            small: {
                button: {
                    padding: '[6px 12px]',
                    fontSize: '[12px]',
                },
            },
            medium: {
                button: {
                    padding: '[12px 24px]',
                    fontSize: '[14px]',
                },
            },
            large: {
                button: {
                    padding: '[24px 36px]',
                    fontSize: '[16px]',
                },
            },
            full: {
                root: {
                    width: '[100%]',
                    height: '[100%]',
                },
                button: {
                    padding: '[12px 24px]',
                    fontSize: '[14px]',
                    width: '[100%]',
                    height: '[100%]',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'outlined',
        size: 'medium',
    },
});

type FileInputRecipeProps = RecipeVariantProps<typeof fileInputRecipe>;

export { fileInputRecipe, fileInputRecipe as default };
export type { FileInputRecipeProps };
