import { cva, RecipeVariantProps } from '@allenai/varnish-panda-runtime/css';

/**
 * @remarks A label recipe
 *
 * @param size - Size of the label, values are `small`, `medium` (default), and `large`
 *
 */

const labelRecipe = cva({
    base: {
        cursor: 'default',
        color: 'text.primary',
        _groupDisabled: {
            color: '[#343434]', // gray.40 -- https://github.com/allenai/varnish/issues/1026
        },
        _groupInvalid: {
            color: 'alert.error',
        },
    },
    variants: {
        size: {
            small: {
                fontSize: '[12px]',
            },
            medium: {
                fontSize: 'sm',
            },
            large: {
                fontSize: 'md',
            },
        },
    },
    defaultVariants: {
        size: 'medium',
    },
});

type LabelRecipeProps = RecipeVariantProps<typeof labelRecipe>;

export { labelRecipe, labelRecipe as default };
export type { LabelRecipeProps };
