import { Form as AriaForm, FormProps as AriaFormProps } from 'react-aria-components';

import formRecipe, { FormRecipeProps } from './form.styles';

type FormProps = {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    onReset?: () => void;
    className?: string;
} & AriaFormProps &
    FormRecipeProps;

function Form({ className, onSubmit, onReset, children, ...rest }: FormProps) {
    const [localProps] = formRecipe.splitVariantProps(rest);

    return (
        <AriaForm {...localProps} className={className} onSubmit={onSubmit} onReset={onReset}>
            {children}
        </AriaForm>
    );
}

export { Form, Form as default };
export type { FormProps };
