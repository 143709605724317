
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Disabled'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The `}<inlineCode parentName="p">{`isDisabled`}</inlineCode>{` prop fully disables the entire RadioGroup, preventing any user interaction.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`isDisabled`}</inlineCode>{` prop can be set on an individual Radio component to disable it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={48}>
        <varnishUi.RadioGroup isDisabled label="All values disabled" defaultValue="baseball">
            <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>

        <varnishUi.RadioGroup label="Disabling a single value" defaultValue="baseball">
            <varnishUi.Radio isDisabled value="soccer">
                Soccer
            </varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;