
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

const CustomLoadingSpinner = mui.styled(varnishUi.LoadingSpinner)\`
     & svg circle {
        fill: purple;
        stroke: teal;
    }
\`;

render(<CustomLoadingSpinner size="large" />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;