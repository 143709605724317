'use client';

import { Link, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import React from 'react';

const ELLIPSIS = '…';

interface TextTruncatorProps extends TypographyProps {
    children: string;
    charLimit?: number;
    showMore?: string;
    showLess?: string;
    expandedByDefault?: boolean;
}

export function TextTruncator({
    children: text,
    charLimit = 200,
    showMore = 'Expand',
    showLess = 'Collapse',
    expandedByDefault,
    ...typographyProps
}: TextTruncatorProps) {
    const [showFullText, setShowFullText] = React.useState(expandedByDefault);

    if (charLimit >= text.length) {
        <Typography {...typographyProps}>{text}</Typography>;
    }

    const substringEnd = showFullText
        ? text.length
        : text.substring(0, charLimit - ELLIPSIS.length).search(/ [^ ]*$/);
    return (
        <Typography {...typographyProps}>
            {text.substring(0, substringEnd)}
            {!showFullText ? ELLIPSIS : null}
            &nbsp;
            <Link
                component="button"
                variant={typographyProps.variant}
                onClick={() => {
                    setShowFullText(!showFullText);
                }}
                sx={{ verticalAlign: 'top' }}>
                {showFullText ? showLess : showMore}
            </Link>
        </Typography>
    );
}
