
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { LogoExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Logo',
  cols: 2,
  iconSrc: './text-14px.svg',
  tag: {
    label: 'Alpha',
    color: 'tertiary'
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const AlphaMsg = makeShortcode("AlphaMsg");
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <React.Fragment>
    <AlphaMsg mdxType="AlphaMsg" />
    </React.Fragment>
    <h2>{`Logo`}</h2>
    <p>{`Logos are components wrapping an SVG, the size by default will fill the width of the container.
The color defaults to `}<inlineCode parentName="p">{`primary`}</inlineCode>{` color (`}<inlineCode parentName="p">{`accent.primary`}</inlineCode>{`).`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={LogoExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`color?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`primary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`secondary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`tertiary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`CSSColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of icon, `}<inlineCode parentName="td">{`undefined`}</inlineCode>{` or `}<inlineCode parentName="td">{`false`}</inlineCode>{` will set no color`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;