import {
    Input as AriaInput,
    InputProps as AriaInputProps,
    TextFieldProps as AriaTextFieldProps,
    TextField as AriaTextField,
    ValidationResult,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { inputRecipe, InputRecipeProps } from './input.styles';
import focusRing from '@/components/shared/focusRing.styles';
import { Label } from '@/components/field/label/Label';
import { FieldError } from '@/components/field/fieldError/FieldError';
import { Description } from '../description/Description';

type InputProps = {
    label?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    description?: string;
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    errorClassName?: string;
    descriptionClassName?: string;
} & AriaInputProps &
    AriaTextFieldProps &
    InputRecipeProps;

const Input = ({
    label,
    errorMessage,
    description,
    className,
    inputClassName,
    labelClassName,
    errorClassName,
    descriptionClassName,
    ...rest
}: InputProps) => {
    const [variantProps, localProps] = inputRecipe.splitVariantProps(rest);
    const recipeClassNames = inputRecipe(variantProps);
    const { size } = rest;
    return (
        <AriaTextField className={cx('group', recipeClassNames.root, className)} {...localProps}>
            <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                {label}
            </Label>
            <AriaInput className={cx(css(focusRing), recipeClassNames.input, inputClassName)} />
            <Description
                size={size}
                className={cx(recipeClassNames.description, descriptionClassName)}>
                {description}
            </Description>
            <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                {errorMessage}
            </FieldError>
        </AriaTextField>
    );
};

export { Input };
export type { InputProps };
