'use client'; // for server side rendering

import { AppBar, AppBarProps, Toolbar, styled } from '@mui/material';
import React, { CSSProperties, DetailedHTMLProps, ElementRef, useRef } from 'react';

import { Content as VContent } from '../Content';
import { AI2Banner } from './AI2Banner';
import { AppName } from './AppName';
import { AppTagline } from './AppTagline';
import { Logo } from './Logo';
import { useSmartAI2Banner } from './useSmartAI2Banner';

const Content = styled(VContent)`
    padding-top: 0;
    padding-bottom: 0;
`;

const StyledAppBar = styled(AppBar)<AppBarProps & { top: CSSProperties['top'] }>`
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.text.primary};
    size: ${({ theme }) => theme.font.size['3xl']};
    box-shadow: 0px ${({ theme }) => theme.spacing(0.5, 2)} rgba(10, 41, 57, 0.08);
    transition: top 200ms ease-in-out;
    top: ${({ top }) => top}px;
`;

interface HeaderProps extends Omit<AppBarProps, 'ref' | 'position'> {
    bannerAlwaysVisible?: boolean;
    customBanner?: React.ReactElement;
    containerRef?: Parameters<typeof useSmartAI2Banner>[0]['containerRef'];
}

function HeaderComponent({
    children,
    customBanner: Banner = <AI2Banner />,
    bannerAlwaysVisible = false,
    containerRef,
    ...appBarProps
}: HeaderProps): JSX.Element {
    const bannerRef = useRef<ElementRef<'div'>>(null);
    const appBarRef = useRef<ElementRef<'div'> | ElementRef<'header'>>(null);

    const topOffset = useSmartAI2Banner({
        containerRef,
        appBarRef,
        bannerRef,
        bannerAlwaysVisible,
    });

    return (
        <StyledAppBar {...appBarProps} position="sticky" top={topOffset} ref={appBarRef}>
            <div ref={bannerRef}>{Banner}</div>
            {children ? (
                <Toolbar disableGutters>
                    <Content>{children}</Content>
                </Toolbar>
            ) : null}
        </StyledAppBar>
    );
}

interface ColumnsProps
    extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    columns?: string;
}

const Columns = styled(({ columns, ...rest }: ColumnsProps) => <div {...rest} />)`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
    grid-row-gap: ${({ theme }) => theme.spacing(2)};
    width: 100%;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(1.5)} 0;
`;

const MenuColumn = styled('div')`
    justify-self: right;
    line-height: 77px;

    a:hover {
        text-decoration: none;
    }

    ul {
        margin: -${({ theme }) => theme.spacing(1.5)} 0;
    }
`;

/**
 * Merge in the components that are tightly coupled to the <Header>,
 * so that they can be used like:
 *  <Header>
 *      <Header.Columns>
 *          ...
 *      </Header.Columns>
 *  </Header>
 */
// eslint-disable-next-line import/prefer-default-export
export const Header = Object.assign(HeaderComponent, {
    AI2Banner,
    AppName,
    AppTagline,
    Columns,
    Logo,
    MenuColumn,
});
