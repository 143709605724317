
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Drag and drop with Image'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A FileDropItem references a file on the user's device. It includes the name and mime type of the file, and methods to read the contents as plain text, or retrieve a native File object which can be attached to form data for uploading.`}</p>
    <p>{`This example accepts JPEG and PNG image files, and renders them by creating a local object URL. When the list is empty, you can drop on the whole collection, and otherwise items can be inserted.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import varnishUi from "@allenai/varnish-ui";

interface ImageItem {
  id: number,
  url: string,
  name: string,
  type: string,
  lastModified: number
}

const Demo = () => {
    let [items, setItems] = React.useState<ImageItem[]>([]);

    let { dragAndDropHooks } = varnishUi.useDragAndDrop({
        acceptedDragTypes: ['image/jpeg', 'image/png'],
        async onRootDrop(e) {
            let items = await Promise.all(
                e.items.filter(varnishUi.isFileDropItem).map(async item => {
                    let file = await item.getFile();
                    return {
                        id: Math.random(),
                        url: URL.createObjectURL(file),
                        name: item.name,
                        type: file.type,
                        lastModified: file.lastModified
                    };
                })
            );
            setItems(items);
        }
    });

    return (
        <varnishUi.Table
            aria-label="Droppable table"
            dragAndDropHooks={dragAndDropHooks}>
            <varnishUi.TableHeader>
                <varnishUi.Column>Image</varnishUi.Column>
                <varnishUi.Column isRowHeader>Name</varnishUi.Column>
                <varnishUi.Column>Type</varnishUi.Column>
                <varnishUi.Column>Last Modified</varnishUi.Column>
            </varnishUi.TableHeader>
            <varnishUi.TableBody items={items} renderEmptyState={() => 'Drop images here'}>
                {item => (
                    <varnishUi.Row>
                        <varnishUi.Cell><img src={item.url} /></varnishUi.Cell>
                        <varnishUi.Cell>{item.name}</varnishUi.Cell>
                        <varnishUi.Cell>{item.type}</varnishUi.Cell>
                        <varnishUi.Cell>{new Date(item.lastModified).toLocaleString()}</varnishUi.Cell>
                    </varnishUi.Row>
                )}
            </varnishUi.TableBody>
        </varnishUi.Table>
  );
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;