
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { SocialShareButtonsExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'Social Share Buttons',
  cols: 2,
  iconSrc: './buttons-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Social Share Buttons`}</h2>
    <p>{`Social Share Buttons to add to your Varnish App.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`When you want to add a link to a social site to your applocation.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={SocialShareButtonsExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;