import { Switch as AriaSwitch } from 'react-aria-components';
import type { SwitchProps as AriaSwitchProps } from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { groupFocusRing } from '../shared/focusRing.styles';
import switchRecipe, { SwitchRecipeProps } from '@/components/switch/switch.styles';
import { FieldError } from '../field/fieldError/FieldError';
import { Description } from '../field/description/Description';

/**
 *  There is no native HTML element with switch styling. <input type="checkbox"> is the closest
 *  semantically, but isn't styled or exposed to assistive technology as a switch. Switch helps
 *  achieve accessible switches that can be styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Switch.html
 */

type SwitchProps = {
    description?: string;
    errorMessage?: string;
    className?: string;
    trackClassName?: string;
    thumbClassName?: string;
    errorClassName?: string;
    descriptionClassName?: string;
} & AriaSwitchProps &
    SwitchRecipeProps;

const Switch = ({
    children,
    description,
    errorMessage,
    className,
    trackClassName,
    thumbClassName,
    errorClassName,
    descriptionClassName,
    ...rest
}: SwitchProps) => {
    const [variantProps, localProps] = switchRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = switchRecipe(variantProps);
    const { size } = rest;

    return (
        // we need to wrap the `raw` focusRing and `raw` recipeClassNames.root in `css()` to merge them
        // there is no garauntee which class will take precidence
        <AriaSwitch {...localProps} className={cx('group', recipeClassNames.root, className)}>
            <>
                <div className={cx(css(groupFocusRing), recipeClassNames.track, trackClassName)}>
                    <div className={cx(recipeClassNames.thumb, thumbClassName)} />
                </div>
                {children}
            </>
            <Description
                size={size}
                className={cx(recipeClassNames.description, descriptionClassName)}>
                {description}
            </Description>
            <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                {errorMessage}
            </FieldError>
        </AriaSwitch>
    );
};

export { Switch, Switch as default };
export type { SwitchProps };
