'use client'; // for server side rendering

import { styled } from '@mui/material';

// we set a name here so we can refer to the component's css class later.
// [class$="-${AppNameTextOpts.name}-${AppNameTextOpts.slot}"]
export const AppNameTextOpts = { name: 'AppNameText', slot: 'root' };
const AppNameText = styled('h3', AppNameTextOpts)`
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ theme }) => theme.font.size['3xl']};
    color: ${({ theme }) => theme.color.N9.hex};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: ${({ theme }) => theme.font.size['2xl']};
    }
`;

export function AppName({ children }: { children: string }) {
    if (children.length > 24) {
        console.warn('The contents of `<AppName>` should be fewer than 25 characters');
    }
    return <AppNameText>{children}</AppNameText>;
}
