
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Description'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The `}<inlineCode parentName="p">{`description`}</inlineCode>{` allows you to add supplementary help text to a radio group, providing users with additional context and guidance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.RadioGroup
        label="Favorite sports"
        defaultValue="baseball"
        description="Select your favorite sport">
        <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
        <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
        <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
    </varnishUi.RadioGroup>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;