
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To customize switch colors, apply the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" align="center" spacing={2}>
        <varnishUi.Switch color="primary" defaultSelected />
        <varnishUi.Switch color="secondary" defaultSelected />
        <varnishUi.Switch color="tertiary" defaultSelected />
        <varnishUi.Switch color="teal" defaultSelected />
        <varnishUi.Switch color="cream" defaultSelected />
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;