
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { HowDoIExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Patterns',
  title: 'How Do I…',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How Do I...`}</h2>
    <p>{`This is documentation for how to utilize Varnish to put together different components for specific scenarios. Such as…`}</p>
    <Examples cols={frontMatter.cols} examples={HowDoIExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;