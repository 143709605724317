import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 *  Radio can be built with the <input> HTML element, but this can be difficult to style.
 *   Radio helps achieve accessible radio inputs that can be styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/RadioGroup.html
 */

/**
 * @remarks A radio recipe
 *
 * @param size - Size of the radio, values are `small`, `medium` (default), and `large`
 * @param color - Color of the radio, values are:
 *   `primary` (default), `secondary`, `tertiary`, `teal`, and `cream`
 */

const radioRecipe = sva({
    slots: ['root', 'circle'],
    base: {
        root: {
            display: 'flex',
            gap: '[8px]',
            alignItems: 'center',
            cursor: 'pointer',
            _disabled: {
                cursor: 'not-allowed',
            },
            _componentReadOnly: {
                cursor: 'default',
            },
        },
        circle: {
            boxSizing: 'border-box',
            position: 'relative',
            width: '[20px]',
            height: '[20px]',
            flexShrink: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '[1px solid]',
            borderColor: 'teal.80',
            borderRadius: 'full',
            backgroundColor: '[white]',
            _before: {
                content: '""',
                display: 'block',
                aspectRatio: '1',
                borderRadius: 'full',
            },
            _groupHover: {
                borderColor: 'teal.100',
            },
            _groupSelected: {
                backgroundColor: '[currentColor]',
                // this overrides other states (namely hover)
                '&': {
                    borderColor: '[transparent]',
                },
                _before: {
                    backgroundColor: '[white]',
                },
            },
            _groupDisabled: {
                color: 'gray.40',
                // this overrides other states (namely hover)
                '&': {
                    borderColor: '[currentColor]',
                },
                _before: {
                    backgroundColor: '[white]',
                },
            },
            _groupInvalid: {
                color: 'alert.error',
                borderColor: '[currentColor]',
                _before: {
                    backgroundColor: '[white]',
                },
            },
        },
    },
    variants: {
        size: {
            small: {
                root: {
                    fontSize: '[12px]',
                },
                circle: {
                    width: '[0.75rem]',
                    height: '[0.75rem]',
                    _before: {
                        width: '[0.25rem]',
                    },
                },
            },
            medium: {
                root: {
                    fontSize: 'sm',
                },
                circle: {
                    width: '[0.875rem]',
                    height: '[0.875rem]',
                    _before: {
                        width: '[0.375rem]',
                    },
                },
            },
            large: {
                root: {
                    fontSize: 'md',
                },
                circle: {
                    width: '[1.25rem]',
                    height: '[1.25rem]',
                    _before: {
                        width: '[0.5rem]',
                    },
                },
            },
        },
        color: {
            primary: {
                circle: {
                    color: 'accent.primary',
                },
            },
            secondary: {
                circle: {
                    color: 'accent.secondary',
                    _groupSelected: {
                        _before: {
                            backgroundColor: 'dark-teal.100',
                        },
                    },
                },
            },
            tertiary: {
                circle: {
                    color: 'accent.tertiary',
                },
            },
            teal: {
                circle: {
                    color: 'teal.100',
                },
            },
            cream: {
                circle: {
                    color: 'cream.100',
                    borderColor: '[white]',
                    _groupSelected: {
                        _before: {
                            backgroundColor: 'dark-teal.100',
                        },
                    },
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        color: 'primary',
    },
});

type RadioRecipeProps = RecipeVariantProps<typeof radioRecipe>;

export { radioRecipe, radioRecipe as default };
export type { RadioRecipeProps };
