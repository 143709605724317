
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Size'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The modal wil size to the content, setting a `}<inlineCode parentName="p">{`size`}</inlineCode>{` property will set a max-width on it.
And adding a `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{` property will ensure it fills that size.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const ScrollingDemo = () => {
    const [limitHeight, setLimitHeight] = React.useState(true);

    return (
        <varnishUi.Modal
            isDismissable
            heading="Modal Heading"
            closeButton={false}
            style={limitHeight ? { maxHeight: '500px' } : undefined}
            buttons={
                <>
                    <varnishUi.Switch
                        onChange={setLimitHeight}
                        isSelected={limitHeight}
                        size="large">
                        Limit Height
                    </varnishUi.Switch>
                    <varnishUi.DialogCloseButton variant="text">Close</varnishUi.DialogCloseButton>
                </>
            }
            fullWidth>
            <Lipsum />
        </varnishUi.Modal>
    );
};

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Small size (one)</varnishUi.Button>
            <varnishUi.Modal isDismissable heading="Modal Heading" color="reversed" size="small">
                <p>Modal is fit to contents</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Small size (two)</varnishUi.Button>
            <varnishUi.Modal isDismissable heading="Modal Heading" color="reversed" size="small">
                <p>Modal is fit to contents, but will max out the size because of the content</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Medium modal w/ fullWidth</varnishUi.Button>
            <varnishUi.Modal
                isDismissable
                heading="Modal Heading"
                color="reversed"
                size="medium"
                fullWidth>
                <p>This modal fills the \`size\`</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Modal scrolling</varnishUi.Button>
            <ScrollingDemo />
        </varnishUi.ModalTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;