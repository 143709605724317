
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'How do I set styles on components?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`We recommend transitioning to `}<inlineCode parentName="p">{`mui-styled`}</inlineCode>{`. `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` will still function with Varnish if
you include a wrapper. However, we suggest moving away from styled components to stay consistent with other projects using Varnish within AI2.
Note that we don’t advise using the outer method pattern because it leads to a loss of syntax
highlighting. Instead, we recommend the following approach:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`const MenuColumn = styled('div')\`
    margin: \${({ theme }) => theme.spacing(1.5)} 0;
    display: inline-block;
    vertical-align: top;
    width: 140px;
    &:hover {
        width: 100%;
    }
\`;
`}</code></pre>
    <p>{`You can also use object syntax to define styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`const MenuColumn = styled('div')(({ theme }) => ({
    margin: \`\${theme.spacing(1.5)} 0\`,
    display: 'inline-block',
    verticalAlign: 'top',
    width: '140px',
    '&:hover': {
        width: '100%',
    },
}));
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;