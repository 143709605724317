
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Patterns',
  title: 'Menu',
  cols: 1,
  iconSrc: './links-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Menu`}</h2>
    <p>{`Describes how to create a proper menu per Skiff Template pattern.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`When your app has sub pages. This pattern is used to navigate between pages.`}</p>
    <h3>{`Pattern`}</h3>
    <p>{`To achieve a menu like the one on `}<a parentName="p" {...{
        "href": "https://skiff-template.apps.allenai.org/"
      }}>{`Skiff Template`}</a>{`, you can use the following structure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import React, { useState } from 'react';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import {
    Toolbar,
    Box,
    List,
    IconButton,
    Drawer,
    ListItem,
    ListItemButton,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const theme = useTheme();
const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));

const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        Component: Home,
    },
    {
        path: '/about',
        label: 'About',
        Component: About,
    },
];

// when we are on a breakpoint greater than md, show a toolbar, else show a drawer.
<Toolbar variant="dense">
    {greaterThanMd ? (
        <>
            {ROUTES.map(({ path, label }) => (
                <Link key={path} to={path}>
                    <ListItemButton selected={location.pathname === path}>{label}</ListItemButton>
                </Link>
            ))}
        </>
    ) : (
        <>
            {ROUTES.length > 1 ? (
                <Box component="nav">
                    <IconButton edge="end" onClick={handleMenuToggle}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        variant="temporary"
                        anchor="right"
                        open={menuOpen}
                        onClose={handleMenuToggle}
                        ModalProps={{keepMounted: true, // Better open performance on mobile}}
                    >
                        <Menu>
                            {ROUTES.map(({ path, label }) => (
                                <ListItem key={path} disablePadding>
                                    <Link to={path} onClick={handleMenuToggle}>
                                        <DrawerMenuButton selected={location.pathname === path}>
                                        {label}
                                        </DrawerMenuButton>
                                    </Link>
                                </ListItem>
                            ))}
                        </Menu>
                    </Drawer>
                </Box>
            ) : null}
        </>
    )}
</Toolbar>;

const Menu = styled(List)\`
    && {
        margin-top: \${({ theme }) => theme.spacing(3)};
    }
\`;

const DrawerMenuButton = styled(ListItemButton)\`
    && {
        min-width: 180px;
        max-width: 240px;
    }
\`;

`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;