
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.`}</p>
    <p><inlineCode parentName="p">{`color`}</inlineCode>{` controls the fill color and `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` controls the size.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const VarnishCopyIcon = varnishUi.varnishIcon(ContentCopyIcon);

const redClassName = emotionCss.css\`
    color: darkred;
\`;

const largeClassName = emotionCss.css\`
    font-size: 32px;
\`;

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Stack spacing={16}>
                <varnishUi.Icons.Spark className={redClassName} size="large" />
                <varnishUi.Label>vUI sprite overriding color</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <VarnishCopyIcon className={redClassName} size="large" />
                <varnishUi.Label>MUI varnished overriding color</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack spacing={16}>
                <varnishUi.Icon className={redClassName} size="large">
                    <ContentCopyIcon />
                </varnishUi.Icon>
                <varnishUi.Label>MUI wrapped overriding color</varnishUi.Label>
            </varnishUi.Stack>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Stack spacing={16}>
                <varnishUi.Icons.Spark className={largeClassName} color="secondary" />
                <varnishUi.Label>vUI sprite overriding size</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack spacing={16}>
                <VarnishCopyIcon className={largeClassName} color="secondary" />
                <varnishUi.Label>MUI varnished overriding size</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack spacing={16}>
                <varnishUi.Icon className={largeClassName} color="secondary">
                    <ContentCopyIcon />
                </varnishUi.Icon>
                <varnishUi.Label>MUI wrapped overriding size</varnishUi.Label>
            </varnishUi.Stack>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;