
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from "@allenai/varnish-ui";
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data = Object.fromEntries(new FormData(e.currentTarget));
    alert(JSON.stringify(data));
};

const onReset = () => {
    alert('Reset Button clicked');
};

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
        padding: 10px;
    \`;
};

render(
    <varnishUi.Form onSubmit={onSubmit} onReset={onReset} className={emotionCss.cx(className())}>
        <varnishUi.Stack spacing={4}>
            <varnishUi.Stack direction="row" spacing={16}>
                <label>Username</label>
                <varnishUi.Input color="primary" placeholder="Please enter your name" name="username" />
            </varnishUi.Stack>
            <varnishUi.Stack direction="row" spacing={16}>
                <label>Email</label>
                <varnishUi.Input color="primary" placeholder="Please enter your email" name="email" />
            </varnishUi.Stack>
            <varnishUi.Stack direction="row" spacing={8}>
                <varnishUi.Button type="submit" variant="outlined">
                    Submit
                </varnishUi.Button>
                <varnishUi.Button type="reset" variant="outlined">
                    Reset
                </varnishUi.Button>
            </varnishUi.Stack>
        </varnishUi.Stack>
    </varnishUi.Form>
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;