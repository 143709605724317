
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'ResponsiveHeatMap with LightTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of the Nivo ResponsiveHeatMap component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { heatMapDefaults } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsiveHeatMap
            // add in varnish theme at top of all charts
            // must use heatMapDefaults to get colors to properly work for heatmap
            {...heatMapDefaults}
            // add options specific to your chart
            // use varnish values when it makes sense
            margin={{
                top: 56,
                right: 16,
                bottom: 16,
                left: 56,
            }}
            axisTop={{
                tickRotation: -25,
                legend: 'Food',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            axisLeft={{
                legend: 'Country',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            // add data
            data={[
                {
                    id: 'AD',
                    data: [
                        {
                            x: 'hotdog',
                            y: 10,
                        },
                        {
                            x: 'burger',
                            y: 20,
                        },
                    ],
                },
                {
                    id: 'AE',
                    data: [
                        {
                            x: 'hotdog',
                            y: 30,
                        },
                        {
                            x: 'burger',
                            y: 40,
                        },
                    ],
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;