import * as Default from './demo/default.mdx';
import * as Color from './demo/color.mdx';
import * as Variant from './demo/variant.mdx';
import * as Icon from './demo/icon.mdx';
import * as Action from './demo/action.mdx';
import * as Title from './demo/title.mdx';
import * as stylingCSS from './demo/stylingCss.mdx';
import * as stylingStyled from './demo/stylingStyled.mdx';

export const AlertExamples = [
    Default,
    Color,
    Variant,
    Icon,
    Action,
    Title,
    stylingCSS,
    stylingStyled,
];
