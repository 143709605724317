
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Sizes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`LoadingSpinner has size prop, which accepts `}<inlineCode parentName="p">{`small, medium, large, full`}</inlineCode>{`. Full will take up 100% of parent container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={8}>
        <varnishUi.Stack>
            <varnishUi.LoadingSpinner size="small" />
            <varnishUi.Label>small - 16px</varnishUi.Label>
        </varnishUi.Stack>
        <varnishUi.Stack>
            <varnishUi.LoadingSpinner size="medium" />
            <varnishUi.Label>medium - 32px</varnishUi.Label>
        </varnishUi.Stack>
        <varnishUi.Stack>
            <varnishUi.LoadingSpinner size="large" />
            <varnishUi.Label>large - 64px</varnishUi.Label>
        </varnishUi.Stack>
        <varnishUi.Stack>
            <div
                style={{
                    width: '128px',
                    height: '128px',
                    margin: '0 auto',
                    border: '2px solid gray',
                }}>
                <varnishUi.LoadingSpinner size="full" />
            </div>
            <varnishUi.Label>full - 100%</varnishUi.Label>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;