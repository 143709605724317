import { color, Color } from '../../theme/colors';

export interface AI2MarkTextProps {
    width?: string | number;
    height?: string | number;
    color?: string | Color;
    viewBox?: string;
    className?: string;
}

/**
 * Component that renders the text portion of the AI2 Logo.
 * TODO (@codeviking): If this uses Lato, we should just render the text.
 */
export function AI2MarkText(props: AI2MarkTextProps) {
    const myColor = `${props.color || color.B6}`;
    const viewBox = props.viewBox || '74 0 190 30';
    return (
        <svg
            viewBox={viewBox}
            width={props.width}
            height={props.height}
            role="img"
            className={props.className}>
            <title>Allen Institute for AI</title>
            <path
                style={{ fill: myColor }}
                d="M89.8,23.1h-1.7c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.4l-1.5-3.8h-7.1l-1.5,3.8c0,0.1-0.1,0.3-0.3,0.4
		           c-0.1,0.1-0.3,0.2-0.5,0.2H75l6.3-15.8h2.2L89.8,23.1z M85.3,17.2l-2.5-6.4c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.3-0.2-0.5-0.2-0.8
		           c-0.1,0.5-0.3,1-0.5,1.5l-2.5,6.4H85.3z"
            />
            <path style={{ fill: myColor }} d="M93.2,6.8v16.2h-2V6.8H93.2z" />
            <path style={{ fill: myColor }} d="M97.8,6.8v16.2h-2V6.8H97.8z" />
            <path
                style={{ fill: myColor }}
                d="M109.6,21.5c-0.2,0.3-0.5,0.6-0.9,0.8c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.2,0.3
		          c-0.4,0.1-0.8,0.1-1.2,0.1c-0.7,0-1.5-0.1-2.1-0.4c-0.6-0.3-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1.2-1.1-1.9c-0.3-0.8-0.4-1.7-0.4-2.6
		          c0-0.7,0.1-1.5,0.4-2.2c0.2-0.6,0.6-1.2,1-1.8c0.5-0.5,1-0.9,1.6-1.2c0.7-0.3,1.4-0.4,2.2-0.4c0.6,0,1.3,0.1,1.9,0.3
		          c0.6,0.2,1.1,0.5,1.5,1c0.4,0.4,0.8,1,1,1.6c0.2,0.7,0.4,1.4,0.4,2.1c0,0.2,0,0.4-0.1,0.6c0,0.1-0.1,0.1-0.2,0.1
		          c-0.1,0-0.1,0-0.2,0h-7.5c0,0.6,0.1,1.2,0.3,1.8c0.1,0.5,0.4,0.9,0.7,1.3c0.3,0.3,0.7,0.6,1.1,0.8c0.5,0.2,0.9,0.3,1.4,0.3
		          c0.4,0,0.9,0,1.3-0.2c0.3-0.1,0.6-0.2,0.9-0.4c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0
		          c0.1,0,0.1,0.1,0.2,0.1L109.6,21.5z M108,16.3c0-0.4-0.1-0.8-0.2-1.3c-0.1-0.4-0.3-0.7-0.6-1c-0.2-0.3-0.6-0.5-0.9-0.6
		          c-0.6-0.2-1.2-0.3-1.8-0.2c-0.6,0.1-1.2,0.4-1.6,0.8c-0.6,0.6-0.9,1.4-1,2.3L108,16.3z"
            />
            <path
                style={{ fill: myColor }}
                d="M113.7,13.5c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.7-0.1,1.1-0.1
                 c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.7,1.3c0.2,0.6,0.2,1.1,0.2,1.7v7.1h-2v-7.1c0-0.7-0.2-1.4-0.6-2
                 c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.1-0.6-0.2-1-0.1c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.3-1,0.7-1.4,1.1v8.2h-2V11.9h1.2
                 c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3L113.7,13.5z"
            />
            <path style={{ fill: myColor }} d="M132.4,23.1h-2.1V7.3h2.1V23.1z" />
            <path
                style={{ fill: myColor }}
                d="M137.3,13.5c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.7-0.1,1.1-0.1
		           c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.7,1.3c0.2,0.6,0.2,1.1,0.2,1.7v7.1h-2v-7.1c0-0.7-0.2-1.4-0.6-2
		           c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.1-0.6-0.2-1-0.1c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.3-1,0.7-1.4,1.1v8.2h-2V11.9h1.2
		           c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3L137.3,13.5z"
            />
            <path
                style={{ fill: myColor }}
                d="M153.7,13.7c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
		           c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.1-0.7-0.1-1-0.1c-0.3,0-0.6,0-0.9,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.3,0.3-0.4,0.5
		           c-0.1,0.2-0.2,0.4-0.1,0.6c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.7,0.2,1.1,0.3
		           c0.4,0.1,0.7,0.3,1.1,0.4c0.3,0.1,0.6,0.3,0.9,0.6c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.4,0.3,0.8,0.2,1.2c0,0.5-0.1,1-0.3,1.4
		           c-0.2,0.4-0.5,0.8-0.8,1.1c-0.4,0.3-0.8,0.6-1.3,0.8c-0.6,0.2-1.2,0.3-1.8,0.3c-0.7,0-1.4-0.1-2.1-0.4c-0.6-0.2-1.1-0.6-1.6-1
		           l0.5-0.7c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.3,0.8,0.3
		           c0.4,0.1,0.7,0.2,1.1,0.2c0.3,0,0.7,0,1-0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.5,0.1-0.7
		           c0-0.3-0.1-0.5-0.2-0.8c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.7-0.3-1.1-0.4
		           c-0.3-0.1-0.7-0.3-0.9-0.6c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.4-0.3-0.8-0.2-1.2c0-0.4,0.1-0.8,0.3-1.2c0.2-0.4,0.4-0.8,0.8-1
		           c0.4-0.3,0.8-0.6,1.2-0.7c0.5-0.2,1.1-0.3,1.7-0.3c0.7,0,1.3,0.1,2,0.3c0.6,0.2,1.1,0.5,1.5,1L153.7,13.7z"
            />
            <path
                style={{ fill: myColor }}
                d="M166.9,23.1h-2v-9.5h-5.8v6.7c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.2,0.4,0.3
		           c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1
		           c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1l0.6,0.9c-0.4,0.3-0.8,0.6-1.2,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.7,0-1.1-0.2
		           c-0.4-0.1-0.7-0.3-0.9-0.6c-0.5-0.5-0.7-1.2-0.7-2.1v-6.8h-1.3c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.8
		           l1.8-0.2l0.5-3.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h1v3.8h7.7V23.1z M167.4,8.3c0,0.2,0,0.4-0.1,0.5
		           c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1c-0.3-0.1-0.6-0.4-0.7-0.7
		           c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.5-0.1
		           c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.5C167.4,7.9,167.4,8.1,167.4,8.3z"
            />
            <path
                style={{ fill: myColor }}
                d="M173.1,23.2c-0.4,0-0.7,0-1.1-0.2c-0.4-0.1-0.7-0.3-0.9-0.6c-0.5-0.5-0.7-1.2-0.7-2.1v-6.8H169
		           c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.8l1.8-0.2l0.5-3.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
		           h1v3.8h3.2v1.4h-3.2v6.7c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.1
		           c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1 c0,0,0.1,0.1,0.1,0.1l0.6,0.9c-0.4,0.3-0.8,0.6-1.2,0.7C174.1,23.1,173.6,23.2,173.1,23.2L173.1,23.2z"
            />
            <path
                style={{ fill: myColor }}
                d="M186.3,11.9v11.2h-1.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3l-0.2-1.2c-0.5,0.5-1,1-1.6,1.3
		           c-0.6,0.3-1.4,0.5-2.1,0.5c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.2-0.9c-0.3-0.4-0.6-0.8-0.7-1.3c-0.2-0.6-0.2-1.1-0.2-1.7
		           v-7.1h2V19c0,0.7,0.2,1.4,0.6,2c0.2,0.2,0.5,0.4,0.8,0.6c0.3,0.1,0.6,0.2,1,0.1c0.6,0,1.1-0.1,1.6-0.4c0.5-0.3,1-0.7,1.4-1.1v-8.2
		           L186.3,11.9z"
            />
            <path
                style={{ fill: myColor }}
                d="M192.4,23.2c-0.4,0-0.7,0-1.1-0.2c-0.4-0.1-0.7-0.3-0.9-0.6c-0.5-0.5-0.7-1.2-0.7-2.1v-6.8h-1.3
		           c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.8l1.8-0.2l0.5-3.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
		           h1v3.8h3.2v1.4h-3.2v6.7c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.1
		           c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1
		           c0,0,0.1,0.1,0.1,0.1l0.6,0.9c-0.4,0.3-0.8,0.6-1.2,0.7C193.4,23.1,192.9,23.2,192.4,23.2L192.4,23.2z"
            />
            <path
                style={{ fill: myColor }}
                d="M205.3,21.5c-0.2,0.3-0.5,0.6-0.9,0.8c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.2,0.3
		           c-0.4,0.1-0.8,0.1-1.2,0.1c-0.7,0-1.5-0.1-2.1-0.4c-0.6-0.3-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1.2-1.1-1.9c-0.3-0.8-0.4-1.7-0.4-2.6
		           c0-0.7,0.1-1.5,0.4-2.2c0.2-0.6,0.6-1.2,1-1.8c0.5-0.5,1-0.9,1.6-1.2c0.7-0.3,1.4-0.4,2.2-0.4c0.6,0,1.3,0.1,1.9,0.3
		           c0.6,0.2,1.1,0.5,1.5,1c0.4,0.4,0.8,1,1,1.6c0.2,0.7,0.4,1.4,0.4,2.1c0,0.2,0,0.4-0.1,0.6c0,0.1-0.1,0.1-0.2,0.1
		           c-0.1,0-0.1,0-0.2,0h-7.5c0,0.6,0.1,1.2,0.3,1.8c0.1,0.5,0.4,0.9,0.7,1.3c0.3,0.3,0.7,0.6,1.1,0.8c0.5,0.2,0.9,0.3,1.4,0.3
		           c0.4,0,0.9,0,1.3-0.2c0.3-0.1,0.6-0.2,0.9-0.4c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0
		           c0.1,0,0.1,0.1,0.2,0.1L205.3,21.5z M203.7,16.3c0-0.4-0.1-0.8-0.2-1.3c-0.1-0.4-0.3-0.7-0.6-1c-0.2-0.3-0.6-0.5-0.9-0.6
		           c-0.6-0.2-1.2-0.3-1.8-0.2c-0.6,0.1-1.2,0.4-1.6,0.8c-0.6,0.6-0.9,1.4-1,2.3L203.7,16.3z"
            />
            <path
                style={{ fill: myColor }}
                d="M214.2,23.1v-9.5l-1.2-0.1c-0.1,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.8h1.8V11
		           c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.4-0.9,0.8-1.3c0.3-0.3,0.8-0.6,1.2-0.8c0.5-0.2,1-0.3,1.6-0.3c0.5,0,0.9,0.1,1.4,0.2l0,1
		           c0,0,0,0.1,0,0.1s0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.7,0c-0.3,0-0.6,0-0.9,0.1
		           c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.4-0.2,0.8-0.2,1.2v1h3.2v1.4h-3.2v9.5L214.2,23.1z"
            />
            <path
                style={{ fill: myColor }}
                d="M225.3,11.7c0.8,0,1.5,0.1,2.2,0.4c0.6,0.3,1.2,0.7,1.7,1.2c0.5,0.5,0.8,1.1,1.1,1.8c0.5,1.5,0.5,3.2,0,4.8
                   c-0.2,0.7-0.6,1.3-1.1,1.8c-0.5,0.5-1,0.9-1.7,1.2c-0.7,0.3-1.5,0.4-2.2,0.4c-0.8,0-1.5-0.1-2.2-0.4c-0.6-0.3-1.2-0.6-1.7-1.2
                   c-0.5-0.5-0.8-1.1-1.1-1.8c-0.5-1.5-0.5-3.2,0-4.8c0.2-0.7,0.6-1.3,1.1-1.8c0.5-0.5,1-0.9,1.7-1.2
                   C223.8,11.8,224.6,11.7,225.3,11.7L225.3,11.7z M225.3,21.7c1.1,0,1.9-0.4,2.5-1.1c0.5-0.7,0.8-1.8,0.8-3.1c0-1.3-0.3-2.4-0.8-3.1
                   c-0.5-0.7-1.4-1.1-2.5-1.1c-0.5,0-1,0.1-1.5,0.3c-0.4,0.2-0.8,0.5-1,0.8c-0.3,0.4-0.5,0.8-0.6,1.3c-0.1,0.6-0.2,1.2-0.2,1.8
                   c0,1.3,0.3,2.4,0.8,3.1C223.4,21.3,224.2,21.7,225.3,21.7L225.3,21.7z"
            />
            <path
                style={{ fill: myColor }}
                d="M234.6,14.1c0.3-0.7,0.7-1.3,1.3-1.8c0.5-0.4,1.2-0.7,1.9-0.6c0.2,0,0.5,0,0.7,0.1c0.2,0,0.4,0.1,0.6,0.2
		           l-0.1,1.5c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.3,0-0.6,0-0.9,0.2
		           c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.3-0.3,0.7-0.5,1v7.1h-2V11.9h1.1c0.2,0,0.3,0,0.4,0.1
		           c0.1,0.1,0.2,0.3,0.2,0.4L234.6,14.1z"
            />
            <path
                style={{ fill: myColor }}
                d="M259.7,23.1H258c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.4l-1.5-3.8h-7.1l-1.5,3.8c0,0.1-0.1,0.3-0.3,0.4
                   c-0.1,0.1-0.3,0.2-0.5,0.2h-1.7l6.3-15.8h2.2L259.7,23.1z M255.2,17.2l-2.5-6.4c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.3-0.2-0.5-0.2-0.8
                   c-0.1,0.5-0.3,1-0.5,1.5l-2.5,6.4H255.2z"
            />
            <path style={{ fill: myColor }} d="M263.5,23.1h-2.1V7.3h2.1V23.1z" />
        </svg>
    );
}
