
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 12,
  title: 'Patterns'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of using patterns on Nivo charts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { barDefaults, patterns } from '@allenai/varnish-nivo';
import Theme from '@allenai/varnish-theme';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsiveBar
            // add in varnish theme at top of all charts
            {...barDefaults}
            // add options specific to your chart
            // use varnish values when it makes sense
            keys={['val']}
            indexBy="id"
            margin={{
                left: 48,
                bottom: 48,
                right: 16,
                top: 32,
            }}
            axisLeft={{
                legend: 'Food',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            axisBottom={{
                legend: 'Country',
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            // define patterns
            defs={Object.values(patterns)}
            // define rules to apply those patterns
            fill={[
                { match: (d) => d.data.data.id === 'A', id: 'dots' },
                { match: (d) => d.data.data.id === 'B', id: 'lines' },
                { match: (d) => d.data.data.id === 'C', id: 'squares' },
                { match: (d) => d.data.data.id === 'D', id: 'dots2' },
                { match: (d) => d.data.data.id === 'E', id: 'lines2' },
                { match: (d) => d.data.data.id === 'F', id: 'squares2' },
                { match: (d) => d.data.data.id === 'G', id: 'gray' },
                // match all others
                { match: '*', id: 'solid' },
            ]}
            // turn off lables for clarity
            enableLabel={false}
            // add data
            data={[
                {
                    id: 'A',
                    val: 23,
                },
                {
                    id: 'B',
                    val: 80,
                },
                {
                    id: 'C',
                    val: 99,
                },
                {
                    id: 'D',
                    val: 82,
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;