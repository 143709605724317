
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from "@allenai/varnish-ui";
import * as mui from "@mui/material";

// This is an example of using mui styles ant the mui theme provider

const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data = Object.fromEntries(new FormData(e.currentTarget));
    alert(JSON.stringify(data));
};

const onReset = () => {
    alert('Reset Button clicked');
};

const CustomForm = mui.styled(varnishUi.Form)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};
    padding: 10px;
\`;

render(
  <CustomForm onSubmit={onSubmit} onReset={onReset}>
    <varnishUi.Stack  spacing={4}>
      <varnishUi.Stack direction="row" spacing={16}>
        <label>Username</label>
        <varnishUi.Input
          color="primary"
          placeholder="Please enter your name"
          name="username"
        />
      </varnishUi.Stack>
      <varnishUi.Stack direction="row" spacing={16}>
        <label>Email</label>
        <varnishUi.Input
          color="primary"
          placeholder="Please enter your email"
          name="email"
        />
      </varnishUi.Stack>
      <varnishUi.Stack direction="row" spacing={8}>
        <varnishUi.Button type="submit" variant="outlined">
            Submit
        </varnishUi.Button>
        <varnishUi.Button type="reset" variant="outlined">
            Reset
        </varnishUi.Button>
      </varnishUi.Stack>
    </varnishUi.Stack>
  </CustomForm>
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;