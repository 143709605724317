
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish',
  type: 'Overview',
  title: 'Welcome',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Welcome`}</h2>
    <p>{`This is a demonstration of `}<a parentName="p" {...{
        "href": "https://github.com/allenai/varnish"
      }}>{`Varnish`}</a>{`, a custom theme provider for both `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/all-components/"
      }}>{`Material Design`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/"
      }}>{`Styled-Components`}</a>{`.`}</p>
    <p>{`Varnish provides common AI2 Styled-Components, components, and utilities.`}</p>
    <p>{`Varnish is derived from the `}<a parentName="p" {...{
        "href": "https://github.com/allenai/skiff-template"
      }}>{`Skiff Template`}</a>{`. Skiff provides a `}<a parentName="p" {...{
        "href": "https://www.python.org/"
      }}>{`Python`}</a>{` based API and a UI constructed with `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`, `}<a parentName="p" {...{
        "href": "https://reactjs.org/"
      }}>{`ReactJS`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/all-components/"
      }}>{`Material Design`}</a>{`.`}</p>
    <p>{`It is deployed to a Google managed Kubernetes cluster and provides DNS, log aggregation, TLS, and other capabilities out of the box, thanks to the `}<a parentName="p" {...{
        "href": "https://github.com/allenai/skiff"
      }}>{`Skiff`}</a>{` project.`}</p>
    <p>{`If you have any questions, concerns, or feedback, please don’t hesitate to reach out. You can open a `}<a parentName="p" {...{
        "href": "https://github.com/allenai/skiff-template/issues/new"
      }}>{`Github Issue`}</a>{` or contact us at `}<a parentName="p" {...{
        "href": "mailto:reviz@allenai.org"
      }}>{`reviz@allenai.org`}</a>{`.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`Is Varnish new to you? If so, start `}<a parentName="p" {...{
        "href": "https://varnish.apps.allenai.org/components/varnishmui/getting_started"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Migrating`}</h2>
    <p>{`Are you on an older version of Varnish? If so, start `}<a parentName="p" {...{
        "href": "https://varnish.apps.allenai.org/components/varnishmui/migrating"
      }}>{`here`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;