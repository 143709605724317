
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling for SnackbarList (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const exampleSnackbarClassName = 'mySnackbar';
const exampleSnackbarMessageClassName = 'mySnackbarMessage';
const exampleSnackbarActionClassName = 'mySnackbarAction';
const exampleSnackbarExitClassName = 'mySnackbarExit';

const CustomSnackbarList = mui.styled(varnishUi.SnackbarList)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};

    .\${exampleSnackbarClassName} {
        background: purple;
    }

    .\${exampleSnackbarMessageClassName} {
        border: 2px dashed orange;
    }
    .\${exampleSnackbarActionClassName} {
        border: 2px dashed yellow;
    }
    .\${exampleSnackbarExitClassName} {
        border: 2px dashed blue;
    }
\`;

const Demo = ({ anchorOrigin }) => {
    const { addSnackbarNotification } = varnishUi.useSnackbarNotification();

    const handleClick = () => {
        addSnackbarNotification(anchorOrigin, {
            message: 'Hello from Varnish UI Snackbar!',
            autoHideDuration: 3000,
            variant: 'default',
            className: exampleSnackbarClassName,
            actionClassName: exampleSnackbarActionClassName,
            messageClassName: exampleSnackbarMessageClassName,
            exitClassName: exampleSnackbarExitClassName,
        });
    };

    return (
        <div>
            <varnishUi.Button variant="outlined" color="primary" onClick={handleClick}>
                {anchorOrigin}
            </varnishUi.Button>
            <CustomSnackbarList anchorOrigin={anchorOrigin} />
        </div>
    );
};

// Wrap the whole app or component tree with SnackbarNotificationProvider

const App = () => (
    <>
        <varnishUi.SnackbarNotificationProvider>
            <Demo anchorOrigin="bottomLeft" />
        </varnishUi.SnackbarNotificationProvider>
    </>
);

render(<App />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;