import {
    TagGroup as AriaChipGroup,
    TagGroupProps as AriaChipGroupProps,
    TagList as AriaChipList,
    FieldError,
    Label,
} from 'react-aria-components';

import React, { createContext, PropsWithChildren } from 'react';

import { cx } from '@/utils/cx';

import ChipGroupRecipeProps, { chipGroupRecipe } from './chipGroup.styles';
import { Description } from '@/components/field/description/Description';
import { Chip, ChipItem } from '@/components/chip/Chip';
import { ChipRecipeProps } from '@/components/chip/chip.styles';

type ChipGroupProps = {
    items?: ChipItem[];
    label?: string;
    description?: string;
    errorMessage?: string;
    content?: React.ReactNode;
    className?: string;
    chipClassName?: string;
    labelClassName?: string;
    descriptionClassName?: string;
    errorClassName?: string;
} & AriaChipGroupProps &
    ChipGroupRecipeProps &
    ChipRecipeProps;

export const ChipGroupContext = createContext<AriaChipGroupProps & ChipGroupRecipeProps>({});

const ChipGroup = ({
    items,
    selectedKeys,
    label,
    description,
    errorMessage,
    className,
    chipClassName,
    labelClassName,
    descriptionClassName,
    errorClassName,
    children,
    ...rest
}: PropsWithChildren<ChipGroupProps>) => {
    const [variantProps, localProps] = chipGroupRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = chipGroupRecipe(variantProps);

    return (
        <AriaChipGroup {...localProps} className={cx(recipeClassNames.root, className)}>
            <ChipGroupContext.Provider value={{ ...rest }}>
                <Label className={cx(recipeClassNames.label, labelClassName)}>{label}</Label>
                <AriaChipList className={cx(recipeClassNames.chipList, className)}>
                    {items?.map((item: ChipItem) => (
                        <Chip key={item.id} {...item} className={chipClassName} />
                    ))}
                    {children}
                </AriaChipList>
                <Description className={cx(recipeClassNames.description, descriptionClassName)}>
                    {description}
                </Description>
                <FieldError className={cx(recipeClassNames.error, errorClassName)}>
                    {errorMessage}
                </FieldError>
            </ChipGroupContext.Provider>
        </AriaChipGroup>
    );
};

export { ChipGroup };
export type { ChipGroupProps };
