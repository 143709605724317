import { useContext } from 'react';
import { Radio as AriaRadio, RadioProps as AriaRadioProps } from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import focusRing from '@/components/shared/focusRing.styles';
import radioRecipe, { type RadioRecipeProps } from '@/components/radio/radio.styles';
import { RadioVariantContext } from '@/components/radioGroup/RadioGroup';

/**
 *  Radios can be built with the <input> HTML element, but this can be difficult to style.
 *  Radio helps achieve accessible radioes that can be styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Radio.html
 */

type RadioProps = {
    className?: string;
    circleClassName?: string;
} & AriaRadioProps &
    RadioRecipeProps;

function Radio({ className, circleClassName, children, ...rest }: RadioProps) {
    const [variantProps, localProps] = radioRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const groupProps = useContext(RadioVariantContext);
    const recipeClassNames = radioRecipe.raw({ ...groupProps, ...variantProps });
    return (
        <AriaRadio
            {...localProps}
            // we need to wrap the `raw` focusRing and `raw` recipeClassNames.root in `css()` to merge them
            // there is no garauntee which class will take precidence
            className={cx('group', css(focusRing, recipeClassNames.root), className)}>
            <>
                <div className={cx(css(recipeClassNames.circle), circleClassName)} />
                {children}
            </>
        </AriaRadio>
    );
}

export { Radio, Radio as default };
export type { RadioProps };
