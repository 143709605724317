
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'How do I select components by name in MUI Styled?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`We set a name on creation so we can refer to the component's css class later.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`// [class$="-\${AppNameTextOpts.name}-\${AppNameTextOpts.slot}"]
const AppNameTextOpts = { name: 'AppNameText', slot: 'root' };
const AppNameText = styled('h3', AppNameTextOpts)\`
    color: \${({ theme }) => theme.color2.N5.hex};
\`;
`}</code></pre>
    <p>{`Then use a regex to style the component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`const LogoGrid = styled('span')\`
    &:hover [class$="-\${AppNameTextOpts.name}-\${AppNameTextOpts.slot}"] {
            text-decoration: none;
        }
    },
\`;
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;