
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { LinkExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Link',
  cols: 2,
  iconSrc: './tab-14px.svg',
  tag: {
    label: 'New',
    color: 'secondary'
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Link`}</h2>
    <p>{`A link allows a user to navigate to another page or resource within a web page or application.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={LinkExamples} mdxType="Examples" />
    <h3>{`Link Provider`}</h3>
    <p><inlineCode parentName="p">{`Varnish-UI`}</inlineCode>{` provides a `}<inlineCode parentName="p">{`Link`}</inlineCode>{` component that works out-of-the-box for standard web applications. However, if you're using a framework like `}<inlineCode parentName="p">{`Next.js`}</inlineCode>{`, you may want to integrate its `}<inlineCode parentName="p">{`Link`}</inlineCode>{` component (e.g., `}<inlineCode parentName="p">{`next/link`}</inlineCode>{`) for optimized navigation and routing capabilities. To achieve this, `}<inlineCode parentName="p">{`@react-aria/router`}</inlineCode>{` offers a `}<inlineCode parentName="p">{`LinkProvider`}</inlineCode>{` that allows you to override the default Link behavior with your own implementation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { RouterProvider } from '@react-aria/router';
import { useRouter } from 'next/router';
import varnishUi from '@allenai/varnish-ui';

// Custom component to wrap Link with routing support
function MyApp({ Component, pageProps }) {
    const router = useRouter();

    return (
        <RouterProvider navigate={(href, opts) => router.push(href, undefined, opts)}>
            <Component {...pageProps} />
        </RouterProvider>
    );
}

// Example component with Link usage
function HomePage() {
    return (
        <div>
            <h1>Welcome to the Home Page</h1>
            <p>Click below to navigate to the About page:</p>
            <varnishUi.Link href="/about">Go to About</varnishUi.Link>
        </div>
    );
}

// To simulate the actual live example
render(<MyApp Component={HomePage} pageProps={{}} />);
`}</code></pre>
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inherit`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`small`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`medium`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inherit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Defines the size of the link.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inherit`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`primary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`secondary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`tertiary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inherit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls the color scheme of the link.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`underline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`always`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`none`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`always`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls weather the link is underlined`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URL the link navigates to when activated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isDisabled`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Indicates that the Link is disabled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional class names for the Link.`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;