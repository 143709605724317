import { css } from '@allenai/varnish-panda-runtime/css';

import { useMediaQuery } from 'react-responsive';

import { cx } from '@/utils/cx';

import headerRecipe, { HeaderRecipeProps } from './header.styles';
import { Icons } from '@/index';

type HeaderProps = {
    logo?: React.ReactNode;
    navItem?: React.ReactNode;
    search?: React.ReactNode;
    action?: React.ReactNode;
    className?: string;
    searchClassName?: string;
    actionClassName?: string;
    navClassName?: string;
    logoClassName?: string;
    iconClassName?: string;
} & HeaderRecipeProps;

const useIsMobile = () => {
    return useMediaQuery({ maxWidth: 768 });
};

function Header({
    logo,
    navItem,
    search,
    action,
    className,
    searchClassName,
    actionClassName,
    navClassName,
    logoClassName,
    ...rest
}: HeaderProps) {
    const [variantProps, localProps] = headerRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = headerRecipe.raw(variantProps);
    // Note: We should add default new Ai2 Icon once the RAC Icon goes in.
    // Ref: https://github.com/allenai/varnish/issues/1001

    const isMobile = useIsMobile();

    return (
        <header className={cx(css(recipeClassNames.root), className)} {...localProps}>
            <div className={cx(css(recipeClassNames.container))}>
                <div
                    aria-label="Site Logo"
                    className={cx(css(recipeClassNames.logo), logoClassName)}>
                    {isMobile && (
                        <span className={cx(css(recipeClassNames.icon))}>
                            <Icons.Menu />
                        </span>
                    )}
                    {logo}
                </div>
                <div className={cx(css(recipeClassNames.slots))}>
                    {!!navItem && (
                        <nav
                            className={cx(css(recipeClassNames.nav), navClassName)}
                            aria-label="Main Navigation">
                            {navItem}
                        </nav>
                    )}
                    <div
                        className={cx(css(recipeClassNames.search), searchClassName)}
                        aria-label="Search Bar">
                        {search}
                    </div>
                    <div
                        className={cx(css(recipeClassNames.action), actionClassName)}
                        aria-label="Action Button">
                        {action}
                    </div>
                </div>
            </div>
        </header>
    );
}

export { Header, Header as default };
export type { HeaderProps };
