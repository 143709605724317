
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'Drag between tables with value'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Allowing users to drag items between tables bidirectionally. It also supports reordering items within the same table.
When a table is empty, it accepts drops on the whole collection.
getDropOperation ensures that items are always moved rather than copied, which avoids duplicate items.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

interface FileItem {
  id: string,
  name: string,
  type: string
}

interface DndTableProps {
  initialItems: FileItem[],
  'aria-label': string
}

const DndTable = (props: DndTableProps) => {
    const [items, setItems] = React.useState<FileItem[]>(props.initialItems);

    let { dragAndDropHooks } = varnishUi.useDragAndDrop({
        getItems(keys) {
            return [...keys].map((key) => {
            let item = items.find((i) => i.id === key);
                return {
                    'custom-app-type': JSON.stringify(item),
                    'text/plain': item?.name
                };
            });
        },

        acceptedDragTypes: ['custom-app-type'],

        getDropOperation: () => 'move',

        async onInsert(e) {
            let processedItems = await Promise.all(
                e.items.map(async (item) => JSON.parse(await item.getText('custom-app-type')))
            );

        setItems((prevItems) => {
            const targetIndex = prevItems.findIndex((item) => item.id === e.target.key);
            if (targetIndex === -1) {
                return [...prevItems, ...processedItems];
            }
            const newItems = [...prevItems];
            if (e.target.dropPosition === 'before') {
                newItems.splice(targetIndex, 0, ...processedItems);
            } else if (e.target.dropPosition === 'after') {
                newItems.splice(targetIndex + 1, 0, ...processedItems);
            }
            return newItems;
        });
        },

        async onRootDrop(e) {
            let processedItems = await Promise.all(
                e.items.map(async (item) => JSON.parse(await item.getText('custom-app-type')))
            );

            setItems((prevItems) => {
                // Ensure we don't duplicate items already in the list
                const newItems = processedItems.filter(
                    (newItem) => !prevItems.some((item) => item.id === newItem.id)
                );
                return [...prevItems, ...newItems];
            });
        },

        onReorder(e) {
            const targetIndex = items.findIndex((item) => item.id === e.target.key);
            const newItems = [...items];
            const movedItems = e.keys.map((key: string) => items.find((item) => item.id === key));
            newItems.splice(targetIndex, 0, ...movedItems);
            setItems(newItems);
        },

        onDragEnd(e) {
            if (e.dropOperation === 'move' && !e.isInternal) {
                    setItems((prevItems) => {
                    const keysArray = Array.from(e.keys); // Convert Set to Array
                    return prevItems.filter((item) => !keysArray.includes(item.id));
                });
            }
        }
    });

    const headers = [
        { id: 'id', name: 'ID' },
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
    ];

  return (
    <varnishUi.Table
      aria-label={props['aria-label']}
      selectionMode="multiple"
      dragAndDropHooks={dragAndDropHooks}
      headers={headers}
      dataItems={items}
    />
  );
}

const Demo = () => {
    return (
        <varnishUi.Stack direction="row" spacing={15}>
            <DndTable
                initialItems={[
                    { id: '1', type: 'file', name: 'Adobe Photoshop' },
                    { id: '2', type: 'file', name: 'Adobe XD' },
                    { id: '3', type: 'folder', name: 'Documents' },
                    { id: '4', type: 'file', name: 'Adobe InDesign' },
                    { id: '5', type: 'folder', name: 'Utilities' },
                    { id: '6', type: 'file', name: 'Adobe AfterEffects' }
                ]}
                aria-label="First Table"
            />
            <DndTable
                initialItems={[
                    { id: '7', type: 'folder', name: 'Pictures' },
                    { id: '8', type: 'file', name: 'Adobe Fresco' },
                    { id: '9', type: 'folder', name: 'Apps' },
                    { id: '10', type: 'file', name: 'Adobe Illustrator' },
                    { id: '11', type: 'file', name: 'Adobe Lightroom' },
                    { id: '12', type: 'file', name: 'Adobe Dreamweaver' }
                ]}
                aria-label="Second Table"
            />
        </varnishUi.Stack>
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;