
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Direction'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`By default, the Stack component arranges items vertically in a column.
To position items horizontally in a row, use the `}<inlineCode parentName="p">{`direction`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={2}>
        <varnishUi.Button variant="outlined">Item 1</varnishUi.Button>
        <varnishUi.Button variant="outlined">Item 2</varnishUi.Button>
        <varnishUi.Button variant="outlined">Item 3</varnishUi.Button>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;