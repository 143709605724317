
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { ModalExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Modal',
  cols: 2,
  iconSrc: './text-14px.svg',
  tag: {
    label: 'Alpha',
    color: 'tertiary'
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const AlphaMsg = makeShortcode("AlphaMsg");
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AlphaMsg mdxType="AlphaMsg" />
    <h2>{`Modal`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` component displays a Dialog, modally. It comes with a default background overlay, and has a close icon in the top right corner.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` by default will size to the content, and has a size property for the max width.`}</p>
    <p><inlineCode parentName="p">{`Modal`}</inlineCode>{`s are not dismissable unless closed with a button, or are set to be dismissable.`}</p>
    <p><a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/Modal.html"
      }}>{`React Aria Modal docs`}</a></p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={ModalExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <h4>{`Properties`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`small`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`medium`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`medium`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the max width of the `}<inlineCode parentName="td">{`Modal`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`fullWidth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the minWidth to the the size, instead of sizing to content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`isDismissable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls weather the modal is dismissable by clicking away`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`isKeyboardDismissDisabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls weather the modal is dismissable with `}<inlineCode parentName="td">{`Esc`}</inlineCode>{` button`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Content Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`heading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pass a heading as text, which will be wrapped in a `}<inlineCode parentName="td">{`ModalHeading`}</inlineCode>{` if its a string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`closeButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Weather to show the default `}<inlineCode parentName="td">{`ModalCloseIconButton`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`buttons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Can be a list of buttons for actions, displayed below the content`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`ClassNames`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`className`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`className`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the Modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`overlayClassName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the overlay / backdrop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`headingClassName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the heading container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`closeButtonClassName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the close button icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`dialogClassName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the dialog container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`contentClassName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the content container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`actionsClassName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies to the actions container`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Components`}</h3>
    <h4>{`Anatomy`}</h4>
    <pre><code parentName="pre" {...{}}>{`<Modal>
    <ModalHeading />
    <ModalContent />
    <ModalActions />
</Modal>
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` component abstracts a lot it away, allowing passing the heading and actions as props,
and the content is wrapped by `}<inlineCode parentName="p">{`ModalContent`}</inlineCode>{` for you.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` component itself is made from the React Aria Components. `}<inlineCode parentName="p">{`ModalOverlay`}</inlineCode>{`, `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`
as well as a Varnish UI `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` (which itself is backed by a RAC `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{`), that has minimal
default behavoir that can be shared.`}</p>
    <p>{`If you do not want this, you can use `}<inlineCode parentName="p">{`ModalBase`}</inlineCode>{`, `}<inlineCode parentName="p">{`ModalHeading`}</inlineCode>{`, `}<inlineCode parentName="p">{`ModalActions`}</inlineCode></p>
    <p><inlineCode parentName="p">{`<ModalHeading />`}</inlineCode>{` comes with a default closeButton, which can be disabled with by passsing
`}<inlineCode parentName="p">{`closeButton={false}`}</inlineCode>{`, you may also pass a custom component into it to replace it.`}</p>
    <p><inlineCode parentName="p">{`<ModalCloseButton />`}</inlineCode>{` is a convieince component that will close the modal.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;