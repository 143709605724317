
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <p>{`Snackbar should be styled when calling the context to add one. The rendering example
you see on VarnishUI is for demo purpose only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import * as varnishUi from "@allenai/varnish-ui";
import * as mui from "@mui/material";

// This is an example of using mui styles ant the mui theme provider

const exampleTabListClassName = "myTabList";
const exampleTabClassName = "myTab";
const exampleTabPanelClassName = "myTabPanel";

const CustomTabs = mui.styled(varnishUi.Tabs)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};

    .\${exampleTabListClassName} {
        border: 2px dashed orange;
    }
    .\${exampleTabClassName} {
        border: 2px dashed yellow;
    }
    .\${exampleTabPanelClassName} {
        border: 2px dashed blue;
    }
\`;

const Demo = () => {
  const [tabSelected, setTabSelect] = React.useState<Key>("FoR");
  const items: Item[] = [
    {
      id: 'FoR',
      header: (props) => <varnishUi.Tab {...props}>Founding of Rome</varnishUi.Tab>,
      content: (props) => <varnishUi.TabPanel {...props}>Arma virumque cano, Troiae qui primus ab oris.</varnishUi.TabPanel>,
    },
    {
      id: 'MaR',
      header: (props) => <varnishUi.Tab {...props}>Monarchy and Republic</varnishUi.Tab>,
      content: (props) => <varnishUi.TabPanel {...props}>Senatus Populusque Romanus.</varnishUi.TabPanel>,
    },
    {
      id: 'Emp',
      header: (props) => <varnishUi.Tab {...props}>Empire</varnishUi.Tab>,
      content: (props) => <varnishUi.TabPanel {...props}>Alea jacta est.</varnishUi.TabPanel>,
    },
  ];

  return (
    <CustomTabs
      onSelectionChange={setTabSelect}
      selectedKey={tabSelected}
      items={items}
      tabListClassName={exampleTabListClassName}
      tabClassName={exampleTabClassName}
      tabPanelClassName={exampleTabPanelClassName}
    />
  );
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;