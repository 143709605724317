
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const overlayClassName = () => {
    return emotionCss.css\`
        background: rgba(0, 100, 0, 0.5);
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const headingClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
    \`;
}

const className = () => {
    return emotionCss.css\`
        font-family: monospace;
    \`;
}

render(
    <varnishUi.ModalTrigger>
        <varnishUi.Button>Open styled modal</varnishUi.Button>
        <varnishUi.Modal isDismissable heading="Heading text" overlayClassName={overlayClassName()} headingClassName={headingClassName()} className={className()}>
            <p>Custom text</p>
        </varnishUi.Modal>
    </varnishUi.ModalTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;