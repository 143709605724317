
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Alert Title'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You may provide either a `}<inlineCode parentName="p">{`title`}</inlineCode>{` property (which is wrapped in an `}<inlineCode parentName="p">{`AlertTitle`}</inlineCode>{` container),
or pass an `}<inlineCode parentName="p">{`AlertTitle`}</inlineCode>{` component in the message to add a title to the alert.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={25}>
        <varnishUi.Alert severity="success" variant="contained" title="Title prop">
            Mission accomplished
        </varnishUi.Alert>
        <varnishUi.Alert severity="success" variant="contained">
            <varnishUi.AlertTitle>Title component</varnishUi.AlertTitle>
            Everything went according to plan
        </varnishUi.Alert>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;