
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Readonly'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The `}<inlineCode parentName="p">{`isReadOnly`}</inlineCode>{` prop makes the selection unchangeable while keeping the RadioGroup focusable, unlike isDisabled, which prevents interaction entirely.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.RadioGroup isReadOnly label="Favorite sports" defaultValue="baseball">
        <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
        <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
        <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
    </varnishUi.RadioGroup>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;