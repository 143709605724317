import { Button as AriaButton, ButtonProps as AriaButtonProps } from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import buttonRecipe, { ButtonRecipeProps } from '@/components/button/button.styles';
import focusRing from '@/components/shared/focusRing.styles';

/**
 *  On the surface, building a custom styled button seems simple. However, there are many cross
 *  browser inconsistencies in interactions and accessibility features to consider. Button handles
 *  all of these interactions for you, so you can focus on the styling.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Button.html
 */

type ButtonProps = {
    className?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    buttonAriaLabel?: string;
    children?: React.ReactNode;
    startIconClassName?: string;
    endIconClassName?: string;
} & AriaButtonProps &
    ButtonRecipeProps;

/**
 * Button component that renders a button with customizable styles based on variant, size, and color.
 *
 * @param props The props for the button including variant, size, color, and additional HTML button props.
 *
 * @returns A button element with the combined styles from the MUI base button and the custom variants.
 */
const Button = ({
    className,
    startIconClassName,
    endIconClassName,
    startIcon,
    endIcon,
    buttonAriaLabel,
    children,
    ...rest
}: ButtonProps) => {
    const [variantProps, localProps] = buttonRecipe.splitVariantProps(rest);
    const recipeClassNames = buttonRecipe.raw(variantProps);

    return (
        <AriaButton
            {...localProps}
            // we need to wrap the `raw` focusRing and `raw` buttonRecipe in `css`() to merge them
            // there is no garauntee which class will take precidence
            className={cx(css(focusRing, recipeClassNames.root), className)}
            aria-label={buttonAriaLabel}>
            {startIcon ? (
                <span className={cx(css(recipeClassNames.startIcon), startIconClassName)}>
                    {startIcon}
                </span>
            ) : null}
            {children}
            {endIcon ? (
                <span className={cx(css(recipeClassNames.endIcon), endIconClassName)}>
                    {endIcon}
                </span>
            ) : null}
        </AriaButton>
    );
};

export { Button, Button as default };
export type { ButtonProps };
