import {
    TextArea as AriaTextArea,
    TextAreaProps as AriaTextAreaProps,
    TextFieldProps as AriaTextFieldProps,
    TextField as AriaTextField,
    ValidationResult,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { useState } from 'react';

import { cx } from '@/utils/cx';

import { textAreaRecipe, TextAreaRecipeProps } from './textArea.styles';
import { Label } from '@/components/field/label/Label';
import { FieldError } from '@/components/field/fieldError/FieldError';
import { childFocusRing } from '@/components/shared/focusRing.styles';
import { Description } from '../description/Description';

type TextAreaProps = {
    minRows?: number;
    maxRows?: number;
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    className?: string;
    textAreaClassName?: string;
    growContainerClassName?: string;
    labelClassName?: string;
    descriptionClassName?: string;
    errorClassName?: string;
} & AriaTextAreaProps &
    AriaTextFieldProps &
    TextAreaRecipeProps;

const TextArea = ({
    minRows = 2,
    maxRows = 3,
    label,
    description,
    errorMessage,
    className,
    textAreaClassName,
    growContainerClassName,
    labelClassName,
    descriptionClassName,
    errorClassName,
    onChange,
    ...rest
}: TextAreaProps) => {
    const [variantProps, localProps] = textAreaRecipe.splitVariantProps(rest);
    const recipeClassNames = textAreaRecipe(variantProps);
    const { size } = rest;

    // The container needs to grow, and have a height between minRows and maxRows. It also needs to allow text-overflow.
    // To acheive this, we give it an example string that satisfies those rules so it can calculate its correct height.
    // More details here: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    const [heightCalculationString, setHeightCalculationString] = useState(
        adjustStringToRowLimits('', minRows, maxRows)
    );
    return (
        <AriaTextField className={cx('group', recipeClassNames.root, className)}>
            <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                {label}
            </Label>
            <div
                {...{ disabled: localProps.isDisabled }}
                data-replicated-value={heightCalculationString}
                className={cx(
                    css(childFocusRing),
                    recipeClassNames.growContainer,
                    growContainerClassName
                )}>
                <AriaTextArea
                    {...{ disabled: localProps.isDisabled }}
                    {...localProps}
                    onChange={(event) => {
                        const expectedVisibleText = adjustStringToRowLimits(
                            event.target.value,
                            minRows,
                            maxRows
                        );
                        setHeightCalculationString(expectedVisibleText);

                        // if an onChange function was passed in, then evoke it here
                        if (onChange) {
                            onChange(event);
                        }
                    }}
                    className={cx(recipeClassNames.textArea, textAreaClassName)}></AriaTextArea>
            </div>
            <Description
                size={size}
                className={cx(recipeClassNames.description, descriptionClassName)}>
                {description}
            </Description>
            <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                {errorMessage}
            </FieldError>
        </AriaTextField>
    );
};

// this function adds/removes newlines from a string until the number of rows is between minRows and maxRows
const adjustStringToRowLimits = (input: string, minRows: number, maxRows: number): string => {
    const rows = input.split('\n');
    for (let i = rows.length; i < minRows; i++) {
        rows.push('');
    }
    return rows.slice(0, maxRows).join('\n');
};

export { TextArea };
export type { TextAreaProps };
