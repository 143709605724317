import type { LineProps } from '@nivo/line';
import { merge } from 'lodash-es';

import { themeDark, themeLight } from '../nivo-theme';
import { DarkColorHexes, LightColorHexes } from './global';

export const lineDefaults: Partial<LineProps> = {
    theme: themeLight,
    lineWidth: 4,
    pointSize: 8,
    curve: 'natural',
    colors: LightColorHexes,
};

export const lineDefaultsDark = merge({}, lineDefaults, {
    theme: themeDark,
    colors: DarkColorHexes,
} satisfies typeof lineDefaults);
