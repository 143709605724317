
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Alert color can be specified with `}<inlineCode parentName="p">{`severity`}</inlineCode>{` (matching MUIs API). Severity can be: `}<inlineCode parentName="p">{`info`}</inlineCode>{`, `}<inlineCode parentName="p">{`success`}</inlineCode>{`, `}<inlineCode parentName="p">{`warning`}</inlineCode>{`, or `}<inlineCode parentName="p">{`error`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={25}>
        <varnishUi.Stack direction="column" spacing={25} fullWidth>
            <varnishUi.Alert severity="success" variant="contained">
                Success Alert
            </varnishUi.Alert>
            <varnishUi.Alert severity="info" variant="contained">
                Info Alert
            </varnishUi.Alert>
            <varnishUi.Alert severity="warning" variant="contained">
                Warning Alert
            </varnishUi.Alert>
            <varnishUi.Alert severity="error" variant="contained">
                Error Alert
            </varnishUi.Alert>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;