
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Sizes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can change the size of the logo to be one of three values: `}<em parentName="p">{`sm`}</em>{`, `}<em parentName="p">{`md`}</em>{` or `}<em parentName="p">{`lg`}</em>{`.`}</p>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

const logos = varnishComponents.logos;

render(
    <div>
        <div>Small</div>
        <logos.AI2Logo size="sm" />
        <br />
        <br />
        {/* For default size, no need to set <AI2Logo size="md" />
    since "md" is the default value of the size property. */}
        <div>Medium</div>
        <logos.AI2Logo size="md" />
        <br />
        <br />
        <div>Large</div>
        <logos.AI2Logo size="lg" />
        <br />
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;