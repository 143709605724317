import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const chipRecipe = sva({
    slots: ['root', 'removeButton'],
    base: {
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '[4px]',
            borderRadius: '[40px]',
            border: '[1px solid]',
            padding: '[5px]',
            color: 'text.primary',
            backgroundColor: '[var(--chip-color)]',
            borderColor: '[var(--chip-color)]',

            _disabled: {
                cursor: 'not-allowed',
                color: 'text.primary',
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
            },
            // default light contained styles
            '--chip-color': '[white]',
            _dark: {
                '--chip-color': '{colors.background}',
                _disabled: {
                    backgroundColor: 'gray.20',
                    borderColor: 'gray.40',
                    color: 'text.primary.reversed',
                },
            },

            // link chips
            '&[data-href]': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },

            // selected chips
            '&[aria-selected=false]': {
                _hover: {
                    _notDisabled: {
                        borderColor: 'green.40',
                    },
                },
            },
            '&[data-selected]': {
                _hover: {
                    _notDisabled: {
                        borderColor: 'green.40',
                    },
                },
                borderColor: 'green.100',
            },
        },
        removeButton: {
            display: 'inline-flex',
            alignItems: 'center',
            background: '[none]',
            marginLeft: '[2px]',
            border: 'none',
            cursor: 'pointer',
            padding: '0',
            color: 'accent.primary',
            _dark: {
                color: 'accent.secondary',
            },
        },
    },
    variants: {
        variant: {
            contained: {},
            outlined: {
                root: {
                    borderColor: 'dark-teal.20',
                    _dark: {
                        borderColor:
                            '[color-mix(in srgb, {colors.cream.100} 20%, var(--chip-color))]',
                        color: 'text.primary',
                    },
                },
            },
        },
        shape: {
            rounded: {},
            box: {
                root: {
                    borderRadius: '[4px]',
                },
            },
        },
        color: {
            primary: {
                root: {
                    color: 'extra-dark-teal.100',
                    '--chip-color': '{colors.accent.primary}',
                    _dark: {
                        color: 'extra-dark-teal.100',
                        '--chip-color': '{colors.accent.primary}',
                    },
                },
                removeButton: {
                    color: 'extra-dark-teal.100',
                    _dark: {
                        color: 'extra-dark-teal.100',
                    },
                },
            },
            secondary: {
                root: {
                    color: 'extra-dark-teal.100',
                    '--chip-color': '{colors.accent.secondary}',
                    _dark: {
                        color: 'extra-dark-teal.100',
                        '--chip-color': '{colors.accent.secondary}',
                    },
                },
                removeButton: {
                    color: 'extra-dark-teal.100',
                    _dark: {
                        color: 'extra-dark-teal.100',
                    },
                },
            },
            tertiary: {
                root: {
                    color: 'text.primary.reversed',
                    '--chip-color': '{colors.accent.tertiary}',
                    _dark: {
                        color: 'text.primary.reversed',
                        '--chip-color': '{colors.accent.tertiary}',
                    },
                },
                removeButton: {
                    color: 'text.primary.reversed',
                    _dark: {
                        color: 'text.primary.reversed',
                    },
                },
            },
        },

        size: {
            small: {
                root: {
                    height: '[32px]',
                    padding: '[5px 12px]',
                    fontSize: '[12px]',
                },
            },
            medium: {
                root: {
                    height: '[36px]',
                    padding: '[8px 15px]',
                    fontSize: 'sm',
                },
            },
            large: {
                root: {
                    height: '[48px]',
                    padding: '[12px 20px]',
                    fontSize: 'md',
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        variant: 'outlined',
    },
});

type ChipRecipeProps = RecipeVariantProps<typeof chipRecipe>;

export { chipRecipe, ChipRecipeProps as default };
export type { ChipRecipeProps };
