import * as React from 'react';

import { cx } from '@/utils/cx';

import stackRecipe, { StackRecipeProps } from '@/components/stack/stack.styles';

/**
 * The Stack component organizes its immediate children along either the vertical or horizontal
 * axis, allowing for customizable spacing and optional dividers between each child.
 */

type StackProps = {
    className?: string;
} & StackRecipeProps &
    React.HTMLProps<HTMLDivElement>;

const Stack = ({ className, children, ...rest }: React.PropsWithChildren<StackProps>) => {
    const [variantProps, localProps] = stackRecipe.splitVariantProps(rest);
    return (
        <div className={cx(stackRecipe(variantProps), className)} {...localProps}>
            {children}
        </div>
    );
};

export { Stack, Stack as default };
export type { StackProps };
