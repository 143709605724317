import {
    DialogTrigger as AriaDialogTrigger,
    type DialogTriggerProps as AriaDialogTriggerProps,
} from 'react-aria-components';

interface DialogTriggerProps extends AriaDialogTriggerProps {
    content?: React.ReactNode;
}

const DialogTrigger = ({ children, content, ...rest }: DialogTriggerProps) => {
    return (
        <AriaDialogTrigger {...rest}>
            {children}
            {content}
        </AriaDialogTrigger>
    );
};

export type { DialogTriggerProps };
export { DialogTrigger, DialogTrigger as default };
