'use client'; // for server side rendering

import { Link, styled } from '@mui/material';
import React, { DetailedHTMLProps } from 'react';

import { LayoutVariant, VarnishContext } from './VarnishContext';
import { Color } from '../theme/colors';

export type FooterVariant = 'default' | 'reversed';

interface Props {
    variant?: FooterVariant;
    children?: React.ReactNode | React.ReactNode[];
    backgroundColor?: Color;
    className?: string;
}

interface FooterProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    backgroundColor?: Color;
    layout?: LayoutVariant;
    reversed?: boolean;
}

const StyledFooter = styled(({ backgroundColor, reversed, layout, ...rest }: FooterProps) => (
    <footer {...rest} />
))`
    padding: ${({ theme }) => theme.spacing(3)};
    color: ${({ reversed, theme }) =>
        reversed ? theme.palette.text.primary : theme.palette.text.reversed};
    text-align: ${({ layout }) => (layout !== 'left-aligned' ? 'center' : null)};
    background-color: ${({ backgroundColor, reversed, theme }) =>
        backgroundColor
            ? backgroundColor.hex
            : reversed
              ? theme.palette.background.paper
              : theme.palette.background.reversed};
`;

export function FooterLinkSeparator() {
    return <React.Fragment> | </React.Fragment>;
}

export function PrivacyLink() {
    return (
        <Link color="secondary" href="https://allenai.org/privacy-policy">
            Privacy Policy
        </Link>
    );
}

export function TermsOfUseLink() {
    return (
        <Link color="secondary" href="https://allenai.org/terms">
            Terms of Use
        </Link>
    );
}

export function BusinessConductLink() {
    return (
        <Link color="secondary" href="https://allenai.org/business-code-of-conduct">
            Business Code of Conduct
        </Link>
    );
}

export function AI2TermsAndConditionsAllLinks() {
    return (
        <React.Fragment>
            <PrivacyLink />
            <FooterLinkSeparator />
            <TermsOfUseLink />
            <FooterLinkSeparator />
            <BusinessConductLink />
        </React.Fragment>
    );
}

export function AI2CopyrightLink() {
    return (
        <React.Fragment>
            <Link color="secondary" href="https://allenai.org">
                © The Allen Institute for Artificial Intelligence
            </Link>{' '}
            - All Rights Reserved
        </React.Fragment>
    );
}

export function Footer(props: Props) {
    // TODO: Make custom styles for elements that have standardized padding across AI2
    return (
        <VarnishContext.Consumer>
            {({ layout }) => (
                <StyledFooter
                    className={props.className}
                    layout={layout}
                    backgroundColor={props.backgroundColor}
                    reversed={props.variant === 'reversed'}>
                    {props.children ? (
                        props.children
                    ) : (
                        <span>
                            <AI2CopyrightLink />
                            <FooterLinkSeparator />
                            <AI2TermsAndConditionsAllLinks />
                        </span>
                    )}
                </StyledFooter>
            )}
        </VarnishContext.Consumer>
    );
}
