import { css } from '@allenai/varnish-panda-runtime/css';
import type { SystemStyleObject } from '@allenai/varnish-panda-runtime/types';

import { cx } from '@/utils/cx';

import { Button, type ButtonProps } from '@/components/button/Button';

import { dialogStyles, type DialogRecipeProps } from '@/components/dialog/dialog.styles';

interface CssProp {
    css?: SystemStyleObject;
}

type DialogActionsProps = React.HTMLProps<HTMLDivElement> & DialogRecipeProps & CssProp;

const DialogActions = ({ className, css: cssProp, children, ...rest }: DialogActionsProps) => {
    const [variantProps, localProps] = dialogStyles.splitVariantProps(rest);
    const variantStyles = dialogStyles.raw(variantProps);

    return (
        <div className={cx(css(variantStyles.actions, cssProp), className)} {...localProps}>
            {children}
        </div>
    );
};

// this is just a convience
const DialogCloseButton = ({ children, ...rest }: ButtonProps) => {
    return (
        <Button slot="close" {...rest}>
            {children}
        </Button>
    );
};

export type { DialogActionsProps, ButtonProps as DialogCloseButtonProps };
export { DialogCloseButton, DialogActions, DialogActions as default };
