
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const CustomModal = mui.styled(varnishUi.Modal)\`
    font-family: monospace;
    background-color: \${({ theme }) => theme.color.B5.hex};
\`;

const CustomHeading = mui.styled(varnishUi.ModalHeading)\`
    font-family: monospace;
\`;

const CustomCloseButtonIcon = mui.styled(varnishUi.ModalCloseIconButton)\`
    color: red;
\`;

const CustomHeadingWithIcon = () => {
    return <CustomHeading closeButton={<CustomCloseButtonIcon />}>Heading Text</CustomHeading>;
};

render(
    <varnishUi.ModalTrigger>
        <varnishUi.Button>Open styled modal</varnishUi.Button>
        <CustomModal isDismissable heading={<CustomHeadingWithIcon />}>
            <p>Custom text</p>
        </CustomModal>
    </varnishUi.ModalTrigger>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;