import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const tooltipRecipe = sva({
    slots: ['root', 'arrow'],
    base: {
        root: {
            padding: '[6px 12px]',
            cursor: 'pointer',

            '&[data-entering]': {
                animation: 'fadeIn 250ms ease-out forwards',
            },
            '&[data-exiting]': {
                animation: 'fadeOut 250ms ease-out forwards',
            },
        },
        arrow: {
            display: 'flex',

            '&[data-placement="top"] svg': {
                marginTop: '[-1px]',
            },
            '&[data-placement="left"] svg': {
                transform: 'rotate(-90deg)',
                marginLeft: '[-4px]',
            },
            '&[data-placement="right"] svg': {
                transform: 'rotate(90deg)',
                marginRight: '[-4px]',
            },
            '&[data-placement="bottom"] svg': {
                transform: 'rotate(180deg)',
                marginBottom: '[-1px]',
            },
        },
    },
    variants: {
        shape: {
            box: {
                root: {
                    borderRadius: 'sm',
                },
            },
            rounded: {
                root: {
                    borderRadius: '[full]',
                },
            },
        },
        color: {
            default: {
                root: {
                    backgroundColor: 'background',
                    color: 'text.primary',
                },
                arrow: {
                    color: 'background',
                },
            },
            reversed: {
                root: {
                    backgroundColor: 'background.reversed',
                    color: 'text.primary.reversed',
                },
                arrow: {
                    color: 'background.reversed',
                },
            },
        },
    },
    defaultVariants: {
        shape: 'box',
        color: 'reversed',
    },
});

type TooltipRecipeProps = RecipeVariantProps<typeof tooltipRecipe>;

export { tooltipRecipe, TooltipRecipeProps as default };
export type { TooltipRecipeProps };
