'use client';
import * as React from 'react';

import { SVGSprite } from '@/components/svg/SVGBase';
import { SVGIcon, type SVGIconProps } from './SVGIcon';

type SVGIconSpritePropsInternal = {
    icon: string;
    forceInternal?: boolean;
} & Omit<SVGIconProps, 'children' | 'viewBox'>;

type SVGIconSpriteProps = Omit<SVGIconSpritePropsInternal, 'icon'>;

const SVGIconSprite = ({ icon, ...rest }: SVGIconSpritePropsInternal) => {
    return <SVGSprite SVGComponent={SVGIcon} use={icon} {...rest} />;
};

// thin wrapper to create a ReactComponent from an icon id
const varnishIconSprite = (icon: string): React.ComponentType<SVGIconSpriteProps> => {
    const VarnishedSprite = (props: SVGIconSpriteProps) => <SVGIconSprite icon={icon} {...props} />;
    return VarnishedSprite;
};

export { SVGIconSprite as default, SVGIconSprite, varnishIconSprite };
export type { SVGIconSpriteProps };
