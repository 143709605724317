import { useContext } from 'react';
import {
    Checkbox as AriaCheckbox,
    CheckboxProps as AriaCheckboxProps,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { Check, Remove as Indeterminate } from '@/components/icons';

import focusRing from '@/components/shared/focusRing.styles';
import checkboxRecipe, { CheckboxRecipeProps } from '@/components/checkbox/checkbox.styles';
import { CheckboxVariantContext } from '@/components/checkboxGroup/CheckboxGroup';

/**
 *  Checkboxes can be built with the <input> HTML element, but this can be difficult to style.
 *  Checkbox helps achieve accessible checkboxes that can be styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Checkbox.html
 */

type CheckboxProps = {
    className?: string;
    iconClassName?: string;
    boxClassName?: string;
} & AriaCheckboxProps &
    CheckboxRecipeProps;

function Checkbox({ className, boxClassName, iconClassName, children, ...rest }: CheckboxProps) {
    const [variantProps, localProps] = checkboxRecipe.splitVariantProps(rest);
    const groupProps = useContext(CheckboxVariantContext);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = checkboxRecipe.raw({ ...groupProps, ...variantProps });
    return (
        <AriaCheckbox
            {...localProps}
            // we need to wrap the `raw` focusRing and `raw` recipeClassNames.root in `css()` to merge them
            // there is no garauntee which class will take precidence
            className={cx('group', css(focusRing, recipeClassNames.root), className)}>
            {({ isSelected, isIndeterminate }) => (
                <>
                    <div className={cx(css(recipeClassNames.box), boxClassName)}>
                        {isIndeterminate ? (
                            <Indeterminate
                                aria-hidden
                                className={cx(css(recipeClassNames.icon), iconClassName)}
                            />
                        ) : isSelected ? (
                            <Check
                                aria-hidden
                                className={cx(css(recipeClassNames.icon), iconClassName)}
                            />
                        ) : null}
                    </div>
                    {children}
                </>
            )}
        </AriaCheckbox>
    );
}

export { Checkbox, Checkbox as default };
export type { CheckboxProps };
