
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <p>{`Snackbar should be styled when calling the context to add one. The rendering example
you see on VarnishUI is for demo purpose only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        text-decoration: none;
        color: red;
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const actionClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.B2.value};
    \`;
}

const exitClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R5.value};
    \`;
}

const messageClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R4.value};
    \`;
}

render(
   <varnishUi.Snackbar message="variant={default}" open={true} className={emotionCss.cx(className())} actionClassName={emotionCss.cx(actionClassName())} exitClassName={emotionCss.cx(exitClassName())} messageClassName={emotionCss.cx(messageClassName())}/>
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;