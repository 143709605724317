import { IconButton } from '@mui/material';
import { Facebook } from '@mui/icons-material';

interface Props {
    url?: string;
}

export function ShareOnFacebookButton({ url }: Props) {
    const myUrlWithParams = new URL('https://www.facebook.com/sharer/sharer.php');
    if (url) {
        myUrlWithParams.searchParams.append('u', url);
    }

    return (
        <IconButton aria-label="Facebook Icon" color="primary" size="small">
            <a href={myUrlWithParams.href} target="_blank" rel="noopener noreferrer">
                <Facebook />
            </a>
        </IconButton>
    );
}

export default ShareOnFacebookButton;
