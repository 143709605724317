
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'With Menu'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This shows how to render a header with a logo and navigation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import mui from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import varnishComponents from '@allenai/varnish2/components';

const { Header, logos, Content } = varnishComponents;

function Demo() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = ['Link 1', 'Link 2', 'Link 3'];

    const ITEM_HEIGHT = 48;

    return (
        <div style={{ width: '100%' }}>
            <Header bannerAlwaysVisible>
                <Header.Columns columns="auto 1fr auto">
                    <Header.Logo
                        href="http://allenai.org"
                        label={<Header.AppName>Title</Header.AppName>}>
                        <logos.AI2Logo includeText={false} />
                    </Header.Logo>
                    <span />
                    <Header.MenuColumn>
                        <div>
                            <mui.IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreHorizIcon />
                            </mui.IconButton>
                            <mui.Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}>
                                {options.map((option) => (
                                    <mui.MenuItem
                                        key={option}
                                        selected={option === 'Pyxis'}
                                        onClick={handleClose}>
                                        {option}
                                    </mui.MenuItem>
                                ))}
                            </mui.Menu>
                        </div>
                    </Header.MenuColumn>
                </Header.Columns>
            </Header>
        </div>
    );
}

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;