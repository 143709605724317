import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps, RecipeVariant } from '@allenai/varnish-panda-runtime/types';

const alertRecipe = sva({
    slots: ['root', 'icon', 'title', 'message', 'actions', 'closeButton'],
    base: {
        root: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: '1',
            borderRadius: 'sm',
            padding: '[8px]',
            width: '[100%]',
            color: 'text.primary',
        },
        icon: {
            display: 'flex',
            fontSize: '[22px]',
            lineHeight: '1',
            color: 'var(--alert-color)',
        },
        title: {
            margin: '0',
        },
        message: {
            flexGrow: '1',
            fontSize: 'sm',
        },
        actions: {},
        closeButton: {
            appearance: 'none',
            backgroundColor: '[transparent]',
            border: '0',
            padding: '0',
            fontSize: '[22px]',
            lineHeight: '1',
            display: 'flex',
        },
    },
    variants: {
        severity: {
            success: {
                root: {
                    '--alert-color': '{colors.success-green.100}',
                },
            },
            info: {
                root: {
                    '--alert-color': '{colors.info-blue.100}',
                },
            },
            warning: {
                root: {
                    '--alert-color': '{colors.warning-orange.100}',
                },
            },
            error: {
                root: {
                    '--alert-color': '{colors.error-red.100}',
                },
            },
        },
        variant: {
            outlined: {
                root: {
                    border: '[1px solid var(--alert-color)]',
                },
            },
            contained: {
                root: {
                    color: 'extra-dark-teal.100',
                    backgroundColor: 'var(--alert-color)',
                },
                icon: {
                    color: '[inherit]',
                },
            },
        },
    },
    compoundVariants: [
        {
            severity: ['success', 'info'],
            variant: 'contained',
            css: {
                root: {
                    color: '[white]',
                },
            },
        },
    ],
    defaultVariants: {
        severity: 'info',
        variant: 'contained',
    },
});

type AlertRecipeProps = RecipeVariantProps<typeof alertRecipe>;
type AlertRecipeVariantProps = RecipeVariant<typeof alertRecipe>;
type AlertSeverity = AlertRecipeVariantProps['severity'];

export { alertRecipe, alertRecipe as default };
export type { AlertRecipeProps, AlertRecipeVariantProps, AlertSeverity };
