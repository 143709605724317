import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import varnishTheme from '@allenai/varnish-theme';

export function VarnishTheme() {
    return (
        <Stack maxWidth={1}>
            {Object.keys(varnishTheme).map((key) => (
                <Accordion key={key}>
                    <AccordionSummary expandIcon={<ExpandMore />}>{key}</AccordionSummary>
                    <AccordionDetails>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {JSON.stringify(
                                varnishTheme[key as keyof typeof varnishTheme],
                                null,
                                2
                            )}
                        </pre>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Stack>
    );
}
