
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Facebook'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can share your website's URL on Facebook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

const ShareOnFacebookButton = varnishComponents.ShareOnFacebookButton;

render(<ShareOnFacebookButton url="https://allenai.org/" />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;