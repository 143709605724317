import * as Default from './demo/default.mdx';
import * as MinMaxRows from './demo/minMaxRows.mdx';
import * as Size from './demo/size.mdx';
import * as Shape from './demo/shape.mdx';
import * as Label from './demo/label.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as FullWidth from './demo/fullWidth.mdx';
import * as Error from './demo/error.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const TextAreaExamples = [
    Default,
    MinMaxRows,
    Size,
    Label,
    Disabled,
    Shape,
    Error,
    FullWidth,
    StylingCss,
    StylingStyled,
];
