
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Pending'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The `}<inlineCode parentName="p">{`isPending`}</inlineCode>{` prop can be used to place a Button in a pending state, which is particularly useful for actions that take time to complete. This state provides visual feedback to users that the action is in progress. Additionally, the pending state communicates the status change to assistive technologies and disables user interactions, except for maintaining focus on the button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const PendingComponent = () => {
    const [seconds, setSeconds] = React.useState(5);
    const [isActive, setIsActive] = React.useState(false);
    React.useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if (isActive && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            setIsActive(false);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isActive, seconds]);

    const startCountdown = () => {
        setSeconds(4);
        setIsActive(true);
    };

    return (
        <varnishUi.Button variant="contained"
            isPending={isActive}
            onPress={startCountdown}>
              {({isPending}) => (
                  <>
                      {!isPending && <span>Save</span>}
                      {isPending && <span>{\`Pending: \${seconds}s\`}</span>}
                  </>
              )}
        </varnishUi.Button>
    );
};

render(<PendingComponent />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;