
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Ai2LogosExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'AI2 Logos',
  cols: 2,
  iconSrc: './logos-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`AI2 Logos`}</h2>
    <p>{`AI2 Specific Logos as inline SVG elements.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`Whenever you need the official AI2 or project logo.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={Ai2LogosExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <h4>{`AI2 Logo`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'Ai2'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets a11y title of svg logo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'sm' `}{`|`}{` 'md' `}{`|`}{` 'lg'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'md'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets size of logo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'default' `}{`|`}{` 'white'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'default'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets color of logo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`includeText`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`display the full org name?`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`AI2 Project Logos`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets icon width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets icon height`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;