import * as Default from './demo/default.mdx';
import * as Size from './demo/size.mdx';
import * as Color from './demo/color.mdx';
import * as Dismissable from './demo/dismissable.mdx';
import * as Controlled from './demo/controlled.mdx';
import * as Customization from './demo/customization.mdx';
import * as Composing from './demo/composing.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const ModalExamples = [
    Default,
    Size,
    Color,
    Dismissable,
    Controlled,
    Customization,
    Composing,
    StylingCss,
    StylingStyled,
];
