
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Error'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can make TextArea field required with the `}<inlineCode parentName="p">{`required`}</inlineCode>{` prop. The error message can be set with `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Form>
        <varnishUi.Stack spacing={8}>
            <varnishUi.TextArea
                variant="contained"
                errorMessage="Please input value"
                required
                placeholder="Required TextArea"
            />
            <varnishUi.Button type="submit" variant="contained">
                Submit
            </varnishUi.Button>
        </varnishUi.Stack>
    </varnishUi.Form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;