import { CopyToClipboardButton } from '@allenai/varnish2/components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@mui/material/Typography';
import { css, styled, useTheme } from '@mui/material/styles';

interface PrimaryColorProps {
    value: string;
    name: string;
}

function PrimaryColor({ value, name }: PrimaryColorProps) {
    return (
        <PrimaryColorGrid>
            <PrimaryColorBox color={value} />
            <PrimaryColorName>
                <CopyToClipboardButton
                    buttonContent={<ContentCopyIcon fontSize="inherit" />}
                    text={`theme.palette.${name}`}>
                    {name}{' '}
                </CopyToClipboardButton>
            </PrimaryColorName>
            <PrimaryColorHex>
                <CopyToClipboardButton buttonContent={<ContentCopyIcon fontSize="inherit" />}>
                    {value}
                </CopyToClipboardButton>
            </PrimaryColorHex>
        </PrimaryColorGrid>
    );
}

export function PrimaryColorValues() {
    const theme = useTheme();

    return (
        <div>
            <h3>Primary</h3>
            <PrimaryGrid>
                <PrimaryColor name="primary.main" value={theme.palette.primary.main} />
                <PrimaryColor name="primary.dark" value={theme.palette.primary.dark} />
                <PrimaryColor name="primary.light" value={theme.palette.primary.light} />
            </PrimaryGrid>
            <h3>Secondary</h3>
            <PrimaryGrid>
                <PrimaryColor name="secondary.main" value={theme.palette.secondary.main} />
                <PrimaryColor name="secondary.dark" value={theme.palette.secondary.dark} />
                <PrimaryColor name="secondary.light" value={theme.palette.secondary.light} />
            </PrimaryGrid>
            <h3>Tertiary</h3>
            <PrimaryGrid>
                <PrimaryColor name="tertiary.main" value={theme.palette.tertiary.main} />
                <PrimaryColor name="tertiary.dark" value={theme.palette.tertiary.dark} />
                <PrimaryColor name="tertiary.light" value={theme.palette.tertiary.light} />
            </PrimaryGrid>
        </div>
    );
}

// TODO:  implement the <Columns count={x} /> component as seen in the Incubator project
const PrimaryGrid = styled('div')(
    ({ theme }) => css`
        display: grid;
        gap: 0 ${theme.spacing(2)};
        align-items: center;
        margin-top: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(3)};
        grid-template-columns: 1fr 1fr 1fr;

        ${theme.breakpoints.down('md')} {
            grid-template-columns: 1fr 1fr;
        }
        ${theme.breakpoints.down('sm')} {
            grid-template-columns: 1fr;
        }
    `
);

const PrimaryColorGrid = styled('div')`
    display: grid;
    grid-template-columns: max-content auto auto;
    align-items: center;
    grid-template-rows: repeat(2, max-content);
    margin-bottom: ${({ theme }) => theme.spacing(2).toString()};
`;

const PrimaryColorBox = styled('div')`
    background: ${(props) => props.color};
    width: 100%;
    height: 76px;
    border-radius: ${({ theme }) => `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`};
    grid-column: 1 / span 3;
`;

const MicroCol = styled(Typography)`
    background: ${({ theme }) => theme.palette.background.paper};
    padding: ${({ theme }) => theme.spacing(1.5).toString()};
`;
MicroCol.defaultProps = {
    variant: 'body2',
};

const PrimaryColorName = styled(MicroCol)`
    grid-column: 1;
    padding-right: 0;
`;

const PrimaryColorHex = styled(MicroCol)`
    white-space: nowrap;
    grid-column: 2 / span 2;
    text-align: center;
    padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(0.5)}`};
`;
