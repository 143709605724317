
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { AI2HeaderExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'AI2 Header',
  cols: 1,
  iconSrc: './header-footer-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`AI2 Header`}</h2>
    <p>{`This component renders a document header that is consistent across AI2 properties.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`You should put this at the top of the page. It is highly recommended to be present on every page of
your application.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={AI2HeaderExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <p>{`Header also accepts all the props for `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/api/app-bar/#props"
      }}>{`MUI's `}<inlineCode parentName="a">{`AppBar`}</inlineCode></a>{` except `}<inlineCode parentName="p">{`ref`}</inlineCode>{` and `}<inlineCode parentName="p">{`position`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bannerAlwaysVisible`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Prevents the AI2 banner from disappearing when the page is scrolled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`customBanner`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`JSX / TSX Element`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A Custom AI2 banner that you can inject into the header via this prop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`containerRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`HTMLElement Ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pass a ref for a container to this to change the scrolling context`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Sub Components`}</h2>
    <h3>{`Header.Columns`}</h3>
    <p>{`A helper component for placing `}<inlineCode parentName="p">{`<Header.Logo/>`}</inlineCode>{` and other items in the `}<inlineCode parentName="p">{`<Header/>`}</inlineCode>{` easier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<Header>
    <Header.Columns columns="auto auto 1fr">
        <Header.Logo ... />
        <Menu ... />
    </Header.Columns>
</Header
`}</code></pre>
    <h3>{`Header.Columns API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`columns`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string? (grid-template-columns)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`null`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Defines the grid columns`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Header.Logo`}</h3>
    <p>{`A helper component for placing a logo and `}<inlineCode parentName="p">{`<Header.AppName/>`}</inlineCode>{` in the `}<inlineCode parentName="p">{`<Header/>`}</inlineCode>{` easier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<Header>
    <Header.Columns columns="auto 1fr">
        <Header.Logo
            label="label">
            <img src={logoWithText} height={'56px'} alt="Varnish" />
        </Header.Logo>
    </Header.Columns>
</Header
`}</code></pre>
    <h3>{`Header.Logo API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.ReactElement?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`null`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Component that displays the name of the app, often a `}<inlineCode parentName="td">{`<Header.AppName>`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tagline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.ReactElement?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`null`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Component that displays the tagline of the app, often a `}<inlineCode parentName="td">{`<Header.Tagline>`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`separator`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.ReactElement?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`—`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Component that displays between the label and the tagline`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`null`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Url to link to`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Header.AppName`}</h3>
    <p>{`A helper component for styling the App Name in the `}<inlineCode parentName="p">{`<Header/>`}</inlineCode>{`.
Note: The app name should be fewer than 25 characters for readability.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<Header>
    <Header.Columns columns="auto 1fr">
        <Header.Logo
            label={
                <Header.AppName>Varnish</Header.AppName>
            }>
            <img ... />
        </Header.Logo>
    </Header.Columns>
</Header
`}</code></pre>
    <h3>{`Header.Tagline`}</h3>
    <p>{`A helper component for styling the App Tagline in the `}<inlineCode parentName="p">{`<Header/>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<Header>
    <Header.Columns columns="auto 1fr">
        <Header.Logo
            tagline={
                <Header.Tagline>An awesome app</Header.Tagline>
            }>
            <img ... />
        </Header.Logo>
    </Header.Columns>
</Header
`}</code></pre>
    <h3>{`Header.AI2Banner`}</h3>
    <p>{`A component that only displays the AI2 Banner.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<Header.AI2Banner />
`}</code></pre>
    <h3>{`AI2Banner API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.ReactNode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Children rendered within the AI2Banner; if undefined, default AI2 Banner is shown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`logoSize?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`LogoSize`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'sm'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Size of the AI2 logo that should be displayed in the Banner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`endSlot?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.ReactNode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An element that is added onto the end of the default AI2 Banner content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`grayscale?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not the AI2 Banner is displayed in black and white`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;