
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-theme',
  type: 'Overview',
  title: 'Migrating',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const WarningBox = makeShortcode("WarningBox");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allenaivarnishtheme300alpha0--allenaivarnishtheme300alpha1"
        }}>{`@allenai/varnish-theme(3.0.0-alpha.0) -> @allenai/varnish-theme(3.0.0-alpha.1)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "##allenaivarnishtheme206--allenaivarnishtheme300alpha0"
        }}>{`@allenai/varnish-theme(2.0.6) -> @allenai/varnish-theme(3.0.0-alpha.0)`}</a></li>
    </ul>
    <hr></hr>
    <WarningBox mdxType="WarningBox">
    Compatibility has been broken between 2.x.x and 3.x.x. Please update tokens to new values as
    needed.
    </WarningBox>
    <h2>{`@allenai/varnish-theme(3.0.0-alpha.0) -> @allenai/varnish-theme(3.0.0-alpha.1)`}</h2>
    <h3>{`Color removals`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`dark-blue-*`}</inlineCode>{`: use `}<inlineCode parentName="li">{`extra-dark-teal-*`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`lime-*`}</inlineCode>{`: no replacement`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sky-*`}</inlineCode>{`: no replacement`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`orange-*`}</inlineCode>{`: no replacement`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yellow-*`}</inlineCode>{`: no replacement`}</li>
    </ul>
    <h3>{`Color shades`}</h3>
    <p>{`All colors have shades, and based on opacity in most cases (when there are 10 shades), and with white blend for 5 shades.
This is considered to be an implementation detail from design, and may change as needed.`}</p>
    <ul>
      <li parentName="ul">{`Most colors come in 10 shades `}<inlineCode parentName="li">{`[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]`}</inlineCode></li>
      <li parentName="ul">{`With the exception of `}<inlineCode parentName="li">{`info-blue`}</inlineCode>{`, `}<inlineCode parentName="li">{`success-green`}</inlineCode>{` and `}<inlineCode parentName="li">{`warning-orange`}</inlineCode>{`, which come in 5 shades `}<inlineCode parentName="li">{`[20, 40, 60, 80, 100]`}</inlineCode></li>
    </ul>
    <hr />
    <h2>{`@allenai/varnish-theme(2.0.6) -> @allenai/varnish-theme(3.0.0-alpha.0)`}</h2>
    <h3>{`Color changes`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`off-white`}</inlineCode>{` is now `}<inlineCode parentName="li">{`cream-100`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dark-teal`}</inlineCode>{` is now `}<inlineCode parentName="li">{`dark-teal-100`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dark-blue`}</inlineCode>{` is now `}<inlineCode parentName="li">{`dark-blue-100`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`blue`}</inlineCode>{` is now `}<inlineCode parentName="li">{`info-blue-100`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`red`}</inlineCode>{` is now `}<inlineCode parentName="li">{`error-red-100`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`alertGreen`}</inlineCode>{` is now `}<inlineCode parentName="li">{`success-green-100`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`alertOrange`}</inlineCode>{` is now `}<inlineCode parentName="li">{`warning-orange-100`}</inlineCode></li>
      <li parentName="ul">{`These following colors now have shades `}{`[100, 60, 20]`}{`: cream, dark-teal, dark-blue, info-blue, error-red, success-green, warning-orange`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;