
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'ResponsiveScatterPlot with LightTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of the Nivo ResponsiveScatterPlot component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { scatterPlotDefaults } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsiveScatterPlot
            // add in varnish theme at top of all charts
            {...scatterPlotDefaults}
            // add options specific to your chart
            // use varnish values when it makes sense
            axisLeft={{
                legend: 'Size',
                legendPosition: 'middle',
                legendOffset: -48,
            }}
            axisBottom={{
                legend: 'Weight',
                legendPosition: 'middle',
                legendOffset: 40,
            }}
            margin={{
                top: 14,
                right: 24,
                bottom: 56,
                left: 64,
            }}
            // add data
            data={[
                {
                    id: 'group A',
                    data: [
                        {
                            x: 83,
                            y: 20,
                        },
                        {
                            x: 100,
                            y: 80,
                        },
                        {
                            x: 8,
                            y: 84,
                        },
                        {
                            x: 47,
                            y: 91,
                        },
                        {
                            x: 30,
                            y: 33,
                        },
                        {
                            x: 64,
                            y: 71,
                        },
                        {
                            x: 4,
                            y: 43,
                        },
                        {
                            x: 70,
                            y: 99,
                        },
                    ],
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;