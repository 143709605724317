import {
    Modal as AriaModal,
    ModalOverlay as AriaModalOverlay,
    type ModalOverlayProps as AriaModalOverlayProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { ModalHeading } from '@/components/modal/ModalHeading';
import { ModalActions } from '@/components/modal/ModalActions';
import { ModalContent } from '@/components/modal/ModalContent';
import { modalStyles, type ModalRecipeProps } from './modal.styles';

import { Dialog, type DialogProps } from '@/components/dialog/Dialog';
import { dialogStyles } from '@/components/dialog/dialog.styles';

interface ModalBaseClassNames {
    dialogClassName?: string;
    overlayClassName?: string;
}

interface ModalBaseProps
    extends Omit<AriaModalOverlayProps, 'className'>,
        ModalRecipeProps,
        ModalBaseClassNames,
        Pick<DialogProps, 'heading'> {
    className?: string; // RAC allows className to be a function, we could handle this, but not now
    children?: React.ReactNode; // RAC allows this to be a render function, we could support in the future
}

// various classNames
const ModalBase = ({
    className,
    style,
    overlayClassName,
    dialogClassName,
    heading,
    children,
    ...rest
}: ModalBaseProps) => {
    const [dialogVariantProps] = dialogStyles.splitVariantProps(rest);
    const [variantProps, localProps] = modalStyles.splitVariantProps(rest);
    const modalClassNames = modalStyles(variantProps);

    return (
        <AriaModalOverlay className={cx(modalClassNames.overlay, overlayClassName)} {...localProps}>
            <AriaModal className={cx(className, modalClassNames.modal)} style={style}>
                <Dialog
                    heading={heading}
                    className={cx(modalClassNames.dialog, dialogClassName)}
                    {...dialogVariantProps}>
                    {children}
                </Dialog>
            </AriaModal>
        </AriaModalOverlay>
    );
};

interface ModalClassNames extends Pick<DialogProps, 'actionsClassName'> {
    closeClassName?: string;
    headingClassName?: string;
    closeButtonClassName?: string;
    contentClassName?: string;
}

interface ModalProps extends ModalBaseProps, Pick<DialogProps, 'buttons'>, ModalClassNames {
    closeButton?: React.ReactNode;
    children: React.ReactNode; // Force ReactNode Override RAC children render functionality - at least for now.
}

// various classNames
const Modal = ({
    heading,
    closeButton,
    buttons,
    headingClassName,
    closeButtonClassName,
    actionsClassName,
    contentClassName,
    children,
    ...rest
}: ModalProps) => {
    const [modalVariantProps] = modalStyles.splitVariantProps(rest);

    const headingNode =
        typeof heading === 'string' ? (
            <ModalHeading
                closeButton={closeButton}
                className={headingClassName}
                closeButtonClassName={closeButtonClassName}>
                {heading}
            </ModalHeading>
        ) : (
            heading
        );

    const buttonsNode = buttons ? (
        <ModalActions className={actionsClassName} {...modalVariantProps}>
            {buttons}
        </ModalActions>
    ) : null;

    return (
        <ModalBase heading={headingNode} {...rest}>
            <ModalContent className={contentClassName} {...modalVariantProps}>
                {children}
            </ModalContent>
            {buttonsNode}
        </ModalBase>
    );
};

// re-export these with new names, cause why not?
export {
    DialogTrigger as ModalTrigger,
    type DialogTriggerProps as ModalTriggerProps,
} from '../dialog';

export { ModalBase, Modal, Modal as default };
export type { ModalBaseProps, ModalProps };
