
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'InlineCode'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import varnishComponents from '@allenai/varnish2/components';
import mui from '@mui/material';

const InlineCode = varnishComponents.InlineCode;
const Box = mui.Box;
const Stack = mui.Stack;

render(
    <Stack spacing={5}>
        <div>
            Light
            <br />
            The command <InlineCode>get_metrics(self, reset: bool = False)</InlineCode> has a
            default parameter.
        </div>
        <div>
            Dark
            <br />
            The command{' '}
            <InlineCode variant="dark">get_metrics(self, reset: bool = False)</InlineCode> has a
            default parameter.
        </div>
    </Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;