import React from 'react';

import type { PaletteModes } from '@allenai/varnish-theme';

const systemColorModeName = 'system';

type SystemColorMode = typeof systemColorModeName;
type ColorMode = PaletteModes | SystemColorMode;
type ExplicitColorMode = Exclude<ColorMode, SystemColorMode>;

const allColorModes: ColorMode[] = [systemColorModeName, 'light', 'dark'];

export interface ThemeContextType {
    colorMode: ColorMode;
    systemPrefersColorMode: ExplicitColorMode;
    computedColorMode: ExplicitColorMode;
    setColorMode: (_: ColorMode) => void;
    // theme -- default, pride, ...etc
    // setTheme
}

interface ThemeProviderProps extends React.PropsWithChildren {
    localStorageKey?: string;
    defaultColorMode?: ColorMode;
    colorModes?: ColorMode[];
    forceClassForSystem?: boolean;
    classElement?: HTMLElement;
}

const ThemeContext = React.createContext<ThemeContextType | null>(null);

// forceClasses = true ?
const ThemeProviderBase = ({
    localStorageKey = 'varnish-ui-theme',
    defaultColorMode = systemColorModeName,
    colorModes = allColorModes,
    forceClassForSystem = false,
    classElement,
    children,
}: ThemeProviderProps) => {
    const [colorMode, setColorMode] = React.useState<ColorMode>(defaultColorMode);

    // do we need to keep this in state? - should this be a ref?
    // and if the window / mq stuff can run outside effect - then we can use that here too
    const [systemPrefersColorMode, setSystemPrefersColorMode] =
        React.useState<ExplicitColorMode>('light');

    // derived state
    const computedColorMode =
        colorMode !== systemColorModeName ? colorMode : systemPrefersColorMode;

    const classElementNode = classElement ?? window.document.body;

    const setColorModeBrowser = React.useCallback(
        (newColorMode: ColorMode) => {
            const classList = classElementNode.classList;

            // we handle `systemColorModeName` (i.e. system, as in prefers: color-mode)
            // differently, it doesn't get classes
            if (forceClassForSystem || newColorMode !== systemColorModeName) {
                classList.add(newColorMode);
            }

            // allColorModes vs colorModes -- if the change between setting it could get weird
            // removing classes we weren't told about is also weird.
            const removeList = colorModes.filter((mode) => mode !== newColorMode);
            classList.remove(...removeList);
        },
        [colorModes, forceClassForSystem, classElementNode]
    );

    // how SSR is this?
    React.useLayoutEffect(() => {
        // light is default by proxy of it existing before prefers-color-scheme did
        // we check for dark, as explicity not light
        const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleMediaQueryChange = (query: MediaQueryListEvent | MediaQueryList) => {
            // here we are explicitly checking dark/light - could be other?
            setSystemPrefersColorMode(query.matches ? 'dark' : 'light');
        };
        handleMediaQueryChange(colorSchemeQuery);

        const item = window.localStorage.getItem(localStorageKey);
        let newColorMode: ColorMode = defaultColorMode;

        if (item) {
            newColorMode = (JSON.parse(item) || defaultColorMode) as ColorMode;
        }

        setColorModeBrowser(newColorMode);
        setColorMode(newColorMode);

        colorSchemeQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            colorSchemeQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, [defaultColorMode, setColorMode, setColorModeBrowser, localStorageKey]);

    const setColorModeState = (newColorMode: ColorMode) => {
        // Should this verify that it is valid?
        // For now, this is the programmers responsibility
        window.localStorage.setItem(localStorageKey, JSON.stringify(newColorMode));
        setColorModeBrowser(newColorMode);
        setColorMode(newColorMode);
    };

    return (
        <ThemeContext.Provider
            value={{
                colorMode,
                setColorMode: setColorModeState,
                systemPrefersColorMode,
                computedColorMode,
            }}>
            {children}
        </ThemeContext.Provider>
    );
};

const useTheme = () => {
    const context = React.useContext(ThemeContext);
    if (!context) {
        throw new Error('`useTheme()` must be used inside a `ThemeProvider`');
    }
    return context;
};
export { ThemeProviderBase, useTheme };
export type { ThemeProviderProps };
