
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Required Validation'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Set isRequired on the RadioGroup to require that a selection be made`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <form>
        <varnishUi.RadioGroup
            label="Favorite sports"
            errorMessage="Please select a sport"
            isRequired>
            <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>
        <br />
        <varnishUi.Button variant="outlined" type="submit">
            Submit
        </varnishUi.Button>
    </form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;