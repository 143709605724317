
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const CustomAlert = mui.styled(varnishUi.Alert)\`
    border: 2px dashed \${({ theme }) => theme.color.R5.hex};
    background-color: #CCC;
    font-family: monospace;
\`;

const CustomAlertTitle = mui.styled(varnishUi.AlertTitle)\`
    background-color: gray;
    border: 2px dashed white;
\`;

const CustomIcon = mui.styled(varnishUi.Icons.Check)\`
    color: aqua;
    border: 2px dashed #CC4444;
\`;

render(
    <varnishUi.Stack direction="row" spacing={25}>
        <CustomAlert icon={<CustomIcon />}>
            <CustomAlertTitle>Custom AlertTitle</CustomAlertTitle>
            Custom Alert
        </CustomAlert>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;