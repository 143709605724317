
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Drag and drop'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Table supports drag and drop interactions when the dragAndDropHooks prop is provided using the useDragAndDrop hook. Users can drop data on the table as a whole, on individual rows, insert new items between existing rows, or reorder rows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
    const [dataItems, setDataItems] = React.useState([
        { id: 1, name: "Games", date: "6/7/2020", type: "File folder" },
        { id: 2, name: "Program Files", date: "4/7/2021", type: "File folder" },
        { id: 3, name: "bootmgr", date: "11/20/2010", type: "System file" },
        { id: 4, name: "log.txt", date: "1/18/2016", type: "Text Document" }
    ]);

    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
        { id: 'date', name: 'Date Modified' }, // Updated key to match \`dataItems\`
    ];

    const getItems = (keys) => {
        return [...keys].map((key) => ({
            "text/plain": dataItems.find((item) => item.id === key)?.name || ""
        }));
    };

    const handleReorder = (e) => {
        setDataItems((prevItems) => {
            const movingKeys = Array.from(e.keys);
            const targetKey = e.target.key;
            const dropPosition = e.target.dropPosition;

            const movingItems = prevItems.filter((item) => movingKeys.includes(item.id));
            const remainingItems = prevItems.filter((item) => !movingKeys.includes(item.id));
            const targetIndex = remainingItems.findIndex((item) => item.id === targetKey);

            if (targetIndex === -1) return prevItems; // If target not found, return unchanged

            const newItems = [...remainingItems];

            if (dropPosition === "before") {
                newItems.splice(targetIndex, 0, ...movingItems);
            } else if (dropPosition === "after") {
                newItems.splice(targetIndex + 1, 0, ...movingItems);
            }

            return newItems;
        });
    };

    const { dragAndDropHooks } = varnishUi.useDragAndDrop({
        getItems,
        onReorder: handleReorder
    });

    return (
        <varnishUi.Table
            aria-label="Files"
            selectionMode="multiple"
            dragAndDropHooks={dragAndDropHooks}
            headers={headers}
            dataItems={dataItems} />
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;