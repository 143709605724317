
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Variants'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Chip component has 2 variants: contained and outlined(default).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';
import Theme from \`@allenai/varnish-theme\`;


const Demo = () => {
    return (
        <varnishUi.Stack direction="row" spacing={16}>
                <varnishUi.ChipGroup variant="contained" items={[{id: 1, text: "contained"}]} />
                <varnishUi.ChipGroup variant="outlined" items={[{id: 1, text: "outlined"}]} />
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;