import {
    OverlayArrow as AriaOverlayArrow,
    type OverlayArrowProps as AriaOverlayArrowProps,
} from 'react-aria-components';

interface OverlayArrowProps extends Omit<AriaOverlayArrowProps, 'className' | 'children'> {
    className?: string; // RAC allows className to be a function, we could support in the future, but not now.
}

const OverlayArrow = ({ className, ...rest }: OverlayArrowProps) => {
    return (
        <AriaOverlayArrow className={className} {...rest}>
            {/* Final design? */}
            <svg width="12" height="7" viewBox="0 0 12 7">
                <path
                    d="M-5.24537e-07 0.899995L12 0.899994L6 6.89999L-5.24537e-07 0.899995Z"
                    fill="currentColor"
                />
            </svg>
        </AriaOverlayArrow>
    );
};

export type { OverlayArrowProps };
export { OverlayArrow, OverlayArrow as default };
