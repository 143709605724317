
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'With Tagline'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This shows how to render a header with an application tagline.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

const { Header, logos, Content } = varnishComponents;

render(
    <div>
        <Header>
            <Header.Columns columns="auto 1fr">
                <Header.Logo
                    href="http://allenai.org"
                    label={<Header.AppName>Title</Header.AppName>}
                    tagline={<Header.AppTagline>Tagline</Header.AppTagline>}>
                    <logos.AI2Logo includeText={false} />
                </Header.Logo>
            </Header.Columns>
        </Header>
        <Content>Content!</Content>
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;