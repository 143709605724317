
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { IconExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Icon',
  cols: 2,
  iconSrc: './text-14px.svg',
  tag: {
    label: 'New',
    color: 'secondary'
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Icon`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` component includes default varnish-icons as well as functions to wrap and create React components
from third parties (or any SVG).`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={IconExamples} mdxType="Examples" />
    <h4>{`Support Components`}</h4>
    <p>{`This segment is taken from the varnish-icons docs located at `}<a parentName="p" {...{
        "href": "https://github.com/allenai/varnish/tree/main/packages/varnish-ui/src/components/icon"
      }}>{`@allenai/varnish-ui/icon`}</a></p>
    <p>{`For full documentaiton on the varnish-ui icon react components, please visit the README.md there.`}</p>
    <h4>{`SpriteProvider`}</h4>
    <p>{`SpriteProvider maintains the the current state of the sprite config, the properties (with their defaults) are:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    idPrefix: 'vui-svg-',
    external: true,
    sprite: '/varnish-icon-sprite.svg',
}
`}</code></pre>
    <p><em parentName="p">{`Passed properties are `}<strong parentName="em">{`merged`}</strong>{` with the defaults`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SpriteProvider idPrefix="custom-prefix" external={true} sprite="/sprite.svg">
    //...
</SpriteProvider>
`}</code></pre>
    <h4>{`SVGSpriteLink`}</h4>
    <p>{`This is just a helper to preload the SVG, it needs to be inside the `}<inlineCode parentName="p">{`SpriteProvider`}</inlineCode>{` to work correctly, and then it will set the `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` href correctly.`}</p>
    <p>{`For React versions < 19, you will need to use something like `}<inlineCode parentName="p">{`<Helmet>`}</inlineCode>{` to ensure it is put in the document `}<inlineCode parentName="p">{`<head>`}</inlineCode>{`.`}</p>
    <h4>{`How they work together`}</h4>
    <p>{`Because sprites work by targeting an `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute, they `}<inlineCode parentName="p">{`id`}</inlineCode>{` on generation and on use need to match.
The defaults will work out of the box, however if you want to change the idPrefix on one, it will need to match on the other.`}</p>
    <p>{`You can specify the sprite in cases like with webpack if you need to serve it through the bundler, you can pass the webpack URL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const varnishSprite = require('@allenai/varnish-ui/varnish-ui-sprite.svg');
function App() {
    return (
        <SpriteProvider sprite={varnishSprite}>
            <SVGSpriteLink />
            // ... rest of app
        </SpriteProvider>
    );
}
`}</code></pre>
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`color?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`primary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`secondary`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`tertiary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color of icon, no color property will use currentColor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`size?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`small`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`medium`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inherit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Size of the icon uses `}<inlineCode parentName="td">{`font-size`}</inlineCode>{` (to match MUI's behavoir). `}<inlineCode parentName="td">{`inherit`}</inlineCode>{` is `}<inlineCode parentName="td">{`1em`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;