import * as React from 'react';

interface SpriteConfig {
    idPrefix?: string;
    external?: boolean;
    sprite?: string | null;
}

const defaultSpriteState = {
    idPrefix: 'vui-svg-',
    external: true,
    sprite: '/varnish-ui-sprite.svg',
};

const SpriteContext = React.createContext<SpriteConfig>(defaultSpriteState);

const SpriteProvider = ({ children, ...spriteState }: React.PropsWithChildren<SpriteConfig>) => {
    const spriteStateMerged = {
        ...defaultSpriteState,
        ...spriteState,
    };
    return <SpriteContext.Provider value={spriteStateMerged}>{children}</SpriteContext.Provider>;
};

const useSpriteConfig = () => {
    return React.useContext(SpriteContext);
};

export { SpriteProvider, useSpriteConfig };
