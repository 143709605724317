
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Logo usage examples'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><inlineCode parentName="p">{`Logo`}</inlineCode>{` component`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const height = emotionCss.css\`
    height: 20px;
    width: auto;
\`;

const allLogos = Object.keys(varnishUi.Logos);
const logosColOne = allLogos.slice(0, Math.ceil(allLogos.length / 2));
const logosColTwo = allLogos.slice(Math.ceil(allLogos.length / 2));

render(
    <varnishUi.Stack spacing={32} direction="row">
        <varnishUi.Stack spacing={32}>
            {logosColOne.map((logo) => {
                const Logo = varnishUi.Logos[logo];
                return <Logo className={height} />;
            })}
        </varnishUi.Stack>
        <varnishUi.Stack spacing={32}>
            {logosColTwo.map((logo) => {
                const Logo = varnishUi.Logos[logo];
                return <Logo className={height} />;
            })}
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;