import { merge } from 'lodash-es';

import type { HeatMapCommonProps, HeatMapDatum } from '@nivo/heatmap';

import { themeDark, themeLight } from '../nivo-theme';
import { colorIdsToValues } from '../utils';
import { DarkValueLabelColor, LightValueLabelColor } from './global';

export const heatMapDefaults: Partial<HeatMapCommonProps<HeatMapDatum>> = {
    theme: themeLight,
    xInnerPadding: 0.1,
    xOuterPadding: 0.1,
    yInnerPadding: 0.1,
    yOuterPadding: 0.1,
    labelTextColor: LightValueLabelColor,
    hoverTarget: 'cell',
    colors: { type: 'quantize', colors: colorIdsToValues('B6', 'B7', 'B8', 'B9', 'B10') },
};

export const heatMapDefaultsDark = merge({}, heatMapDefaults, {
    theme: themeDark,
    labelTextColor: DarkValueLabelColor,
    colors: { type: 'quantize', colors: colorIdsToValues('B1', 'B2', 'B3', 'B4', 'B5') },
} satisfies typeof heatMapDefaults);
