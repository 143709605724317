import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 * Checkbox groups can be built in HTML with the <fieldset> and <input> elements, however these
 * can be difficult to style. CheckboxGroup helps achieve accessible checkbox groups that can be
 * styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/CheckboxGroup.html
 */

const checkboxGroupRecipe = sva({
    slots: ['root', 'label', 'description', 'error'],
    base: {
        root: {
            display: 'flex',
            flexDir: 'column',
            gap: '[2px]',
        },
        label: {},
        description: {},
        error: {},
    },
});

type CheckboxGroupRecipeProps = RecipeVariantProps<typeof checkboxGroupRecipe>;

export { checkboxGroupRecipe, checkboxGroupRecipe as default };
export type { CheckboxGroupRecipeProps };
