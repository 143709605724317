import { Code, InlineCode } from '@allenai/varnish2/components';
import { mdx } from '@mdx-js/react';
import { styled } from '@mui/material';
import slug from 'slug';

import { ComponentType } from 'react';

import { DefaultLiveProvider } from './shared';
import { LinkedHeader } from './shared/LinkedHeaders';

// grid of examples
export const Examples = (props: {
    examples: { frontMatter: { order: number; title: string }; default: ComponentType }[];
    cols: number;
}) => {
    return (
        <ExampleGrid cols={props.cols}>
            {props.examples
                .sort((a, b) => a.frontMatter.order - b.frontMatter.order)
                .map((Example, i) => (
                    <ExampleWrapper key={i} id={slug(Example.frontMatter.title, '_')}>
                        <ExampleTitle>{Example.frontMatter.title}</ExampleTitle>
                        <Example.default />
                    </ExampleWrapper>
                ))}
        </ExampleGrid>
    );
};

// DefaultLiveProvider used with MDX content
export const LiveProvider = (props: {
    children: string;
    live: boolean;
    dark: boolean;
    lightAndDark: boolean;
}) => {
    if (props.live) {
        return (
            <DefaultLiveProvider
                lightAndDark={props.lightAndDark}
                code={props.children.trim()}
                transformCode={(code: string) =>
                    // remove import statements
                    '/** @jsx mdx */' + code.replace(/^import .+$/gm, '')
                }
                scope={{ mdx }}
                noInline={true}
            />
        );
    } else if (props.dark) {
        return <Code variant="dark">{props.children}</Code>;
    }
    return <InlineCode>{props.children}</InlineCode>;
};

// use column-count to allow demos to 'flow'
export const ExampleGrid = styled('div')<{ cols: number }>`
    width: 100%;
    column-count: ${({ cols }) => cols};
    column-gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.down('lg')} {
        column-count: 1;
    }

    /* this creates a new stacking context to avoid going above the appbar */
    isolation: isolate;
`;

export const ExampleTitle = ({ children }: { children: string }) => (
    <LinkedHeader
        variant="h4"
        m={(theme) => theme.spacing(0, 0, 1.5)}
        fontWeight={(theme) => theme.typography.fontWeightBold}>
        {children}
    </LinkedHeader>
);

export const ExampleWrapper = styled('div')`
    padding: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(4.5)};
    border: 1px solid ${({ theme }) => theme.palette.grey.A200};
    // inline-block allows demos to not break across columns
    display: inline-block;
    width: 100%;
`;
