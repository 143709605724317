import * as Custom from './demo/custom.mdx';
import * as Default from './demo/default.mdx';
import * as JustBanner from './demo/justBanner.mdx';
import * as WithLogo from './demo/withLogo.mdx';
import * as WithMenu from './demo/withMenu.mdx';
import * as WithTagLine from './demo/withTagline.mdx';
import * as WithHideOnScroll from './demo/withHideOnScroll.mdx';

export const AI2HeaderExamples = [
    Custom,
    Default,
    JustBanner,
    WithLogo,
    WithMenu,
    WithTagLine,
    WithHideOnScroll,
];
