
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 16,
  title: 'How do I use styled components for custom padding?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import mui from '@mui/material';

const CustomButton = mui.styled(mui.Button)\`
    width: 50%;
    text-align: center;
    background-color: \${({ theme }) => theme.color.T8.hex};
    padding: \${({ theme }) => theme.spacing(3)};
    font-size: \${({ theme }) => theme.typography.h4.fontSize};
    color: black;
\`;

render(
    <div style={{ width: '100%' }}>
        <div>
            <CustomButton>👾 🤖 👽 Custom 👾 🤖 👽</CustomButton>
        </div>
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;