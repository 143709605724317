
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The modal color scheme is color aware, it can show the default color scheme, or reversed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Modal color \`default\`</varnishUi.Button>
            <varnishUi.Modal isDismissable heading="Modal Heading" size="small">
                <p>Same color scheme as current theme</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Modal color \`reversed\`</varnishUi.Button>
            <varnishUi.Modal isDismissable heading="Modal Heading" color="reversed" size="small">
                <p>Reveresed color scheme</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;