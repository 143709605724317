
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'eslint-config-varnish',
  type: 'Overview',
  title: 'Getting Started',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`ESLint Config Varnish`}</h2>
    <p>{`AI2 Varnish ESLint Package`}</p>
    <p>{`This is documentation for the ESLint extension packages that Varnish provides.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`In all AI2 application. This package is used to lint and reformat code.`}</p>
    <h3>{`Setup and Usage`}</h3>
    <p>{`Varnish proves `}<inlineCode parentName="p">{`eslint`}</inlineCode>{` configurations that you can utilize with a few simple setup steps. For the quickest setup, do the following:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install Varnish ESLint:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`~ yarn add @allenai/eslint-config-varnish
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add targets for linting and reformatting code to your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`"scripts": {
    "lint": "eslint '**/*.{js,ts,tsx,json}' && echo '💫  Lint complete.'",
    "lint:fix": "eslint '**/*.{js,ts,tsx,json}' --fix && echo '🛠  Lint --fix complete.'",
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Try it out:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`# See what's wrong
~ yarn lint

# Reformat and fix things
~ yarn lint:fix
`}</code></pre>
      </li>
    </ol>
    <p>{`For more information about Varnish ESLint and further customization options, visit the `}<a parentName="p" {...{
        "href": "https://github.com/allenai/varnish/tree/main/packages/eslint-config-varnish/README.md"
      }}>{`documentation`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;