
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
        padding: 2px;
    \`;
};

const labelClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.G6.value};
        padding: 2px;
        margin: 2px;
    \`;
};

const descriptionClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.B6.value};
        padding: 2px;
        margin: 2px;
    \`;
};

const errorClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.O6.value};
        padding: 2px;
        margin: 2px;
    \`;
};

const radioClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.G6.value};
        padding: 2px;
        margin: 2px;
    \`;
}

const radioCircleClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
        padding: 2px;
        margin: 2px;
    \`;
}

render(
    <form>
        <varnishUi.RadioGroup label="Favorite sports"
            description="Select your favorite sports"
            errorMessage="Please select a sport"
            className={className()}
            labelClassName={labelClassName()}
            descriptionClassName={descriptionClassName()}
            errorClassName={errorClassName()}
            isRequired>
            <varnishUi.Radio value="soccer" className={radioClassName()} circleClassName={radioCircleClassName()}>
                Soccer
            </varnishUi.Radio>
            <varnishUi.Radio value="baseball" className={radioClassName()} circleClassName={radioCircleClassName()}>
                Baseball
            </varnishUi.Radio>
            <varnishUi.Radio value="basketball" className={radioClassName()} circleClassName={radioCircleClassName()}>
                Basketball
            </varnishUi.Radio>
        </varnishUi.RadioGroup>
        <br />
        <varnishUi.Button variant="outlined" type="submit">
            Submit
        </varnishUi.Button>
    </form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;