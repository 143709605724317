import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const modalStyles = sva({
    slots: ['overlay', 'modal', 'dialog', 'heading', 'content', 'actions', 'closeButton'],
    base: {
        overlay: {
            inset: '[0]',
            position: 'fixed',
            display: 'flex',
            zIndex: 'modal',
            alignItems: 'center',
            justifyContent: 'center',
            // maybe move to overlay slot
            backgroundColor: '[black/80]',
        },
        modal: {
            boxSizing: 'border-box',
            display: 'flex',
            '--modal-margin': '32px',
            margin: 'var(--modal-margin)',
            maxHeight: '[calc(100% - var(--modal-margin))]',

            borderRadius: 'sm',
            outline: 'none', // we don't want a focus ring on the div
        },
        dialog: {},
        heading: {
            padding: '[8px 16px]',

            display: 'flex',
            columnGap: '[8px]', // TODO: token
            justifyContent: 'space-between',
            alignItems: 'start',
        },
        content: {
            overflowY: 'auto',
            // check into this:
            // scrollbarGutter: 'stable both-edges',
            paddingInline: '[16px]',
        },
        actions: {
            padding: '[8px 16px]',
        },
        closeButton: {
            alignSelf: 'start',
            padding: '0',
        },
    },
    variants: {
        fullWidth: {
            true: {
                modal: {
                    flex: '1',
                    width: '[100%]',
                },
            },
        },
        size: {
            small: {
                modal: {
                    maxWidth: '[300px]',
                },
            },
            medium: {
                modal: {
                    maxWidth: '[500px]',
                },
            },
            large: {
                modal: {
                    maxWidth: '[800px]',
                },
            },
            // maybe? fullScreen?
            // full: {
            //     modal: {
            //         width: '[100%]',
            //         height: '[100%]',
            //         margin: '[0]',
            //     },
            // },
        },
        color: {
            default: {
                modal: {
                    backgroundColor: 'background',
                    color: 'text.primary',
                },
            },
            reversed: {
                modal: {
                    backgroundColor: 'background.reversed',
                    color: 'text.primary.reversed',
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        color: 'default',
    },
});

type ModalRecipeProps = Exclude<RecipeVariantProps<typeof modalStyles>, undefined>;

export { modalStyles };
export type { ModalRecipeProps };
