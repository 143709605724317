import { ReactNode, createContext } from 'react';
import {
    CheckboxGroup as AriaCheckboxGroup,
    CheckboxGroupProps as AriaCheckboxGroupProps,
    ValidationResult,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { Label } from '@/components/field/label/Label';
import { FieldError } from '@/components/field/fieldError/FieldError';
import { Description } from '@/components/field/description/Description';
import { checkboxGroupRecipe } from '@/components/checkboxGroup/checkboxGroup.styles';
import { checkboxRecipe, type CheckboxRecipeProps } from '@/components/checkbox/checkbox.styles';

export const CheckboxVariantContext = createContext<CheckboxRecipeProps>(undefined);

/**
 * Checkbox groups can be built in HTML with the <fieldset> and <input> elements, however these
 * can be difficult to style. CheckboxGroup helps achieve accessible checkbox groups that can be
 * styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/CheckboxGroup.html
 */

type CheckboxGroupProps = Omit<AriaCheckboxGroupProps, 'children'> &
    CheckboxRecipeProps & {
        label?: string;
        children?: ReactNode;
        description?: string;
        errorMessage?: string | ((validation: ValidationResult) => string);
        className?: string;
        labelClassName?: string;
        descriptionClassName?: string;
        errorClassName?: string;
    };

function CheckboxGroup({
    label,
    children,
    description,
    errorMessage,
    className,
    labelClassName,
    descriptionClassName,
    errorClassName,
    ...props
}: CheckboxGroupProps) {
    const [groupVariantProps, rest] = checkboxGroupRecipe.splitVariantProps(props);
    const [checkboxVariantProps, localProps] = checkboxRecipe.splitVariantProps(rest);

    const { size } = props;

    const recipeClassNames = checkboxGroupRecipe(groupVariantProps);
    return (
        <AriaCheckboxGroup {...localProps} className={cx(recipeClassNames.root, className)}>
            <CheckboxVariantContext.Provider value={checkboxVariantProps}>
                <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                    {label}
                </Label>
                {children}
                <Description
                    size={size}
                    className={cx(recipeClassNames.description, descriptionClassName)}>
                    {description}
                </Description>
                <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                    {errorMessage}
                </FieldError>
            </CheckboxVariantContext.Provider>
        </AriaCheckboxGroup>
    );
}

export { CheckboxGroup, CheckboxGroup as default };
export type { CheckboxGroupProps };
