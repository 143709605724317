import { TabList as AriaTabList, TabListProps as AriaTabListProps } from 'react-aria-components';
import { css } from '@allenai/varnish-panda-runtime/css';

import { PropsWithChildren } from 'react';

import { cx } from '@/utils/cx';

import TabsRecipeProps, { tabsRecipe } from './tabs.styles';

type TabListProps<T extends object> = {
    className?: string;
    children?: React.ReactNode;
} & AriaTabListProps<T> &
    TabsRecipeProps;

const TabList = <T extends object>({
    className,
    children,
    ...rest
}: PropsWithChildren<TabListProps<T>>) => {
    const [variantProps, localProps] = tabsRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = tabsRecipe.raw(variantProps);

    return (
        <AriaTabList {...localProps} className={cx(css(recipeClassNames.tabList), className)}>
            {children}
        </AriaTabList>
    );
};

export { TabList };
export type { TabListProps };
