
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Shapes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Use the shape prop to change the border radius. The select component has a box(default) shape, and a rounded shape.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const exampleItems = ['Cat', 'Dog', 'Alligator', 'Llama', 'Lizard', 'Frog'];
render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Select shape="box" placeholder="box" items={exampleItems} />
            <varnishUi.Select shape="rounded" placeholder="rounded" items={exampleItems} />
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Select
                shape="box"
                placeholder="box"
                variant="outlined"
                items={exampleItems}
            />
            <varnishUi.Select
                shape="rounded"
                placeholder="rounded"
                variant="outlined"
                items={exampleItems}
            />
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;