
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'ResponsiveScatterPlot with DarkTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A complex example of the Nivo ResponsiveScatterPlot component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { scatterPlotDefaultsDark } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 350px;
    max-width: 100%;
    background: \${({ theme }) => theme.palette.background.reversed};
    padding: \${({ theme }) => theme.spacing['3']};
\`;

const data = [
    {
        id: 'group A',
        data: [
            {
                x: 83,
                y: 20,
            },
            {
                x: 100,
                y: 80,
            },
            {
                x: 8,
                y: 84,
            },
            {
                x: 47,
                y: 91,
            },
            {
                x: 30,
                y: 33,
            },
            {
                x: 64,
                y: 71,
            },
            {
                x: 4,
                y: 43,
            },
            {
                x: 70,
                y: 119,
            },
            {
                x: 48,
                y: 2,
            },
            {
                x: 84,
                y: 66,
            },
            {
                x: 26,
                y: 105,
            },
            {
                x: 23,
                y: 66,
            },
            {
                x: 32,
                y: 50,
            },
            {
                x: 65,
                y: 9,
            },
            {
                x: 13,
                y: 52,
            },
            {
                x: 29,
                y: 48,
            },
            {
                x: 44,
                y: 62,
            },
            {
                x: 70,
                y: 100,
            },
            {
                x: 91,
                y: 46,
            },
            {
                x: 25,
                y: 69,
            },
            {
                x: 98,
                y: 94,
            },
            {
                x: 20,
                y: 84,
            },
            {
                x: 31,
                y: 10,
            },
            {
                x: 60,
                y: 44,
            },
            {
                x: 5,
                y: 91,
            },
            {
                x: 66,
                y: 105,
            },
            {
                x: 55,
                y: 16,
            },
            {
                x: 36,
                y: 3,
            },
            {
                x: 19,
                y: 5,
            },
            {
                x: 44,
                y: 12,
            },
            {
                x: 42,
                y: 72,
            },
            {
                x: 23,
                y: 13,
            },
            {
                x: 56,
                y: 49,
            },
            {
                x: 19,
                y: 95,
            },
            {
                x: 13,
                y: 105,
            },
            {
                x: 38,
                y: 10,
            },
            {
                x: 29,
                y: 74,
            },
            {
                x: 57,
                y: 116,
            },
            {
                x: 9,
                y: 68,
            },
            {
                x: 43,
                y: 21,
            },
            {
                x: 26,
                y: 95,
            },
            {
                x: 55,
                y: 43,
            },
            {
                x: 100,
                y: 20,
            },
            {
                x: 96,
                y: 68,
            },
            {
                x: 1,
                y: 14,
            },
            {
                x: 100,
                y: 0,
            },
            {
                x: 27,
                y: 105,
            },
            {
                x: 43,
                y: 64,
            },
            {
                x: 4,
                y: 119,
            },
            {
                x: 93,
                y: 100,
            },
        ],
    },
    {
        id: 'group B',
        data: [
            {
                x: 12,
                y: 5,
            },
            {
                x: 40,
                y: 26,
            },
            {
                x: 100,
                y: 64,
            },
            {
                x: 77,
                y: 25,
            },
            {
                x: 98,
                y: 16,
            },
            {
                x: 36,
                y: 90,
            },
            {
                x: 62,
                y: 112,
            },
            {
                x: 94,
                y: 111,
            },
            {
                x: 76,
                y: 55,
            },
            {
                x: 26,
                y: 30,
            },
            {
                x: 61,
                y: 78,
            },
            {
                x: 65,
                y: 46,
            },
            {
                x: 37,
                y: 27,
            },
            {
                x: 78,
                y: 94,
            },
            {
                x: 54,
                y: 88,
            },
            {
                x: 22,
                y: 21,
            },
            {
                x: 75,
                y: 59,
            },
            {
                x: 66,
                y: 7,
            },
            {
                x: 93,
                y: 96,
            },
            {
                x: 38,
                y: 22,
            },
            {
                x: 7,
                y: 24,
            },
            {
                x: 70,
                y: 35,
            },
            {
                x: 5,
                y: 32,
            },
            {
                x: 20,
                y: 25,
            },
            {
                x: 58,
                y: 66,
            },
            {
                x: 35,
                y: 22,
            },
            {
                x: 69,
                y: 81,
            },
            {
                x: 81,
                y: 9,
            },
            {
                x: 71,
                y: 18,
            },
            {
                x: 18,
                y: 31,
            },
            {
                x: 60,
                y: 49,
            },
            {
                x: 7,
                y: 93,
            },
            {
                x: 33,
                y: 112,
            },
            {
                x: 21,
                y: 36,
            },
            {
                x: 60,
                y: 32,
            },
            {
                x: 4,
                y: 117,
            },
            {
                x: 19,
                y: 119,
            },
            {
                x: 52,
                y: 75,
            },
            {
                x: 68,
                y: 12,
            },
            {
                x: 58,
                y: 36,
            },
            {
                x: 3,
                y: 50,
            },
            {
                x: 10,
                y: 38,
            },
            {
                x: 13,
                y: 67,
            },
            {
                x: 77,
                y: 52,
            },
            {
                x: 11,
                y: 86,
            },
            {
                x: 71,
                y: 31,
            },
            {
                x: 15,
                y: 68,
            },
            {
                x: 90,
                y: 80,
            },
            {
                x: 66,
                y: 34,
            },
            {
                x: 95,
                y: 71,
            },
        ],
    },
    {
        id: 'group C',
        data: [
            {
                x: 45,
                y: 120,
            },
            {
                x: 45,
                y: 93,
            },
            {
                x: 93,
                y: 36,
            },
            {
                x: 41,
                y: 74,
            },
            {
                x: 92,
                y: 63,
            },
            {
                x: 98,
                y: 49,
            },
            {
                x: 100,
                y: 20,
            },
            {
                x: 86,
                y: 108,
            },
            {
                x: 12,
                y: 4,
            },
            {
                x: 1,
                y: 68,
            },
            {
                x: 5,
                y: 17,
            },
            {
                x: 45,
                y: 64,
            },
            {
                x: 54,
                y: 56,
            },
            {
                x: 79,
                y: 29,
            },
            {
                x: 38,
                y: 28,
            },
            {
                x: 19,
                y: 48,
            },
            {
                x: 80,
                y: 63,
            },
            {
                x: 75,
                y: 65,
            },
            {
                x: 1,
                y: 49,
            },
            {
                x: 63,
                y: 60,
            },
            {
                x: 83,
                y: 98,
            },
            {
                x: 3,
                y: 80,
            },
            {
                x: 85,
                y: 6,
            },
            {
                x: 19,
                y: 25,
            },
            {
                x: 36,
                y: 97,
            },
            {
                x: 78,
                y: 38,
            },
            {
                x: 27,
                y: 116,
            },
            {
                x: 5,
                y: 47,
            },
            {
                x: 0,
                y: 106,
            },
            {
                x: 38,
                y: 30,
            },
            {
                x: 30,
                y: 97,
            },
            {
                x: 41,
                y: 64,
            },
            {
                x: 56,
                y: 111,
            },
            {
                x: 19,
                y: 77,
            },
            {
                x: 36,
                y: 27,
            },
            {
                x: 89,
                y: 37,
            },
            {
                x: 55,
                y: 60,
            },
            {
                x: 72,
                y: 22,
            },
            {
                x: 15,
                y: 106,
            },
            {
                x: 35,
                y: 43,
            },
            {
                x: 16,
                y: 76,
            },
            {
                x: 11,
                y: 92,
            },
            {
                x: 54,
                y: 101,
            },
            {
                x: 64,
                y: 53,
            },
            {
                x: 30,
                y: 114,
            },
            {
                x: 54,
                y: 113,
            },
            {
                x: 25,
                y: 5,
            },
            {
                x: 28,
                y: 101,
            },
            {
                x: 9,
                y: 101,
            },
            {
                x: 72,
                y: 109,
            },
        ],
    },
    {
        id: 'group D',
        data: [
            {
                x: 54,
                y: 1,
            },
            {
                x: 7,
                y: 43,
            },
            {
                x: 97,
                y: 41,
            },
            {
                x: 11,
                y: 39,
            },
            {
                x: 87,
                y: 47,
            },
            {
                x: 16,
                y: 104,
            },
            {
                x: 15,
                y: 23,
            },
            {
                x: 71,
                y: 67,
            },
            {
                x: 50,
                y: 33,
            },
            {
                x: 56,
                y: 37,
            },
            {
                x: 30,
                y: 116,
            },
            {
                x: 13,
                y: 119,
            },
            {
                x: 62,
                y: 4,
            },
            {
                x: 13,
                y: 105,
            },
            {
                x: 39,
                y: 115,
            },
            {
                x: 48,
                y: 36,
            },
            {
                x: 37,
                y: 48,
            },
            {
                x: 91,
                y: 33,
            },
            {
                x: 40,
                y: 55,
            },
            {
                x: 78,
                y: 71,
            },
            {
                x: 63,
                y: 73,
            },
            {
                x: 22,
                y: 86,
            },
            {
                x: 36,
                y: 118,
            },
            {
                x: 32,
                y: 72,
            },
            {
                x: 57,
                y: 38,
            },
            {
                x: 91,
                y: 21,
            },
            {
                x: 98,
                y: 85,
            },
            {
                x: 73,
                y: 116,
            },
            {
                x: 95,
                y: 25,
            },
            {
                x: 18,
                y: 88,
            },
            {
                x: 57,
                y: 47,
            },
            {
                x: 40,
                y: 99,
            },
            {
                x: 76,
                y: 86,
            },
            {
                x: 97,
                y: 10,
            },
            {
                x: 0,
                y: 76,
            },
            {
                x: 90,
                y: 95,
            },
            {
                x: 21,
                y: 83,
            },
            {
                x: 55,
                y: 52,
            },
            {
                x: 12,
                y: 95,
            },
            {
                x: 15,
                y: 8,
            },
            {
                x: 62,
                y: 43,
            },
            {
                x: 65,
                y: 99,
            },
            {
                x: 56,
                y: 10,
            },
            {
                x: 49,
                y: 69,
            },
            {
                x: 22,
                y: 44,
            },
            {
                x: 77,
                y: 70,
            },
            {
                x: 36,
                y: 39,
            },
            {
                x: 77,
                y: 81,
            },
            {
                x: 93,
                y: 4,
            },
            {
                x: 32,
                y: 30,
            },
        ],
    },
];

render(
    <ChartWrapper>
        <ResponsiveScatterPlot
            // add in varnish theme at top of all charts
            {...scatterPlotDefaultsDark}
            // add options specific to your chart
            // use varnish values when it makes sense
            margin={{
                top: 32,
                right: 24,
                bottom: 64,
                left: 64,
            }}
            axisLeft={{
                format: (d) => new Intl.NumberFormat().format(d),
                tickValues: 5,
                legend: 'Size',
                legendPosition: 'middle',
                legendOffset: -48,
            }}
            axisBottom={{
                format: (d) => new Intl.NumberFormat().format(d),
                tickValues: 7,
                legend: 'Weight',
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    translateX: 0,
                    translateY: 64,
                    itemWidth: 56,
                    itemHeight: 24,
                    itemsSpacing: 32,
                    symbolSize: 14,
                },
            ]}
            // add data
            data={data}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;