export const PadDiv = ({
    bgColor,
    padding = '5px',
    width,
    height,
    children,
}: {
    padding?: string;
    bgColor?: string;
    width?: string;
    height?: string;
} & React.PropsWithChildren) => {
    const styles = {
        padding,
        backgroundColor: bgColor === 'darkGreen' ? '#0A3235' : undefined,
        color: bgColor === 'darkGreen' ? '#FFF' : undefined,
        width,
        height,
    };
    return <div style={styles}>{children}</div>;
};
