
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider
const labelClassName = 'myLabel';
const outputClassName = 'myOutput';
const controlClassName = 'myControl';
const trackClassName = 'myTrack';
const indicatorClassName = 'myIndicator';
const thumbClassName = 'myThumb';

const CustomSlider = mui.styled(varnishUi.Slider)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};
    padding: 2px;

    .\${labelClassName} {
        border: 1px dashed \${({ theme }) => theme.color.G5.hex};
        padding: 2px;
        margin: 2px;
    }
    .\${outputClassName} {
        border: 1px dashed \${({ theme }) => theme.color.B5.hex};
        padding: 2px;
        margin: 2px;
    }
    .\${controlClassName} {
        border: 1px dashed \${({ theme }) => theme.color.O5.hex};
        padding: 2px;
        margin: 2px;
    }
    .\${trackClassName} {
        background-color: \${({ theme }) => theme.color.R5.hex};
    }
    .\${indicatorClassName} {
        background-color: \${({ theme }) => theme.color.B5.hex};
    }
    .\${thumbClassName} {
        border: 2px dashed \${({ theme }) => theme.color.T5.hex};
        padding: 2px;
        border-radius: 0;
    }
\`;

render(
    <CustomSlider
        defaultValue={[37, 55]}
        color="primary"
        label="Multiple"
        labelClassName={labelClassName}
        outputClassName={outputClassName}
        controlClassName={controlClassName}
        trackClassName={trackClassName}
        indicatorClassName={indicatorClassName}
        thumbClassName={thumbClassName}
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;