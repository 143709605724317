
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from "@allenai/varnish-ui";
import * as mui from "@mui/material";

// This is an example of using mui styles ant the mui theme provider

const exampleHeaderClassName = 'myHeader';
const exampleContentClassName = 'myContent';

const CustomDrawer = mui.styled(varnishUi.Drawer)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};
    padding: 10px;
    .\${exampleHeaderClassName} {
        border: 2px dashed orange;
    }
    .\${exampleContentClassName} {
        border: 2px dashed yellow;
    }

\`;

const Demo = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
          <varnishUi.Button variant="outlined" color="primary" onClick={() => setOpen(true)}>Drawer</Button>
          <CustomDrawer
                isOpen={open}
                onClose={() => setOpen(false)}
                headerClassName={exampleHeaderClassName}
                contentClassName={exampleContentClassName} />
        </>
    )
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;