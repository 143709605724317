
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Links'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Table rows may also be links to another page or website. This can be achieved by passing the href prop to the Row component.
Links behave the same way as described above for row actions depending on the selectionMode and selectionBehavior.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'url', name: 'URL' },
        { id: 'dateAdded', name: 'Date Added' },
    ];

    const dataItems = [
        { id: '1', name: 'Adobe', url: 'https://adobe.com/', dateAdded: 'January 28, 2023' },
        { id: '2', name: 'Google', url: 'https://google.com/', dateAdded: 'April 5, 2023' },
        { id: '3', name: 'New York Times', url: 'https://nytimes.com/', dateAdded: 'July 12, 2023' },
    ];
    return (
        <varnishUi.Table aria-label="Files" headers={headers} dataItems={dataItems} />
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;