import * as Default from './demo/default.mdx';
import * as ContextProvider from './demo/contextProvider.mdx';
import * as Action from './demo/action.mdx';
import * as Shape from './demo/shape.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StyledCss from './demo/stylingStyled.mdx';
import * as SnackbarListStylingCss from './demo/snackbarList/stylingCss.mdx';
import * as SnackbarListStyledCss from './demo/snackbarList/stylingStyled.mdx';

export const SnackbarExamples = [
    Default,
    ContextProvider,
    Action,
    Shape,
    StylingCss,
    StyledCss,
    SnackbarListStylingCss,
    SnackbarListStyledCss,
];
