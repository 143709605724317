
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Default'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import mui from '@mui/material';

const Markdown = varnishComponents.Markdown;
const markdownText = \`A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

##### Todo Checklist

* [ ] todo
* [x] done

##### Unordered List

* Milk
* Apples
* Eggs

##### Ordered List

1. Use markdown
2. ???
3. Profit

##### Horizontal Rule

---

##### Code Block

~~~
   function hello() {
      console.log("hello world!")
   }
~~~

##### Table

| Product          | Estimated Price ($) | Estimated Weight (kg) |
|------------------|---------------------:|------------------------:|
| Widget X         |               $49.99 |                    0.5 |
| Gizmo Y          |               $79.95 |                    1.2 |
| Gadget Z         |              $129.99 |                    0.8 |
| Thingamajig A    |               $34.50 |                    0.3 |
| Doodad B         |               $89.75 |                    1.5 |

##### Image

![Image](https://t4.ftcdn.net/jpg/00/53/45/31/360_F_53453175_hVgYVz0WmvOXPd9CNzaUcwcibiGao3CL.jpg)

\`;
render(<Markdown>{markdownText}</Markdown>);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;