
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Light and dark backgrounds'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Links can be on light or dark backgrounds. Links on dark backgrounds should use the `}<inlineCode parentName="p">{`linkContrast`}</inlineCode>{` class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled } from '@mui/material';
import varnishComponents from '@allenai/varnish2/components';

import { NavLink } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

const DarkBackground = styled('li')\`
    background: \${({ theme }) => theme.palette.background.dark};
    padding: \${({ theme }) => theme.spacing(1)};
\`;

const LiteBackground = styled(DarkBackground)\`
    background: \${({ theme }) => theme.palette.common.white};
\`;

const Demo = () => {
    const { Content, Header, VarnishApp } = varnishComponents;
    return (
        <div>
            <Header.AI2Banner />
            <Content main>
                <ul>
                    <DarkBackground>
                        <NavLink
                            reloadDocument
                            className={'linkContrast'}
                            to="/material-ui/getting-started/overview/">
                            Link on dark background
                        </NavLink>
                    </DarkBackground>
                    <LiteBackground>
                        <NavLink reloadDocument to="/components/varnishmui/ai2_header">
                            Link on light background
                        </NavLink>
                    </LiteBackground>
                </ul>
            </Content>
        </div>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;