
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { TypographyExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'Typography',
  cols: 1,
  iconSrc: './text-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Typography`}</h2>
    <p>{`Typography components are used to display text, such as headers, paragraphs, or subtitles.`}</p>
    <p>{`You can specify your Typography type via the `}<inlineCode parentName="p">{`variant`}</inlineCode>{` param. For example, `}<inlineCode parentName="p">{`<Typography variant="caption">text</Typography>`}</inlineCode>{` will create a caption.`}</p>
    <p>{`For more info, check out the Material UI docs for `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/react-typography/"
      }}>{`Typography`}</a></p>
    <h3>{`When To Use`}</h3>
    <p>{`Most of the time you won't need to use these components, as the text should be styled according to our defaults simply by using Varnish. That said you should use these components when you want to make the text larger or smaller, or if you're rendering a specific type of text, like source code or quoted material.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={TypographyExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;