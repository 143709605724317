import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 *  On the surface, building a custom styled button seems simple. However, there are many cross
 *  browser inconsistencies in interactions and accessibility features to consider. Button handles
 *  all of these interactions for you, so you can focus on the styling.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Button.html
 */

/**
 * Button component allows users to perform actions and make selections with a single tap.
 * It supports different visual styles based on its variants: text (default), contained, and outlined.
 *
 * @variant variant Defines the visual appearance of the button.
 * - `text`: Default style with no background, minimal padding.
 * - `contained`: Button with a solid background and padding.
 * - `outlined`: Button with a border and no background.
 *
 * @variant size Defines the size of the button.
 * - `small`: Smaller button for compact UI areas.
 * - `medium`: Default size for the button.
 * - `large`: Larger button for increased touch targets.
 *
 * @variant color Controls the color scheme of the button.
 * - `primary`: Button styled with the primary color.
 * - `secondary`: Button styled with the secondary color.
 * - `tertiary`: Button styled with a less prominent tertiary color.
 */

const buttonRecipe = sva({
    slots: ['root', 'startIcon', 'endIcon'],
    base: {
        root: {
            boxSizing: 'border-box',
            // button has a font, so we need to ensure this is set
            // for the most part, manrope should be default font though (cascade down)
            fontFamily: 'body',
            gap: '[5px]',

            // Override HTML button base styles
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            backgroundColor: '[transparent]',

            cursor: 'pointer', // should should probably only apply to interactive buttons ...

            // Defaults apply to all variants
            fontWeight: 'medium',
            borderRadius: 'sm',
            _disabled: {
                cursor: 'not-allowed',
                color: 'gray.60',
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
            },
            transition: '[background-color 250ms, color 250ms, opacity 250ms]',

            // defaults - applies to bordered & text, contained will override these
            color: 'var(--button-color)',
        },
        startIcon: {
            verticalAlign: 'middle',
            display: 'flex',
        },
        endIcon: {
            verticalAlign: 'middle',
            display: 'flex',
        },
    },

    // Variants
    variants: {
        // Colors follow a pattern
        //   The `--button-color` var will be used to set all the color related properties
        //
        // Colors will need to take into account the contrast versions
        color: {
            primary: {
                root: {
                    '--button-color': '{colors.accent.primary}', // pink.100 ?
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'pink.10',
                        },
                    },
                },
            },
            secondary: {
                root: {
                    '--button-color': '{colors.accent.secondary}', // green.100 ?
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'green.10',
                        },
                    },
                },
            },
            tertiary: {
                root: {
                    '--button-color': '{colors.accent.tertiary}', // purple.100 ?
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'purple.10',
                        },
                    },
                },
            },
            warning: {
                root: {
                    '--button-color': '{colors.alert.warning}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'warning-orange.20',
                        },
                    },
                },
            },
            error: {
                root: {
                    '--button-color': '{colors.alert.error}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'error-red.20',
                        },
                    },
                },
            },
            info: {
                root: {
                    '--button-color': '{colors.alert.information}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'info-blue.20',
                        },
                    },
                },
            },
            confirmation: {
                root: {
                    '--button-color': '{colors.alert.confirmation}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'success-green.20',
                        },
                    },
                },
            },
            teal: {
                root: {
                    '--button-color': '{colors.teal.100}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'teal.10',
                        },
                    },
                },
            },
            cream: {
                root: {
                    '--button-color': '{colors.cream.100}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'dark-teal.10',
                        },
                    },
                },
            },
            darkTeal: {
                root: {
                    '--button-color': '{colors.dark-teal.100}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'cream.10',
                        },
                    },
                },
            },
            default: {
                root: {
                    '--button-color': '{colors.text.primary}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: {
                                base: 'dark-teal.10',
                                _dark: 'cream.10',
                            },
                        },
                    },
                },
            },
            reversed: {
                root: {
                    '--button-color': '{colors.text.primary.reversed}',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: {
                                base: 'cream.10',
                                _dark: 'dark-teal.10',
                            },
                        },
                    },
                },
            },
        },
        // Visual variant of buttons
        variant: {
            text: {
                root: {},
            },
            outlined: {
                root: {
                    border: '[1px solid var(--button-color)]',
                    _disabled: {
                        borderColor: 'gray.10', // gray.100 in figma
                    },
                },
            },
            contained: {
                root: {
                    backgroundColor: '[var(--button-color)]',
                },
            },
        },

        size: {
            small: {
                root: {
                    padding: '[4px 10px]',
                    fontSize: 'sm',
                    lineHeight: '1.55',
                },
            },
            medium: {
                root: {
                    padding: '[6px 16px]',
                    fontSize: 'sm',
                    lineHeight: '1.55',
                },
            },
            large: {
                root: {
                    padding: '[12px 24px]',
                    fontSize: 'md',
                    lineHeight: '1.5',
                },
            },
        },
    },
    compoundVariants: [
        // Contained buttons text color can be broken into a few categories
        {
            variant: 'contained',
            color: ['primary', 'secondary', 'warning', 'error'],
            css: {
                root: {
                    color: 'extra-dark-teal.100',
                },
            },
        },
        {
            variant: 'contained',
            color: ['tertiary', 'info', 'confirmation', 'darkTeal', 'teal'],
            css: {
                root: {
                    color: 'cream.100',
                },
            },
        },
        // contained background colors need to be set specifically
        {
            variant: 'contained',
            color: 'primary',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'pink.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'secondary',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'green.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'tertiary',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'purple.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'info',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'info-blue.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'confirmation',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'success-green.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'warning',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'warning-orange.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'error',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'error-red.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'cream',
            css: {
                root: {
                    color: 'extra-dark-teal.100',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'cream.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'teal',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'teal.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'darkTeal',
            css: {
                root: {
                    _hover: {
                        _notDisabled: {
                            backgroundColor: 'dark-teal.80',
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'default',
            css: {
                root: {
                    color: 'text.primary.reversed',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: {
                                base: 'dark-teal.80',
                                _dark: 'cream.80',
                            },
                        },
                    },
                },
            },
        },
        {
            variant: 'contained',
            color: 'reversed',
            css: {
                root: {
                    color: 'text.primary',
                    _hover: {
                        _notDisabled: {
                            backgroundColor: {
                                base: 'cream.80',
                                _dark: 'dark-teal.80',
                            },
                        },
                    },
                },
            },
        },
        // Outlined sizes:
        // need to reduce padding for these by 1px each side
        // to account for border
        {
            variant: 'outlined',
            size: 'small',
            css: {
                root: {
                    padding: '[3px 9px]',
                },
            },
        },
        {
            variant: 'outlined',
            size: 'medium',
            css: {
                root: {
                    padding: '[5px 15px]',
                },
            },
        },
        {
            variant: 'outlined',
            size: 'large',
            css: {
                root: {
                    padding: '[11px 23px]',
                },
            },
        },
    ],
    defaultVariants: {
        variant: 'outlined',
        size: 'medium',
        color: 'default',
    },
});

type ButtonRecipeProps = RecipeVariantProps<typeof buttonRecipe>;

export { buttonRecipe, buttonRecipe as default };
export type { ButtonRecipeProps };
