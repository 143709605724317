
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-theme',
  type: 'Overview',
  title: 'Getting Started',
  cols: 1,
  iconSrc: './palette-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VarnishTheme = makeShortcode("VarnishTheme");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Varnish Theme`}</h2>
    <p>{`The default Varnish Theme provides programmatic access to AI2 branded colors, typography and other properties related to the user interface. Using these values helps ensure your application's look and feel matches that of other AI2 applications.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`Automatically included in all VarnishApp projects.`}</p>
    <h2>{`Usage in a Styled Component`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { styled } from '@mui/material';

const DarkBanner = styled('div')\`
    background: \${({ theme }) => theme.palette.background.dark};
    padding: \${({ theme }) => theme.spacing(0.5)} 0;
    line-height: 1;
\`;
`}</code></pre>
    <h2>{`Varnish Base Theme`}</h2>
    <p>{`These values are from the Varnish base theme. When using Varnish without our components you should use this theme.
`}<inlineCode parentName="p">{`import varnishTheme from '@allenai/varnish-theme'`}</inlineCode></p>
    <VarnishTheme mdxType="VarnishTheme" />
    <h3>{`Simple Unitless Theme With Hex Colors`}</h3>
    <p>{`If you need a theme that only has colors in hex values and spacing in unitless pixels (`}<inlineCode parentName="p">{`16`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`16px`}</inlineCode>{`) you can use this theme. The values are the same as the base theme except that `}<inlineCode parentName="p">{`rem`}</inlineCode>{` values are converted to their `}<inlineCode parentName="p">{`px`}</inlineCode>{` equivalent and colors are in hexAlpha.
`}<inlineCode parentName="p">{`import simpleVarnishTheme from '@allenai/varnish-theme/simple-hex-unitless'`}</inlineCode></p>
    <h2>{`Theme in Action`}</h2>
    <p>{`You can see the Theme in action in the `}<a parentName="p" {...{
        "href": "/material-ui/react-typography/"
      }}>{`Typography section`}</a>{` and the `}<a parentName="p" {...{
        "href": "varnishtheme/colors/"
      }}>{`Varnish Colors section`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;