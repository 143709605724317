import * as Default from './demo/default.mdx';
import * as EmptyState from './demo/emptyState.mdx';
import * as Sorting from './demo/sorting.mdx';
import * as DragAndDropWithImage from './demo/dragAndDropWithImage.mdx';
import * as DragAndDrop from './demo/dragAndDrop.mdx';
import * as DragAndDropWithDirectory from './demo/dragAndDropDirectory.mdx';
import * as Selectable from './demo/selectable.mdx';
import * as Links from './demo/links.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as Resizing from './demo/resizing.mdx';
import * as DragBetweenTables from './demo/dragBetweenTables.mdx';
import * as DragBetweenTablesWithValue from './demo/dragBetweenTablesWithValue.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const TableExamples = [
    Default,
    EmptyState,
    Sorting,
    DragAndDropWithImage,
    DragAndDrop,
    DragAndDropWithDirectory,
    Selectable,
    Links,
    Disabled,
    Resizing,
    DragBetweenTables,
    DragBetweenTablesWithValue,
    StylingCss,
    StylingStyled,
];
