
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Action'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Header offers an action slot for action button like sign in or sign out.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
const { logos } = varnishComponents;

render(
    <varnishUi.Header
        logo={<varnishUi.Icons.Spark size="large" />}
        action={
            <varnishUi.Button
                variant="text"
                style={{ color: 'inherit', borderColor: 'inherit' }}
                size="sm">
                Sign In
            </varnishUi.Button>
        }
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;