import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const snackbarRecipe = sva({
    slots: ['root', 'message', 'action', 'exit'],
    base: {
        root: {
            padding: '[16px 16px 16px 24px]',
            display: 'flex',
            width: '[100%]',
            alignItems: 'center',
            justifyContent: 'space-between',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            animation: 'fadeIn 250ms ease-out forwards',
        },
        message: {},
        action: {
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '[8px]',
        },
        exit: {
            background: '[none]',
            cursor: 'pointer',
            border: 'none',
            padding: '[5px]',
        },
    },
    variants: {
        variant: {
            default: {
                root: {
                    backgroundColor: 'extra-dark-teal.100',
                    color: 'text.primary.reversed',
                    _dark: {
                        backgroundColor: 'cream.100',
                        color: 'text.primary',
                    },
                },
                message: {
                    color: 'cream.100',
                    _dark: {
                        color: 'text.primary.reversed',
                    },
                },
                action: {
                    color: 'accent.secondary',
                    _dark: {
                        color: 'accent.primary',
                    },
                },
                exit: {
                    color: 'cream.100',
                    _dark: {
                        color: 'text.primary.reversed',
                    },
                    _hover: {
                        backgroundColor: 'cream.10',
                        _dark: {
                            backgroundColor: 'extra-dark-teal.10',
                        },
                    },
                },
            },
            confirmation: {
                root: {
                    backgroundColor: 'alert.confirmation',
                    color: 'cream.100',
                    _dark: {
                        color: 'text.primary',
                    },
                },
                action: {
                    color: '[inherit]',
                },
                exit: {
                    color: '[inherit]',
                    _hover: {
                        backgroundColor: 'cream.10',
                    },
                },
            },
            error: {
                root: {
                    backgroundColor: 'alert.error',
                    color: 'extra-dark-teal.100',
                },
                action: {
                    color: '[inherit]',
                },
                exit: {
                    color: '[inherit]',
                    _hover: {
                        backgroundColor: 'extra-dark-teal.10',
                    },
                },
            },
        },
        shape: {
            rounded: {
                root: {
                    borderRadius: 'full',
                },
            },
            square: {
                root: {
                    borderRadius: 'sm',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'default',
        shape: 'square',
    },
});

type SnackbarRecipeProps = RecipeVariantProps<typeof snackbarRecipe>;

export { snackbarRecipe, snackbarRecipe as default };
export type { SnackbarRecipeProps };
