
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'White Logos'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`If you're using the logo against a dark background, you will want to set the component's `}<inlineCode parentName="p">{`color`}</inlineCode>{` value to `}<inlineCode parentName="p">{`white`}</inlineCode>{`.`}</p>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

render(
    <div style={{ background: '#1b4596', padding: '26px 16px 16px' }}>
        <varnishComponents.logos.AI2Logo color="white" size="lg" />
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;