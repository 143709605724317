
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Form control fields'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Labels and fields come with all controls, but can be used on their own. This would be most useful when you are composing a custom control.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={32}>
        <varnishUi.Stack direction="column" spacing={16}>
            <varnishUi.Label size="small">Small Label</varnishUi.Label>
            <varnishUi.Label size="medium">Medium Label</varnishUi.Label>
            <varnishUi.Label size="large">Large Label</varnishUi.Label>
        </varnishUi.Stack>
        <varnishUi.Stack direction="column" spacing={16}>
            <varnishUi.Description size="small">Small Description</varnishUi.Description>
            <varnishUi.Description size="medium">Medium Description</varnishUi.Description>
            <varnishUi.Description size="large">Large Description</varnishUi.Description>
        </varnishUi.Stack>
        <varnishUi.Stack direction="column" spacing={16}>
            <varnishUi.FieldError size="small">Small FieldError</varnishUi.FieldError>
            <varnishUi.FieldError size="medium">Medium FieldError</varnishUi.FieldError>
            <varnishUi.FieldError size="large">Large FieldError</varnishUi.FieldError>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;