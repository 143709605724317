
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Custom Content'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Footer with custom content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import varnishComponents from '@allenai/varnish2/components';
import ThemeMui from '@allenai/varnish2/theme';

render(
    <varnishComponents.Footer backgroundColor={ThemeMui.color.G4}>
        Custom footer content - 🦶🏻 🦶🏼 🦶🏽 🦶🏾 🦶🏿
    </varnishComponents.Footer>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;