
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'How do I pass custom attributes to MUI styled?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Often you want to style a component based on passing in an attribute that is not used by the dom.
To do this without causing dom warning, we need to exclude the attribute from being passed to the dom element.
This is the pattern to do so safely. In this example, we are passing a `}<inlineCode parentName="p">{`columns`}</inlineCode>{` attribute to the
`}<inlineCode parentName="p">{`Columns`}</inlineCode>{` component, which is not a valid dom attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`interface ColumnsProps
    extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    columns?: string;
}

const Columns = styled(({ columns, ...rest }: ColumnsProps) => <div {...rest} />)\`
    grid-template-columns: \${({ columns }) => columns};
    grid-column-gap: \${({ theme }) => theme.spacing(2)};
\`;
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;