import {
    ProgressBar as AriaProgressBar,
    ProgressBarProps as AriaProgressBarProps,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { loadingSpinnerRecipe, LoadingSpinnerRecipeProps } from './loadingSpinner.styles';

type LoadingSpinnerProps = {
    className?: string;
} & AriaProgressBarProps &
    LoadingSpinnerRecipeProps;

const LoadingSpinner = ({ className, ...rest }: LoadingSpinnerProps) => {
    const [variantProps] = loadingSpinnerRecipe.splitVariantProps(rest);

    return (
        <AriaProgressBar
            className={cx(css(loadingSpinnerRecipe.raw(variantProps)), className)}
            aria-label="Loading…">
            <svg
                style={{ width: '100%', height: '100%' }}
                viewBox="0 0 32 32"
                fill="none"
                strokeWidth={4}>
                <circle
                    cx="16"
                    cy="16"
                    r="14"
                    strokeDashoffset="2"
                    stroke="var(--spinner-color)"
                    strokeDasharray="60 60"
                    strokeLinecap="round"
                />
            </svg>
        </AriaProgressBar>
    );
};

export { LoadingSpinner };
export type { LoadingSpinnerProps };
