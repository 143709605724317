
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { VarnishAppExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Overview',
  title: 'VarnishApp',
  cols: 1,
  iconSrc: './palette-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`VarnishApp`}</h2>
    <p>{`A top-level container for `}<inlineCode parentName="p">{`Varnish`}</inlineCode>{` applications.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`All applications should be wrapped in a `}<inlineCode parentName="p">{`<VarnishApp>`}</inlineCode>{`. This ensures
Varnish is setup correctly. If you don't do this your application
probably won't compile, or if it does things probably won't work.`}</p>
    <h3>{`Example Usage in a Component`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import React from 'react';
import { createRoot } from 'react-dom/client';

import { VarnishApp } from '@allenai/varnish2/components';

const App = () => (
    <VarnishApp layout="left-aligned">
        <MainApp />
    </VarnishApp>
);

const container = document.getElementById('root');
if (!container) {
    throw new Error("No element with an id of 'root' was found.");
}
const root = createRoot(container);
root.render(<App />);
`}</code></pre>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={VarnishAppExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`layout`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'left-aligned' `}{`|`}{` 'center-aligned'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'center-aligned'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Changes the top level layout, which right now only impacts the horizontal alignment of your application`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`theme`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`{}`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`(Optional)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Modifications to the VarnishTheme, which are deep-merged with the defaults.`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;