import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const tabsRecipe = sva({
    slots: ['root', 'tabList', 'tab', 'tabPanel'],
    base: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            '&[data-orientation=horizontal]': {
                flexDirection: 'column',
            },
            '&[data-orientation=vertical]': {
                flexDirection: 'row',
            },
        },
        tabList: {
            display: 'flex',
            gap: '[8px]',
            '&[data-orientation="horizontal"]': {
                borderBottom: '[1px solid gray]',
            },
            '&[data-orientation=vertical]': {
                flexDirection: 'column',
                borderInlineEnd: '[1px solid gray]',
            },
        },
        tab: {
            padding: '[8px 16px]',
            outline: 'none',
            position: 'relative',
            cursor: 'default',
            color: 'text.primary',
            fontSize: '[14px]',
            border: '[1px solid]',
            borderColor: '[transparent]',
            boxSizing: 'border-box',
            borderBottom: '3px solid transparent',
            borderInlineEnd: '3px solid transparent',
            '& svg': {
                color: 'dark-teal.100',
            },
            _dark: {
                '&[data-selected]': {
                    cursor: 'pointer',
                    borderColor: 'accent.secondary',
                    color: 'accent.secondary',
                },
                _hover: {
                    cursor: 'pointer',
                    borderInlineEndColor: 'accent.secondary',
                },
                '& svg': {
                    color: 'cream.100',
                },
            },
            _hover: {
                borderBottomColor: 'accent.primary',
                cursor: 'pointer',
                _dark: {
                    borderBottomColor: 'accent.secondary',
                    borderRightColor: '[transparent]',
                },
            },
            '&[data-selected]': {
                color: 'accent.primary',
                borderTopColor: '[transparent]',
                borderLeftColor: '[transparent]',
                borderRightColor: '[transparent]',
                borderBottomColor: 'accent.primary',
                cursor: 'pointer',
                '& svg': {
                    color: 'accent.primary',
                },
                _dark: {
                    borderTopColor: '[transparent]',
                    borderLeftColor: '[transparent]',
                    borderRightColor: '[transparent]',
                    borderBottomColor: 'accent.secondary',
                    '& svg': {
                        color: 'accent.secondary',
                    },
                },
            },
            '&[data-selected]:is([data-orientation="vertical"] &)': {
                borderInlineEndColor: 'accent.primary',
                borderBottomColor: '[transparent]',
                cursor: 'pointer',
                _dark: {
                    borderInlineEndColor: 'accent.secondary',
                    borderBottomColor: '[transparent]',
                    cursor: 'pointer',
                },
            },
            '&:not([data-selected]):is([data-orientation="horizontal"] &)': {
                cursor: 'pointer',
            },
            '&:not([data-selected]):is([data-orientation="vertical"] &)': {
                _hover: {
                    borderInlineEndColor: 'accent.primary',
                    borderBottomColor: '[transparent]',
                    cursor: 'pointer',
                    _dark: {
                        borderInlineEndColor: 'accent.secondary',
                        borderBottomColor: '[transparent]',
                    },
                },
            },
            '&[data-disabled]': {
                color: 'gray.60',
                cursor: 'not-allowed',
                borderBottom: '3px solid transparent',
                borderInlineEnd: '3px solid transparent',
                '& svg': {
                    color: '[gray.60]',
                },
                _dark: {
                    borderColor: '[transparent]',
                    '& svg': {
                        color: '[gray.60]',
                    },
                },
                _hover: {
                    cursor: 'not-allowed',
                    borderBottomColor: '[transparent]',
                    _dark: {
                        borderBottomColor: '[transparent]',
                    },
                    '&[data-selected]:is([data-orientation="vertical"] &)': {
                        borderInlineEndColor: 'gray.30',
                        borderBottomColor: '[transparent]',
                    },
                },
                '&[data-selected]': {
                    borderBottomColor: 'gray.30',
                    _dark: {
                        borderBottomColor: 'gray.30',
                        color: 'gray.60',
                    },
                },
                '&[data-selected]:is([data-orientation="vertical"] &)': {
                    borderInlineEndColor: 'gray.30',
                    borderBottomColor: '[transparent]',
                    cursor: 'not-allowed',
                },
                '&:not([data-selected]):is([data-orientation="vertical"] &)': {
                    _hover: {
                        borderColor: '[transparent]',
                        cursor: 'not-allowed',
                    },
                },
                '&:not([data-selected]):is([data-orientation="horizontal"] &)': {
                    cursor: 'not-allowed',
                },
            },
        },
        tabPanel: {
            marginTop: '[4px]',
            padding: '[10px]',
            borderRadius: '[4px]',
            outline: 'none',
            color: 'text.primary',
            fontSize: '[14px]',
        },
    },
});

type TabsRecipeProps = RecipeVariantProps<typeof tabsRecipe>;

export { tabsRecipe, TabsRecipeProps as default };
export type { TabsRecipeProps };
