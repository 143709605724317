
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Labels and fields come with all controls. They inherit their `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop from their component and have some default styling.`}</p>
    <p>{`The input component below includes a label and a description field.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={32}>
        <varnishUi.Stack direction="column" spacing={16}>
            <varnishUi.Input
                color="primary"
                size="small"
                placeholder="Labeled input (small)"
                label="Label"
                description="Description of input"
            />
            <varnishUi.Input
                color="primary"
                size="medium"
                placeholder="Labeled input (medium)"
                label="Label"
                description="Description of input"
            />
            <varnishUi.Input
                color="primary"
                size="large"
                placeholder="Labeled input (large)"
                label="Label"
                description="Description of input"
            />
        </varnishUi.Stack>
        <varnishUi.Stack direction="column" spacing={16}>
            <varnishUi.Input
                isInvalid={true}
                color="primary"
                size="small"
                placeholder="Labeled input (small)"
                label="Label"
                description="Description of input"
                errorMessage="Error message"
            />
            <varnishUi.Input
                isInvalid={true}
                color="primary"
                size="medium"
                placeholder="Labeled input (medium)"
                label="Label"
                description="Description of input"
                errorMessage="Error message"
            />
            <varnishUi.Input
                isInvalid={true}
                color="primary"
                size="large"
                placeholder="Labeled input (large)"
                label="Label"
                description="Description of input"
                errorMessage="Error message"
            />
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;