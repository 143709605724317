
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
        padding: 2px;
    \`;
};

const labelClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.G6.value};
        padding: 2px;
        margin: 2px;
    \`;
};

const descriptionClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.B6.value};
        padding: 2px;
        margin: 2px;
    \`;
};

const errorClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.O6.value};
        padding: 2px;
        margin: 2px;
    \`;
};

render(
    <form>
        <varnishUi.CheckboxGroup label="Favorite sports"
         description="Select your favorite sports"
         errorMessage="Please select all sports"
           className={emotionCss.cx(className())}
            labelClassName={emotionCss.cx(labelClassName())}
            descriptionClassName={emotionCss.cx(descriptionClassName())}
            errorClassName={emotionCss.cx(errorClassName())}
            >
            <varnishUi.Checkbox isRequired value="soccer">
                Soccer
            </varnishUi.Checkbox>
            <varnishUi.Checkbox isRequired value="baseball">
                Baseball
            </varnishUi.Checkbox>
            <varnishUi.Checkbox isRequired value="basketball">
                Basketball
            </varnishUi.Checkbox>
        </varnishUi.CheckboxGroup>
        <br />
        <varnishUi.Button variant="outlined" type="submit">
            Submit
        </varnishUi.Button>
    </form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;