
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Styling for SnackbarList (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        text-decoration: none;
        color: red;
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const snackbarClassName = () => {
    return emotionCss.css\`
        background: red;
    \`;
};

const snackbarActionClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.B2.value};
    \`;
}

const snackbarExitClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R5.value};
    \`;
}

const snackbarMessageClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R4.value};
    \`;
}


const Demo = ({ anchorOrigin }) => {
  const { addSnackbarNotification } = varnishUi.useSnackbarNotification();

  const handleClick = () => {
    addSnackbarNotification(anchorOrigin, {
      message: "Hello from Varnish UI Snackbar!",
      autoHideDuration: 3000,
      variant: "default",
      className: emotionCss.cx(snackbarClassName()),
      actionClassName: emotionCss.cx(snackbarActionClassName()),
      messageClassName: emotionCss.cx(snackbarMessageClassName()),
      exitClassName: emotionCss.cx(snackbarExitClassName()),
    });
  };

  return (
    <div>
      <varnishUi.Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        {anchorOrigin}
      </varnishUi.Button>
      <varnishUi.SnackbarList anchorOrigin={anchorOrigin} className={emotionCss.cx(className())} />
    </div>
  );
};

// Wrap the whole app or component tree with SnackbarNotificationProvider

const App = () => (
  <>
    <varnishUi.SnackbarNotificationProvider>
      <Demo anchorOrigin="bottomLeft" />
    </varnishUi.SnackbarNotificationProvider>
  </>
);

render(<App />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;