
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Row Limits'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The `}<inlineCode parentName="p">{`TextArea`}</inlineCode>{` component accepts the props `}<inlineCode parentName="p">{`minRows`}</inlineCode>{` and/or `}<inlineCode parentName="p">{`maxRows`}</inlineCode>{` which control the height the component is allowed to grow.
It will start at the `}<inlineCode parentName="p">{`minRows`}</inlineCode>{` height, and will grow to `}<inlineCode parentName="p">{`maxRows`}</inlineCode>{`. Any additional text will be overflow shown with a scroll wheel.
Default is `}<inlineCode parentName="p">{`minRows={2}`}</inlineCode>{` and `}<inlineCode parentName="p">{`maxRows={3}`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.TextArea
                variant="contained"
                placeholder="minRows={5}&#10;maxRows={10}&#10;I will grow if you input more lines"
                minRows={5}
                maxRows={10}
            />
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;