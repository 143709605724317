import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const tableRecipe = sva({
    slots: [
        'root',
        'tableHeader',
        'tableBody',
        'row',
        'cell',
        'column',
        'resizableTableContainer',
        'columnResizer',
        'dragButton',
        'checkbox',
    ],
    base: {
        root: {
            padding: '[4.5px]',
            border: '1px solid',
            borderRadius: '[6px]',
            borderColor: 'gray.30',
            borderSpacing: '0',
            alignSelf: 'start',
            wordBreak: 'break-word',
            maxWidth: '[100%]',
            minHeight: '[100px]',
            forcedColorAdjust: 'none',
        },
        tableHeader: {
            color: 'text.primary',
            whiteSpace: 'nowrap',
            '&:after': {
                content: '',
                display: 'table-row',
                height: '[2px]',
            },
            '& tr:last-child': {
                '& th': {
                    borderBottom: '1px solid',
                    borderColor: 'gray.30',
                    cursor: 'default',
                },
            },
        },
        tableBody: {
            '&[data-empty]': {
                color: 'text.primary',
                _dark: {
                    color: 'cream.100',
                },
                textAlign: 'center',
                fontStyle: 'italic',
            },
        },
        row: {
            cursor: 'default',
            position: 'relative',
            transform: 'scale(1)',
            color: 'text.primary',
            borderRadius: '[6px]',
            '&[data-selected]': {
                color: 'cream.100',
                backgroundColor: 'accent.tertiary',
            },
            '&[data-disabled]': {
                color: 'gray.60',
            },
            '&[data-dragging]': {
                opacity: '0.6',
                transform: 'translateZ(0)',
                backgroundColor: 'accent.tertiary',
                color: 'cream.100',
            },
            '&[data-href]': {
                cursor: 'pointer',
            },
        },
        column: {
            padding: '[4px 8px]',
            textAlign: 'left',
        },
        cell: {
            padding: '[4px 8px]',
            textAlign: 'left',
            transform: 'translateZ(0)',
            '&:first-child': {
                borderRadius: '[6px 0 0 6px]',
            },
            '&:last-child': {
                borderRadius: '[0 6px 6px 0]',
            },
            '& img': {
                width: '[30px]',
                height: '[30px]',
                objectFit: 'cover',
                display: 'block',
            },
        },
        resizableTableContainer: {
            maxWidth: '[400px]',
            overflow: 'auto',
            position: 'relative',
            '& .flex-wrapper': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .column-name': {
                flex: '1',
                font: 'inherit',
                textAlign: 'start',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderColor: '[transparent]',
            },

            '& [role="columnheader"], & [role="grid-cell"]': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        columnResizer: {
            width: '[15px]',
            backgroundColor: 'gray.60',
            height: '[25px]',
            flex: '[0 0 auto]',
            touchAction: 'none',
            boxSizing: 'border-box',
            border: '[5px]',
            borderStyle: 'none solid',
            borderColor: '[transparent]',
            backgroundClip: 'content-box',

            '&[data-resizable-direction=both]': {
                cursor: 'ew-resize',
            },
            '&[data-resizable-direction=left]': {
                cursor: 'e-resize',
            },

            '&[data-resizable-direction=right]': {
                cursor: 'w-resize',
            },
            '&[data-resizing]': {
                backgroundColor: '[transparent]',
                borderColor: 'accent.tertiary',
            },
        },
        dragButton: {
            border: 'none',
            color: '[inherit]',
        },
        checkbox: {},
    },
});

type TableRecipeProps = RecipeVariantProps<typeof tableRecipe>;

export { tableRecipe, TableRecipeProps as default };
export type { TableRecipeProps };
