
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 11,
  title: 'How do I use SX for custom padding?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import mui from '@mui/material';

render(
    <div style={{ width: '100%' }}>
        <div>
            <mui.Button
                sx={{
                    width: '50%',
                    textAlign: 'center',
                    bgcolor: (theme) => theme.color.T8.hex,
                    padding: (theme) => theme.spacing(3),
                    fontSize: (theme) => theme.typography.h4.fontSize,
                    color: 'black',
                }}>
                👾 🤖 👽 Custom 👾 🤖 👽
            </mui.Button>
        </div>
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;