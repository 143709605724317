
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Overview',
  title: 'Panda CSS',
  cols: 1,
  iconSrc: './palette-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Using Panda CSS with Varnish-UI`}</h1>
    <h2>{`Background`}</h2>
    <p>{`varnish-ui uses panda for its styling system, "out of the box" it will utilize its own runtime,
which is used to generate the CSS classNames from JS.`}</p>
    <p>{`To use panda in your project along side varnish-ui, we need to use a shared runtime to allow overriding any functionality of
varnish-ui (not to mention to avoid having two runtimes doing the same thing unnecessarily).`}</p>
    <p>{`varnish-panda-runtime is a `}<inlineCode parentName="p">{`peerDependency`}</inlineCode>{` on varnish-ui which allows us to specify our own copy of this runtime
which we do via linking to "pseudo" package in our project that gets populated by our local copy of panda.`}</p>
    <p>{`For development purposes we utilize package linking (`}<inlineCode parentName="p">{`bun link`}</inlineCode>{`, `}<inlineCode parentName="p">{`yarn link`}</inlineCode>{`)`}</p>
    <h3>{`Skiff-chat-template`}</h3>
    <p>{`If you started your application from `}<a parentName="p" {...{
        "href": "https://github.com/allenai/skiff-chat-template"
      }}>{`Skiff Chat Template`}</a>{`, then you're already using Varnish-UI with PandaCSS, as it comes installed by default.`}</p>
    <h2>{`Adding to project`}</h2>
    <h3>{`Create varnish-panda-runtime local package`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`mkdir varnish-panda-runtime
`}</code></pre>
    <ul>
      <li parentName="ul">{`copy package.json from `}<a parentName="li" {...{
          "href": "https://github.com/allenai/varnish/blob/main/packages/varnish-panda-runtime/package.json"
        }}>{`@allenai/varnish-panda-runtime repo`}</a></li>
    </ul>
    <p><em parentName="p">{`Note: The "version" field in this package.json should match the version of varnish-ui you are using.`}</em></p>
    <ul>
      <li parentName="ul">{`Add `}<inlineCode parentName="li">{`.gitignore`}</inlineCode>{` file with the following contents:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`/varnish-panda-runtime
`}</code></pre>
    <p>{`Use one of the following methods:`}</p>
    <h4>{`Bun`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`bun add -D @pandacss/dev
bun add @allenai/varnish-ui react-aria-components
bun link --cwd ./varnish-panda-runtime
`}</code></pre>
    <h4>{`Yarn (v1.x)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add -D @pandacss/dev
yarn add @allenai/varnish-ui react-aria-components link:./varnish-panda-runtime
`}</code></pre>
    <h4>{`Yarn (berry)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add -D @pandacss/dev
yarn add @allenai/varnish-ui react-aria-components
`}</code></pre>
    <h4>{`Manually add`}</h4>
    <p><em parentName="p">{`If manually adding determine versions based of `}<inlineCode parentName="em">{`varnish-ui`}</inlineCode>{` package.`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
//...
  "dependencies": {
    "@allenai/varnish-panda-runtime": "link:./varnish-panda-runtime",
    "@allenai/varnish-ui": "^x.y.z",
    "react-aria-component": "^x.y.z"
    //...
  },
  "devDepenencies": {
    "@pandacss/dev": "^x.y.z",
    //...
  }
//...
}
`}</code></pre>
    <p>{`to package.json`}</p>
    <h3>{`Configure panda for varnish-ui`}</h3>
    <h4>{`Configure panda.config.ts`}</h4>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://github.com/allenai/skiff-chat-template/blob/main/panda.config.ts"
      }}>{`@allenai/skiff-chat-template repo`}</a>{` for an example`}</p>
    <p>{`Needed changes / updates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { defineConfig } from '@pandacss/dev';
import varnishPreset from '@allenai/varnish-ui/panda';

export default defineConfig({
    // ...
    presets: [varnishPreset],

    include: [
        './src/**/*.{ts,tsx,js,jsx}',
        // This is all of the panda gathered meta-data for varnish-ui, essentially the stuff it would
        // have picked up if varnish-ui was a sub-directory of your projects
        './node_modules/@allenai/varnish-ui/dist/varnish.panda.include.json',
    ],

    // helps panda find/track the {css, cx, cva, sva, styled, types} imports
    importMap: '@allenai/varnish-panda-runtime',

    strictTokens: true, // Recommended

    jsxFramework: 'react', // Assuming React

    // The output generated files into varnish-panda-runtime package
    outdir: './varnish-panda-runtime/dist',
    // ...
});
`}</code></pre>
    <h4>{`Generate initial runtime`}</h4>
    <p><em parentName="p">{`using npm as example package manager, subsitute the package manager you are using (i.e. `}<inlineCode parentName="em">{`yarn`}</inlineCode>{` or `}<inlineCode parentName="em">{`bun`}</inlineCode>{`)`}</em></p>
    <p>{`Run panda generation for initial runtime`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm panda
`}</code></pre>
    <h4>{`Panda css generation`}</h4>
    <ul>
      <li parentName="ul">{`Watch files to generate CSS`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm panda cssgen --watch -o public/styles.css
`}</code></pre>
    <ul>
      <li parentName="ul">{`Optionally add a script to package.json`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
//...
  "scripts": {
    "watch:css": "panda --watch -o public/styles.css"
    // then add to "dev" or "start" script
  }
//...
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`Using PostCSS, add the `}<inlineCode parentName="li">{`postcss.config.json`}</inlineCode>{` file, with the following contents:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`module.exports = {
  plugins: {
    '@pandacss/dev/postcss': {}
  }
}
`}</code></pre>
    <h2>{`Final install`}</h2>
    <p>{`If needed, run package management install again`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;