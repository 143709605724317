
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <p>{`Snackbar should be styled when calling the context to add one. The rendering example
you see on VarnishUI is for demo purpose only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const exampleMessageClassName = 'myMessage';
const exampleActionClassName = 'myAction';
const exampleExitClassName = 'myExit';

const CustomSnackbar = mui.styled(varnishUi.Snackbar)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};

    .\${exampleMessageClassName} {
        border: 2px dashed orange;
    }
    .\${exampleActionClassName} {
        border: 2px dashed yellow;
    }
    .\${exampleExitClassName} {
        border: 2px dashed blue;
    }
\`;

render(
    <CustomSnackbar
        message="variant={default}"
        open={true}
        messageClassName={exampleMessageClassName}
        actionClassName={exampleActionClassName}
        exitClassName={exampleExitClassName}
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;