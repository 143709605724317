import { cva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const loadingSpinnerRecipe = cva({
    base: {
        display: 'flex',
        transformOrigin: 'center',
        animation: 'loading-spinner-rotate 0.75s infinite linear',

        // default light styles
        '--spinner-color': '{colors.dark-teal.100}',
        _dark: {
            '--spinner-color': '[white]',
        },
    },
    variants: {
        size: {
            small: {
                width: '[16px]',
                height: '[16px]',
            },
            medium: {
                width: '[32px]',
                height: '[32px]',
            },
            large: {
                width: '[64px]',
                height: '[64px]',
            },
            full: {
                width: '[100%]',
                height: '[100%]',
            },
        },
        color: {
            primary: {
                '--spinner-color': 'colors.accent.primary',
                _dark: {
                    '--spinner-color': 'colors.accent.primary',
                },
            },
            secondary: {
                '--spinner-color': 'colors.accent.secondary',
                _dark: {
                    '--spinner-color': 'colors.accent.secondary',
                },
            },
            tertiary: {
                '--spinner-color': 'colors.accent.tertiary',
                _dark: {
                    '--spinner-color': 'colors.accent.tertiary',
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
    },
});

type LoadingSpinnerRecipeProps = RecipeVariantProps<typeof loadingSpinnerRecipe>;

export { loadingSpinnerRecipe, loadingSpinnerRecipe as default };
export type { LoadingSpinnerRecipeProps };
