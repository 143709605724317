import { cx } from '@/utils/cx';

import { Close } from '@/components/icons';

import { modalStyles, type ModalRecipeProps } from '@/components/modal/modal.styles';
import {
    DialogActions,
    DialogCloseButton as ModalCloseButton,
    type DialogCloseButtonProps as ModalCloseButtonProps,
} from '@/components/dialog/DialogActions';

interface ModalCloseIconButtonProps extends ModalRecipeProps, React.PropsWithChildren {
    className?: string;
}

// TODO this should be an IconButton -- or we need to have better support for Icons as buttons
// This is specifically the X -- for a text like "close" button, use a regular DialogCloseButton
const ModalCloseIconButton = ({ className, children, ...rest }: ModalCloseIconButtonProps) => {
    const [variantProps, localProps] = modalStyles.splitVariantProps(rest);
    const modalClassNames = modalStyles(variantProps);

    return (
        <ModalCloseButton
            variant="text"
            className={cx(modalClassNames.closeButton, className)}
            {...localProps}>
            {children ?? <Close size="large" />}
        </ModalCloseButton>
    );
};

interface ModalActionsProps extends ModalRecipeProps, React.PropsWithChildren {
    className?: string;
}

const ModalActions = ({ className, children, ...rest }: ModalActionsProps) => {
    const [variantProps, localProps] = modalStyles.splitVariantProps(rest);
    const modalClassNames = modalStyles(variantProps);

    return (
        <DialogActions className={cx(modalClassNames.actions, className)} {...localProps}>
            {children}
        </DialogActions>
    );
};

export type { ModalCloseButtonProps, ModalCloseIconButtonProps, ModalActionsProps };
export { ModalCloseButton, ModalCloseIconButton, ModalActions, ModalActions as default };
