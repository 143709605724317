import React, { PropsWithChildren, ReactElement } from 'react';
import {
    Tabs as AriaTabs,
    TabsProps as AriaTabsProps,
    Key,
    TabsContext,
} from 'react-aria-components';
import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import TabsRecipeProps, { tabsRecipe } from './tabs.styles';
import { TabList } from './TabsList';
import { TabPanel } from './TabPanel';
import { Tab } from './Tab';

type Items = {
    id: string;
    header: (props?: React.ComponentProps<typeof Tab>) => ReactElement;
    content: (props?: React.ComponentProps<typeof TabPanel>) => ReactElement;
    isDisabled?: boolean;
};

type TabsProps = {
    className?: string;
    disabledKeys?: Key[];
    isDisabled?: boolean;
    selectedKey?: Key | null;
    orientation?: 'horizontal' | 'vertical';
    onSelectionChange?: (key: string) => void;
    items?: Items[];
    tabListClassName?: string;
    tabClassName?: string;
    tabPanelClassName?: string;
    children?: React.ReactNode;
} & AriaTabsProps &
    TabsRecipeProps;

const Tabs = ({
    className,
    disabledKeys,
    selectedKey,
    orientation = 'horizontal',
    isDisabled,
    onSelectionChange,
    items,
    tabListClassName,
    tabClassName,
    tabPanelClassName,
    children,
    ...rest
}: PropsWithChildren<TabsProps>) => {
    const [variantProps, localProps] = tabsRecipe.splitVariantProps(rest);
    const recipeClassNames = tabsRecipe.raw({
        ...variantProps,
    });

    return (
        <TabsContext.Provider value={{ selectedKey, onSelectionChange }}>
            <AriaTabs
                {...localProps}
                className={cx(css(recipeClassNames.root), className)}
                orientation={orientation}
                selectedKey={selectedKey}
                isDisabled={isDisabled}
                disabledKeys={disabledKeys}
                onSelectionChange={onSelectionChange}>
                {items !== undefined && items.length > 0 ? (
                    <>
                        <TabList className={cx(css(recipeClassNames.tabList), tabListClassName)}>
                            {items.map(({ id, header, isDisabled }) => (
                                <React.Fragment key={id}>
                                    {header({
                                        id,
                                        className: cx(css(recipeClassNames.tab), tabClassName),
                                        isDisabled,
                                    })}
                                </React.Fragment>
                            ))}
                        </TabList>
                        {items.map(({ id, content }) => (
                            <React.Fragment key={id}>
                                {content({
                                    id,
                                    className: cx(
                                        css(recipeClassNames.tabPanel),
                                        tabPanelClassName
                                    ),
                                })}
                            </React.Fragment>
                        ))}
                    </>
                ) : null}
                {children}
            </AriaTabs>
        </TabsContext.Provider>
    );
};

export { Tabs, TabsContext };
export type { TabsProps, Items };
