
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Sorting'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`By default you can pass a list of headers & data items to render table. Or you can use each individual components to assemble table.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import type {SortDescriptor} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true, allowsSorting: true },
        { id: 'height', name: 'Height', allowsSorting: true },
        { id: 'mass', name: 'Mass', allowsSorting: true },
        { id: 'birthYear', name: 'Birth Year', allowsSorting: true },
    ];

    const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
        column: 'name',
        direction: 'ascending',
    });

    const dataItems = [
        { id: '1', name: 'Luke Skywalker', height: '172', mass: '77', birthYear: '19BBY' },
        { id: '2', name: 'Leia Organa', height: '150', mass: '49', birthYear: '19BBY' },
        { id: '3', name: 'Biggs Darklighter', height: '183', mass: '84', birthYear: '24BBY' },
        { id: '4', name: 'R2-D2', height: '96', mass: '32', birthYear: '33BBY' },
        { id: '5', name: 'Darth Vader', height: '202', mass: '136', birthYear: '41.9BBY' },
        { id: '6', name: 'Beru Whitesun lars', height: '165', mass: '75', birthYear: '47BBY' },
        { id: '7', name: 'Owen Lars', height: '178', mass: '120', birthYear: '52BBY' },
        { id: '8', name: 'Obi-Wan Kenobi', height: '182', mass: '77', birthYear: '57BBY' },
        { id: '9', name: 'C-3PO', height: '167', mass: '75', birthYear: '112BBY' },
        { id: '10', name: 'R5-D4', height: '97', mass: '32', birthYear: 'unknown' },
    ].sort((a, b) => {
        let d = a[sortDescriptor.column].localeCompare(b[sortDescriptor.column]);
        return sortDescriptor.direction === 'descending' ? -d : d;
    });

    return (
         <varnishUi.Table
            aria-label="Files"
            headers={headers}
            dataItems={dataItems}
            sortDescriptor={sortDescriptor}
            onSortChange={setSortDescriptor}
        />
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;