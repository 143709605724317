export const Lipsum = () => {
    return (
        <>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ex ac
                metus placerat pharetra. Pellentesque tincidunt dapibus ligula, quis dictum quam
                hendrerit non. Quisque tempor pharetra ante, eu volutpat dolor condimentum sit amet.
                Nulla ac odio a nulla semper viverra. Vivamus id urna sit amet diam posuere
                sagittis. In aliquet, ligula nec porttitor dictum, dolor tellus consectetur nisl, at
                imperdiet nisi urna id lorem. Nulla vel enim justo. Duis bibendum tempus molestie.
                Fusce ornare euismod aliquam. Morbi ac laoreet justo. Praesent feugiat volutpat
                ornare. Aliquam quis pretium enim. Vivamus dictum diam in pulvinar lacinia. Cras
                congue neque id felis fringilla, eget fermentum libero lacinia.
            </p>
            <p>
                Donec volutpat sem at est luctus eleifend. Integer viverra dolor nisi, et mollis
                dolor feugiat a. Integer ante lorem, faucibus id erat a, accumsan accumsan urna.
                Etiam ac justo sit amet nisi pellentesque convallis nec ut arcu. Nullam leo lectus,
                porta vitae tristique ac, gravida eu nulla. Phasellus auctor feugiat odio ut
                convallis. Mauris feugiat orci nunc, quis posuere nunc blandit nec. Aliquam turpis
                lacus, tincidunt a purus at, sagittis semper metus. Phasellus convallis, leo vel
                sodales placerat, diam tellus gravida ex, non porttitor tellus sem ac sem.
            </p>
            <p>
                Ut ac cursus risus. Donec accumsan, leo vitae iaculis pharetra, leo erat dignissim
                quam, blandit cursus lorem orci ac odio. Vivamus laoreet augue in viverra molestie.
                Etiam ultrices sagittis risus sed aliquet. Maecenas volutpat dolor lacus, in mollis
                mi tincidunt interdum. Duis nec turpis pharetra, fermentum erat eu, blandit diam.
                Mauris vitae feugiat urna. Aenean nec fermentum nisi. Cras luctus odio et nibh
                ornare, sed vulputate justo vestibulum. Praesent dapibus id erat a suscipit. Nunc
                consequat tincidunt erat in viverra. Pellentesque vel posuere urna. Quisque
                porttitor augue turpis, sed mollis sapien convallis sit amet.
            </p>
            <p>
                Suspendisse accumsan ante eros, id pellentesque lorem euismod eu. Pellentesque porta
                venenatis turpis ut congue. Sed sem orci, scelerisque sed pharetra ut, posuere eu
                velit. Aenean non tincidunt arcu. Nullam vel sem quis dui eleifend accumsan vel sed
                justo. Integer arcu mi, interdum ut commodo nec, efficitur et dolor. Vivamus rutrum
                ante id tempor dapibus. Donec ullamcorper sit amet magna in condimentum. Nullam sed
                felis tempus, eleifend mi eu, volutpat erat. Aenean tellus nibh, dignissim in erat
                sed, sodales aliquet nisi. Vivamus in ornare orci, vitae auctor purus. Nam ornare
                metus rutrum egestas posuere. Morbi hendrerit convallis sapien quis semper.
            </p>
            <p>
                In eu accumsan est, vel pellentesque lacus. Proin sed efficitur lorem. Sed eros
                neque, vestibulum et aliquet commodo, rhoncus ac purus. Aliquam mollis turpis vitae
                lobortis posuere. Nam vulputate neque erat, nec hendrerit urna tempus ac. Praesent
                ullamcorper luctus lacus sed ultricies. Etiam iaculis purus nisl. Quisque dictum sem
                sed lectus ultricies, quis convallis purus molestie. Nullam nec erat vitae libero
                aliquam ultrices eget quis purus. Sed tincidunt eleifend lacinia. Quisque sit amet
                metus feugiat, lobortis mi non, maximus lectus.
            </p>
        </>
    );
};
