
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'How do I use SX?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`How do I style an element with Varnish's color and typography using the sx prop?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import mui from '@mui/material';

render(
    <mui.Box sx={{ width: '100%' }}>
        <mui.Box
            sx={{
                width: '100%',
                textAlign: 'center',
                margin: (theme) => theme.spacing(0.5),
                bgcolor: (theme) => theme.color.B10.hex,
                color: (theme) => theme.color.N4.hex,
                fontFamily: (theme) => theme.typography.body1.fontFamily,
            }}>
            © The Allen Institute for Artificial Intelligence
        </mui.Box>
    </mui.Box>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;