
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Direct Usage'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can use a Varnish color via the theme.`}</p>
    <p>{`eg: `}<inlineCode parentName="p">{`theme.color.B3`}</inlineCode></p>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled, css } from '@mui/material/styles';
import mui from '@mui/material';

const Demo = () => {
  const ColorDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'col',
  })<{ col: string }>(
    ({ theme, col }) =>
      css\`
        width: 100%;
        height: \${theme.spacing(5)};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: \${theme.spacing(1)};
        background: \${theme.color[col].toString()};
        color: \${theme.color[col].contrastTextColor};
        border: 1px solid
          \${theme.color[col].contrastTextColor};
      \`,
  );

  const colorsToRender = ['B8', 'B6', 'B4', 'P8', 'P6', 'P4'];

  return (
    <mui.Stack spacing={1.5} style={{ width: '100%' }}>
      {colorsToRender.map((color) => (
        <ColorDiv key={color} col={color}>
          {color}
        </ColorDiv>
      ))}
    </mui.Stack>
  );
}

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;