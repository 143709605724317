import React from 'react';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { FooterRecipeProps, footerRecipe } from './footer.styles';
import Link from '../link/Link';

type FooterProps = {
    className?: string;
    linksGroupClassName?: string;
    copyRightClassName?: string;
    variant?: 'full' | 'slimLeft' | 'slimCenter';
    linksGroup?: React.ReactNode[];
} & FooterRecipeProps;

function Footer({
    className,
    linksGroupClassName,
    copyRightClassName,
    variant = 'full',
    linksGroup = [],
    ...rest
}: FooterProps) {
    const [variantProps, localProps] = footerRecipe.splitVariantProps(rest);
    const recipeClassNames = footerRecipe.raw({ ...variantProps, variant });

    return (
        <footer className={cx(css(recipeClassNames.root), className)} {...localProps}>
            {variant === 'full' && (
                <div className={cx(css(recipeClassNames.linksGroup), linksGroupClassName)}>
                    {linksGroup.map((link, id) => (
                        <React.Fragment key={id}>{link}</React.Fragment>
                    ))}
                </div>
            )}
            <div className={cx(css(recipeClassNames.copyRight), copyRightClassName)}>
                <span>
                    © The Allen Institute for Artificial Intelligence - All Rights Reserved
                </span>
                {variant !== 'full' && (
                    <ul>
                        <li>
                            <Link
                                color="primary"
                                href="https://allenai.org/privacy-policy/2022-07-21">
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link color="primary" href="https://allenai.org/terms/2024-09-25">
                                Terms of Service
                            </Link>
                        </li>
                        <li>
                            <Link color="primary" href="https://allenai.org/responsible-use">
                                Responsible use
                            </Link>
                        </li>
                    </ul>
                )}
            </div>
        </footer>
    );
}

export { Footer, Footer as default };
export type { FooterProps };
