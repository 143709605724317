
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Icon usage examples'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Basic built in `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` component and basic external Icon component`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const VarnishCopyIcon = varnishUi.varnishIcon(ContentCopyIcon);

// although this icon exists, if you included it in your sprite, you could make
// a component with:
const CustomArrowIcon = varnishUi.varnishIconSprite('close');

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Stack>
                <varnishUi.Icons.Spark size="large" color="primary" />
                <varnishUi.Label>vUI sprite</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <VarnishCopyIcon size="large" color="secondary" />
                <varnishUi.Label>MUI varnished</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <varnishUi.Icon size="large" color="tertiary">
                    <ContentCopyIcon />
                </varnishUi.Icon>
                <varnishUi.Label>MUI wrapped</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <CustomArrowIcon size="large" color="primary" />
                <varnishUi.Label>"Custom" sprite</varnishUi.Label>
            </varnishUi.Stack>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;