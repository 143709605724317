'use client';

import { Divider, styled, Typography } from '@mui/material';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ComponentProps, PropsWithChildren } from 'react';

import { Code } from './CodeBlocks';

interface Props {
    children?: string;
    className?: string;
}

export function Markdown({ children, className }: Props) {
    return (
        <ReactMarkdown components={components} className={className} remarkPlugins={[remarkGfm]}>
            {children}
        </ReactMarkdown>
    );
}

export const TableWrapper = styled('div')`
    overflow-x: auto;
    margin: ${({ theme }) => theme.spacing(3)} 0;

    table {
        width: 100%;
        min-width: ${({ theme }) => theme.breakpoints.values.md.toString()};
        font-family: ${({ theme }) => theme.typography.fontFamily};
        border: 1px solid ${({ theme }) => theme.palette.grey.A200};

        th,
        td {
            padding: ${({ theme }) => theme.spacing(1.5)};
            border-color: ${({ theme }) => theme.palette.grey.A200};
            border-width: 1px 0;
        }

        th {
            padding-top: ${({ theme }) => theme.spacing(1.5)};
            border-width: 0 0 2px 0;
            white-space: pre;
        }

        tbody tr {
            transition: all 0.3s;
            &:hover {
                background: rgba(60, 90, 100, 0.04);
            }
        }
    }
`;

const Img = styled('img')`
    max-width: 100%;
    img[src*='#thumbnail'] {
        width: ${({ theme }) => theme.spacing(8)};
    }
`;

const Ol = styled('ol')`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Ul = styled('ul')`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    list-style-type: revert;
`;

const Li = styled('li')`
    margin-bottom: 0;
`;

// a mapping of components overrides
// some placeholders in here
const components: Components = {
    h1: (props: PropsWithChildren) => (
        <Typography gutterBottom variant={'h1'}>
            {props.children}
        </Typography>
    ),
    h2: (props: PropsWithChildren) => (
        <Typography gutterBottom variant={'h2'}>
            {props.children}
        </Typography>
    ),
    h3: (props: PropsWithChildren) => (
        <Typography gutterBottom variant={'h3'}>
            {props.children}
        </Typography>
    ),
    h4: (props: PropsWithChildren) => (
        <Typography gutterBottom variant={'h4'}>
            {props.children}
        </Typography>
    ),
    h5: (props: PropsWithChildren) => (
        <Typography gutterBottom variant={'h5'}>
            {props.children}
        </Typography>
    ),
    h6: (props: PropsWithChildren) => (
        <Typography gutterBottom variant={'h6'}>
            {props.children}
        </Typography>
    ),
    p: (props: PropsWithChildren) => <Typography variant={'body1'}>{props.children}</Typography>,
    b: (props: PropsWithChildren) => <span style={{ fontWeight: 'bold' }}>{props.children}</span>,
    em: (props: PropsWithChildren) => <span style={{ fontStyle: 'italic' }}>{props.children}</span>,
    hr: () => <Divider />,
    del: (props: PropsWithChildren) => (
        <span style={{ textDecoration: 'line-through' }}>{props.children}</span>
    ),
    li: (props: ComponentProps<typeof Li>) => <Li {...props} />,
    ul: (props: ComponentProps<typeof Ul>) => <Ul {...props} />,
    ol: (props: ComponentProps<typeof Ol>) => <Ol {...props} />,
    img: (props: ComponentProps<typeof Img>) => <Img {...props} />,
    pre: (props: ComponentProps<'span'>) => <span {...props} />,
    code: (props: PropsWithChildren) => <Code variant="dark">{props.children}</Code>,
    table: (props: ComponentProps<'table'>) => (
        <TableWrapper>
            <table {...props} />
        </TableWrapper>
    ),
};

export default Markdown;
