import React from 'react';
import { ThemeProvider } from '@emotion/react';

import VarnishTheme from '@allenai/varnish-theme';
import type { PaletteModes, TypographyKeys } from '@allenai/varnish-theme';

import { useTheme } from './ThemeProviderBase';

type VarnishThemeType = typeof VarnishTheme;

const fontProperty = (typography: TypographyKeys) => {
    const { fontFamily, fontSize, fontWeight, lineHeight } =
        VarnishTheme.typography[typography].value;
    return `${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;
};

// either add disabled prop here (or maybe better for treeshaking-- conditionally render this provider)
const EmotionThemeProvider = ({ children }: React.PropsWithChildren) => {
    const { computedColorMode } = useTheme();

    const theme = {
        ...VarnishTheme,
        palette: VarnishTheme.palette[computedColorMode],
        // helper: -- TBD usefulness
        fontProperty,
    };

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

declare module '@emotion/react' {
    export interface Theme extends VarnishThemeType {
        palette: VarnishThemeType['palette'][PaletteModes];
    }
}

export { EmotionThemeProvider, EmotionThemeProvider as default };
