
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { ErrorBoundaryExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'ErrorBoundary',
  cols: 1,
  iconSrc: './modal-2-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Error Boundary`}</h2>
    <p>{`By default, `}<inlineCode parentName="p">{`VarnishApp`}</inlineCode>{` is wrapped in this ErrorBoundary component, so all runtime errors will be
caught.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import { VarnishApp } from '@allenai/varnish2/components';

// Varnish app includes an ErrorBoundary
const App = () => (
    <VarnishApp>
        <YourApp />
    </VarnishApp>
);
`}</code></pre>
    <p>{`However you can also use it directly. This is helpful if you want to have a sub section of a page
catch without affecting other sections.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import { VarnishApp, ErrorBoundary } from '@allenai/varnish2/components';

const App = () => (
    <VarnishApp>
        <ErrorBoundary>
            <YourComponentA />
        </ErrorBoundary>
        <ErrorBoundary>
            <YourComponentB />
        </ErrorBoundary>
    </VarnishApp>
);
`}</code></pre>
    <h3>{`When To Use`}</h3>
    <p>{`The skiff-tamplate includes a global ErrorBoundary around the entire app. But you should also use this if you dont start with the `}<a parentName="p" {...{
        "href": "https://skiff-template.apps.allenai.org/"
      }}>{`Skiff Template`}</a>{`.`}</p>
    <h3>{`Example`}</h3>
    <Examples cols={frontMatter.cols} examples={ErrorBoundaryExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;