
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Third party ThemeProvider'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The ThemeProviderBase can be used to select the correct color palette, a basic
`}<inlineCode parentName="p">{`EmotionThemeProvider`}</inlineCode>{` is provided to handle that in emotion, to create a third
party ThemeProvider, you can use the properties returned from `}<inlineCode parentName="p">{`useTheme()`}</inlineCode>{`, this
is what the `}<inlineCode parentName="p">{`EmotionThemeProvider`}</inlineCode>{` does.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';

const ThemeSwitcher = () => {
    const { computedColorMode, setColorMode } = varnishUi.useTheme();
    return (
        <varnishUi.Select selectedKey={computedColorMode} onSelectionChange={setColorMode}>
            <varnishUi.SelectListBoxItem id="light">Light</varnishUi.Radio>
            <varnishUi.SelectListBoxItem id="dark">Dark</varnishUi.Radio>
        </varnishUi.RadioGroup>
    );
};

const Div = emotionStyled.div\`
    padding: 20px;
    background: \${({ theme }) => theme.palette.background.default.value};
\`;
render(
    <varnishUi.AppProvider themeProvider={varnishUi.EmotionThemeProvider}>
        <Div>
            <ThemeSwitcher />
        </Div>
    </varnishUi.AppProvider>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;