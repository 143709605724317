import * as Default from './demo/default.mdx';
import * as Shapes from './demo/shapes.mdx';
import * as Sizes from './demo/sizes.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as Labels from './demo/labels.mdx';
import * as FullWidth from './demo/fullWidth.mdx';
import * as IsRequired from './demo/isRequired.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const SelectExamples = [
    Default,
    Shapes,
    Sizes,
    Disabled,
    Labels,
    FullWidth,
    IsRequired,
    StylingCss,
    StylingStyled,
];
