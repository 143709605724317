
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Font Weights'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import varnishTheme from '@allenai/varnish2/theme';
import mui from '@mui/material';

const Box = mui.Box;
const Typography = mui.Typography;
const MaxWidthText = varnishComponents.MaxWidthText;
const varnishDesignTokens = varnishTheme.getTheme();

render(
    <MaxWidthText>
        <Typography variant="body1" fontWeight="bold" gutterBottom>
            [fontWeightBold] Example text ({varnishDesignTokens.typography.fontWeightBold})
        </Typography>
        <Typography variant="body1" fontWeight="semiBold" gutterBottom>
            [fontWeightSemiBold] Example text ({varnishDesignTokens.typography.fontWeightSemiBold})
        </Typography>
        <Typography variant="body1" gutterBottom>
            [fontWeightRegular] Example text ({varnishDesignTokens.typography.fontWeightRegular})
        </Typography>
        <Typography variant="body1" fontWeight="light" gutterBottom>
            [fontWeightLight] Example text ({varnishDesignTokens.typography.fontWeightLight})
        </Typography>
    </MaxWidthText>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;