import { Text as AriaText, TextProps as AriaTextProps } from 'react-aria-components';

import { cx } from '@/utils/cx';

import { descriptionRecipe, DescriptionRecipeProps } from './description.styles';

type DescriptionProps = {
    className?: string;
} & AriaTextProps &
    DescriptionRecipeProps;

const Description = ({ className, ...rest }: DescriptionProps) => {
    const [variantProps, localProps] = descriptionRecipe.splitVariantProps(rest);
    return (
        <AriaText
            {...localProps}
            slot="description"
            className={cx(descriptionRecipe(variantProps), className)}
        />
    );
};

export { Description };
export type { DescriptionProps };
