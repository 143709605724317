import { Link, LinkProps, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import React from 'react';
import { LinkProps as ReactRouterLinkProps } from 'react-router-dom';

type RouterLinkProps = Pick<ReactRouterLinkProps, 'to' | 'className'>;
type RouterLinkType = React.ForwardRefExoticComponent<
    Omit<RouterLinkProps, 'ref'> & React.RefAttributes<HTMLAnchorElement>
>;

export const getLinkBehavior = (
    RouterLink: RouterLinkType
): React.ForwardRefExoticComponent<
    Omit<unknown, 'ref'> & React.RefAttributes<HTMLAnchorElement>
> => {
    const LinkBehavior = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
        const { href, ...rest } = props;
        // Map href (Material UI) -> to (RouterLink like passed in)
        // The mapping from href -> to isn't clean because MUI allows it to be nullish. Just asserting that it's null here to get TS to allow it
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <RouterLink ref={ref} {...rest} to={href!} />;
    });

    LinkBehavior.displayName = 'LinkBehaviorWrapper';
    return LinkBehavior;
};

export const getRouterOverriddenTheme = (
    RouterLink: RouterLinkType,
    overrides: ThemeOptions = {}
) =>
    deepmerge(
        {
            components: {
                MuiLink: {
                    defaultProps: {
                        component: getLinkBehavior(RouterLink),
                    } as LinkProps,
                },
                MuiButtonBase: {
                    defaultProps: {
                        LinkComponent: getLinkBehavior(RouterLink),
                    },
                },
            },
        },
        overrides
    );

export const MaybeLink = ({ children, href, ...rest }: LinkProps) => {
    return href?.length ? (
        <Link href={href} {...rest}>
            {children}
        </Link>
    ) : (
        children
    );
};
