import { PropsWithChildren, ReactNode } from 'react';
import {
    Key,
    TableBody as AriaTableBody,
    TableBodyProps as AriaTableBodyProps,
} from 'react-aria-components';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';

type TableBodyProps = {
    renderEmptyState?: (props: AriaTableBodyProps<object>) => ReactNode;
    children?: ReactNode;
    items: object[];
    disabledKeys?: Key[];
    className?: string;
} & AriaTableBodyProps<object>;

const TableBody = ({
    renderEmptyState,
    children,
    items,
    disabledKeys,
    className,
    ...rest
}: PropsWithChildren<TableBodyProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaTableBody
            {...localProps}
            renderEmptyState={renderEmptyState}
            items={items}
            disabledKeys={disabledKeys}
            className={cx(recipeClassNames.tableBody, className)}>
            {children}
        </AriaTableBody>
    );
};

export { TableBody };
export type { TableBodyProps };
