import { cva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const linkRecipe = cva({
    base: {
        fontWeight: 'medium', // Do we want this?
        cursor: 'pointer',
        color: '[inherit]',
        _disabled: {
            color: 'gray.40',
            cursor: 'not-allowed',
        },
    },
    variants: {
        color: {
            inherit: {
                color: 'text.primary',
            },
            primary: {
                color: 'accent.primary',
            },
            secondary: {
                color: 'accent.secondary',
            },
            tertiary: {
                color: 'accent.tertiary',
            },
        },
        underline: {
            // not implemented: `hover` only version
            // can manually use `none` with a _hover style
            always: {
                textDecorationLine: 'underline',
                textDecorationColor: '[currentColor/50]',
                _hover: {
                    _notDisabled: {
                        textDecorationColor: '[inherit]',
                    },
                },
            },
            none: {
                textDecoration: 'none',
            },
        },
        size: {
            inherit: {},
            small: {
                fontSize: 'sm',
            },
            medium: {
                fontSize: 'md',
            },
            large: {
                fontSize: 'lg',
            },
        },
    },
    defaultVariants: {
        color: 'inherit',
        size: 'inherit',
        underline: 'always',
    },
});

type LinkRecipeProps = RecipeVariantProps<typeof linkRecipe>;

export { linkRecipe, linkRecipe as default };
export type { LinkRecipeProps };
