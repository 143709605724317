
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { PaperRowExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'PaperRow',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`PaperRow`}</h2>
    <p>{`A component to show details of a paper, including title, authors, venue, and published date.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`This component is helpful for showing papers that are relevant to your project. It is based on how papers are shown at `}<a parentName="p" {...{
        "href": "https://www.semanticscholar.org/"
      }}>{`Semantic Scholar`}</a>{`.`}</p>
    <h3>{`Example`}</h3>
    <Examples cols={frontMatter.cols} examples={PaperRowExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title of Paper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`authors`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Author[]`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Author Names and optional URL link`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`url`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URL link to paper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`authorLimit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Max # of authors to show before 'Expand' button is shown. Default is 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`venue`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Where paper was published. (Arxiv, new york times, etc.)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`abstract`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Abstract explaining the paper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`abstractCharLimit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Char limit before abstract is truncated and a 'Expand' button is shown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fieldsOfStudy`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string[]?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`What type of paper. (Math, Computer Science, Biology, etc)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`publicationDate`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Date paper was published`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`shortenAuthorNames`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Abbreviate author names. ie: Sarah Jessica Parker -> S. J. Parker`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Paper Types`}</h3>
    <pre><code parentName="pre" {...{}}>{`Paper {
    title: string;
    authors: Author[];
    url?: string;
    venue?: string;
    abstract?: string;
    fieldsOfStudy?: string[];
    publicationDate?: string;
}

Author = {
    name: string;
    url?: string;
};
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;