
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Persistent Variant'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Persistent Variant allows the item to display on the same level as the content. It's only accept placement left, right.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const Demo = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* The persistent drawer sits on the left */}
            <varnishUi.Drawer
                isOpen={open}
                onClose={() => setOpen(false)}
                placement="left"
                variant="persistent">
                {/* Drawer content */}
                Navigation Items
            </varnishUi.Drawer>

            {/* Main content on the right */}
            <div style={{ flex: 1, padding: 16 }}>
                <varnishUi.Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
                    Open Drawer
                </varnishUi.Button>
            </div>
        </div>
    );
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;