
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider
const labelClassName = 'myLabel';
const descriptionClassName = 'myDescription';
const errorClassName = 'myError';

const CustomCheckboxGroup = mui.styled(varnishUi.CheckboxGroup)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};
    padding: 2px;

    .\${labelClassName} {
        border: 1px dashed \${({ theme }) => theme.color.G5.hex};
        padding: 2px;
        margin: 2px;
    }
    .\${descriptionClassName} {
        border: 1px dashed \${({ theme }) => theme.color.B5.hex};
        padding: 2px;
        margin: 2px;
    }
      .\${errorClassName} {
        border: 1px dashed \${({ theme }) => theme.color.O5.hex};
        padding: 2px;
        margin: 2px;
    }
\`;

render(
    <form>
        <CustomCheckboxGroup
            label="Favorite sports"
            description="Select your favorite sports"
            errorMessage="Please select all sports"
            labelClassName={labelClassName}
            descriptionClassName={descriptionClassName}
            errorClassName={errorClassName}>
            <varnishUi.Checkbox isRequired value="soccer">
                Soccer
            </varnishUi.Checkbox>
            <varnishUi.Checkbox isRequired value="baseball">
                Baseball
            </varnishUi.Checkbox>
            <varnishUi.Checkbox isRequired value="basketball">
                Basketball
            </varnishUi.Checkbox>
        </CustomCheckboxGroup>
        <br />
        <varnishUi.Button variant="outlined" type="submit">
            Submit
        </varnishUi.Button>
    </form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;