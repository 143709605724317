
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'ResponsivePie with LightTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of the Nivo ResponsivePie component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { pieDefaults } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsivePie
            // add in varnish theme at top of all charts
            {...pieDefaults}
            // add options specific to your chart
            // use varnish values when it makes sense

            // add data
            data={[
                {
                    id: 'True',
                    value: 448,
                },
                {
                    id: 'False',
                    value: 134,
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;