
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Sizes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To adjust select size, use the size prop for larger or smaller options.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const MySelectContent = () => {
    return (
        <varnishUi.SelectListBoxSection>
            <varnishUi.SelectListBoxHeader>List of Animals</varnishUi.SelectListBoxHeader>
            <varnishUi.SelectListBoxItem text="Llama" />
            <varnishUi.SelectListBoxItem text="Lizard" />
            <varnishUi.SelectListBoxItem text="Frog" />
            <varnishUi.SelectListBoxItem icon={<varnishUi.Icons.Spark />} text="Cat" />
            <varnishUi.SelectListBoxItem icon={<varnishUi.Icons.Add />} text="Dog" />
            <varnishUi.SelectListBoxItem icon={<varnishUi.Icons.Upload />} text="Alligator" />
        </varnishUi.SelectListBoxSection>
    );
};

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Select size="small" placeholder="small">
                <MySelectContent />
            </varnishUi.Select>
            <varnishUi.Select size="medium" placeholder="medium">
                <MySelectContent />
            </varnishUi.Select>
            <varnishUi.Select size="large" placeholder="large">
                <MySelectContent />
            </varnishUi.Select>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;