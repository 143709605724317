
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To adjust the color of radio, use the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop on the group.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={8}>
        <varnishUi.RadioGroup color="primary" defaultValue="baseball" label="Primary">
            <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>

        <varnishUi.RadioGroup color="secondary" defaultValue="soccer" label="Secondary">
            <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>

        <varnishUi.RadioGroup color="tertiary" defaultValue="basketball" label="Tertiary">
            <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>

        <varnishUi.RadioGroup color="teal" defaultValue="baseball" label="Teal">
            <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
            <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
            <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
        </varnishUi.RadioGroup>

        <PadDiv>
            <varnishUi.RadioGroup color="cream" defaultValue="basketball" label="Cream">
                <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
                <varnishUi.Radio value="baseball">Baseball</varnishUi.Radio>
                <varnishUi.Radio value="basketball">Basketball</varnishUi.Radio>
            </varnishUi.RadioGroup>
        </PadDiv>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;