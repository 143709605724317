
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

const Demo = () => {
    const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

    const className = () => {
        return emotionCss.css\`
            text-decoration: none;
            color: red;
            border: 1px dashed \${theme.color.R6.value};
        \`;
    };

    const tabListClassName = () => {
        return emotionCss.css\`
            border: 1px dashed \${theme.color.B2.value};
        \`;
    }

    const tabClassName = () => {
        return emotionCss.css\`
            border: 1px dashed \${theme.color.R5.value};
        \`;
    }

    const tabPanelClassName = () => {
        return emotionCss.css\`
            border: 1px dashed \${theme.color.R4.value};
        \`;
    }

    const [tabSelected, setTabSelect] = React.useState<Key>("FoR");
    const items: Item[] = [
        {
            id: 'FoR',
            header: (props) => <varnishUi.Tab {...props}>Founding of Rome</varnishUi.Tab>,
            content: (props) => <varnishUi.TabPanel {...props}>Arma virumque cano, Troiae qui primus ab oris.</varnishUi.TabPanel>,
        },
        {
            id: 'MaR',
            header: (props) => <varnishUi.Tab {...props}>Monarchy and Republic</varnishUi.Tab>,
            content: (props) => <varnishUi.TabPanel {...props}>Senatus Populusque Romanus.</varnishUi.TabPanel>,
        },
        {
            id: 'Emp',
            header: (props) => <varnishUi.Tab {...props}>Empire</varnishUi.Tab>,
            content: (props) => <varnishUi.TabPanel {...props}>Alea jacta est.</varnishUi.TabPanel>,
        },
    ];

    return (
        <varnishUi.Tabs
            onSelectionChange={setTabSelect}
            selectedKey={tabSelected}
            items={items}
            className={emotionCss.cx(className())}
            tabListClassName={emotionCss.cx(tabListClassName())}
            tabClassName={emotionCss.cx(tabClassName())}
            tabPanel={emotionCss.cx(tabPanelClassName())}
        />
    );
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;