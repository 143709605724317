import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const codeBlockRecipe = sva({
    slots: ['root', 'code'],
    base: {
        root: {},
        code: {},
    },
});

type CodeBlockRecipeProps = RecipeVariantProps<typeof codeBlockRecipe>;

export { codeBlockRecipe, codeBlockRecipe as default };
export type { CodeBlockRecipeProps };
