import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 *  There is no native HTML element with switch styling. <input type="checkbox"> is the closest
 *  semantically, but isn't styled or exposed to assistive technology as a switch. Switch helps
 *  achieve accessible switches that can be styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Switch.html
 */
const switchRecipe = sva({
    className: 'switch-recipe',
    slots: ['root', 'label', 'track', 'thumb', 'description', 'error'],
    base: {
        root: {
            display: 'flex',
            gap: '[8px]',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'text.primary',
            _disabled: {
                cursor: 'not-allowed',
            },
            _componentReadOnly: {
                cursor: 'default',
            },
        },
        label: {},
        track: {
            boxSizing: 'border-box',
            position: 'relative',
            flexShrink: '0',
            display: 'flex',
            alignItems: 'center',
            padding: '[1px]',
            border: '[1px solid]',
            borderColor: 'teal.80',
            borderRadius: 'full',
            backgroundColor: '[white]',

            _groupHover: {
                borderColor: 'teal.100',
            },
            _groupSelected: {
                backgroundColor: '[currentColor]',
                // this overrides other states (namely hover)
                '&': {
                    borderColor: '[currentColor]',
                },
            },
            _groupDisabled: {
                color: 'gray.40',
                // this overrides other states (namely hover)
                '&': {
                    borderColor: '[currentColor]',
                },
            },
        },
        thumb: {
            boxSizing: 'border-box',
            position: 'absolute',
            display: 'block',
            aspectRatio: '1',
            borderRadius: 'full',
            transition: '[right 0.2s ease-in-out]',

            backgroundColor: 'teal.80',

            _groupHover: {
                backgroundColor: 'var(--switch-hover-thumb-color, {colors.teal.100})',
            },
            _groupSelected: {
                right: '[1px]',
                // this overrides other states
                '&': {
                    backgroundColor: '[currentColor]',
                },
            },
            _groupDisabled: {
                // this is used to override the hover background color
                // its extra special to override readonly
                '&': {
                    '--switch-hover-thumb-color': '{colors.gray.40}',
                },
                backgroundColor: 'gray.40',
                color: '[white]',
            },
            _groupComponentReadOnly: {
                '--switch-hover-thumb-color': '{colors.teal.80}',
            },
        },
        description: {},
        error: {},
    },
    variants: {
        size: {
            small: {
                root: {
                    fontSize: '[12px]',
                },
                track: {
                    width: '[1.375rem]',
                    height: '[0.75rem]',
                },
                thumb: {
                    width: '[0.5rem]',
                    // 100% - width - padding
                    right: '[calc(100% - 0.5rem - 1px)]',
                },
            },
            medium: {
                root: {
                    fontSize: 'sm',
                },
                track: {
                    width: '[1.625rem]',
                    height: '[0.875rem]',
                },
                thumb: {
                    width: '[0.625rem]',
                    // 100% - width - padding
                    right: '[calc(100% - 0.625rem - 1px)]',
                },
            },
            large: {
                root: {
                    fontSize: 'md',
                },
                track: {
                    width: '[2.3125rem]',
                    height: '[1.25rem]',
                },
                thumb: {
                    width: '[0.875rem]',
                    // 100% - width - padding
                    right: '[calc(100% - 0.875rem - 2px)]',
                    _groupSelected: {
                        right: '[2px]',
                    },
                },
            },
        },
        color: {
            primary: {
                track: {
                    color: 'accent.primary',
                },
                thumb: {
                    _groupSelected: {
                        color: 'cream.100',
                    },
                },
            },
            secondary: {
                track: {
                    color: 'accent.secondary',
                },
                thumb: {
                    _groupSelected: {
                        color: 'dark-teal.100',
                    },
                },
            },
            tertiary: {
                track: {
                    color: 'accent.tertiary',
                },
                thumb: {
                    _groupSelected: {
                        color: 'cream.100',
                    },
                },
            },
            teal: {
                track: {
                    color: 'teal.100',
                },
                thumb: {
                    _groupSelected: {
                        color: 'cream.100',
                    },
                },
            },
            cream: {
                track: {
                    backgroundColor: 'dark-teal.100', // unselected track color (instead of white)
                    color: 'cream.100', // selected track color
                    borderColor: 'cream.100/60', // not sure about using opacity here
                    _groupHover: {
                        borderColor: 'cream.100',
                    },
                },
                thumb: {
                    backgroundColor: 'cream.100', // unselected thumb color (instead of teal)
                    _groupSelected: {
                        color: 'dark-teal.100', // selected thumb color
                    },
                    _groupHover: {
                        // hover thumb color, instead of teal default
                        backgroundColor: 'var(--switch-hover-thumb-color, {colors.cream.100})',
                    },
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        color: 'primary',
    },
});

type SwitchRecipeProps = RecipeVariantProps<typeof switchRecipe>;

export { switchRecipe, switchRecipe as default };
export type { SwitchRecipeProps };
