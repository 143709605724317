import * as Default from './demo/default.mdx';
import * as Shape from './demo/shape.mdx';
import * as Placement from './demo/placement.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as Offset from './demo/offset.mdx';
import * as IsOpen from './demo/isOpen.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StyledCss from './demo/stylingStyled.mdx';
import * as Delay from './demo/delay.mdx';

export const TooltipExamples = [
    Default,
    Shape,
    Placement,
    Disabled,
    Offset,
    IsOpen,
    StylingCss,
    StyledCss,
    Delay,
];
