
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Default'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Default Usage.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

render(
    <varnishComponents.CopyToClipboardButton
        buttonContent={<ContentCopyIcon fontSize="inherit" />}
        text={'Test Copy Text'}>
        {'Test Copy Text'}
    </varnishComponents.CopyToClipboardButton>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;