
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Orientation'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Sliders can be `}<inlineCode parentName="p">{`horizontal`}</inlineCode>{` or `}<inlineCode parentName="p">{`vertical`}</inlineCode>{` based on the `}<inlineCode parentName="p">{`orientation`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack spacing={20}>
        <varnishUi.Slider defaultValue={37} color="primary" />
        <varnishUi.Stack direction="row" spacing={10} style={{ height: '250px' }}>
            <PadDiv width="80px" height="100%" padding="0">
                <varnishUi.Slider defaultValue={37} color="primary" orientation="vertical" />
            </PadDiv>
            <PadDiv width="80px" height="100%" padding="0">
                <varnishUi.Slider
                    defaultValue={37}
                    color="primary"
                    orientation="vertical"
                    isDisabled
                />
            </PadDiv>
            <PadDiv width="80px" height="100%" padding="0">
                <varnishUi.Slider
                    label="Label"
                    defaultValue={77}
                    color="primary"
                    orientation="vertical"
                />
            </PadDiv>
            <PadDiv width="80px" height="100%" padding="0">
                <varnishUi.Slider
                    label="Label"
                    defaultValue={[25, 75]}
                    color="primary"
                    orientation="vertical"
                />
            </PadDiv>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;