
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Overriding the Theme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can override theme values by setting the property you'd like to change.
The overrides are deeply merged with the default Varnish theme, which
means that defaults are used for unspecified values.`}</p>
    <p>{`Generally speaking you'll want to verify the `}<inlineCode parentName="p">{`palette`}</inlineCode>{`. Changing other values
might have weird side-effects.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import ThemeMui from '@allenai/varnish2/theme';

const overridenTheme = {
    palette: {
        background: {
            dark: new ThemeMui.Color('coolGreen', '#18991A', true),
        },
    },
};

render(
    <varnishComponents.VarnishApp theme={overridenTheme}>
        <varnishComponents.Header.AI2Banner />
    </varnishComponents.VarnishApp>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;