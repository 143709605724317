export const NewAI2Mark = ({ title = 'AI2' }: { title?: string }) => {
    return (
        <svg viewBox="0 0 334.97 105.26">
            <title>{title}</title>
            <path d="M196.16,105.25l-9.09-24.35h-49.63l-9.1,24.35h-16.03L150.39,2.77h23.89l38.06,102.48h-16.18ZM182.29,68.11l-20.03-54.09-20.03,54.09h40.07,0Z" />
            <path d="M334.97,92.46h-59.34l31.29-24.66c18.18-14.33,26.2-24.35,26.2-38.06,0-15.1-9.09-29.74-36.52-29.74-28.88,0-39.95,16.31-43.07,33.22h16.65c2.61-12.19,10.55-20.12,26.42-20.12,14.18,0,19.88,7.4,19.88,16.8,0,7.71-2.31,12.95-11.25,20.19l-51.91,41.99c-.31.25-.49.62-.49,1.02v12.17h82.14v-12.79h0Z" />
            <path d="M238.41,99.29v-25.05c0-9.24.07-18.49-.02-27.73-.07-7.51-4.91-12.92-11.96-13.29-3.32-.18-6.66-.13-9.99-.1-.68,0-1.35,0-2.02.01l-3.65-.04v13.06h2.36c.08,0,.16.01.25.01,1.99-.03,3.98,0,5.98,0,3.89,0,4.05.15,4.05,4.1v54.98h15.04v-5.95h-.03Z" />
            <path d="M18.53,42.29c2.95.04,9.74.01,12.68.01h6.11c12.81,0,21.53-8.47,21.88-21.38.14-5.19.01-13.99-.03-19.19l-19.8-.11c-.02,4.35,0,12.91-.01,17.26-.01,2.84-1.08,3.91-3.97,3.93-4.35.03-12.38-.02-16.74.02l-.12,19.46Z" />
            <path d="M60.51,21.94c-.04,2.95-.01,9.74-.01,12.68v6.11c0,12.81,8.47,21.53,21.38,21.88,5.19.14,13.99.01,19.19-.03l.11-19.8c-4.35-.02-12.91,0-17.26-.01-2.84-.01-3.91-1.08-3.93-3.97-.03-4.35.02-12.39-.02-16.74l-19.46-.12Z" />
            <path d="M82.56,63.94c-2.95-.04-9.74-.01-12.68-.01h-6.11c-12.81,0-21.53,8.47-21.88,21.38-.14,5.19-.01,13.99.03,19.19l19.8.11c.02-4.35,0-12.91.01-17.26.01-2.84,1.08-3.91,3.97-3.93,4.35-.03,12.39.02,16.74-.02l.12-19.46Z" />
            <path d="M0,43.6c2.95-.04,9.74-.01,12.68-.01h6.11c12.81,0,21.53,8.47,21.88,21.38.14,5.19.01,13.99-.03,19.19l-19.8.11c-.02-4.35,0-12.91-.01-17.26-.01-2.84-1.08-3.91-3.97-3.93-4.35-.03-12.38.02-16.74-.02l-.12-19.46Z" />
            <rect x="217" y="3.05" width="19" height="13" />
        </svg>
    );
};
