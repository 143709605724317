
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-theme',
  type: 'Components',
  title: 'Css Only',
  cols: 1,
  iconSrc: './modal-2-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VarnishThemeFormattedCss = makeShortcode("VarnishThemeFormattedCss");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`varnish-theme`}</h2>
    <p><inlineCode parentName="p">{`varnish-theme`}</inlineCode>{` is a pared down version of `}<inlineCode parentName="p">{`Varnish`}</inlineCode>{` that lets you make a polished, AI2 branded website with HTML and CSS alone.`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1hMNIA_1_69d00pIQQow2dy4ZVxtsFqSawJ1reidJSP8"
      }}>{`Varnish-Theme Design Doc`}</a></p>
    <h3>{`When To use`}</h3>
    <p>{`You should use varnish-theme for building simple, static sites. If your application is likely to be complex and include a lot of interactivity, use Varnish proper instead.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Add this HTML to the `}<inlineCode parentName="p">{`head`}</inlineCode>{` of your page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "dark",
        "dark": true
      }}>{`<link
    rel="preload"
    href="https://cdn.jsdelivr.net/npm/@allenai/varnish-theme@latest/varnish.css"
    as="style"
/>
<link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/@allenai/varnish-theme@latest/varnish.css"
/>
`}</code></pre>
    <h2>{`What you get`}</h2>
    <ul>
      <li parentName="ul">{`Varnish styles expressed as CSS Variables`}</li>
      <li parentName="ul">{`A CSS reset to ensure browser quirks are standardized`}</li>
      <li parentName="ul">{`Default styles for header and body elements`}</li>
      <li parentName="ul">{`Classes to apply default Varnish styles to things like `}<inlineCode parentName="li">{`button`}</inlineCode>{`s`}</li>
    </ul>
    <VarnishThemeFormattedCss mdxType="VarnishThemeFormattedCss" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;