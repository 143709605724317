
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'ResponsiveHeatMap with DarkTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A complex example of the Nivo ResponsiveHeatMap component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { heatMapDefaultsDark } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 350px;
    max-width: 100%;
    background: \${({ theme }) => theme.palette.background.reversed};
    padding: \${({ theme }) => theme.spacing['3']};
\`;

const data = [
    {
        id: 'AD',
        data: [
            { x: 'hotdog', y: 23 },
            { x: 'burger', y: 12 },
            { x: 'sandwich', y: 15 },
            { x: 'kebab', y: 163 },
            { x: 'fries', y: 158 },
            { x: 'donut', y: 129 },
        ],
    },
    {
        id: 'AE',
        data: [
            { x: 'hotdog', y: 128 },
            { x: 'burger', y: 105 },
            { x: 'sandwich', y: 44 },
            { x: 'kebab', y: 99 },
            { x: 'fries', y: 56 },
            { x: 'donut', y: 50 },
        ],
    },
    {
        id: 'AF',
        data: [
            { x: 'hotdog', y: 176 },
            { x: 'burger', y: 165 },
            { x: 'sandwich', y: 92 },
            { x: 'kebab', y: 140 },
            { x: 'fries', y: 53 },
            { x: 'donut', y: 114 },
        ],
    },
    {
        id: 'AG',
        data: [
            { x: 'hotdog', y: 82 },
            { x: 'burger', y: 177 },
            { x: 'sandwich', y: 24 },
            { x: 'kebab', y: 20 },
            { x: 'fries', y: 127 },
            { x: 'donut', y: 19 },
        ],
    },
    {
        id: 'AI',
        data: [
            { x: 'hotdog', y: 2 },
            { x: 'burger', y: 10 },
            { x: 'sandwich', y: 155 },
            { x: 'kebab', y: 170 },
            { x: 'fries', y: 46 },
            { x: 'donut', y: 19 },
        ],
    },
    {
        id: 'AL',
        data: [
            { x: 'hotdog', y: 100 },
            { x: 'burger', y: 127 },
            { x: 'sandwich', y: 101 },
            { x: 'kebab', y: 59 },
            { x: 'fries', y: 85 },
            { x: 'donut', y: 193 },
        ],
    },
    {
        id: 'AM',
        data: [
            { x: 'hotdog', y: 173 },
            { x: 'burger', y: 35 },
            { x: 'sandwich', y: 46 },
            { x: 'kebab', y: 55 },
            { x: 'fries', y: 135 },
            { x: 'donut', y: 21 },
        ],
    },
];

render(
    <ChartWrapper>
        <ResponsiveHeatMap
            // add in varnish theme at top of all charts
            // must use heatMapDefaults to get colors to properly work for heatmap
            {...heatMapDefaultsDark}
            // add options specific to your chart
            // use varnish values when it makes sense
            forceSquare={true}
            axisTop={{
                tickRotation: -25,
                legend: 'Food',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            axisLeft={{
                legend: 'Country',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            margin={{
                top: 56,
                right: 16,
                bottom: 16,
                left: 56,
            }}
            enableLabels={false}
            sizeVariation={{ sizes: [0.5, 1] }}
            // add data
            data={data}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;