
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'Drag between tables'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Allowing users to drag items between tables bidirectionally. It also supports reordering items within the same table.
When a table is empty, it accepts drops on the whole collection.
getDropOperation ensures that items are always moved rather than copied, which avoids duplicate items.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

interface Pokemon {
    id: number;
    name: string;
    type: string;
    level: string;
}

const DroppableTable = () => {
  const [items, setItems] = React.useState<Pokemon[]>([]);

  const { dragAndDropHooks } = varnishUi.useDragAndDrop({
    acceptedDragTypes: ['pokemon'],
    async onRootDrop(e) {
      let items = await Promise.all(
        e.items
          .map(async item => JSON.parse(await item.getText('pokemon')))
      );
      setItems(items);
    }
  });

  let [selectedKeys, setSelectedKeys] = React.useState<Selection>(new Set());

  return (
        <varnishUi.Table onSelectionChange={setSelectedKeys} selectedKeys={selectedKeys} dragAndDropHooks={dragAndDropHooks}>
            <varnishUi.TableHeader>
                <varnishUi.Column isRowHeader>Name</varnishUi.Column>
                <varnishUi.Column>Type</varnishUi.Column>
                <varnishUi.Column>Level</varnishUi.Column>
            </varnishUi.TableHeader>
            <varnishUi.TableBody items={items} renderEmptyState={() => 'No results found.'}>
                {item => (
                    <varnishUi.Row>
                        <varnishUi.Cell>{item.name}</varnishUi.Cell>
                        <varnishUi.Cell>{item.type}</varnishUi.Cell>
                        <varnishUi.Cell>{item.level}</varnishUi.Cell>
                    </varnishUi.Row>
                )}
            </varnishUi.TableBody>
        </varnishUi.Table>

  )
}

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
        { id: 'level', name: 'Level' },
    ];

    const dataItems: Pokemon[] = [
        {id: 1, name: 'Charizard', type: 'Fire, Flying', level: '67'},
        {id: 2, name: 'Blastoise', type: 'Water', level: '56'},
        {id: 3, name: 'Venusaur', type: 'Grass, Poison', level: '83'},
        {id: 4, name: 'Pikachu', type: 'Electric', level: '100'}
    ];
    let { dragAndDropHooks } = varnishUi.useDragAndDrop({
        getItems(keys) {
            return [...keys].map(key => {
            let item = dataItems.find(item => item.id === key)!;
            return {
                'text/plain': \`\${item.name} – \${item.type}\`,
                'text/html': \`<strong>\${item.name}</strong> – <em>\${item.type}</em>\`,
                'pokemon': JSON.stringify(item)
            };
        });
    },
  });

    return (
        <varnishUi.Stack direction="row" spacing={15}>
            <varnishUi.Table aria-label="Files" headers={headers} dataItems={dataItems} dragAndDropHooks={dragAndDropHooks} selectionMode="multiple" />
            <DroppableTable />
        </varnishUi.Stack>
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;