
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.`}</p>
    <p>{`There is `}<inlineCode parentName="p">{`className`}</inlineCode>{` to style the root, `}<inlineCode parentName="p">{`textAreaClassName`}</inlineCode>{` to style the TextArea, and `}<inlineCode parentName="p">{`labelClassName`}</inlineCode>{` to style the label.
These classNames must have css integrated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        border: 2px dashed red;
    \`;
};

const textAreaClassName = () => {
    return emotionCss.css\`
        border: 2px dashed blue;
    \`;
};

const growContainerClassName = () => {
    return emotionCss.css\`
        border: 2px dashed yellow;
    \`;
};

const labelClassName = () => {
    return emotionCss.css\`
        border: 2px dashed green;
    \`;
};

render(
    <varnishUi.TextArea variant="filled" placeholder="TextArea" className={emotionCss.cx(className())}
    label="Label"
    textAreaClassName={emotionCss.cx(textAreaClassName())}
    labelClassName={emotionCss.cx(labelClassName())}
    growContainerClassName={emotionCss.cx(growContainerClassName())}
    />
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;