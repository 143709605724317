
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Basic customization'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can customize certain parts of the UI with the basic component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const CustomHeading = () => {
    return (
        <varnishUi.ModalHeading closeButton={false}>
            <varnishUi.Stack direction="row" spacing={16}>
                <varnishUi.ModalCloseIconButton />
                <strong>
                    Custom <em>Heading</em>
                </strong>
            </varnishUi.Stack>
        </varnishUi.ModalHeading>
    );
};

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>No close button</varnishUi.Button>
            <varnishUi.Modal
                isDismissable
                heading="Modal Heading"
                closeButton={false}
                color="reversed">
                <p>This is not obvious that you can close it</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Modal Actions</varnishUi.Button>
            <varnishUi.Modal
                isDismissable
                heading="Modal Heading"
                closeButton={false}
                buttons={
                    <varnishUi.DialogCloseButton variant="text">Close</varnishUi.DialogCloseButton>
                }
                color="reversed">
                <p>This has a passed in close button</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Custom Heading</varnishUi.Button>
            <varnishUi.Modal isDismissable heading={<CustomHeading />} color="reversed">
                <p>This has a passed in heading</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;