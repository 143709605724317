
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Step size, min, and max'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Sliders allow users to adjust step values using the `}<inlineCode parentName="p">{`step`}</inlineCode>{` property.
Set min and max values using the `}<inlineCode parentName="p">{`minValue`}</inlineCode>{` and`}<inlineCode parentName="p">{`maxValue`}</inlineCode>{` properties.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(<varnishUi.Slider minValue={20} maxValue={80} step={10} defaultValue={30} />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;