import { cva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const iconStyles = cva({
    base: {
        aspectRatio: '1',
        width: '[1em]',
    },
    variants: {
        // colors are handled by SVG
        // do this the same as MUI ? use fontSize
        size: {
            inherit: {},
            small: {
                fontSize: '[12px]',
            },
            medium: {
                fontSize: '[16px]',
            },
            large: {
                fontSize: '[24px]',
            },
        },
    },
});

type IconRecipeProps = Exclude<RecipeVariantProps<typeof iconStyles>, undefined>;

export { iconStyles };
export type { IconRecipeProps };
