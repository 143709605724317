
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Placement'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The placement of the drawer with respect to the trigger (left, right, bottom, top).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const Demo = () => {
    const [leftOpen, setLeftOpen] = React.useState(false);
    const [rightOpen, setRightOpen] = React.useState(false);
    const [topOpen, setTopOpen] = React.useState(false);
    const [bottomOpen, setBottomOpen] = React.useState(false);

    return (
        <varnishUi.Stack spacing={15}>
            <>
                <varnishUi.Button variant="outlined" color="primary" onClick={() => setLeftOpen(true)}>Left</Button>
                <varnishUi.Drawer
                    isOpen={leftOpen}
                    onClose={() => setLeftOpen(false)} />
            </>
            <>
                <varnishUi.Button variant="outlined" color="primary" onClick={() => setRightOpen(true)}>Right</Button>
                <varnishUi.Drawer
                    isOpen={rightOpen}
                    onClose={() => setRightOpen(false)}
                    placement="right" />
            </>
            <>
                <varnishUi.Button variant="outlined" color="primary" onClick={() => setTopOpen(true)}>Top</Button>
                <varnishUi.Drawer
                    isOpen={topOpen}
                    onClose={() => setTopOpen(false)}
                    placement="top" />
            </>
            <>
                <varnishUi.Button variant="outlined" color="primary" onClick={() => setBottomOpen(true)}>Bottom</Button>
                <varnishUi.Drawer
                    isOpen={bottomOpen}
                    onClose={() => setBottomOpen(false)}
                    placement="bottom" />
            </>
        </varnishUi.Stack>

    )
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;