import { createRoot } from 'react-dom/client';

import { App } from './App';

import './main.css';

const container = document.getElementById('root');
if (!container) {
    throw new Error("No element with an id of 'root' was found.");
}
const root = createRoot(container);
root.render(<App />);
