
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Selecting Tags'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Chip component allows selecting Chips via toggling. Disabled Chips wont allow selecting.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const defaultItems = [
    { id: '1', text: 'Mario' },
    { id: '2', text: 'Luigi' },
    { id: '3', text: 'Peach', isDisabled: true },
    { id: '4', text: 'Bowser' },
    { id: '5', text: 'Yoshi' },
    { id: '6', text: 'Toad' },
    { id: '7', text: 'Donkey Kong' },
    { id: '8', text: 'Daisy' },
];

const defaultSelected = ['1', '2'];

const Demo = () => {
    const [items, setItems] = React.useState<Set<Key>>(defaultItems);
    const [selected, setSelected] = React.useState<Set<Key>>(defaultSelected);

    return (
        <varnishUi.Stack spacing={16}>
            <varnishUi.Stack direction="column" spacing={16}>
                <varnishUi.ChipGroup
                    items={items}
                    selectionMode="multiple"
                    selectedKeys={selected}
                    onSelectionChange={(keys: Set<Key>)=> {
                        const newSelected = new Set(selected);
                        keys.forEach((key) => {
                            if (newSelected.has(key)) {
                                newSelected.delete(key);
                            } else {
                                newSelected.add(key);
                            }
                        });
                        setSelected(newSelected);
                    }}
                />
            </varnishUi.Stack>
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;