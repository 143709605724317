'use client'; // for server side rendering

import React from 'react';
import { Box, BoxProps, Theme, styled } from '@mui/material';

import { LayoutVariant, VarnishContext } from './VarnishContext';

export interface ContentProps {
    children: React.ReactNode | React.ReactNodeArray;
    /**
     * If true, a `<main />` tag is used, which tells web scrapers and accessibility devices
     * that the content is of significant importance. There should only be a single `<main />
     * element per page.
     */
    main?: boolean;
    className?: string;
    bgcolor?: keyof Theme['color'];
}

interface InternalContentProps extends BoxProps {
    main?: boolean;
    layout?: LayoutVariant;
    bgcolor?: keyof Theme['color'];
}

const InternalContent = styled(({ main, layout, bgcolor, ...rest }: InternalContentProps) => (
    <Box component={main ? 'main' : 'div'} {...rest} />
))`
    max-width: ${({ theme, layout }) =>
        layout === 'center-aligned' ? `${theme.breakpoints.values.lg}px` : null};
    width: 100%;
    ${({ layout }) => (layout === 'center-aligned' ? 'margin: 0 auto;' : '')}
    padding: ${({ theme }) => theme.spacing(3)};
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;

    // if the user passes a key to the color theme, use the mapped color,
    // else treat the string as a normal color string
    background: ${({ theme, bgcolor }) =>
        // We're playing it very safe here _just in case_
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        bgcolor ? (theme.color[bgcolor] ? theme.color[bgcolor].hex : bgcolor) : 'none'};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        padding: ${({ theme }) => theme.spacing(1.5)};
    }
`;

export function Content({ children, className, ...basicProps }: ContentProps) {
    return (
        <VarnishContext.Consumer>
            {({ layout }) => (
                <InternalContent layout={layout} className={className} {...basicProps}>
                    {children}
                </InternalContent>
            )}
        </VarnishContext.Consumer>
    );
}

export default Content;
