
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles and the mui theme provider

const className = 'className';
const chipClassName = 'chipClassName';
const labelClassName = 'labelClassName';
const descriptionClassName = 'descriptionClassName';

const CustomChipGroup = mui.styled(varnishUi.ChipGroup)\`
    border: 2px dashed red;
    .\${chipClassName} {
         border: 2px dashed orange;
         background: orange;
    }
    .\${labelClassName} {
        border: 2px dashed green;
    }
    .\${descriptionClassName} {
        border: 2px dashed yellow;
    }
\`;

const defaultItems = [
    { id: '1', text: 'Mario' },
    { id: '2', text: 'Luigi' },
    { id: '3', text: 'Peach' },
    { id: '5', text: 'Yoshi' },
    { id: '6', text: 'Toad' },
];

const Demo = () => {
    const [items, setItems] = React.useState(defaultItems);

    return (
        <varnishUi.Stack spacing={16}>
            <CustomChipGroup
                label="Label: Pick a MarioKart character"
                description="Description: Toad is the best choice tbh"
                className={className}
                chipClassName={chipClassName}
                labelClassName={labelClassName}
                descriptionClassName={descriptionClassName}
                items={items}
            />
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;