import varnishTokens from '@allenai/varnish-theme/mui';
import { createTheme, type ThemeOptions } from '@mui/material';

import { color, color2, darkCategoricalColor, lightCategoricalColor } from './colors';
import { getBetaPaletteTokens } from './theme-utils';

// This import ensures the MUI module augmentation is included in the bundle
import { font } from './font';
import './muiThemeOverride.d';

// baseTheme is only used to give us an `augmentColor` so we can use it in the `varnishTheme`
// Since we have a `ternary` accent color we need it to generate light and dark variants of the color
const baseTheme = createTheme();

export const varnishTheme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,
                }),
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,
                }),
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,
                }),
            },
            defaultProps: {
                disableGutters: true,
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiRating: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                }),
            },
        },
        MuiBadge: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        span: {
                            color: theme.palette.tertiary.main,
                        },
                    }),
                },
            ],
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.tertiary.main,
                    }),
                },
            ],
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiCheckbox: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiChip: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiCircularProgress: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiLinearProgress: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiRadio: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiSlider: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiSwitch: {
            variants: [
                {
                    props: { color: 'tertiary' },
                    style: ({ theme }) => ({
                        color: theme.palette.tertiary.main,
                    }),
                },
            ],
        },
        MuiAppBar: {
            defaultProps: {
                position: 'sticky',
                color: 'default',
            },
            styleOverrides: {
                colorDefault: ({ theme }) => ({
                    backgroundColor: theme.palette.background.reversed,
                    color: theme.palette.text.reversed,
                }),
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.reversed,
                }),
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    backgroundColor: theme.palette.background.reversed,
                    color: theme.palette.text.reversed,
                }),
            },
        },
    },
    typography: {
        fontFamily: varnishTokens.font.family.body.value,
        fontWeightLight: varnishTokens.font.weight.light.value,
        fontWeightRegular: varnishTokens.font.weight.regular.value,
        fontWeightMedium: varnishTokens.font.weight.medium.value,
        fontWeightSemiBold: varnishTokens.font.weight.semiBold.value,
        fontWeightBold: varnishTokens.font.weight.bold.value,
        h1: varnishTokens.typography.h1.value,
        h2: varnishTokens.typography.h2.value,
        h3: varnishTokens.typography.h3.value,
        h4: varnishTokens.typography.h4.value,
        h5: varnishTokens.typography.h5.value,
        h6: varnishTokens.typography.h6.value,
        subtitle1: varnishTokens.typography.subtitle1.value,
        subtitle2: varnishTokens.typography.subtitle2.value,
        body1: varnishTokens.typography.body1.value,
        body2: varnishTokens.typography.body2.value,
        caption: varnishTokens.typography.caption.value,
        overline: varnishTokens.typography.overline.value,
        monospace: varnishTokens.typography.monospace.value,
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        mode: 'light',
        primary: getBetaPaletteTokens(varnishTokens.palette.light.accent.primary),
        secondary: getBetaPaletteTokens(varnishTokens.palette.light.accent.secondary),
        tertiary: baseTheme.palette.augmentColor({
            color: getBetaPaletteTokens(varnishTokens.palette.light.accent.tertiary),
        }),
        error: getBetaPaletteTokens(varnishTokens.palette.light.alert.error),
        warning: getBetaPaletteTokens(varnishTokens.palette.light.alert.warning),
        info: getBetaPaletteTokens(varnishTokens.palette.light.alert.information),
        success: getBetaPaletteTokens(varnishTokens.palette.light.alert.confirmation),
        grey: {
            50: varnishTokens.color['gray-10'].value,
            100: varnishTokens.color['gray-10'].value,
            200: varnishTokens.color['gray-30'].value,
            300: varnishTokens.color['gray-30'].value,
            400: varnishTokens.color['gray-50'].value,
            500: varnishTokens.color['gray-50'].value,
            600: varnishTokens.color['gray-50'].value,
            700: varnishTokens.color['gray-80'].value,
            800: varnishTokens.color['gray-80'].value,
            900: varnishTokens.color['gray-80'].value,
            A100: varnishTokens.color['gray-10'].value,
            A200: varnishTokens.color['gray-30'].value,
            A400: varnishTokens.color['gray-50'].value,
            A700: varnishTokens.color['gray-80'].value,
        },
        text: {
            primary: varnishTokens.palette.light.text.primary.default.value,
            reversed: varnishTokens.palette.light.text.primary.reversed.value,
        },
        divider: varnishTokens.color['gray-80'].value,
        background: {
            paper: varnishTokens.palette.light.background.default.value,
            reversed: varnishTokens.palette.light.background.reversed.value,
        },
        common: {
            white: varnishTokens.color.white.value,
            black: varnishTokens.color.black.value,
        },
    },
    color,
    color2,
    darkCategoricalColor,
    lightCategoricalColor,
    font,
} as ThemeOptions);

export const getTheme = (overrides: ThemeOptions = {}) => {
    const mergedTheme = createTheme(varnishTheme, overrides);
    return createTheme(mergedTheme);
};

export default { getTheme };
