import * as Contrast from './demo/contrast.mdx';
import * as ExampleDefault from './demo/default.mdx';
import * as Palette from './demo/palette.mdx';

export * from './ColorPicker';
export * from './ColorValues';
export * from './DarkCategoricalColorValues';
export * from './LightCategoricalColorValues';
export * from './PrimaryColorValues';
export * from './ColorShades';

export const ColorsExamples = [Contrast, ExampleDefault, Palette];
