
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'Resizing'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Table supports resizable columns, allowing users to dynamically adjust the width of a column
Ref: `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/Table.html#column-resizing"
      }}>{`https://react-spectrum.adobe.com/react-aria/Table.html#column-resizing`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true, allowsResizing: true },
        { id: 'type', name: 'Type' },
        { id: 'dateModified', name: 'Date Modified' },
    ];

    const dataItems = [
        { id: '1', name: 'Games', type: 'File folder', dateModified: '6/7/2020', width: '1fr',  },
        { id: '2', name: 'Program Files', type: 'File folder', dateModified: '4/7/2021', width: 80 },
        { id: '3', name: 'bootmgr', type: 'System file', dateModified: '11/20/2010' , width: 80 },
        { id: '4', name: 'log.txt', type: 'Text Document', dateModified: '1/18/2016', width: 100 },
    ];

    let [columns, setColumns] = React.useState(() => {
        let localStorageWidths = localStorage.getItem('table-widths');
        if (localStorageWidths) {
            let widths = JSON.parse(localStorageWidths);
            return dataItems.map((col) => ({ ...col, width: widths[col.id] }));
        } else {
            return dataItems;
        }
    });

    let onResize = (widths) => {
        setColumns((columns) =>
            columns.map((col) => ({ ...col, width: widths.get(col.id) }))
        );
    };

    let onResizeEnd = (widths) => {
        localStorage.setItem(
            'table-widths',
            JSON.stringify(Object.fromEntries(widths))
        );
    };

    return (
        <varnishUi.ResizableTableContainer
            onResize={onResize}
            onResizeEnd={onResizeEnd}
        >
            <varnishUi.Table aria-label="Files" headers={headers} dataItems={dataItems} enableResizing={true} />
        </varnishUi.ResizableTableContainer>
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;