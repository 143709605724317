import {
    ListBoxSection as AriaListBoxSection,
    ListBoxSectionProps as AriaListBoxSectionProps,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import selectRecipe, { SelectRecipeProps } from '@/components/select/select.styles';
import focusRing from '@/components/shared/focusRing.styles';

type SelectListBoxSectionProps = {
    className?: string;
} & AriaListBoxSectionProps<HTMLDivElement> &
    SelectRecipeProps;

const SelectListBoxSection = ({ className, ...rest }: SelectListBoxSectionProps) => {
    const [variantProps, localProps] = selectRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = selectRecipe.raw(variantProps);
    return (
        <AriaListBoxSection
            {...localProps}
            // we need to wrap the `raw` focusRing and `raw` recipeClassNames.thumb in `css()` to merge them
            // there is no garauntee which class will take precidence
            className={cx(css(recipeClassNames.listBoxSection, focusRing), className)}
        />
    );
};

export { SelectListBoxSection, SelectListBoxSection as default };
export type { SelectListBoxSectionProps };
