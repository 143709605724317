import { ReactNode } from 'react';
import { Link, styled } from '@mui/material';

import { AppNameTextOpts } from './AppName';
import { AppTagline } from './AppTagline';

const LogoGrid = styled('span')`
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};

    &:hover [class$="-${AppNameTextOpts.name}-${AppNameTextOpts.slot}"] {
        text-decoration: none;
        display: inline-block;
    },
`;

const DecorationlessAnchor = styled(Link)`
    &&:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.text.primary};
    }
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.primary};
`;

// Wraps the header logo with a href if href is passed in
export function ConditionalAnchorWrapper(props: { href?: string; children: React.ReactElement }) {
    return props.href ? (
        <DecorationlessAnchor href={props.href} rel="noopener noreferrer">
            {props.children}
        </DecorationlessAnchor>
    ) : (
        props.children
    );
}

export function Logo(props: {
    label?: ReactNode;
    tagline?: ReactNode;
    separator?: ReactNode;
    href?: string;
    children?: ReactNode;
    className?: string;
}) {
    return (
        <ConditionalAnchorWrapper href={props.href}>
            <LogoGrid className={props.className}>
                {props.children}
                {props.label}
                {props.label && props.tagline ? (
                    props.separator ? (
                        props.separator
                    ) : (
                        <AppTagline>&mdash;</AppTagline>
                    )
                ) : null}
                {props.tagline}
            </LogoGrid>
        </ConditionalAnchorWrapper>
    );
}
