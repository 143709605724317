import { cva, RecipeVariantProps } from '@allenai/varnish-panda-runtime/css';

const fieldErrorRecipe = cva({
    base: {
        color: 'alert.error',
    },
    variants: {
        size: {
            small: {
                fontSize: '[12px]',
            },
            medium: {
                fontSize: 'sm',
            },
            large: {
                fontSize: 'md',
            },
        },
    },
    defaultVariants: {
        size: 'medium',
    },
});

type FieldErrorRecipeProps = RecipeVariantProps<typeof fieldErrorRecipe>;

export { fieldErrorRecipe, fieldErrorRecipe as default };
export type { FieldErrorRecipeProps };
