
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Label'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can add a label to the checkbox for clarity.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="column" spacing={16}>
        <varnishUi.Checkbox defaultSelected> Default </varnishUi.Checkbox>
        <varnishUi.Checkbox defaultSelected isDisabled>
            Disabled
        </varnishUi.Checkbox>
        <varnishUi.Checkbox defaultSelected isIndeterminate>
            Indeterminate
        </varnishUi.Checkbox>
        <varnishUi.Checkbox defaultSelected isReadOnly>
            ReadOnly
        </varnishUi.Checkbox>
        <varnishUi.Checkbox defaultSelected isInvalid>
            Invalid
        </varnishUi.Checkbox>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;