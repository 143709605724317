
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Palette Usage'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can also access common Varnish colors via the MUI theme palette.`}</p>
    <p>{`eg: `}<inlineCode parentName="p">{`theme.palette.error.main`}</inlineCode></p>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled, css } from '@mui/material/styles';

const Demo = () => {
    const Error = styled('div')(
        ({ theme }) => css\`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: \${theme.spacing(1)};
            height: \${theme.spacing(5)};
            color: \${theme.palette.error.contrastText};
            background: \${theme.palette.error.main};
            border: 1px solid \${theme.palette.error.contrastText};
        \`,
    );

    return <Error>I can&apos;t do that, Dave.</Error>;
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;