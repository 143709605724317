import type { BarCommonProps, BarDatum } from '@nivo/bar';

import { merge } from 'lodash-es';

import { themeLight, themeDark } from '../nivo-theme';
import { DarkColorHexes, LightColorHexes, globalDefaults } from './global';

export const barDefaults: Partial<BarCommonProps<BarDatum>> = {
    theme: themeLight,
    padding: 0.3,
    borderColor: {
        from: 'color',
        modifiers: [['darker', 1.6]],
    },
    labelSkipWidth: 12,
    labelSkipHeight: 15,
    labelTextColor: globalDefaults.lightValueLabelColor,
    colors: Object.values(LightColorHexes),
};

export const barDefaultsDark = merge({}, barDefaults, {
    theme: themeDark,
    borderColor: {
        from: 'color',
        modifiers: [['brighter', 1.6]],
    },
    labelTextColor: globalDefaults.darkValueLabelColor,
    colors: Object.values(DarkColorHexes),
} satisfies typeof barDefaults);
