import {
    SliderTrackRenderProps as AriaSliderTrackRenderProps,
    SliderRenderProps as AriaSliderRenderProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { Label } from '../field/label/Label';
import sliderRecipe, { SliderRecipeProps } from '@/components/slider/slider.styles';
import SliderThumb from './SliderThumb';
import SliderTrack from './SliderTrack';
import SliderOutput from './SliderOutput';
import SliderBase, { SliderBaseProps } from './SliderBase';
import SliderTrackIndicator from './SliderTrackIndicator';
import { Description } from '../field/description/Description';
import { FieldError } from '../field/fieldError/FieldError';

/**
 *  The <input type="range"> HTML element can be used to build a slider, however it is very
 *  difficult to style cross browser. Slider helps achieve accessible sliders that can be styled as
 *  needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Slider.html
 */

type SliderProps<T> = {
    label?: string;
    thumbLabels?: string[];
    description?: string;
    errorMessage?: string;
    className?: string;
    labelClassName?: string;
    outputClassName?: string;
    controlClassName?: string;
    trackClassName?: string;
    indicatorClassName?: string;
    thumbClassName?: string;
    descriptionClassName?: string;
    errorClassName?: string;
} & SliderBaseProps<T> &
    SliderRecipeProps;

const Slider = <T extends number | number[]>({
    label,
    thumbLabels,
    description,
    errorMessage,
    className,
    labelClassName,
    outputClassName,
    controlClassName,
    trackClassName,
    indicatorClassName,
    thumbClassName,
    descriptionClassName,
    errorClassName,
    ...props
}: SliderProps<T>) => {
    const [variantProps] = sliderRecipe.splitVariantProps(props);
    const recipeClassNames = sliderRecipe(variantProps);
    const { size } = props;

    return (
        <SliderBase {...props} className={className}>
            <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                {label}
            </Label>
            <SliderOutput {...variantProps} className={outputClassName}>
                {({ state }: AriaSliderRenderProps) =>
                    state.values.map((_, i) => state.getThumbValueLabel(i)).join(' – ')
                }
            </SliderOutput>
            <SliderTrack
                {...variantProps}
                className={controlClassName}
                trackClassName={trackClassName}>
                {({ orientation, state }: AriaSliderTrackRenderProps) => (
                    <>
                        <SliderTrackIndicator
                            {...variantProps}
                            orientation={orientation}
                            state={state}
                            className={indicatorClassName}
                        />
                        {state.values.map((_, i) => {
                            return (
                                <SliderThumb
                                    {...variantProps}
                                    key={i}
                                    index={i}
                                    aria-label={thumbLabels?.[i]}
                                    className={thumbClassName}
                                />
                            );
                        })}
                    </>
                )}
            </SliderTrack>
            <Description
                size={size}
                className={cx(recipeClassNames.description, descriptionClassName)}>
                {description}
            </Description>
            <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                {errorMessage}
            </FieldError>
        </SliderBase>
    );
};

export { Slider, Slider as default };
export type { SliderProps };
