import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const selectRecipe = sva({
    slots: [
        'root',
        'button',
        'arrowIcon',
        'listBox',
        'listBoxSection',
        'listBoxHeader',
        'listBoxItem',
        'listBoxItemIcon',
        'listBoxItemText',
        'popover',
        'selectValue',
        'label',
        'description',
        'error',
    ],
    base: {
        root: {
            boxSizing: 'border-box',
            display: 'grid',
            gridTemplateRows: '[1fr auto 1fr]',
            fontWeight: 'medium',
            cursor: 'pointer',
            _disabled: {
                cursor: 'not-allowed',
            },
        },
        button: {
            fontSize: '[inherit]',
            fontFamily: 'body',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '[fit-content]',
            cursor: 'pointer',
            fontWeight: 'medium',
            border: '[1px solid]',
            gap: '1',
            transition: '[background-color 250ms, color 250ms, opacity 250ms]',
            '& [data-empty-icon="true"]': {
                display: 'none', // if no icon, then hide the default space for it
            },
            _disabled: {
                cursor: 'not-allowed',
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
                color: 'gray.80',
            },
            _hover: {
                _notDisabled: {
                    borderColor: 'green.100',
                },
            },
            _groupInvalid: {
                borderColor: 'error-red.100',
                color: 'error-red.100',
                _dark: {
                    color: 'error-red.60',
                },
            },
            // default light styles
            backgroundColor: '[white]',
            borderColor: '[white]',
            color: 'text.primary',

            _dark: {
                backgroundColor: 'background',
                borderColor: 'background',
                color: 'text.primary',
                _disabled: {
                    cursor: 'not-allowed',
                    backgroundColor: 'gray.20',
                    borderColor: 'gray.40',
                    color: 'gray.80',
                },
            },
        },
        popover: {
            minWidth: 'var(--trigger-width)',
            width: '[fit-content]',
            padding: '[0]',
            border: 'none',
            borderRadius: 'md',
            marginTop: '0',
            backgroundColor: '[white]',
            _dark: {
                backgroundColor: 'background',
                color: 'text.primary',
            },
        },
        listBox: {
            padding: '0',
            backgroundColor: '[inherit]',
            borderRadius: 'md',
            border: '[none]',
            color: '[inherit]',
            fontSize: '[inherit]',
        },
        listBoxItem: {
            display: 'grid',
            gridTemplateColumns: '[24px auto]',
            alignItems: 'center',
            gap: '[8px]',
            border: '[none]',
            borderRadius: 'md',
            backgroundColor: '[inherit]',
            padding: '[8px 8px]',
            color: '[inherit]',
            cursor: 'pointer',
            _active: {
                backgroundColor: 'cream.100', // form/menu/selected/bg
                _dark: {
                    backgroundColor: 'extra-dark-teal.100', // form/menu/selected/bg
                },
            },
            _hover: {
                backgroundColor: 'dark-teal.10', // form/menu/hovered/bg
                _dark: {
                    backgroundColor: 'cream.10', // form/menu/hovered/bg
                },
            },
        },
        listBoxItemIcon: {
            justifySelf: 'center',
            '& svg': {
                width: '[100%]',
                height: '[100%]',
            },
        },
        listBoxItemText: {
            justifySelf: 'start',
        },
        arrowIcon: {
            color: 'accent.secondary',
            pointerEvents: 'none',
            transition: '[transform 250ms]',
            _groupDisabled: {
                color: 'gray.80',
            },
            _groupOpen: {
                transform: 'rotate(180deg)',
            },
        },
        selectValue: {
            display: 'flex',
            gap: '[8px]',
            alignItems: 'center',
        },
        listBoxSection: {
            fontSize: '[inherit]',
            backgroundColor: '[inherit]',
            border: '[none]',
            borderRadius: 'md',
        },
        listBoxHeader: {
            fontSize: 'md',
            border: '[none]',
            padding: '[8px 16px]',
        },
        label: {},
        description: {},
        error: {},
    },
    variants: {
        fullWidth: {
            false: {},
            true: {
                root: {
                    width: '[100%]',
                },
                button: {
                    width: '[100%]',
                },
            },
        },
        variant: {
            contained: {}, // default
            outlined: {
                button: {
                    border: '[1px solid]',
                    borderColor: 'dark-teal.20',
                    _dark: {
                        borderColor:
                            '[color-mix(in srgb, {colors.cream.100} 20%, {colors.background})]',
                        color: 'text.primary',
                    },
                },
            },
        },
        shape: {
            box: {
                button: {
                    borderRadius: 'sm',
                },
            },
            rounded: {
                button: {
                    borderRadius: 'full',
                },
            },
        },
        size: {
            small: {
                root: {
                    fontSize: '[12px]', // todo: add fontsize for xs
                },
                button: {
                    padding: '[8px 20px]',
                },
                listBoxItem: {
                    height: '[32px]',
                    fontSize: '[12px]', // todo: add fontsize for xs
                },
                listBoxItemIcon: {
                    width: '[16px]',
                    height: '[16px]',
                },
            },
            medium: {
                root: {
                    fontSize: 'sm',
                },
                button: {
                    padding: '[8px 24px]',
                },
                listBoxItem: {
                    height: '[36px]',
                    fontSize: 'sm',
                },
                listBoxItemIcon: {
                    width: '[20px]',
                    height: '[20px]',
                },
            },
            large: {
                root: {
                    fontSize: 'md',
                },
                button: {
                    padding: '[16px 24px]',
                },
                listBoxItem: {
                    height: '[56px]',
                    fontSize: 'md',
                },
                listBoxItemIcon: {
                    width: '[24px]',
                    height: '[24px]',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'outlined',
        shape: 'box',
        size: 'medium',
    },
});

type SelectRecipeProps = RecipeVariantProps<typeof selectRecipe>;

export { selectRecipe, selectRecipe as default };
export type { SelectRecipeProps };
