import { styled } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const WarningBoxBase = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.warning.dark}`,
    marginBottom: theme.spacing(3),
}));

interface WarningBoxProps {
    title: string;
    children: React.ReactNode;
}

export const WarningBox = ({ title, children }: WarningBoxProps) => {
    return (
        <WarningBoxBase>
            <WarningIcon />
            <Box>
                <Typography variant="body1" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="body2">{children}</Typography>
            </Box>
        </WarningBoxBase>
    );
};

export const AlphaMsg = () => {
    return (
        <WarningBox title="Warning: Alpha Feature">
            This feature is currently in alpha and may be unstable or missing brand elements. Use
            with caution as it is subject to significant changes.
        </WarningBox>
    );
};
