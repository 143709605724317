'use client'; // for server side rendering

import { Box, Link, Stack, Typography } from '@mui/material';

import React, { PropsWithChildren } from 'react';

import { MaybeLink, shortenName } from '../utils';

import { TextTruncator } from '.';

interface Author {
    name: string;
    url?: string;
}

interface Paper {
    title: string;
    authors: Author[];
    url?: string;
    venue?: string;
    abstract?: string;
    fieldsOfStudy?: string[];
    publicationDate?: string;
}

interface PaperRowProps extends Paper {
    authorLimit?: number;
    abstractCharLimit?: number;
    shortenAuthorNames?: boolean;
}

export function PaperRow({
    title,
    authors,
    url,
    authorLimit,
    venue,
    abstract,
    abstractCharLimit,
    fieldsOfStudy,
    publicationDate,
    shortenAuthorNames,
}: PaperRowProps) {
    return (
        <Box>
            <PaperTitle title={title} url={url} />
            <Stack direction="row" spacing={0.5} flexWrap="wrap" alignItems="center">
                <PaperAuthors
                    authors={authors}
                    authorLimit={authorLimit}
                    shortenAuthorNames={shortenAuthorNames}
                />
                {!!fieldsOfStudy?.length && <BulletedItem>{fieldsOfStudy.join(', ')}</BulletedItem>}
                {!!venue && <BulletedItem>{venue}</BulletedItem>}
                {!!publicationDate && <BulletedItem>{publicationDate}</BulletedItem>}
            </Stack>
            <PaperAbstract abstract={abstract} charLimit={abstractCharLimit} />
        </Box>
    );
}

const PaperTitle = ({ title, url }: { title: string; url?: string }) => {
    return (
        <Typography gutterBottom component="h2" variant="h6">
            <MaybeLink href={url}>{title}</MaybeLink>
        </Typography>
    );
};

const BulletedItem = ({ children }: PropsWithChildren) => {
    return (
        <>
            <Typography component="p" variant="body2">
                •
            </Typography>
            <Typography noWrap component="span" variant="subtitle1">
                {children}
            </Typography>
        </>
    );
};

const PaperAuthors = ({
    authors,
    authorLimit = 4,
    shortenAuthorNames,
}: {
    authors: Author[];
    authorLimit?: number;
    shortenAuthorNames?: boolean;
}) => {
    const [showAllAuthors, setShowAllAuthors] = React.useState(false);

    const AuthorElement = ({ name, url }: { name: string; url?: string }) => {
        return (
            <Typography
                component="span"
                variant="body2"
                sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: '4px',
                    backgroundColor: (theme) => theme.color.N3.hex,
                    textTransform: 'capitalize',
                }}>
                <MaybeLink href={url}>{shortenAuthorNames ? shortenName(name) : name}</MaybeLink>
            </Typography>
        );
    };

    const showLastAuthor = authorLimit > 1;
    const authorsEnd = showAllAuthors
        ? authors.length
        : showLastAuthor && authorLimit !== authors.length
          ? authorLimit - 1
          : authorLimit;
    return (
        <div>
            <Stack direction="row" flexWrap="wrap" gap={0.5}>
                {authors.slice(0, authorsEnd).map((a: Author) => (
                    <AuthorElement key={a.name} name={a.name} url={a.url} />
                ))}
                {authors.length > authorLimit && (
                    <>
                        {/* Expand/Collapse button */}
                        <Link
                            component="button"
                            variant="body1"
                            onClick={() => {
                                setShowAllAuthors(!showAllAuthors);
                            }}
                            sx={{
                                px: 1,
                                py: 0.5,
                                borderRadius: '4px',
                                backgroundColor: (theme) => theme.color.N3.hex,
                            }}>
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ verticalAlign: 'top' }}>
                                {showAllAuthors ? (
                                    <>&#8863; less</>
                                ) : (
                                    `+${authors.length - authorsEnd - (showLastAuthor ? 1 : 0)} authors`
                                )}
                            </Typography>
                        </Link>
                        {/* always render the last author, as that is usually the Principle Investigator */}
                        {!showAllAuthors && authorLimit > 1 && (
                            <AuthorElement
                                key={authors[authors.length - 1].name}
                                name={authors[authors.length - 1].name}
                                url={authors[authors.length - 1].url}
                            />
                        )}
                    </>
                )}
            </Stack>
        </div>
    );
};

const PaperAbstract = ({ abstract, charLimit }: { abstract?: string; charLimit?: number }) => {
    if (!abstract?.length) {
        return null;
    }
    return (
        <TextTruncator charLimit={charLimit} mt={0.5}>
            {abstract}
        </TextTruncator>
    );
};
