
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish',
  type: 'Patterns',
  title: 'Accessibility',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Accessibility`}</h2>
    <p>{`When developing websites, accessibility is important to keep in mind. If you are using Varnish, your compoonents will adhere to accessibility (a11y)
standards by default. However, it is important to keep in mind the various a11y issues to test for:`}</p>
    <ul>
      <li parentName="ul">{`Keyboard-only navigation: Can someone navigate your website using just their keyboard (no mouse)?`}</li>
      <li parentName="ul">{`Screen reader compatibility: Are there any visual to semantic mismatches on your application? This will cause issues for screen-reader users/`}</li>
      <li parentName="ul">{`Color contrast: Are your visual and font colors following appropriate `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html"
        }}>{`contrast ratios`}</a>{`?`}</li>
      <li parentName="ul">{`Captioning for any sound: Do you have captions or text accompaniments for any sounds or videos on your app?`}</li>
    </ul>
    <p>{`You can use the following tools to test for accessibility issues:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dequelabs/axe-core"
        }}>{`Axe-core`}</a>{`: build in a11y automation to your workflow`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.chrome.com/docs/lighthouse/accessibility/scoring"
        }}>{`Lighthouse`}</a>{`: accessibility audits for websites`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibilityinsights.io/"
        }}>{`Accessibility Insights`}</a>{`: a browser extension to test for web accessibility`}</li>
    </ul>
    <p>{`Additionally, please refer to `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/"
      }}>{`WCAG`}</a>{` guidelines for web accessibility best practices.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;