import { cva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps, SystemStyleObject } from '@allenai/varnish-panda-runtime/types';

const svgStyles = cva({
    base: {
        width: '[100%]',
    },
    variants: {
        color: {
            default: {
                color: 'text.primary',
            },
            reversed: {
                color: 'text.primary.reversed',
            },
            primary: {
                color: 'accent.primary',
            },
            secondary: {
                color: 'accent.secondary',
            },
            tertiary: {
                color: 'accent.tertiary',
            },
        },
    },
});

// Types for handling variant colors and "system" colors
type SVGRecipePropsBase = RecipeVariantProps<typeof svgStyles>;
type SVGRecipeColorProp = Exclude<SVGRecipePropsBase, undefined>['color'];
type SystemColorProp = SystemStyleObject['color'];
type SVGColorPropType = SystemColorProp | SVGRecipeColorProp | false;
type SVGColorProp = {
    color?: SVGColorPropType;
};

// Right now this is just the color prop, but it is written to work
// if variants are added
type SVGRecipeProps = Omit<SVGRecipePropsBase, 'color'> & SVGColorProp;

export { svgStyles };
export type { SVGRecipeProps, SVGColorProp, SVGRecipeColorProp, SVGColorPropType, SystemColorProp };
