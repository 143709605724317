
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Group Property Overrides'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The CheckboxGroup accepts all the variant props that Checkbox does, which provides defaults for the Checkbox and can be overriden on the Checkbox.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.CheckboxGroup
        size="large"
        defaultValue={['baseball', 'basketball']}
        color="secondary">
        <varnishUi.Checkbox value="soccer">Soccer</varnishUi.Checkbox>
        <varnishUi.Checkbox value="baseball">Baseball</varnishUi.Checkbox>
        <varnishUi.Checkbox value="basketball" size="small" color="primary">
            Basketball (Small &amp; Primary color override)
        </varnishUi.Checkbox>
    </varnishUi.CheckboxGroup>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;