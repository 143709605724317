'use client'; // for server side rendering

import { styled } from '@mui/material';
import React, { DetailedHTMLProps } from 'react';

import { Color, color as varnishColor } from '../../theme/colors';
import { AI2Mark } from './AI2Mark';
import { AI2MarkText } from './AI2MarkText';

type ColorVariants = 'default' | 'white';
export type LogoSize = 'sm' | 'md' | 'lg';

interface DimData {
    height: number;
    width: {
        mark: number;
        gap: number;
        text: number;
    };
}

const dimsBySize: Record<string, DimData> = {
    sm: {
        height: 16,
        width: {
            mark: 30,
            gap: 9,
            text: 138,
        },
    },
    md: {
        height: 30,
        width: {
            mark: 64,
            text: 189,
            gap: 12,
        },
    },
    lg: {
        height: 37,
        width: {
            mark: 79,
            gap: 14,
            text: 233,
        },
    },
};

interface LogoGridProps
    extends DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    gap: number;
}

const LogoGrid = styled(({ gap, children, ...rest }: LogoGridProps) => (
    <span {...rest}>{children}</span>
))`
    display: inline-grid;
    grid-template-columns: repeat(2, min-content);
    gap: ${({ gap }) => gap}px;
`;

export interface AI2LogoProps {
    color?: ColorVariants;
    includeText?: boolean;
    size?: LogoSize;
    className?: string;
    title?: string;
}

/**
 * Component that renders the AI2 Logo.
 */
export function AI2Logo({
    size = 'md',
    color = 'default',
    includeText = true,
    className,
    title = 'Ai2',
}: AI2LogoProps) {
    const dims = dimsBySize[size];
    const [gradients, myColor]: [false | undefined, Color | undefined] =
        color === 'white' ? [false, varnishColor.N2] : [undefined, undefined];
    if (!includeText) {
        return (
            <AI2Mark
                className={className}
                width={dims.width.mark}
                height={dims.height}
                gradients={gradients}
                color={myColor}
                title={title}
            />
        );
    }

    // When the logo is small we resize the viewbox of the text so it's more legible.
    const vw = size === 'sm' ? '74 6 190 18' : undefined;
    return (
        <LogoGrid gap={dims.width.gap} className={className}>
            <AI2Mark
                width={dims.width.mark}
                height={dims.height}
                gradients={gradients}
                color={myColor}
                title={title}
            />
            <AI2MarkText
                width={dims.width.text}
                height={dims.height}
                color={myColor}
                viewBox={vw}
            />
        </LogoGrid>
    );
}
