import { css } from '@allenai/varnish-panda-runtime/css';
import { createPortal } from 'react-dom';

import { cx } from '@/utils/cx';

import { AnchorOrigin, useSnackbarNotification } from './SnackbarContext';
import snackbarListRecipe, { SnackbarListRecipeProps } from './snackbarList.styles';
import Snackbar from './Snackbar';

type SnackbarListProps = {
    className?: string;
    anchorOrigin: AnchorOrigin;
    ariaLabel?: string;
} & SnackbarListRecipeProps;

function SnackbarList({ className, ariaLabel, anchorOrigin, ...rest }: SnackbarListProps) {
    const { snackbarNotifications, removeSnackbarNotification } = useSnackbarNotification();

    const notificationsForAnchor = snackbarNotifications[anchorOrigin];

    const [variantProps, localProps] = snackbarListRecipe.splitVariantProps(rest);
    const recipeClassNames = snackbarListRecipe.raw({
        ...variantProps,
        anchorOrigin,
    });

    const content = (
        <div
            {...localProps}
            className={cx(css(recipeClassNames.root), className)}
            aria-label={ariaLabel}>
            {notificationsForAnchor.map((notification, index) => (
                <Snackbar
                    key={notification.id}
                    {...notification}
                    open
                    index={index}
                    onClose={() => {
                        removeSnackbarNotification(anchorOrigin, notification.id);
                    }}
                />
            ))}
        </div>
    );

    return createPortal(content, document.body);
}

export { SnackbarList, SnackbarList as default };
export type { SnackbarListProps };
