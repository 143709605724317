
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Deleting Chips'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Chips can be deleted via an X button or keyboard keys. To do this, you must pass an `}<inlineCode parentName="p">{`onRemove`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const defaultItems = [
    { id: '1', text: 'Mario' },
    { id: '2', text: 'Luigi' },
    { id: '3', text: 'Peach' },
    { id: '4', text: 'Bowser' },
    { id: '5', text: 'Yoshi' },
    { id: '6', text: 'Toad' },
    { id: '7', text: 'Donkey Kong' },
    { id: '8', text: 'Daisy' },
];

const Demo = () => {
    const [items, setItems] = React.useState(defaultItems);

    return (
        <varnishUi.Stack spacing={16}>
            <varnishUi.Stack direction="column" spacing={16}>
                <varnishUi.ChipGroup
                    items={items}
                    onRemove={(keys: Set<Key>) => {
                        setItems((prevItems) =>
                            prevItems.filter((i) => !keys.has(i.id))
                        );
                    }}
                />
            </varnishUi.Stack>
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;