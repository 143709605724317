import ReactMarkdown, { Components, Options } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PropsWithChildren } from 'react';

import { css } from '@allenai/varnish-panda-runtime/css';

import MarkdownRecipeProps, { markdownRecipe } from './markdown.styles';
import { cx } from '@/utils/cx';

type MarkdownProps = {
    className?: string;
    children: string;
    components?: Components;
} & MarkdownRecipeProps &
    Options;

const Markdown = ({
    components,
    className,
    children,
    ...rest
}: PropsWithChildren<MarkdownProps>) => {
    const [variantProps, localProps] = markdownRecipe.splitVariantProps(rest);
    const recipeClassNames = markdownRecipe.raw(variantProps);

    const DefaultComponents: Components = {
        h1: (props: PropsWithChildren) => (
            <h1 className={cx(css(recipeClassNames.h1))}>{props.children}</h1>
        ),
        h2: (props: PropsWithChildren) => (
            <h2 className={cx(css(recipeClassNames.h2))}>{props.children}</h2>
        ),
        h3: (props: PropsWithChildren) => (
            <h3 className={cx(css(recipeClassNames.h3))}>{props.children}</h3>
        ),
        h4: (props: PropsWithChildren) => (
            <h4 className={cx(css(recipeClassNames.h4))}>{props.children}</h4>
        ),
        h5: (props: PropsWithChildren) => (
            <h5 className={cx(css(recipeClassNames.h5))}>{props.children}</h5>
        ),
        h6: (props: PropsWithChildren) => (
            <h6 className={cx(css(recipeClassNames.h6))}>{props.children}</h6>
        ),
        b: (props: PropsWithChildren) => <strong>{props.children}</strong>,
        ul: (props: PropsWithChildren) => (
            <ul className={cx(css(recipeClassNames.ul))} {...props} />
        ),
        li: (props: PropsWithChildren) => (
            <li className={cx(css(recipeClassNames.li))} {...props} />
        ),
        img: (props: PropsWithChildren) => (
            <img className={cx(css(recipeClassNames.img))} alt="" {...props} />
        ),
        em: (props: PropsWithChildren) => (
            <span className={cx(css(recipeClassNames.em))}>{props.children}</span>
        ),
        del: (props: PropsWithChildren) => (
            <span className={cx(css(recipeClassNames.del))}>{props.children}</span>
        ),
        ol: (props: PropsWithChildren) => (
            <ol className={cx(css(recipeClassNames.ol))} {...props} />
        ),
        pre: (props: PropsWithChildren) => <pre {...props} />,
        hr: () => <hr className={cx(css(recipeClassNames.hr))} />,
        table: (props: PropsWithChildren) => (
            <table {...props} className={cx(css(recipeClassNames.table))} /> // replace with our varnishUI table
        ),
    };
    return (
        /* Latest version of React-Mark-Down does not support className anymore */
        <div className={cx(css(recipeClassNames.root), className)}>
            <ReactMarkdown
                components={components !== undefined ? components : DefaultComponents}
                remarkPlugins={[remarkGfm]}
                {...localProps}>
                {children}
            </ReactMarkdown>
        </div>
    );
};

export { Markdown, Markdown as default };
export type { MarkdownProps };
