
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To customize button colors, apply the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="contained" color="primary">Primary</Button>
            <varnishUi.Button variant="contained" color="secondary">Secondary</Button>
            <varnishUi.Button variant="contained" color="tertiary">Tertiary</Button>
            <varnishUi.Button variant="contained" color="darkTeal">Dark Teal</Button>
            <varnishUi.Button variant="contained" color="teal">Teal</Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="contained" color="info">Info</Button>
            <varnishUi.Button variant="contained" color="confirmation">Confirmation</varnishUi.Button>
            <varnishUi.Button variant="contained" color="warning">Warning</varnishUi.Button>
            <varnishUi.Button variant="contained" color="error">Error</varnishUi.Button>
            <varnishUi.Button variant="contained" color="cream">Cream</varnishUi.Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="outlined" color="primary">Primary</Button>
            <varnishUi.Button variant="outlined" color="secondary">Secondary</Button>
            <varnishUi.Button variant="outlined" color="tertiary">Tertiary</Button>
            <varnishUi.Button variant="outlined" color="darkTeal">Dark Teal</Button>
            <varnishUi.Button variant="outlined" color="teal">Teal</Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="outlined" color="info">Info</Button>
            <varnishUi.Button variant="outlined" color="confirmation">Confirmation</varnishUi.Button>
            <varnishUi.Button variant="outlined" color="warning">Warning</varnishUi.Button>
            <varnishUi.Button variant="outlined" color="error">Error</varnishUi.Button>
            <varnishUi.Button variant="outlined" color="cream">Cream</varnishUi.Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="text" color="primary">Primary</Button>
            <varnishUi.Button variant="text" color="secondary">Secondary</Button>
            <varnishUi.Button variant="text" color="tertiary">Tertiary</Button>
            <varnishUi.Button variant="text" color="darkTeal">Dark Teal</Button>
            <varnishUi.Button variant="text" color="teal">Teal</Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="text" color="info">Info</Button>
            <varnishUi.Button variant="text" color="confirmation">Confirmation</varnishUi.Button>
            <varnishUi.Button variant="text" color="warning">Warning</varnishUi.Button>
            <varnishUi.Button variant="text" color="error">Error</varnishUi.Button>
            <varnishUi.Button variant="text" color="cream">Cream</varnishUi.Button>
        </varnishUi.Stack>
    </varnishUi.Stack>
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;