
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Default'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Default Usage.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

render(
    <div style={{ width: '100%' }}>
        <h5>MaxLengthText div</h5>
        <varnishComponents.MaxWidthText style={{ borderRight: 'solid 1px red' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac est dignissim,
            eleifend libero a, aliquam tellus. Integer orci lectus, aliquam laoreet commodo
            condimentum, posuere id purus. Fusce laoreet volutpat sem, non gravida justo lobortis
            id. Mauris id arcu dignissim, fermentum velit et, placerat erat. Phasellus mi quam,
            mattis a libero vitae, finibus feugiat orci. Sed rutrum mollis arcu, placerat lacinia ex
            luctus nec. Nulla tempus ante a lobortis pretium. Sed ut maximus augue. Pellentesque at
            volutpat dui.
        </varnishComponents.MaxWidthText>
        <h5>Default div</h5>
        <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac est dignissim,
            eleifend libero a, aliquam tellus. Integer orci lectus, aliquam laoreet commodo
            condimentum, posuere id purus. Fusce laoreet volutpat sem, non gravida justo lobortis
            id. Mauris id arcu dignissim, fermentum velit et, placerat erat. Phasellus mi quam,
            mattis a libero vitae, finibus feugiat orci. Sed rutrum mollis arcu, placerat lacinia ex
            luctus nec. Nulla tempus ante a lobortis pretium. Sed ut maximus augue. Pellentesque at
            volutpat dui.
        </div>
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;