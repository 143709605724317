import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import avatarRecipe, { AvatarRecipeProps } from './avatar.styles';

type AvatarProps = {
    className?: string;
    src?: string;
    alt?: string;
    children?: React.ReactNode;
} & AvatarRecipeProps;

function Avatar({ className, src, alt, children, ...rest }: AvatarProps) {
    const hasImage = !!src; // Check if an image is present
    const [variantProps, localProps] = avatarRecipe.splitVariantProps({
        hasImage,
        ...rest,
    });

    const recipeClassNames = avatarRecipe.raw(variantProps);

    return (
        <div className={cx(css(recipeClassNames.root), className)} {...localProps}>
            {hasImage ? (
                <img src={src} alt={alt} className={css(recipeClassNames.image)} />
            ) : (
                children
            )}
        </div>
    );
}

export { Avatar, Avatar as default };
export type { AvatarProps };
