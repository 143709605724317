
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Variant'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Alert comes in two variants, `}<inlineCode parentName="p">{`outlined`}</inlineCode>{` and `}<inlineCode parentName="p">{`contained`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={25}>
        <varnishUi.Stack direction="column" spacing={25} fullWidth>
            <varnishUi.Alert severity="info" variant="outlined">
                Outlined
            </varnishUi.Alert>
            <varnishUi.Alert severity="info" variant="contained">
                Contained
            </varnishUi.Alert>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;