import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const headerRecipe = sva({
    slots: ['root', 'container', 'search', 'action', 'nav', 'logo', 'slots', 'container', 'icon'],
    base: {
        root: {
            border: 'none',
            top: '0',
            backgroundColor: 'background.reversed',
            color: 'accent.secondary',
            _dark: {
                color: 'accent.primary',
            },
        },
        container: {
            color: '[inherit]',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            gap: '[12px]',
            flexShrink: 0,
        },
        slots: {
            color: '[inherit]',
            display: 'flex',
            alignItems: 'center',
            gap: '[1rem]',
            flexDirection: 'row',
        },
        nav: {
            display: 'flex',
            gap: '[16px]',
            alignSelf: 'center',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            color: '[inherit]',
            flexDirection: 'row',
        },
        search: {},
        action: {
            whiteSpace: 'nowrap',
            color: '[inherit]',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            verticalAlign: 'middle',
        },
    },
    variants: {
        position: {
            fixed: {
                root: {
                    position: 'fixed',
                },
            },
            sticky: {
                root: {
                    position: 'sticky',
                },
            },
        },
        shape: {
            box: {
                root: {
                    borderRadius: '[16px]',
                    smOnly: {
                        borderRadius: '[8px]',
                    },
                },
                container: {
                    padding: '[16px]',
                    smOnly: {
                        padding: '[16px]',
                    },
                },
            },
            rounded: {
                root: {
                    borderRadius: 'full',
                },
                container: {
                    padding: '[16px 32px]',
                    smOnly: {
                        padding: '[16px 24px]',
                    },
                },
            },
            full: {
                root: {
                    border: 'none',
                    backgroundColor: '[transparent]',
                },
                container: {
                    padding: '[16px 24px]',
                    smOnly: {
                        padding: '[16px]',
                    },
                },
            },
        },
        fullWidth: {
            false: {},
            true: {
                root: {
                    width: '[100%]',
                    backgroundColor: '[transparent]',
                    border: 'none',
                },
            },
        },
    },
    defaultVariants: {
        shape: 'box',
        position: 'sticky',
    },
});

type HeaderRecipeProps = RecipeVariantProps<typeof headerRecipe>;

export { headerRecipe, headerRecipe as default };
export type { HeaderRecipeProps };
