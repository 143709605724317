
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Patterns',
  title: 'App Layout',
  cols: 1,
  iconSrc: './header-footer-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`App Layout`}</h2>
    <p>{`Example of a standard application layout`}</p>
    <h2>{`Basic Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { Content, Footer, Header, VarnishApp } from '@allenai/varnish2/components';

<VarnishApp>
  <div>
    <Header>{/* see: varnish.apps.allenai.org/components/varnishmui/ai2_header/ */}</Header>
    <Content main>
      Hello World
    </Content>
    <Footer />
  <div>
</VarnishApp>;
`}</code></pre>
    <h2>{`With Styled Components`}</h2>
    <p>{`It is recomended to use `}<inlineCode parentName="p">{`@mui/material/styles`}</inlineCode>{` to style components. However, if you prefer,
you can use Styled Components by wrapping the VarnishApp in a styled component theme provider.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { ThemeProvider } from '@mui/material';
import { Content, Footer, Header, VarnishApp } from '@allenai/varnish2/components';
import { getTheme } from '@allenai/varnish2/theme';

const theme = getTheme(); // if you want to use ReactRouter, pass in getRouterOverriddenTheme(Link)

<ThemeProvider theme={theme}>
    <VarnishApp>
        <Header>{/* see: varnish.apps.allenai.org/components/varnishmui/ai2_header/ */}</Header>
        <Content main>Hello World</Content>
        <Footer />
    </VarnishApp>
</ThemeProvider>;
`}</code></pre>
    <h2>{`With React Router`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { Route, Routes, BrowserRouter, Link } from 'react-router-dom';
import { Content, Footer, Header, VarnishApp } from '@allenai/varnish2/components';

<BrowserRouter>
  <VarnishApp>
    <div>
      <Header>{/* see: varnish.apps.allenai.org/components/varnishmui/ai2_header/ */}</Header>
      <Content main>
        <div>
          {ROUTES.map(({ path, label }) => (
            <Link key={path} to={path}>
                {label}
            </Link>
          ))}
        </div>
        <Routes>
          {ROUTES.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      </Content>
      <Footer />
    <div>
  </VarnishApp>
</BrowserRouter>;
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;