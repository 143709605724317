
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Position'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Header offers two position: Sticky, Fixed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import mui from '@mui/material';

const Header = varnishComponents.Header;
const muiStyled = mui.styled;

const ScrollableDiv = muiStyled('div')\`
    height: 10rem;
    overflow-y: scroll;
\`;
const DivThatForcesScroll = muiStyled('div')\`
    height: 15rem;
\`;

render(() => {
    // You won't need to use a ref unless you want the header to show/hide based on a container other than the window
    // We use it here so that it's easier to demonstrate inside this example!
    const containerRef = React.useRef(null);

    return (
        <ScrollableDiv ref={containerRef}>
            <varnishUi.Header logo={<varnishUi.Icons.Spark size="large" />} position="sticky" />
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Donec pretium vulputate sapien nec
                sagittis aliquam malesuada bibendum arcu. Laoreet sit amet cursus sit amet dictum
                sit amet justo. Aliquet sagittis id consectetur purus ut faucibus pulvinar. Neque
                gravida in fermentum et sollicitudin. In hac habitasse platea dictumst vestibulum.
                Nulla porttitor massa id neque aliquam vestibulum morbi. Aliquet lectus proin nibh
                nisl condimentum id. Dolor morbi non arcu risus quis. Sagittis aliquam malesuada
                bibendum arcu vitae elementum curabitur. Faucibus nisl tincidunt eget nullam non
                nisi. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Risus in
                hendrerit gravida rutrum. Et magnis dis parturient montes nascetur ridiculus mus.
                Morbi tincidunt augue interdum velit euismod. Ut consequat semper viverra nam
                libero. Iaculis eu non diam phasellus vestibulum lorem sed. Eget velit aliquet
                sagittis id consectetur purus ut faucibus. Quis auctor elit sed vulputate mi sit
                amet. Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum.
                Tincidunt praesent semper feugiat nibh. Enim tortor at auctor urna nunc. Viverra
                accumsan in nisl nisi. Eget nunc lobortis mattis aliquam faucibus purus in.
                Tincidunt tortor aliquam nulla facilisi cras fermentum. Leo vel fringilla est
                ullamcorper eget nulla facilisi etiam. Elit scelerisque mauris pellentesque pulvinar
                pellentesque habitant morbi tristique senectus. Proin libero nunc consequat interdum
                varius. Et malesuada fames ac turpis egestas integer eget aliquet nibh. Id aliquet
                lectus proin nibh nisl. Neque volutpat ac tincidunt vitae semper quis lectus.
                Convallis tellus id interdum velit laoreet. Hac habitasse platea dictumst
                vestibulum. Turpis egestas integer eget aliquet. Placerat vestibulum lectus mauris
                ultrices. Magna fringilla urna porttitor rhoncus dolor purus non enim. Amet
                consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. In aliquam
                sem fringilla ut. Pellentesque eu tincidunt tortor aliquam nulla. Mi eget mauris
                pharetra et ultrices. Morbi tincidunt augue interdum velit euismod in pellentesque
                massa placerat. A lacus vestibulum sed arcu non odio euismod lacinia. Fringilla
                phasellus faucibus scelerisque eleifend donec pretium vulputate. A pellentesque sit
                amet porttitor eget dolor morbi. Euismod quis viverra nibh cras pulvinar mattis nunc
                sed. Pellentesque elit eget gravida cum sociis. Elementum nisi quis eleifend quam
                adipiscing vitae. Tellus at urna condimentum mattis pellentesque. Vitae aliquet nec
                ullamcorper sit amet risus nullam eget felis. Aenean vel elit scelerisque mauris
                pellentesque pulvinar pellentesque. Magna etiam tempor orci eu lobortis elementum
                nibh tellus molestie. Morbi tincidunt augue interdum velit euismod in pellentesque.
                Vestibulum lorem sed risus ultricies tristique nulla. Fermentum dui faucibus in
                ornare. Ac tortor dignissim convallis aenean et tortor at risus. Non quam lacus
                suspendisse faucibus interdum. Enim facilisis gravida neque convallis a cras.
                Convallis posuere morbi leo urna molestie. Tincidunt lobortis feugiat vivamus at
                augue eget arcu. Quis hendrerit dolor magna eget est lorem ipsum dolor sit. Non quam
                lacus suspendisse faucibus interdum. Cursus metus aliquam eleifend mi in. Odio
                pellentesque diam volutpat commodo. Lacus sed turpis tincidunt id aliquet risus
                feugiat. Purus viverra accumsan in nisl nisi scelerisque eu ultrices. Nibh mauris
                cursus mattis molestie a iaculis at. Hendrerit gravida rutrum quisque non tellus
                orci. Elit pellentesque habitant morbi tristique senectus. Quam id leo in vitae
                turpis massa. Orci nulla pellentesque dignissim enim sit. Id eu nisl nunc mi ipsum
                faucibus vitae aliquet nec.
            </div>
        </ScrollableDiv>
    );
});
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;