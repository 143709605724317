
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 9,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles and the mui theme provider

const exampleButtonClassName = 'myButton';
const exampleIconClassName = 'myIcon';
const exampleTextClassName = 'myText';

const CustomFileDropper = mui.styled(varnishUi.FileInput)\`
    border: 2px dashed red;
    }
    .\${exampleButtonClassName} {
        border: 2px dashed orange;
    }
    .\${exampleIconClassName} {
        stroke: purple;
    }
    .\${exampleTextClassName} {
        color: green;
    }
\`;

render(
    <CustomFileDropper
        buttonClassName={exampleButtonClassName}
        iconClassName={exampleIconClassName}
        textClassName={exampleTextClassName}
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;