
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Styling'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To customize the styles of a component, simply pass an overriding `}<inlineCode parentName="p">{`className`}</inlineCode>{` to the component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<varnishUi.Button className="myButton">StyledButton</varnishUi.Button>
`}</code></pre>
    <p>{`This approach is similar to what libraries like `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{` and `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` do under the
hood. You can leverage utilities such as `}<inlineCode parentName="p">{`cx`}</inlineCode>{`, `}<inlineCode parentName="p">{`clsx`}</inlineCode>{`, `}<inlineCode parentName="p">{`emotion`}</inlineCode>{`, or even directly import CSS to
convert styles into a `}<inlineCode parentName="p">{`className`}</inlineCode>{`.`}</p>
    <p>{`This is an example of using Emotion CSS directly. You can also utilize the Styled Components Theme
Provider, the MUI Theme Provider, or create your own custom Theme Provider. Each of these options
will expose Varnish theme variables to your components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

const customClassName = () => {
    const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it
    return emotionCss.css\`
        padding: \${theme.spacing[1].value}; // old theme
        font-size: \${theme.font.size['2xl'].value};
        color: white;
        background-color: light-dark(\${theme.color['dark-teal-100'].value},  \${theme.color['info-blue-100'].value});
    \`;
};

render(
    <varnishUi.Button className={emotionCss.cx(customClassName())}>className button</varnishUi.Button>
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;