import { type CommonBulletProps } from '@nivo/bullet';

import varnishTokens from '@allenai/varnish-theme/simple-hex-unitless';

import { merge } from 'lodash-es';

import { themeLight, themeDark } from '../nivo-theme';
import { colorIdsToValues } from '../utils';

const { spacing } = varnishTokens;

export const bulletDefaults: Partial<CommonBulletProps> = {
    theme: themeLight,
    titleOffsetX: -spacing['1'],
    markerSize: 1.6,
    measureSize: 0.3,
    titleAlign: 'end',
    rangeColors: colorIdsToValues('N4', 'N5', 'N6', 'N7', 'N8', 'N9'),
    measureColors: colorIdsToValues('B5', 'B6', 'B7', 'B8'),
    markerColors: colorIdsToValues('G6', 'G7', 'G9', 'G10'),
};

export const bulletDefaultsDark = merge({}, bulletDefaults, {
    theme: themeDark,
});
