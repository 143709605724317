'use client';

import { useSpriteConfig } from './SpriteProvider';

const SVGSpriteLink = ({ href: hrefIn }: { href?: string }) => {
    const spriteConfig = useSpriteConfig();

    const href = hrefIn ?? spriteConfig.sprite ?? undefined; // Error - not configured correctly
    return <link rel="preload" href={href} as="image" type="image/svg+xml" />;
};

export { SVGSpriteLink as default, SVGSpriteLink };
