
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Disabled rows'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A Row can be disabled with the isDisabled prop. This will disable all interactions on the row, unless the disabledBehavior prop on Table is used to change this behavior.
Note that you are responsible for the styling of disabled rows, however, the selection checkbox will be automatically disabled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
        { id: 'dateModified', name: 'Date Modified' },
    ];

    const dataItems = [
        { id: '1', name: 'Games', type: 'File folder', dateModified: '6/7/2020', isDisabled: true },
        { id: '2', name: 'Program Files', type: 'File folder', dateModified: '4/7/2021' },
        { id: '3', name: 'bootmgr', type: 'System file', dateModified: '11/20/2010' },
        { id: '4', name: 'log.txt', type: 'Text Document', dateModified: '1/18/2016' },
    ];
    return (
        <varnishUi.Table aria-label="Files" headers={headers} dataItems={dataItems} />
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;