import assetIcons from '@allenai/varnish-theme/assets_icons.json';
import { Typography, styled } from '@mui/material';

export const IconGrid = () => {
    return (
        <IconsGrid>
            <IconsGridItem>
                <Typography variant="subtitle1" m="0">
                    ICON
                </Typography>
            </IconsGridItem>
            <IconsGridItem>
                <Typography variant="subtitle1" m="0">
                    NAME
                </Typography>
            </IconsGridItem>
            {Object.entries(assetIcons).map(([name, base64String]) => (
                <>
                    <IconsGridItem>
                        <img
                            width="22px"
                            src={`data:image/svg+xml;base64,${base64String}`}
                            alt={name}
                        />
                    </IconsGridItem>
                    <IconsGridItem>
                        <span className="name"> {name}</span>
                    </IconsGridItem>
                </>
            ))}
        </IconsGrid>
    );
};

const IconsGrid = styled('div')`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2px;
    width: fit-content;
`;

const IconsGridItem = styled('div')`
    display: flex;
    align-items: start;
    padding: 10px;
`;
