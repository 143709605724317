import { cx } from '@/utils/cx';

// import { type IconExtendedRecipeProps } from './Icon';
import { iconStyles, type IconRecipeProps } from './icon.styles';
import { SVGBase } from '@/components/svg/SVGBase';
import type { SVGColorProp } from '@/components/svg/svg.styles';

type SVGIconProps = Omit<React.SVGAttributes<SVGElement>, 'color' | 'children'> &
    IconRecipeProps &
    SVGColorProp;

// Thin wrapper
// TODO: re-eval viewBox vs intrinisicWidth/Height
const SVGIcon = ({ viewBox, color, className, ...rest }: SVGIconProps) => {
    const [variantProps, localProps] = iconStyles.splitVariantProps(rest);

    return (
        <SVGBase
            viewBox={viewBox}
            className={cx(iconStyles(variantProps), className)}
            color={color}
            {...localProps}
        />
    );
};

export { SVGIcon as default, SVGIcon };
export type { SVGIconProps };
