import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const formRecipe = sva({
    slots: ['root'],
    base: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '[8px]',
            alignItems: 'stretch',
            fontWeight: 'medium',
            color: '[inherit]',
            _hover: {
                color: '[inherit]',
            },
            _focus: {
                outline: '2px solid',
                outlineColor: '[currentColor]',
            },
            _disabled: {
                opacity: 0.6,
                cursor: 'not-allowed',
            },
        },
    },
});

type FormRecipeProps = RecipeVariantProps<typeof formRecipe>;

export { formRecipe, formRecipe as default };
export type { FormRecipeProps };
