// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer varnish.reset, varnish.theme;

@import './dist/varnish-tokens.css';

@layer varnish { 
    @layer reset {
        /*
        Josh's Custom CSS Reset
        https://www.joshwcomeau.com/css/custom-css-reset/
        */
        *, *::before, *::after {
            box-sizing: border-box;
        }

        * {
            margin: 0;
            line-height: calc(1em + 0.5rem);
        }

        body {
            line-height: 1.5;
        }

        img, picture, video, canvas, svg {
            display: block;
            max-width: 100%;
        }

        input, button, textarea, select {
            font: inherit;
        }

        p, h1, h2, h3, h4, h5, h6 {
            overflow-wrap: break-word;
        }

        #root, #__next {
            isolation: isolate;
        }
    }

    @layer theme {
        body {
            font-family: var(--typography-body-font-family);
            font-size: var(--typography-body-font-size);
            line-height: var(--typography-body-line-height);
            color: var(--palette-text-primary);
            font-weight: var(--typography-body-font-weight);
            letter-spacing: var(--typography-body-letter-spacing);

            /**
            * These settings are non-standard, but important to ensure things
            * look as expected.
            */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
        }

        h1 {
            color: var(--typography-h1-color);
            font-family: var(--typography-h1-font-family);
            font-weight: var(--typography-h1-font-weight);
            font-size: var(--typography-h1-font-size);
            line-height: var(--typography-h1-line-height);
            letter-spacing: var(--typography-h1-letter-spacing);
            margin: var(--typography-h1-margin);
        }

        h2 {
            color: var(--typography-h2-color);
            font-family: var(--typography-h2-font-family);
            font-weight: var(--typography-h2-font-weight);
            font-size: var(--typography-h2-font-size);
            line-height: var(--typography-h2-line-height);
            letter-spacing: var(--typography-h2-letter-spacing);
            margin: var(--typography-h2-margin);
        }

        h3 {
            color: var(--typography-h3-color);
            font-family: var(--typography-h3-font-family);
            font-weight: var(--typography-h3-font-weight);
            font-size: var(--typography-h3-font-size);
            line-height: var(--typography-h3-line-height);
            letter-spacing: var(--typography-h3-letter-spacing);
            margin: var(--typography-h3-margin);
        }

        h4 {
            color: var(--typography-h4-color);
            font-family: var(--typography-h4-font-family);
            font-weight: var(--typography-h4-font-weight);
            font-size: var(--typography-h4-font-size);
            line-height: var(--typography-h4-line-height);
            letter-spacing: var(--typography-h4-letter-spacing);
            margin: var(--typography-h4-margin);
        }

        h5 {
            color: var(--typography-h5-color);
            font-family: var(--typography-h5-font-family);
            font-weight: var(--typography-h5-font-weight);
            font-size: var(--typography-h5-font-size);
            line-height: var(--typography-h5-line-height);
            letter-spacing: var(--typography-h5-letter-spacing);
            margin: var(--typography-h5-margin);
        }

        h6, legend {
            color: var(--typography-h6-color);
            font-family: var(--typography-h6-font-family);
            font-weight: var(--typography-h6-font-weight);
            font-size: var(--typography-h6-font-size);
            line-height: var(--typography-h6-line-height);
            letter-spacing: var(--typography-h6-letter-spacing);
            margin: var(--typography-h6-margin);
        }

        .subtitle1 {
            font-family: var(--typography-subtitle1-font-family);
            font-weight: var(--typography-subtitle1-font-weight);
            font-size: var(--typography-subtitle1-font-size);
            line-height: var(--typography-subtitle1-line-height);
            letter-spacing: var(--typography-subtitle1-letter-spacing);
        }

        .subtitle2 {
            font-family: var(--typography-subtitle2-font-family);
            font-weight: var(--typography-subtitle2-font-weight);
            font-size: var(--typography-subtitle2-font-size);
            line-height: var(--typography-subtitle2-line-height);
            letter-spacing: var(--typography-subtitle2-letter-spacing);
        }

        .body {
            font-family: var(--typography-body-font-family);
            font-weight: var(--typography-body-font-weight);
            font-size: var(--typography-body-font-size);
            line-height: var(--typography-body-line-height);
            letter-spacing: var(--typography-body-letter-spacing);
        }

        .body-small {
            font-family: var(--typography-body-small-font-family);
            font-weight: var(--typography-body-small-font-weight);
            font-size: var(--typography-body-small-font-size);
            line-height: var(--typography-body-small-line-height);
            letter-spacing: var(--typography-body-small-letter-spacing);
        }

        .button {
            font-family: var(--typography-button-font-family);
            font-weight: var(--typography-button-font-weight);
            font-size: var(--typography-button-font-size);
            line-height: var(--typography-button-line-height);
            letter-spacing: var(--typography-button-letter-spacing);
            text-transform: var(--typography-button-textTransform);
        }

        .caption {
            color: var(--typography-caption-color);
            font-family: var(--typography-caption-font-family);
            font-weight: var(--typography-caption-font-weight);
            font-size: var(--typography-caption-font-size);
            line-height: var(--typography-caption-line-height);
            letter-spacing: var(--typography-caption-letter-spacing);
        }

        .overline {
            font-family: var(--typography-overline-font-family);
            font-weight: var(--typography-overline-font-weight);
            font-size: var(--typography-overline-font-size);
            line-height: var(--typography-overline-line-height);
            letter-spacing: var(--typography-overline-letter-spacing);
            text-transform: var(--typography-overline-textTransform);
        }
    }
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
