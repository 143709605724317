
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Composing'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can compose the Provider with other Providers to use its properties.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as varnishComponents from '@allenai/varnish2/components';
import * as ThemeMui from '@allenai/varnish2/theme';

const customGetComputedTheme = (theme, computedColorMode) => {
    const colorModeColor = computedColorMode === 'dark' ? 'rgb(255, 0, 0)' : 'rgb(0, 0, 255)';
    const updatedTheme = ThemeMui.getTheme({
        palette: {
            text: {
                other: colorModeColor,
            },
        },
    });

    return updatedTheme;
};

const ThemeSwitcher = () => {
    const { computedColorMode, setColorMode } = varnishUi.useTheme();

    return (
        <varnishUi.Select selectedKey={computedColorMode} onSelectionChange={setColorMode}>
            <varnishUi.SelectListBoxItem id="light">Light</varnishUi.Radio>
            <varnishUi.SelectListBoxItem id="dark">Dark</varnishUi.Radio>
        </varnishUi.RadioGroup>
    );
};

const App = () => {
    return (
        <varnishUi.Stack>
            <ThemeSwitcher />
            <mui.Typography sx={{ color: 'text.other' }}>Text</mui.Typography>
        </varnishUi.Stack>
    );
};

const MuiThemeProvider = ({ theme, children }) => {
    const { computedColorMode } = varnishUi.useTheme();

    const computedTheme = customGetComputedTheme(theme, computedColorMode);

    return (
        <varnishUi.EmotionThemeProvider theme={computedTheme}>
            <varnishComponents.VarnishApp theme={computedTheme}>
                {children}
            </varnishComponents.VarnishApp>
        </varnishUi.EmotionThemeProvider>
    );
};

render(
    <varnishUi.AppProvider>
        <MuiThemeProvider theme={ThemeMui}>
            <App />
        </MuiThemeProvider>
    </varnishUi.AppProvider>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;