
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'ThemeProvider'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The `}<inlineCode parentName="p">{`ThemeProviderBase`}</inlineCode>{` provides color switching capability to all varnish-ui components.
It has values for the current `}<inlineCode parentName="p">{`colorMode`}</inlineCode>{`, `}<inlineCode parentName="p">{`computedColorMode`}</inlineCode>{` and the `}<inlineCode parentName="p">{`systemColorMode`}</inlineCode>{` and also
has a setColorMode function.`}</p>
    <p>{`Included here is basic theme switching functionality`}</p>
    <p>{`Note: `}<inlineCode parentName="p">{`system`}</inlineCode>{` theme is not currently supported in varnish-ui.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';

const ThemeSwitcher = () => {
    const { setColorMode, ...theme } = varnishUi.useTheme();
    const { computedColorMode } = theme;
    return (
        <>
            <varnishUi.Select selectedKey={computedColorMode} onSelectionChange={setColorMode}>
                <varnishUi.SelectListBoxItem id="system">System</varnishUi.SelectListBoxItem>
                <varnishUi.SelectListBoxItem id="light">Light</varnishUi.SelectListBoxItem>
                <varnishUi.SelectListBoxItem id="dark">Dark</varnishUi.SelectListBoxItem>
            </varnishUi.RadioGroup>
            <pre>{JSON.stringify(theme, null, 2)}</pre>
        </>
    );
};

render(
    <varnishUi.AppProvider>
        <ThemeSwitcher />
    </varnishUi.AppProvider>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;