
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Icon'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can pass an `}<inlineCode parentName="p">{`icon`}</inlineCode>{` to display, or pass `}<inlineCode parentName="p">{`false`}</inlineCode>{` to display no icon, otherwise a default icon based on the severity will be provided.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="column" spacing={25}>
        <varnishUi.Alert severity="success" variant="contained">
            Default success icon
        </varnishUi.Alert>
        <varnishUi.Alert severity="success" variant="contained" icon={false}>
            No icon provided
        </varnishUi.Alert>
        <varnishUi.Alert severity="success" variant="contained" icon={<varnishUi.Icons.Spark />}>
            Custom icon
        </varnishUi.Alert>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;