
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
const { logos } = varnishComponents;

const IconContent = mui.styled(logos.AI2Logo)\`
    padding-right: 2px;
    height: 12px;
\`;

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.Link href="https://allenai.org/">Ai2 Link</varnishUi.Link>
        <varnishUi.Link isDisabled href="https://allenai.org/">
            Disabled
        </varnishUi.Link>
        <varnishUi.Link href="https://allenai.org/">
            <IconContent size="sm" includeText={false} />
            Linked Image
        </varnishUi.Link>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;