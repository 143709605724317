import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

// `Popover` also uses Tooltip styles (../tooltip/tooltip.styles.ts)
const popoverStyles = sva({
    slots: ['root', 'arrow', 'heading'], // mimics Dialog and Tooltip
    base: {
        root: {
            cursor: 'default',
            outline: 'none',
        },
    },
    variants: {
        shape: {
            rounded: {},
            box: {},
        },
        color: {
            default: {},
            reversed: {},
        },
    },
    defaultVariants: {
        shape: 'box',
        color: 'default',
    },
});

type PopoverRecipeProps = Exclude<RecipeVariantProps<typeof popoverStyles>, undefined>;

export { popoverStyles };
export type { PopoverRecipeProps };
