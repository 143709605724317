// see: https://github.com/Zenoo/mui-theme-creator

import slug from 'slug';
import { Box, Grid, createTheme, Button, ThemeProvider, Divider, Skeleton } from '@mui/material';
import React from 'react';

import componentSamples from './samples';
import { ExampleGrid, ExampleTitle, ExampleWrapper } from '../../../Examples';

type MuiExample = {
    id: string;
    title: string;
    component: JSX.Element;
    docs: string;
};

export const MuiComponentSamples = () => {
    const originalTheme = createTheme();
    return (
        <Box sx={{ maxWidth: '100%' }}>
            <ExampleGrid cols={1}>
                {componentSamples.map((example: MuiExample) => {
                    return (
                        <React.Suspense
                            key={example.title}
                            fallback={
                                <Skeleton
                                    sx={{
                                        height: '525px',
                                        width: '100%',
                                    }}
                                    animation="wave"
                                />
                            }>
                            <ExampleWrapper id={slug(example.title, '_')}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <ExampleTitle>{example.title}</ExampleTitle>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        href={example.docs}
                                        target="_blank"
                                        rel="noreferrer">
                                        Docs
                                    </Button>
                                </Grid>
                                <Grid container columnGap={2}>
                                    <Grid item sx={{ width: 'calc(50% - 25px)' }}>
                                        <Divider sx={{ mb: 1 }} textAlign="left">
                                            Varnish
                                        </Divider>
                                        {example.component}
                                    </Grid>
                                    <Grid item>
                                        <Divider orientation="vertical" />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(50% - 24px)' }}>
                                        <Divider sx={{ mb: 1 }} textAlign="left">
                                            Original MUI
                                        </Divider>
                                        <ThemeProvider theme={originalTheme}>
                                            {example.component}
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </ExampleWrapper>
                        </React.Suspense>
                    );
                })}
            </ExampleGrid>
        </Box>
    );
};
