
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
          <varnishUi.Button variant="outlined" color="primary" onClick={() => setOpen(true)}>Drawer</Button>
          <varnishUi.Drawer
                isOpen={open}
                onClose={() => setOpen(false)} />
        </>
    )
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;