
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Components'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`VarnishUI provides a default components mapping for markdown. But you can pass your own to override it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import { PropsWithChildren } from 'react';
import varnishUi from "@allenai/varnish-ui";
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it
const markdownText = \`A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

##### Todo Checklist

* [ ] todo
* [x] done

##### Unordered List

* Milk
* Apples
* Eggs

##### Horizontal Rule

---

##### Table

| Product          | Estimated Price ($) | Estimated Weight (kg) |
|------------------|---------------------:|------------------------:|
| Widget X         |               $49.99 |              -      0.5 |
| Gizmo Y          |               $79.95 |                    1.2 |
| Gadget Z         |              $129.99 |                    0.8 |
| Thingamajig A    |               $34.50 |                    0.3 |
| Doodad B         |               $89.75 |                    1.5 |

##### Ordered List

1. Use markdown
2. ???
3. Profit

##### Image

![Image](https://t4.ftcdn.net/jpg/00/53/45/31/360_F_53453175_hVgYVz0WmvOXPd9CNzaUcwcibiGao3CL.jpg)
\`;

const className = () => {
    return emotionCss.css\`
        color: red;
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const strongClassName = () => {
    return emotionCss.css\`
        color: red;
        border: 1px dashed \${theme.color.R6.value};
    \`;
}

const customComponents = {
    ol: (props: PropsWithChildren) => (
        <ol className={emotionCss.cx(className())}>{props.children}</ol>
    ),
    img: (props: PropsWithChildren) => (
        <img className={emotionCss.cx(strongClassName())}>{props.children}</img>
    ),
};

// To extend the default component map, override only the components you want. For example: \`customComponents = { ...varnishUi.Markdown.DefaultComponents, ...customComponents }\`
const Demo = () => {
  return <varnishUi.Markdown components={customComponents}>{markdownText}</varnishUi.Markdown>;
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;