
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Here is an example of styling via `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const exampleClassName = () => {
    return emotionCss.css\`
        border: 2px dashed red;
    \`;
};

const exampleButtonClassName = () => {
    return emotionCss.css\`
        border: 2px dashed orange;
    \`;
};

const exampleIconClassName = () => {
    return emotionCss.css\`
        stroke: purple;
    \`;
};

const exampleTextClassName = () => {
    return emotionCss.css\`
        color: green;
    \`;
};

render(
        <varnishUi.FileInput
            className={emotionCss.cx(exampleClassName())}
            buttonClassName={emotionCss.cx(exampleButtonClassName())}
            iconClassName={emotionCss.cx(exampleIconClassName())}
            textClassName={emotionCss.cx(exampleTextClassName())}

        />
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;