import {
    Heading as AriaHeading,
    type HeadingProps as AriaHeadingProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { dialogStyles, type DialogRecipeProps } from '@/components/dialog/dialog.styles';

type DialogHeadingProps = AriaHeadingProps & DialogRecipeProps;

const DialogHeadingBase = (props: DialogHeadingProps) => {
    return <AriaHeading slot="title" {...props} />;
};

const DialogHeading = ({ className, ...rest }: DialogHeadingProps) => {
    const [variantProps, localProps] = dialogStyles.splitVariantProps(rest);
    const variantClassNames = dialogStyles(variantProps);

    return (
        <DialogHeadingBase className={cx(variantClassNames.heading, className)} {...localProps} />
    );
};

export type { DialogHeadingProps };
export { DialogHeadingBase, DialogHeading, DialogHeading as default };
