import { cx } from '@/utils/cx';

import { modalStyles, type ModalRecipeProps } from '@/components/modal/modal.styles';

interface ModalContentProps extends ModalRecipeProps, React.PropsWithChildren {
    className?: string;
}

const ModalContent = ({ className, children, ...rest }: ModalContentProps) => {
    const [variantProps, localProps] = modalStyles.splitVariantProps(rest);
    const modalClassNames = modalStyles(variantProps);
    return (
        <div className={cx(modalClassNames.content, className)} {...localProps}>
            {children}
        </div>
    );
};

export type { ModalContentProps };
export { ModalContent, ModalContent as default };
