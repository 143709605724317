
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { FooterExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'AI2 Footer',
  cols: 1,
  iconSrc: './header-footer-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`AI2 Footer`}</h2>
    <p>{`This component renders a document footer that contains a copyright statement and links to AI2's
Privacy Policy and Terms of Use. This component must be used on all pages of your application.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`You should put this towards the end of the page, as that's where this information is usually
expected. It must be present on every page of your application.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={FooterExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'default' `}{`|`}{` 'dark'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'default'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used contrast text color when 'dark.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Background color of footer.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Footer Components`}</h3>
    <p>{`The Footer consists of multiple different links that can each be used individually or together for a custom footer:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Link Component`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PrivacyLink`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The AI2 Privacy Policy Link, with variant default or dark depending on footer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`TermsOfUseLink`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The AI2 Terms of Use Link, with variant default or dark depending on footer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`BusinessConductLink`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The AI2 Business Code of Conduct Link, with variant default or dark depending on footer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`AI2TermsAndConditionsAllLinks`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The combination of the AI2 Privacy Policy, Terms of Use, and Business Code of Conduct links`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`AI2CopyrightLink`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The AI2 Copyright Link, with variant default or dark depending on footer`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`You can separate Footer links if used in a custom footer with a `}<inlineCode parentName="p">{`<FooterLinkSeparator />`}</inlineCode>{` component`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;