import varnishTokens from '@allenai/varnish-theme/simple-hex-unitless';
import { Theme, ThemeWithoutInheritance } from '@nivo/core';
import { merge } from 'lodash-es';

const { palette, font, color, spacing } = varnishTokens;

const lightThemeWithoutInheritance: ThemeWithoutInheritance = {
    text: {
        fontFamily: font.family.body,
        fontSize: font.size.sm,
        fill: palette.light.text.primary.default,
        outlineWidth: 0,
        outlineColor: 'transparent',
    },
    background: color.transparent,
    axis: {
        domain: {
            line: {
                stroke: color.transparent,
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: color.N7,
                strokeWidth: 1,
            },
            text: {},
        },
        legend: {
            text: {
                fontSize: font.size.md,
                fontWeight: font.weight.bold,
            },
        },
    },
    grid: {
        line: {
            stroke: color.N7,
            strokeWidth: 1,
        },
    },
    legends: {
        hidden: {
            symbol: {
                fill: color.N9,
                opacity: 0.6,
            },
            text: {
                fill: color.N9,
                opacity: 0.6,
            },
        },
        text: {
            fill: color.N10,
        },
        title: { text: {} },
        ticks: { text: {}, line: {} },
    },
    labels: {
        text: {
            fontSize: font.size.sm,
        },
    },
    markers: {
        lineColor: color.black,
        lineStrokeWidth: 1,
    },
    tooltip: {
        container: {
            background: palette.light.background.default,
            color: 'inherit',
            fontSize: 'inherit',
            // borderRadius: shape.borderRadius.default,
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: `${spacing['1']} ${spacing['1']}`,
        },
        basic: {
            whiteSpace: 'pre',
            display: 'flex',
            alignItems: 'center',
        },
        chip: {
            marginRight: spacing[1],
        },
        table: {},
        tableCell: {
            padding: spacing[1],
        },
        tableCellValue: {
            fontWeight: font.weight.bold,
        },
    },
    crosshair: {
        line: {
            stroke: color.black,
            strokeWidth: 1,
            strokeOpacity: 0.75,
            strokeDasharray: '6 6',
        },
    },
    annotations: {
        text: {
            fontSize: font.size.sm,
            outlineWidth: 2,
            outlineColor: color.white,
        },
        link: {
            stroke: color.black,
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: color.white,
            outlineOpacity: 1,
        },
        outline: {
            fill: 'none',
            stroke: color.black,
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: color.white,
            outlineOpacity: 1,
        },
        symbol: {
            fill: color.black,
            outlineWidth: 2,
            outlineColor: color.white,
            outlineOpacity: 1,
        },
    },
    dots: {
        text: {},
    },
};

// We re-export these as a Theme to make typing easier. When the themes get passed into components Nivo fills in the blanks!
export const themeLight = lightThemeWithoutInheritance as Theme;

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

const darkThemeWithoutInheritance = merge({}, lightThemeWithoutInheritance, {
    text: {
        fill: palette.light.text.secondary.default,
    },
    axis: {
        ticks: {
            line: {
                opacity: 0.15,
            },
        },
    },
    grid: {
        line: {
            opacity: 0.15,
        },
    },
    legends: {
        hidden: {
            symbol: {
                fill: color.N3,
                opacity: 0.6,
            },
            text: {
                fill: color.N3,
                opacity: 0.6,
            },
        },
        text: {
            fill: color.N2,
        },
    },
    markers: {
        lineColor: color.white,
    },
    crosshair: {
        line: {
            stroke: color.white,
        },
    },
    annotations: {
        text: {
            outlineColor: color.black,
        },
        link: {
            stroke: color.white,
            outlineColor: color.black,
        },
        outline: {
            stroke: color.white,
            outlineColor: color.black,
        },
        symbol: {
            fill: color.white,
            outlineColor: color.black,
        },
    },
    // This is a DeepPartial because we're merging with the base theme. We're guaranteed to have everything we need as long as the base is typed correctly.
} satisfies DeepPartial<ThemeWithoutInheritance>) as ThemeWithoutInheritance;

export const themeDark = darkThemeWithoutInheritance as Theme;
