
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider
const boxClassName = 'myBox';
const iconClassName = 'myIcon';

const CustomCheckbox = mui.styled(varnishUi.Checkbox)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};
    padding: 2px;

    .\${boxClassName} {
        border: 1px dashed \${({ theme }) => theme.color.G5.hex};
        padding: 2px;
        margin: 2px;
        height: 26px;
        width: 26px;
    }
    .\${iconClassName} {
        border: 1px dashed \${({ theme }) => theme.color.B5.hex};
        padding: 2px;
        margin: 2px;
    }
\`;

render(
    <CustomCheckbox defaultSelected boxClassName={boxClassName} iconClassName={iconClassName}>
        Label
    </CustomCheckbox>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;