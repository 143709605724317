
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'ResponsiveLine with LightTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of the Nivo ResponsiveLine component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { lineDefaults } from '@allenai/varnish-nivo';
import Theme from '@allenai/varnish-theme';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsiveLine
            // add in varnish theme at top of all charts
            {...lineDefaults}
            margin={{
                left: 48,
                bottom: 56,
                right: 16,
                top: 32,
            }}
            axisLeft={{
                legend: 'Count',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            axisBottom={{
                legend: 'Weight',
                legendOffset: 40,
                legendPosition: 'middle',
            }}
            // add data
            data={[
                {
                    id: '0',
                    data: [
                        {
                            x: 0,
                            y: 57,
                        },
                        {
                            x: 1,
                            y: 3,
                        },
                        {
                            x: 2,
                            y: 53,
                        },
                        {
                            x: 3,
                            y: 57,
                        },
                        {
                            x: 4,
                            y: 99,
                        },
                    ],
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;