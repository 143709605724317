const varnishPandaTheme = {
  "breakpoints": {
    "xs": "0",
    "sm": "37.5rem",
    "md": "56.25rem",
    "lg": "75rem",
    "xl": "96rem"
  },
  "tokens": {
    "colors": {
      "extra-dark-teal": {
        "10": {
          "value": "rgba(3, 38, 41, 0.1)"
        },
        "20": {
          "value": "rgba(3, 38, 41, 0.2)"
        },
        "30": {
          "value": "rgba(3, 38, 41, 0.3)"
        },
        "40": {
          "value": "rgba(3, 38, 41, 0.4)"
        },
        "50": {
          "value": "rgba(3, 38, 41, 0.5)"
        },
        "60": {
          "value": "rgba(3, 38, 41, 0.6)"
        },
        "70": {
          "value": "rgba(3, 38, 41, 0.7)"
        },
        "80": {
          "value": "rgba(3, 38, 41, 0.8)"
        },
        "90": {
          "value": "rgba(3, 38, 41, 0.9)"
        },
        "100": {
          "value": "rgba(3, 38, 41, 1)"
        }
      },
      "dark-teal": {
        "10": {
          "value": "rgba(10, 50, 53, 0.1)"
        },
        "20": {
          "value": "rgba(10, 50, 53, 0.2)"
        },
        "30": {
          "value": "rgba(10, 50, 53, 0.3)"
        },
        "40": {
          "value": "rgba(10, 50, 53, 0.4)"
        },
        "50": {
          "value": "rgba(10, 50, 53, 0.5)"
        },
        "60": {
          "value": "rgba(10, 50, 53, 0.6)"
        },
        "70": {
          "value": "rgba(10, 50, 53, 0.7)"
        },
        "80": {
          "value": "rgba(10, 50, 53, 0.8)"
        },
        "90": {
          "value": "rgba(10, 50, 53, 0.9)"
        },
        "100": {
          "value": "rgba(10, 50, 53, 1)"
        }
      },
      "cream": {
        "10": {
          "value": "rgba(250, 242, 233, 0.1)"
        },
        "20": {
          "value": "rgba(250, 242, 233, 0.2)"
        },
        "30": {
          "value": "rgba(250, 242, 233, 0.3)"
        },
        "40": {
          "value": "rgba(250, 242, 233, 0.4)"
        },
        "50": {
          "value": "rgba(250, 242, 233, 0.5)"
        },
        "60": {
          "value": "rgba(250, 242, 233, 0.6)"
        },
        "70": {
          "value": "rgba(250, 242, 233, 0.7)"
        },
        "80": {
          "value": "rgba(250, 242, 233, 0.8)"
        },
        "90": {
          "value": "rgba(250, 242, 233, 0.9)"
        },
        "100": {
          "value": "rgba(250, 242, 233, 1)"
        }
      },
      "info-blue": {
        "20": {
          "value": "rgba(212, 231, 252, 1)"
        },
        "40": {
          "value": "rgba(170, 207, 249, 1)"
        },
        "60": {
          "value": "rgba(127, 184, 245, 1)"
        },
        "80": {
          "value": "rgba(85, 160, 242, 1)"
        },
        "100": {
          "value": "rgba(42, 136, 239, 1)"
        }
      },
      "error-red": {
        "20": {
          "value": "rgba(255, 218, 218, 1)"
        },
        "40": {
          "value": "rgba(254, 181, 181, 1)"
        },
        "60": {
          "value": "rgba(254, 144, 143, 1)"
        },
        "80": {
          "value": "rgba(253, 107, 106, 1)"
        },
        "100": {
          "value": "rgba(253, 70, 69, 1)"
        }
      },
      "success-green": {
        "20": {
          "value": "rgba(221, 235, 215, 1)"
        },
        "40": {
          "value": "rgba(187, 215, 174, 1)"
        },
        "60": {
          "value": "rgba(152, 196, 134, 1)"
        },
        "80": {
          "value": "rgba(118, 176, 93, 1)"
        },
        "100": {
          "value": "rgba(84, 156, 53, 1)"
        }
      },
      "warning-orange": {
        "20": {
          "value": "rgba(255, 237, 210, 1)"
        },
        "40": {
          "value": "rgba(255, 218, 164, 1)"
        },
        "60": {
          "value": "rgba(255, 200, 119, 1)"
        },
        "80": {
          "value": "rgba(255, 181, 73, 1)"
        },
        "100": {
          "value": "rgba(255, 163, 28, 1)"
        }
      },
      "teal": {
        "10": {
          "value": "rgba(16, 82, 87, 0.1)"
        },
        "20": {
          "value": "rgba(16, 82, 87, 0.2)"
        },
        "30": {
          "value": "rgba(16, 82, 87, 0.3)"
        },
        "40": {
          "value": "rgba(16, 82, 87, 0.4)"
        },
        "50": {
          "value": "rgba(16, 82, 87, 0.5)"
        },
        "60": {
          "value": "rgba(16, 82, 87, 0.6)"
        },
        "70": {
          "value": "rgba(16, 82, 87, 0.7)"
        },
        "80": {
          "value": "rgba(16, 82, 87, 0.8)"
        },
        "90": {
          "value": "rgba(16, 82, 87, 0.9)"
        },
        "100": {
          "value": "rgba(16, 82, 87, 1)"
        }
      },
      "pink": {
        "10": {
          "value": "rgba(253, 238, 245, 1)"
        },
        "20": {
          "value": "rgba(252, 220, 235, 1)"
        },
        "30": {
          "value": "rgba(251, 203, 225, 1)"
        },
        "40": {
          "value": "rgba(249, 186, 215, 1)"
        },
        "50": {
          "value": "rgba(247, 168, 205, 1)"
        },
        "60": {
          "value": "rgba(246, 151, 196, 1)"
        },
        "70": {
          "value": "rgba(245, 134, 186, 1)"
        },
        "80": {
          "value": "rgba(243, 117, 176, 1)"
        },
        "90": {
          "value": "rgba(242, 99, 166, 1)"
        },
        "100": {
          "value": "rgba(240, 82, 156, 1)"
        }
      },
      "purple": {
        "10": {
          "value": "rgba(247, 232, 253, 1)"
        },
        "20": {
          "value": "rgba(239, 209, 250, 1)"
        },
        "30": {
          "value": "rgba(232, 187, 248, 1)"
        },
        "40": {
          "value": "rgba(224, 164, 246, 1)"
        },
        "50": {
          "value": "rgba(216, 141, 243, 1)"
        },
        "60": {
          "value": "rgba(208, 118, 241, 1)"
        },
        "70": {
          "value": "rgba(200, 95, 239, 1)"
        },
        "80": {
          "value": "rgba(193, 73, 237, 1)"
        },
        "90": {
          "value": "rgba(185, 50, 234, 1)"
        },
        "100": {
          "value": "rgba(177, 27, 232, 1)"
        }
      },
      "green": {
        "10": {
          "value": "rgba(231, 250, 243, 1)"
        },
        "20": {
          "value": "rgba(207, 245, 232, 1)"
        },
        "30": {
          "value": "rgba(183, 239, 221, 1)"
        },
        "40": {
          "value": "rgba(159, 234, 209, 1)"
        },
        "50": {
          "value": "rgba(135, 229, 197, 1)"
        },
        "60": {
          "value": "rgba(111, 224, 186, 1)"
        },
        "70": {
          "value": "rgba(87, 219, 175, 1)"
        },
        "80": {
          "value": "rgba(63, 213, 163, 1)"
        },
        "90": {
          "value": "rgba(39, 208, 156, 1)"
        },
        "100": {
          "value": "rgba(15, 203, 140, 1)"
        }
      },
      "gray": {
        "10": {
          "value": "rgba(235, 235, 235, 1)"
        },
        "20": {
          "value": "rgba(214, 214, 214, 1)"
        },
        "30": {
          "value": "rgba(194, 194, 194, 1)"
        },
        "40": {
          "value": "rgba(174, 174, 174, 1)"
        },
        "50": {
          "value": "rgba(153, 153, 153, 1)"
        },
        "60": {
          "value": "rgba(133, 133, 133, 1)"
        },
        "70": {
          "value": "rgba(113, 113, 113, 1)"
        },
        "80": {
          "value": "rgba(93, 93, 93, 1)"
        },
        "90": {
          "value": "rgba(72, 72, 72, 1)"
        },
        "100": {
          "value": "rgba(52, 52, 52, 1)"
        }
      }
    },
    "fonts": {
      "body": {
        "value": "Manrope, Arial, sans-serif"
      },
      "heading": {
        "value": "Telegraf, Manrope, Arial, sans-serif"
      },
      "monospace": {
        "value": "'Space Mono', monospace"
      }
    },
    "fontSizes": {
      "4xl": {
        "value": "2.25rem"
      },
      "3xl": {
        "value": "2rem"
      },
      "2xl": {
        "value": "1.5rem"
      },
      "xl": {
        "value": "1.25rem"
      },
      "lg": {
        "value": "1.125rem"
      },
      "md": {
        "value": "1rem"
      },
      "sm": {
        "value": "0.875rem"
      }
    },
    "fontWeights": {
      "light": {
        "value": 300
      },
      "regular": {
        "value": 400
      },
      "medium": {
        "value": 500
      },
      "semiBold": {
        "value": 600
      },
      "bold": {
        "value": 700
      }
    },
    "spacing": {
      "0": {
        "value": "0px"
      },
      "1": {
        "value": "8px"
      },
      "2": {
        "value": "16px"
      },
      "3": {
        "value": "24px"
      },
      "4": {
        "value": "32px"
      },
      "5": {
        "value": "40px"
      },
      "6": {
        "value": "48px"
      },
      "7": {
        "value": "56px"
      },
      "8": {
        "value": "64px"
      },
      "10": {
        "value": "80px"
      },
      "11": {
        "value": "88px"
      },
      "12": {
        "value": "96px"
      },
      "13": {
        "value": "104px"
      },
      "14": {
        "value": "112px"
      },
      "15": {
        "value": "120px"
      },
      "16": {
        "value": "128px"
      },
      "baseSpacingValue": {
        "value": 8
      }
    },
    "radii": {
      "xs": {
        "value": "2px"
      },
      "sm": {
        "value": "4px"
      },
      "md": {
        "value": "8px"
      },
      "lg": {
        "value": "12px"
      },
      "xl": {
        "value": "16px"
      },
      "full": {
        "value": "9999px"
      }
    }
  },
  "semanticTokens": {
    "colors": {
      "background": {
        "DEFAULT": {
          "value": {
            "base": "{colors.cream.100}",
            "_dark": "{colors.dark-teal.100}"
          }
        },
        "reversed": {
          "value": {
            "base": "{colors.dark-teal.100}",
            "_dark": "{colors.cream.100}"
          }
        }
      },
      "text": {
        "primary": {
          "DEFAULT": {
            "value": {
              "base": "{colors.dark-teal.100}",
              "_dark": "{colors.cream.100}"
            }
          },
          "reversed": {
            "value": {
              "base": "{colors.cream.100}",
              "_dark": "{colors.dark-teal.100}"
            }
          }
        },
        "secondary": {
          "DEFAULT": {
            "value": {
              "base": "{colors.extra-dark-teal.100}",
              "_dark": "{colors.gray.10}"
            }
          },
          "reversed": {
            "value": {
              "base": "{colors.cream.100}",
              "_dark": "{colors.gray.80}"
            }
          }
        }
      },
      "accent": {
        "primary": {
          "value": "{colors.pink.100}"
        },
        "secondary": {
          "value": "{colors.green.100}"
        },
        "tertiary": {
          "value": "{colors.purple.100}"
        }
      },
      "alert": {
        "error": {
          "value": "{colors.error-red.100}"
        },
        "warning": {
          "value": "{colors.warning-orange.100}"
        },
        "confirmation": {
          "value": "{colors.success-green.100}"
        },
        "information": {
          "value": "{colors.info-blue.100}"
        }
      },
      "charting": {
        "categorical": {
          "dark": {
            "Red": {
              "value": "{colors.R4}"
            },
            "Orange": {
              "value": "{colors.O4}"
            },
            "Aqua": {
              "value": "{colors.A3}"
            },
            "Teal": {
              "value": "{colors.T3}"
            },
            "Blue": {
              "value": "{colors.B3}"
            },
            "Magenta": {
              "value": "{colors.M4}"
            },
            "Purple": {
              "value": "{colors.P4}"
            },
            "Green": {
              "value": "{colors.G4}"
            }
          },
          "light": {
            "Red": {
              "value": "{colors.R10}"
            },
            "Orange": {
              "value": "{colors.O9}"
            },
            "Aqua": {
              "value": "{colors.A10}"
            },
            "Teal": {
              "value": "{colors.T8}"
            },
            "Blue": {
              "value": "{colors.B6}"
            },
            "Magenta": {
              "value": "{colors.M10}"
            },
            "Purple": {
              "value": "{colors.P8}"
            },
            "Green": {
              "value": "{colors.G10}"
            }
          }
        }
      }
    }
  }
};
var varnish_tokens_panda_theme_default = varnishPandaTheme;

export { varnish_tokens_panda_theme_default as default };
