import { Tag as AriaChip, TagProps as AriaChipProps, Button } from 'react-aria-components';
import { useContext } from 'react';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { Close } from '@/components/icons';

import focusRing from '@/components/shared/focusRing.styles';

import ChipRecipeProps, { chipRecipe } from './chip.styles';
import { ChipGroupContext } from '@/components/chip/ChipGroup';

export type ChipItem = {
    id: string;
    text?: string;
    icon?: React.ReactNode;
    href?: string;
    isDisabled?: boolean;
    className?: string;
    removeButtonClassName?: string;
};

type ChipProps = ChipItem & AriaChipProps & ChipRecipeProps;

const Chip = ({ icon, text, className, removeButtonClassName, ...rest }: ChipProps) => {
    const [variantProps, localProps] = chipRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)

    const groupProps = useContext(ChipGroupContext);
    const recipeClassNames = chipRecipe({ ...groupProps, ...variantProps });
    return (
        <AriaChip
            {...localProps}
            {...groupProps}
            className={cx(css(focusRing), recipeClassNames.root, className)}>
            {(renderProps) => (
                <>
                    {icon}
                    <span>{text}</span>
                    {renderProps.allowsRemoving && (
                        <Button
                            slot="remove"
                            className={cx(recipeClassNames.removeButton, removeButtonClassName)}>
                            <Close size="medium" />
                        </Button>
                    )}
                </>
            )}
        </AriaChip>
    );
};

export { Chip };
export type { ChipProps };
