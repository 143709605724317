
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { TextTruncatorExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'TextTruncator',
  cols: 1,
  iconSrc: './text-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`TextTruncator`}</h2>
    <p>{`A Typography element that truncates text with an elipses (…) followed by a 'Expand' button.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`When you have too much text to show. For example, TextTruncator is used in the PaperRow component to shorten the paper's abstract.`}</p>
    <h3>{`Example`}</h3>
    <Examples cols={frontMatter.cols} examples={TextTruncatorExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The text to truncate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`charLimit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The max amount of characters to show. Default is 200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`showMore`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Button text. Default is 'Expand'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`showLess`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Button text. Default is 'Collapse'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`expandedByDefault`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Show text expanded by default`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`...typographyProps`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`TypographyProps?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://mui.com/material-ui/api/typography/"
            }}>{`Extends TypographyProps`}</a></td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;