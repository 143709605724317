import { PropsWithChildren, ReactNode } from 'react';
import { Cell as AriaCell, CellProps as AriaCellProps } from 'react-aria-components';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';

type CellProps = {
    textValue?: string;
    children?: ReactNode;
    className?: string;
} & AriaCellProps;

const Cell = ({ textValue, children, className, ...rest }: PropsWithChildren<CellProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaCell
            {...localProps}
            textValue={textValue}
            className={cx(recipeClassNames.cell, className)}>
            {children}
        </AriaCell>
    );
};

export { Cell };
export type { CellProps };
