import React from 'react';

interface LegalProviderProps extends React.PropsWithChildren {
    localStorageKey: string;
}

interface LegalContextType {
    hasAgreed: boolean | undefined;
    setAgreed: (_: boolean) => void;
}

const LegalContext = React.createContext<LegalContextType | null>(null);

const LegalProvider = ({ localStorageKey, children }: LegalProviderProps) => {
    // we use undefined here to deal with unknown state -- before useEffect runs we aren't sure
    // so we track the third state of unknown.
    const [hasAgreed, setHasAgreed] = React.useState<LegalContextType['hasAgreed']>(undefined);

    React.useEffect(() => {
        const item = window.localStorage.getItem(localStorageKey);
        let hasAgreedValue = false;

        if (item) {
            hasAgreedValue = (JSON.parse(item) || false) as boolean;
        }

        setHasAgreed(hasAgreedValue);
    }, [setHasAgreed, localStorageKey]);

    const setAgreedState = (newHasAgreed: boolean) => {
        window.localStorage.setItem(localStorageKey, JSON.stringify(newHasAgreed));
        setHasAgreed(newHasAgreed);
    };

    return (
        <LegalContext.Provider value={{ hasAgreed, setAgreed: setAgreedState }}>
            {children}
        </LegalContext.Provider>
    );
};

const useLegalTerms = () => {
    const context = React.useContext(LegalContext);
    if (!context) {
        throw new Error('`useLegalTerms()` must be used inside a `LegalProvider`');
    }
    return context;
};

export { LegalProvider, useLegalTerms };
