import { createContext, useContext, useState, ReactNode, useCallback } from 'react';

import { SnackbarProps } from './Snackbar';

type AnchorOrigin =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
interface SnackbarNotification extends SnackbarProps {
    id: string;
}

interface SnackbarNotificationContextProps {
    snackbarNotifications: { [key in AnchorOrigin]: SnackbarNotification[] };
    addSnackbarNotification: (
        anchorOrigin: AnchorOrigin,
        snackbarNotification: Omit<SnackbarNotification, 'id'>
    ) => void;
    removeSnackbarNotification: (anchorOrigin: AnchorOrigin, id: string) => void;
    clearAllSnackbarNotification: (anchorOrigin: AnchorOrigin) => void;
}

const SnackbarNotificationContext = createContext<SnackbarNotificationContextProps | undefined>(
    undefined
);

const SnackbarNotificationProvider = ({
    children,
    maxStack = 4, // Default max stack limit
}: {
    children: ReactNode;
    maxStack?: number;
}) => {
    const [snackbarNotifications, setSnackbarNotifications] = useState<{
        [key in AnchorOrigin]: SnackbarNotification[];
    }>({
        topLeft: [],
        topCenter: [],
        topRight: [],
        bottomLeft: [],
        bottomCenter: [],
        bottomRight: [],
    });

    const removeSnackbarNotification = useCallback((anchorOrigin: AnchorOrigin, id: string) => {
        setSnackbarNotifications((prev) => ({
            ...prev,
            [anchorOrigin]: prev[anchorOrigin].filter((notification) => notification.id !== id),
        }));
    }, []);

    const addSnackbarNotification = useCallback(
        (anchorOrigin: AnchorOrigin, snackbarNotification: Omit<SnackbarNotification, 'id'>) => {
            const id = crypto.randomUUID();
            const newSnackbarNotification = { id, ...snackbarNotification };

            setSnackbarNotifications((prev) => {
                const updatedSnackbarNotifications = [
                    ...prev[anchorOrigin],
                    newSnackbarNotification,
                ];
                return {
                    ...prev,
                    [anchorOrigin]:
                        updatedSnackbarNotifications.length > maxStack
                            ? updatedSnackbarNotifications.slice(1)
                            : updatedSnackbarNotifications,
                };
            });

            if (snackbarNotification.autoHideDuration !== undefined) {
                setTimeout(() => {
                    removeSnackbarNotification(anchorOrigin, id);
                }, snackbarNotification.autoHideDuration);
            }
        },
        [maxStack, removeSnackbarNotification]
    );

    const clearAllSnackbarNotification = useCallback(() => {
        setSnackbarNotifications({
            topLeft: [],
            topCenter: [],
            topRight: [],
            bottomLeft: [],
            bottomCenter: [],
            bottomRight: [],
        });
    }, []);

    return (
        <SnackbarNotificationContext.Provider
            value={{
                snackbarNotifications,
                addSnackbarNotification,
                removeSnackbarNotification,
                clearAllSnackbarNotification,
            }}>
            {children}
        </SnackbarNotificationContext.Provider>
    );
};

const useSnackbarNotification = () => {
    const context = useContext(SnackbarNotificationContext);
    if (context === undefined)
        throw new Error(
            'useSnackbarNotification must be used within a SnackbarNotificationContext'
        );
    return context;
};

export { SnackbarNotificationProvider, useSnackbarNotification };
export { AnchorOrigin };
