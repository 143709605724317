'use client'; // for server side rendering

import { styled } from '@mui/material';

export const AppTagline = styled('h4')`
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ theme }) => theme.font.size['2xl']};
    color: ${({ theme }) => theme.color.N9.hex};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        display: none;
    }
`;
