
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Composing'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can compose a Modal with its parts, this allows controlling each part individually.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Open composed modal</varnishUi.Button>
            <varnishUi.ModalBase isDismissable color="reversed">
                <varnishUi.ModalHeading closeIcon={true}>
                    <strong>Title:</strong>
                    Composed modal
                </varnishUi.ModalHeading>
                <varnishUi.ModalContent>
                    <p>Modal Contents</p>
                    <p>Another Link</p>
                </varnishUi.ModalContent>
                <varnishUi.ModalActions>
                    <varnishUi.Button>Useless Button</varnishUi.Button>
                    <varnishUi.ModalCloseButton>OK</varnishUi.ModalCloseButton>
                </varnishUi.ModalActions>
            </varnishUi.ModalBase>
        </varnishUi.ModalTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;