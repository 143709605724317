
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Colors'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Chip component by default uses light/dark styles, but can override using the color prop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const defaultItems = [
    { id: '1', text: 'Mario' },
    { id: '2', text: 'Luigi' },
    { id: '3', text: 'Bowser' },
    { id: '4', text: 'Yoshi' },
];

const Demo = () => {
    const [items, setItems] = React.useState(defaultItems);

    return (
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.ChipGroup
                onRemove={() => {}}
                color="primary"
                items={[{ id: '1', text: 'primary' }]}
            />
            <varnishUi.ChipGroup
                onRemove={() => {}}
                color="secondary"
                items={[{ id: '1', text: 'secondary' }]}
            />
            <varnishUi.ChipGroup
                onRemove={() => {}}
                color="tertiary"
                items={[{ id: '1', text: 'tertiary' }]}
            />
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;