import * as Default from './demo/default.mdx';
import * as Text from './demo/text.mdx';
import * as Outlined from './demo/outlined.mdx';
import * as Contained from './demo/contained.mdx';
import * as Color from './demo/color.mdx';
import * as Sizes from './demo/sizes.mdx';
import * as Pending from './demo/pending.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';
import * as Icon from './demo/icon.mdx';

export const ButtonExamples = [
    Default,
    Text,
    Outlined,
    Contained,
    Color,
    Sizes,
    Pending,
    StylingCss,
    StylingStyled,
    Icon,
];
