
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'How to use'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The best way to render Chips is to pass an array of chip data to the ChipGroup component.
This chip data has an exported type of ChipItem, and can be used to render text, icons, links, and disabled Chips.
More info on this ChipItem type below`}</p>
    <p>{`You can also pass Chips to ChipGroup as children, but you will miss out on some React-Aria functionality like remove and select.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';
import Theme from \`@allenai/varnish-theme\`;

const SparkIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.4 6.24402H3.2V3.2H5.77607C6.12001 3.2 6.4 2.92001 6.4 2.57607V0H9.44402V3.2C9.44402 4.8815 8.0815 6.24402 6.4 6.24402ZM3.2 6.55598H0V9.6H2.57607C2.92001 9.6 3.2 9.87999 3.2 10.2239V12.8H6.24402V9.6C6.24402 7.9185 4.8815 6.55598 3.2 6.55598ZM13.4239 6.4C13.08 6.4 12.8 6.12001 12.8 5.77607V3.2H9.75598V6.4C9.75598 8.0815 11.1185 9.44402 12.8 9.44402H16V6.4H13.4239ZM6.55598 12.8V16H9.6V13.4239C9.6 13.08 9.87999 12.8 10.2239 12.8H12.8V9.75598H9.6C7.9185 9.75598 6.55598 11.1185 6.55598 12.8Z"
            fill={Theme.color["pink-100"].value}
        />
    </svg>
);

const defaultItems = [
    { id: '1', text: 'Regular' },
    { id: '2', text: 'Icon', icon: <SparkIcon /> },
    { id: '3', text: 'Disabled', isDisabled: true },
    { id: '4', text: 'Link', href: "https://en.wikipedia.org/wiki/Toad_(Mario)" },
];

const Demo = () => {
    const [items, setItems] = React.useState(defaultItems);

    return (
        <varnishUi.Stack spacing={16}>
            <varnishUi.Stack direction="column" spacing={16}>
                <varnishUi.ChipGroup items={items} />
            </varnishUi.Stack>
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;