'use client'; // for server side rendering

import React from 'react';
import { Snackbar, IconButton } from '@mui/material';

interface Props {
    text?: string;
    autoHideDuration?: number;
    buttonContent?: React.ReactNode; // we recommend '@mui/icons-material/ContentCopy'
    children: NonNullable<React.ReactNode>;
}
export function CopyToClipboardButton({ text, autoHideDuration, buttonContent, children }: Props) {
    const [open, setOpen] = React.useState(false);
    const value = text || JSON.stringify(children);
    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(value);
    };

    return (
        <React.Fragment>
            {children}
            <IconButton color="primary" size="small" onClick={handleClick}>
                {buttonContent || 'Copy'}
            </IconButton>
            <Snackbar
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                autoHideDuration={autoHideDuration || 2500}
                message={`Copied '${value}' to clipboard`}
            />
        </React.Fragment>
    );
}

export default CopyToClipboardButton;
