
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic Usage'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const MyPopover = () => {
    return (
        <varnishUi.Popover heading="My Heading">
            <p>My popover content!</p>
        </varnishUi.Popover>
    );
};

render(
    <varnishUi.Stack direction="row" spacing="16">
        <varnishUi.PopoverTrigger>
            <varnishUi.Button>Open inline popover</varnishUi.Button>
            <varnishUi.Popover heading="heading">
                <p>
                    Popover content to <strong>display</strong>
                </p>
            </varnishUi.Popover>
        </varnishUi.PopoverTrigger>
        <varnishUi.PopoverTrigger content={<MyPopover />}>
            <varnishUi.Button>Open referenced popover</varnishUi.Button>
        </varnishUi.PopoverTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;