import React from 'react';

import {
    Button as AriaButton,
    type ButtonProps as AriaButtonProps,
    Heading as AriaHeading,
    type HeadingProps as AriaHeadingProps,
    type PressEvent,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { alertRecipe, type AlertRecipeProps, type AlertSeverity } from './alert.styles';
import * as Icons from '@/components/icons';

interface AlertProps extends React.PropsWithChildren, Exclude<AlertRecipeProps, undefined> {
    icon?: React.ReactNode;
    title?: React.ReactNode; // string?
    action?: React.ReactElement;
    onClose?: (_: PressEvent) => void;
    closeText?: string;
    // classNames
    className?: string;
    titleClassName?: string;
    messageClassName?: string;
    iconClassName?: string;
    actionClassName?: string;
}

const sevrityIconMap: Record<AlertSeverity, React.ElementType> = {
    info: Icons.Check,
    success: Icons.Check,
    warning: Icons.Close,
    error: Icons.Close,
};

const Alert = ({
    icon,
    action,
    title,
    children,
    onClose,
    closeText = 'Close',
    className,
    titleClassName,
    messageClassName,
    iconClassName,
    actionClassName,
    ...rest
}: AlertProps) => {
    const [variantProps, localProps] = alertRecipe.splitVariantProps(rest);
    const alertClassNames = alertRecipe(variantProps);
    const { severity = 'info' } = variantProps;

    const actionComponent =
        action || (onClose ? <DefaultCloseAction onClose={onClose} closeText={closeText} /> : null);

    return (
        <div className={cx(alertClassNames.root, className)} {...localProps}>
            {icon !== false ? (
                <div className={cx(alertClassNames.icon, iconClassName)} {...localProps}>
                    {icon || <DefaultAlertIcon severity={severity} />}
                </div>
            ) : null}
            <div className={cx(alertClassNames.message, messageClassName)}>
                {title ? <AlertTitle className={titleClassName}>{title}</AlertTitle> : null}
                {children}
            </div>
            {actionComponent ? (
                <div className={cx(alertClassNames.actions, actionClassName)} {...localProps}>
                    {actionComponent}
                </div>
            ) : null}
        </div>
    );
};

const AlertTitle = ({
    level,
    className,
    children,
}: Pick<AriaHeadingProps, 'level' | 'className' | 'children'>) => {
    const alertClassNames = alertRecipe();

    return (
        <AriaHeading level={level} className={cx(alertClassNames.title, className)}>
            {children}
        </AriaHeading>
    );
};

const AlertCloseButton = ({
    children,
    className,
    ...props
}: AriaButtonProps & { className?: string }) => {
    const alertClassNames = alertRecipe();
    return (
        <AriaButton {...props} className={cx(className, alertClassNames.closeButton)}>
            {children}
        </AriaButton>
    );
};

const DefaultCloseAction = ({ onClose, closeText }: Pick<AlertProps, 'onClose' | 'closeText'>) => {
    return (
        <AlertCloseButton onPress={onClose} aria-label={closeText}>
            <Icons.Close />
        </AlertCloseButton>
    );
};

const DefaultAlertIcon = ({ severity }: { severity: AlertSeverity }) => {
    const Icon = sevrityIconMap[severity];
    return <Icon />;
};

export { Alert, Alert as default, AlertTitle, AlertCloseButton };
export type { AlertProps };
