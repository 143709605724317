
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import {
    themeLight,
    barDefaults,
    barDefaultsDark,
    lineDefaults,
    lineDefaultsDark,
    scatterPlotDefaults,
    scatterPlotDefaultsDark,
    pieDefaults,
    pieDefaultsDark,
    heatMapDefaults,
    heatMapDefaultsDark,
    bulletDefaults,
    bulletDefaultsDark,
    themeDark,
    patterns,
} from '@allenai/varnish-nivo';
import { NivoExamples } from '.';
export const frontMatter = {
  lib: 'varnish-nivo',
  type: 'Overview',
  title: 'Getting Started',
  cols: 2,
  iconSrc: './color-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const NivoThemeValues = makeShortcode("NivoThemeValues");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Nivo`}</h2>
    <p>{`Varnish recommends using `}<a parentName="p" {...{
        "href": "https://nivo.rocks/components"
      }}>{`Nivo`}</a>{` for charting.`}</p>
    <p>{`To add Nivo run: `}<inlineCode parentName="p">{`yarn add @nivo/core @allenai/varnish-nivo`}</inlineCode>{` and any specific charting dependencies, e.g. `}<inlineCode parentName="p">{`yarn add @nivo/line`}</inlineCode>{`.`}</p>
    <p>{`Then use Nivo components as described in their docs WITH the Varnish Nivo Defaults:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { ResponsiveLine } from '@nivo/line';
import { themeLight } from '@allenai/varnish-nivo';

<ResponsiveLine {...lineDefaults} data={data} ... />
`}</code></pre>
    <p>{`Or specify just the defaults for a specific componet:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { ResponsiveLine } from '@nivo/line';
import { themeLight } from '@allenai/varnish-nivo'

<ResponsiveLine {...lineDefaults} data={data} ... />
`}</code></pre>
    <p>{`If a chart sits on a dark background, use the Dark Theme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { ResponsiveLine } from '@nivo/line';
import { themeDark } from '@allenai/varnish-nivo'

<ResponsiveLine {...lineDefaultsDark} data={data} ... />
`}</code></pre>
    <h3>{`Examples`}</h3>
    <p>{`These are only a few of the many many components available from
`}<a parentName="p" {...{
        "href": "https://nivo.rocks/components"
      }}>{`Nivo`}</a>{`. Please see the full documentation for complete examples.`}</p>
    <Examples cols={frontMatter.cols} examples={NivoExamples} mdxType="Examples" />
    <h3>{`Theme Values`}</h3>
    <NivoThemeValues map={themeLight} keyPrefix="themeLight" mdxType="NivoThemeValues" />
    <h4>{`Bar Defaults`}</h4>
    <NivoThemeValues map={barDefaults} keyPrefix="barDefaults" mdxType="NivoThemeValues" />
    <h4>{`Line Defaults`}</h4>
    <NivoThemeValues map={lineDefaults} keyPrefix="lineDefaults" mdxType="NivoThemeValues" />
    <h4>{`ScatterPlot Defaults`}</h4>
    <NivoThemeValues map={scatterPlotDefaults} keyPrefix="scatterPlotDefaults" mdxType="NivoThemeValues" />
    <h4>{`Pie Defaults`}</h4>
    <NivoThemeValues map={pieDefaults} keyPrefix="pieDefaults" mdxType="NivoThemeValues" />
    <h4>{`Heatmap Defaults`}</h4>
    <NivoThemeValues map={heatMapDefaults} keyPrefix="heatMapDefaults" mdxType="NivoThemeValues" />
    <h4>{`Bullet Defaults`}</h4>
    <NivoThemeValues map={bulletDefaults} keyPrefix="bulletDefaults" mdxType="NivoThemeValues" />
    <h3>{`Dark Theme Values`}</h3>
    <NivoThemeValues map={themeDark} keyPrefix="themeDark" mdxType="NivoThemeValues" />
    <h4>{`Dark Bar Defaults`}</h4>
    <NivoThemeValues map={barDefaultsDark} keyPrefix="barDefaultsDark" mdxType="NivoThemeValues" />
    <h4>{`Dark Line Defaults`}</h4>
    <NivoThemeValues map={lineDefaultsDark} keyPrefix="lineDefaultsDark" mdxType="NivoThemeValues" />
    <h4>{`Dark ScatterPlot Defaults`}</h4>
    <NivoThemeValues map={scatterPlotDefaultsDark} keyPrefix="scatterPlotDefaultsDark" mdxType="NivoThemeValues" />
    <h4>{`Dark Pie Defaults`}</h4>
    <NivoThemeValues map={pieDefaultsDark} keyPrefix="pieDefaultsDark" mdxType="NivoThemeValues" />
    <h4>{`Dark Heatmap Defaults`}</h4>
    <NivoThemeValues map={heatMapDefaultsDark} keyPrefix="heatMapDefaultsDark" mdxType="NivoThemeValues" />
    <h4>{`Dark Bullet Defaults`}</h4>
    <NivoThemeValues map={bulletDefaultsDark} keyPrefix="bulletDefaultsDark" mdxType="NivoThemeValues" />
    <h3>{`Patterns`}</h3>
    <NivoThemeValues map={patterns} keyPrefix="patterns" mdxType="NivoThemeValues" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;