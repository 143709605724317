
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Underline'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To control the underline of link, use the `}<inlineCode parentName="p">{`underline`}</inlineCode>{` prop. The default is `}<inlineCode parentName="p">{`underline="always"`}</inlineCode>{` which provides an underline with some opacity for the default state, and a full opacity and color underline for the hover state.`}</p>
    <p>{`Note: `}<inlineCode parentName="p">{`underline="none"`}</inlineCode>{` is provided as a base to allow greater customization, if you are linking text, please either use `}<inlineCode parentName="p">{`underline="always"`}</inlineCode>{` or provide some indication that the text is a link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.Link href="#" color="primary">
            Default (underline)
        </varnishUi.Link>
        <varnishUi.Link href="#" color="primary" underline="always">
            Underline
        </varnishUi.Link>
        <varnishUi.Link href="#" color="primary" underline="none">
            None
        </varnishUi.Link>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;