import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 *  Checkboxes can be built with the <input> HTML element, but this can be difficult to style.
 *   Checkbox helps achieve accessible checkboxes that can be styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Checkbox.html
 */

/**
 * @remarks A checkbox recipe
 *
 * @param size - Size of the checkbox, values are `small`, `medium` (default), and `large`
 * @param color - Color of the checkbox, values are:
 *   `primary` (default), `secondary`, `tertiary`, `teal`, and `cream`
 */

const checkboxRecipe = sva({
    slots: ['root', 'box', 'icon'],
    base: {
        root: {
            display: 'flex',
            gap: '[8px]',
            alignItems: 'center',
            cursor: 'pointer',
            color: 'text.primary',
            _disabled: {
                cursor: 'not-allowed',
            },
            _componentReadOnly: {
                cursor: 'default',
            },
        },
        box: {
            boxSizing: 'border-box',
            width: '[20px]',
            height: '[20px]',
            flexShrink: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '[1px solid]',
            borderColor: 'teal.80',
            borderRadius: 'xs',
            backgroundColor: '[white]',
            _groupHover: {
                borderColor: 'teal.100',
            },
            _groupSelected: {
                backgroundColor: '[currentColor]',
                // this overrides other states (namely hover)
                '&': {
                    borderColor: '[transparent]',
                },
            },
            _groupDisabled: {
                color: 'gray.40',
                // this overrides other states (namely hover)
                '&': {
                    borderColor: '[currentColor]',
                },
            },
            _groupInvalid: {
                color: 'alert.error',
                borderColor: '[currentColor]',
            },
        },
        icon: {
            width: '[0.625rem]',
            height: '[auto]',
            strokeWidth: '[4px]',
            stroke: '[currentColor]',
            color: '[transparent]',
            _groupSelected: {
                color: 'cream.100',
            },
            _groupDisabled: {
                color: 'cream.100',
            },
            _groupInvalid: {
                color: 'cream.100',
            },
        },
    },
    variants: {
        size: {
            small: {
                root: {
                    fontSize: '[12px]',
                },
                box: {
                    width: '[0.75rem]',
                    height: '[0.75rem]',
                },
            },
            medium: {
                root: {
                    fontSize: 'sm',
                },
                box: {
                    width: '[0.875rem]',
                    height: '[0.875rem]',
                },
            },
            large: {
                root: {
                    fontSize: 'md',
                },
                box: {
                    width: '[1.25rem]',
                    height: '[1.25rem]',
                },
                icon: {
                    width: '[0.75rem]',
                },
            },
        },
        color: {
            primary: {
                box: {
                    color: 'accent.primary',
                },
            },
            secondary: {
                box: {
                    color: 'accent.secondary',
                },
                icon: {
                    _groupSelected: {
                        color: 'dark-teal.100',
                    },
                },
            },
            tertiary: {
                box: {
                    color: 'accent.tertiary',
                },
            },
            teal: {
                box: {
                    color: 'teal.100',
                },
            },
            cream: {
                box: {
                    color: 'cream.100',
                    borderColor: '[transparent]',
                },
                icon: {
                    _groupSelected: {
                        color: 'teal.100',
                    },
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        color: 'primary',
    },
});

type CheckboxRecipeProps = RecipeVariantProps<typeof checkboxRecipe>;

export { checkboxRecipe, checkboxRecipe as default };
export type { CheckboxRecipeProps };
