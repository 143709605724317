import { merge } from 'lodash-es';

import type { ScatterPlotCommonProps, ScatterPlotDatum } from '@nivo/scatterplot';

import { themeDark, themeLight } from '../nivo-theme';
import { DarkColorHexes, LightColorHexes, UseMesh } from './global';

export const scatterPlotDefaults: ScatterPlotCommonProps<ScatterPlotDatum> = {
    theme: themeLight,
    nodeSize: 12,
    useMesh: UseMesh,
    // @ts-expect-error this is an SVG prop. Using the svg type is a pain so I'm just ignoring this
    blendMode: 'multiply',
    colors: LightColorHexes,
};

export const scatterPlotDefaultsDark = merge({}, scatterPlotDefaults, {
    theme: themeDark,
    // @ts-expect-error this is an SVG prop. Using the svg type is a pain so I'm just ignoring this
    blendMode: 'normal',
    colors: DarkColorHexes,
} satisfies typeof scatterPlotDefaults);
