
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Controlled'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`In addition to using a trigger to controla a popover, popover can also be controlled by programatically (with state),
when controlling the popover state, you need to give the Popover a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` to position to.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

const ControlledOpenDemo = ({ children }) => {
    const [isOpen, setOpen] = React.useState(false);
    // this is a ref to the item to position relative to
    let positionRef = React.useRef(null);

    const handlePress = () => {
        setOpen(!isOpen);
    };

    return (
        <varnishUi.Stack direction="row" spacing="16">
            <varnishUi.Button onPress={handlePress}>Controlled state [open]</varnishUi.Button>
            <span ref={positionRef}>
                <varnishUi.Label>Text to position popover relative to</varnishUi.Label>
            </span>
            <varnishUi.Popover
                heading="heading"
                isOpen={isOpen}
                onOpenChange={setOpen}
                triggerRef={positionRef}>
                <p>Content</p>
            </varnishUi.Popover>
        </varnishUi.Stack>
    );
};

render(<ControlledOpenDemo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;