import varnishTokens from '@allenai/varnish-theme/simple-hex-unitless';

const { palette, color } = varnishTokens;

// these are used by more than 1 component
export const globalDefaults = {
    useMesh: true,
    lightColorHexes: Object.values(palette.light.charting.categorical.light),
    darkColorHexes: Object.values(palette.light.charting.categorical.light),
    lightValueLabelColor: color['dark-blue'],
    darkValueLabelColor: palette.light.text.primary.default,
};

export const UseMesh = true as const;
export const LightColorHexes = Object.values(palette.light.charting.categorical.light);
export const DarkColorHexes = Object.values(palette.light.charting.categorical.dark);
export const LightValueLabelColor = palette.light.accent.primary;
export const DarkValueLabelColor = palette.light.text.primary.default;
