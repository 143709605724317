import * as Default from './demo/default.mdx';
import * as Color from './demo/color.mdx';
import * as Size from './demo/size.mdx';
import * as Step from './demo/step.mdx';
import * as Orientation from './demo/orientation.mdx';
import * as Multiple from './demo/multiple.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';
import * as Composition from './demo/composition.mdx';
export const SliderExamples = [
    Default,
    Color,
    Size,
    Step,
    Orientation,
    Multiple,
    Composition,
    StylingCss,
    StylingStyled,
];
