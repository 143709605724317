import {
    Tooltip as AriaTooltip,
    TooltipTrigger,
    type TooltipTriggerComponentProps as TooltipTriggerProps,
    type TooltipProps as AriaTooltipProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { OverlayArrow, type OverlayArrowProps } from '@/components/shared/OverlayArrow';

import TooltipRecipeProps, { tooltipRecipe } from './tooltip.styles';

type TooltipProps = {
    content: React.ReactNode;
    className?: string;
    arrowClassName?: string;
} & Pick<TooltipTriggerProps, 'isDisabled' | 'isOpen' | 'onOpenChange' | 'delay'> &
    AriaTooltipProps &
    TooltipRecipeProps;

const Tooltip = ({
    content,
    isOpen,
    className,
    placement = 'top',
    offset = 10,
    delay = 150,
    arrowClassName,
    onOpenChange,
    children,
    ...rest
}: React.PropsWithChildren<TooltipProps>) => {
    const [variantProps, localProps] = tooltipRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = tooltipRecipe(variantProps);

    return (
        <TooltipTrigger
            isOpen={isOpen}
            isDisabled={localProps.isDisabled}
            delay={delay}
            onOpenChange={onOpenChange}>
            {children}
            <AriaTooltip
                {...localProps}
                placement={placement}
                offset={offset}
                className={cx(recipeClassNames.root, className)}>
                <TooltipOverlayArrow className={arrowClassName} />
                {content}
            </AriaTooltip>
        </TooltipTrigger>
    );
};

// make this the default

type TooltipOverlayArrowProps = OverlayArrowProps & TooltipRecipeProps;

const TooltipOverlayArrow = ({ className, ...rest }: TooltipOverlayArrowProps) => {
    const [variantProps, localProps] = tooltipRecipe.splitVariantProps(rest);
    const recipeClassNames = tooltipRecipe(variantProps);
    return <OverlayArrow className={cx(recipeClassNames.arrow, className)} {...localProps} />;
};

export { Tooltip, TooltipOverlayArrow };
export type { TooltipProps, TooltipOverlayArrowProps };
