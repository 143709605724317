import varnishTokens from '@allenai/varnish-theme/simple-hex-unitless';

const { color } = varnishTokens;

export const dots = {
    id: 'dots',
    type: 'patternDots',
    size: 2,
    padding: 2,
    stagger: false,
    background: color.transparent,
    color: 'inherit',
};

export const squares = {
    id: 'squares',
    type: 'patternSquares',
    size: 4,
    padding: 4,
    stagger: false,
    background: color.transparent,
    color: 'inherit',
};

export const dots2 = {
    id: 'dots2',
    type: 'patternDots',
    size: 4,
    padding: 1,
    stagger: true,
    background: color.transparent,
    color: 'inherit',
};

export const lines2 = {
    id: 'lines2',
    type: 'patternLines',
    rotation: -45,
    lineWidth: 6,
    spacing: 10,
    background: color.transparent,
    color: 'inherit',
};

export const squares2 = {
    id: 'squares2',
    type: 'patternSquares',
    size: 6,
    padding: 1,
    stagger: true,
    background: color.transparent,
    color: 'inherit',
};

export const solid = {
    id: 'solid',
    type: 'patternDots',
    size: 1,
    padding: 0,
    stagger: false,
    background: color.transparent,
    color: 'inherit',
};

export const gray = {
    id: 'gray',
    type: 'patternDots',
    size: 1,
    padding: 1,
    stagger: false,
    background: color.transparent,
    color: 'inherit',
};

export const lines = {
    id: 'lines',
    type: 'patternLines',
    spacing: 5,
    rotation: 45,
    lineWidth: 2,
    background: color.transparent,
    color: 'inherit',
};
