import { Link as AriaLink, LinkProps as AriaLinkProps } from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import linkRecipe, { LinkRecipeProps } from './link.styles';
import focusRing from '@/components/shared/focusRing.styles';

type LinkProps = {
    className?: string;
    isDisabled?: boolean;
    target?: string;
} & AriaLinkProps &
    LinkRecipeProps;

function Link({ className, children, ...rest }: LinkProps) {
    const [variantProps, localProps] = linkRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const linkRecipeClassNames = linkRecipe.raw(variantProps);

    // RAC converts Link to use a span if there is no `href` or `isDisabled`, this is fine
    // We could swap for a button if no `href` and `!isDisabled` (onClick/onPress only)
    return (
        <AriaLink {...localProps} className={cx(css(focusRing, linkRecipeClassNames), className)}>
            {children}
        </AriaLink>
    );
}

export { Link, Link as default };
export type { LinkProps };
