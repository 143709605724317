import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const dialogStyles = sva({
    slots: ['root', 'heading', 'actions'],
    base: {
        root: {
            outline: 'none',
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
        },
        heading: {
            fontSize: 'lg',
            fontWeight: 'medium',
            margin: '[0]',
        },
        actions: {
            display: 'flex',
            justifyContent: 'end',
            gap: '[16px]',
        },
    },
    variants: {},
});

type DialogRecipeProps = Exclude<RecipeVariantProps<typeof dialogStyles>, undefined>;

export { dialogStyles };
export type { DialogRecipeProps };
