
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'ResponsiveBar with LightTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of the Nivo ResponsiveBar component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { barDefaults } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsiveBar
            // add in varnish theme at top of all charts
            {...barDefaults}
            // add options specific to your chart
            // use varnish values when it makes sense
            keys={['val']}
            indexBy="id"
            margin={{
                left: 48,
                bottom: 48,
                right: 16,
                top: 32,
            }}
            axisLeft={{
                legend: 'Food',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            axisBottom={{
                legend: 'Country',
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            // add data
            data={[
                {
                    id: 'AD',
                    val: 23,
                },
                {
                    id: 'AE',
                    val: 80,
                },
                {
                    id: 'AF',
                    val: 99,
                },
                {
                    id: 'AG',
                    val: 82,
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;