import { lightCategoricalColor } from '@allenai/varnish2/theme';
import { dictionaryToArray } from '@allenai/varnish2/utils';

import { ChartingGrid, ColorValues, ColorGroupDict } from './ColorValues';

const lightCategoricalColorGroup: ColorGroupDict = {
    lightCategorical: dictionaryToArray(lightCategoricalColor),
};

export function LightCategoricalColorValues() {
    return (
        <ChartingGrid id="lightCategoricalColor">
            <ColorValues
                colorGroupDict={lightCategoricalColorGroup}
                themeProp="lightCategoricalColor"
            />
        </ChartingGrid>
    );
}
