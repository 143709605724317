import {
    Box,
    Chip,
    Divider,
    Drawer,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
    styled,
    useMediaQuery,
    useTheme,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import React from 'react';

import Typography from '@mui/material/Typography';

import { Content, Footer, Header } from '@allenai/varnish2/components';
import FilterAlt from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import packageJson from '@allenai/varnish2/package.json';

import { AppRoute, LibAppRouteGroup } from './AppRoute';
import { libComponentGroups } from './ComponentGroups';

import logoWithText from './varnishLogo.svg';

const ROUTES: AppRoute[] = [
    ...libComponentGroups.reduce((acc: AppRoute[], group: LibAppRouteGroup) => {
        return acc.concat(
            group.typeGroups.reduce((innerAcc: AppRoute[], item) => {
                return innerAcc.concat(item.routes);
            }, [])
        );
    }, []), // flatmap
];

const drawerWidth = 240;
const drawerClosedWidth = 75;

export const AppChrome = () => {
    const [search, setSearch] = React.useState('');
    const containerRef = React.useRef(null);

    const location = useLocation();
    const theme = useTheme();
    const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box ref={containerRef}>
            <Header bannerAlwaysVisible containerRef={containerRef}>
                <Header.Columns columns="auto 1fr">
                    <Header.Logo
                        href="/"
                        label={<Header.AppName>Varnish </Header.AppName>}
                        tagline={
                            <Header.AppTagline>
                                A beautiful finish for your demo ({packageJson.version})
                            </Header.AppTagline>
                        }>
                        <img src={logoWithText} height={'56px'} alt="Varnish" />
                    </Header.Logo>
                </Header.Columns>
            </Header>
            <MenuContentWrapper>
                <Drawer
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: greaterThanSm ? `${drawerWidth}px` : `${drawerClosedWidth}px`,
                            top: '115px', // this is hard-coded to prevent different headers feeding into/pulling from context
                            height: 'calc(100% - 115px)',
                            zIndex: (theme) => theme.zIndex.appBar - 1,
                        },
                    }}>
                    <div>
                        {greaterThanSm ? (
                            <TextField
                                sx={{ p: 2, pt: 0 }}
                                placeholder="Filter"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FilterAlt />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                        ) : null}
                        {libComponentGroups.map((libGroup) => (
                            <Accordion key={libGroup.label} defaultExpanded={libGroup.startOpen}>
                                <PrimarySummary
                                    expandIcon={<ContrastExpandMoreIcon />}
                                    id={libGroup.label}>
                                    {greaterThanSm ? (
                                        <React.Fragment>
                                            <div>{libGroup.icon}</div>
                                            <Typography
                                                key={libGroup.label}
                                                sx={{ ml: 1, fontWeight: 'bold' }}
                                                variant="body1">
                                                {libGroup.label}
                                            </Typography>
                                        </React.Fragment>
                                    ) : (
                                        <Tooltip title={libGroup.label}>
                                            <div>{libGroup.icon}</div>
                                        </Tooltip>
                                    )}
                                </PrimarySummary>
                                <PrimaryAccordionDetails>
                                    <List>
                                        {libGroup.typeGroups.map((typeGroup) => (
                                            <React.Fragment key={typeGroup.label}>
                                                {greaterThanSm ? (
                                                    <Typography
                                                        key={typeGroup.label}
                                                        sx={{ ml: 2 }}
                                                        variant="subtitle1">
                                                        {typeGroup.label}
                                                    </Typography>
                                                ) : (
                                                    <Divider key={typeGroup.label} />
                                                )}
                                                <List>
                                                    {typeGroup.routes
                                                        .filter(
                                                            (route) =>
                                                                !search.trim() ||
                                                                route.label
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        search.trim().toLowerCase()
                                                                    )
                                                        )
                                                        .map((route) => (
                                                            <IconMenuItem
                                                                key={route.label}
                                                                route={route}
                                                                menuCollapsed={!greaterThanSm}
                                                                selected={
                                                                    location.pathname === route.path
                                                                }
                                                            />
                                                        ))}
                                                </List>
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </PrimaryAccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </Drawer>
                <Content main>
                    <Box sx={{ maxWidth: theme.breakpoints.values.lg }}>
                        <Routes>
                            {ROUTES.map(({ path, component: Component, componentProps }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={<Component {...componentProps} />}
                                />
                            ))}
                            <Route
                                path="/*"
                                element={<Navigate to="/components/varnish/welcome" replace />}
                            />
                            <Route
                                path="/components/*"
                                element={<Navigate to="/components/varnish/welcome" replace />}
                            />
                            <Route
                                path="/components/varnish/*"
                                element={<Navigate to="/components/varnish/welcome" replace />}
                            />
                            <Route
                                path="/components/varnishtheme/*"
                                element={<Navigate to="/varnishtheme/getting_started" replace />}
                            />
                            <Route
                                path="/components/varnishui/*"
                                element={
                                    <Navigate to="/components/varnishui/getting_started" replace />
                                }
                            />
                            <Route
                                path="/components/varnishmui/*"
                                element={
                                    <Navigate to="/components/varnishmui/getting_started" replace />
                                }
                            />
                            <Route
                                path="/components/varnishnivo/*"
                                element={
                                    <Navigate
                                        to="/components/varnishnivo/getting_started"
                                        replace
                                    />
                                }
                            />
                            <Route
                                path="/components/eslintconfigvarnish/*"
                                element={
                                    <Navigate
                                        to="/components/eslintconfigvarnish/getting_started"
                                        replace
                                    />
                                }
                            />
                        </Routes>
                        <Footer />
                    </Box>
                </Content>
            </MenuContentWrapper>
        </Box>
    );
};

const PrimarySummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.reversed,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const ContrastExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: theme.palette.text.reversed,
}));

const PrimaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 0,
}));

const IconMenuItem = ({
    route,
    menuCollapsed,
    selected,
}: {
    route: AppRoute;
    menuCollapsed: boolean;
    selected: boolean;
}) => {
    const theme = useTheme();
    return (
        <Tooltip title={menuCollapsed ? route.label : null}>
            <ListItemButton
                selected={selected}
                disabled={route.disabled}
                href={route.path}
                sx={{
                    p: !menuCollapsed
                        ? `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(
                              0.5
                          )} ${theme.spacing(3)}`
                        : `${theme.spacing(2)} ${theme.spacing(3)}`,
                    justifyContent: !menuCollapsed ? 'initial' : 'center',
                }}>
                <ListItemIcon
                    sx={{
                        mr: !menuCollapsed ? 1 : 'auto',
                        justifyContent: 'center',
                        minWidth: 'unset',
                    }}>
                    {route.icon}
                </ListItemIcon>
                {!menuCollapsed ? (
                    <ListItemText
                        primary={
                            <>
                                {route.label}{' '}
                                {route.tag ? (
                                    <Chip
                                        size="small"
                                        color={route.tag.color}
                                        label={route.tag.label}
                                    />
                                ) : null}
                            </>
                        }
                    />
                ) : null}
            </ListItemButton>
        </Tooltip>
    );
};

// this is needed as kind of a hack, the drawer does not need it, but the content does not know about the drawer size change, so we do it here
const MenuContentWrapper = styled('div')`
    display: grid;
    grid-template-columns: ${() => `${drawerWidth}px 1fr`};
    ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: ${() => `${drawerClosedWidth}px 1fr`};
    }
`;
