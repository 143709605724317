'use client'; // for server side rendering

import { Grid, styled } from '@mui/material';
import React, { ComponentPropsWithRef } from 'react';

import { Content } from '../Content';
import { AI2Logo, LogoSize } from '../logos/AI2Logo';

export interface AI2BannerProps {
    children?: React.ReactNode;
    logoSize?: LogoSize;
    endSlot?: React.ReactNode; // a space on the right side of the banner for additional content
    grayscale?: boolean;
}

export const BannerLink = styled('a')`
    display: inline-block;
    padding: 5px 0 2px 0;
`;

const BannerContent = styled(Content)`
    padding-top: 0;
    padding-bottom: 0;
`;

interface StyledBannerProps extends ComponentPropsWithRef<'div'> {
    grayscale?: boolean;
}

const StyledBanner = styled(({ grayscale, ...rest }: StyledBannerProps) => <div {...rest} />)`
    background: ${({ theme, grayscale }) =>
        grayscale ? `black` : theme.palette.background.reversed};
    padding: ${({ theme }) => theme.spacing(0.5)} 0;
    line-height: 1;
`;

export const AI2Banner = React.forwardRef<HTMLDivElement, AI2BannerProps>(
    ({ children, logoSize, grayscale, endSlot, ...rest }, ref) => (
        <StyledBanner grayscale={grayscale} ref={ref} {...rest}>
            <BannerContent>
                {children || (
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <BannerLink href="https://allenai.org">
                                <AI2Logo color="white" size={logoSize || 'sm'} />
                            </BannerLink>
                        </Grid>
                        {!!endSlot && <Grid item>{endSlot}</Grid>}
                    </Grid>
                )}
            </BannerContent>
        </StyledBanner>
    )
);

AI2Banner.displayName = 'AI2Banner';
