
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Overview',
  title: 'Migrating',
  cols: 1,
  iconSrc: './spacing-14px.svg'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allenaivarnish2029--allenaivarnish20210"
        }}>{`varnish 2 v0.2.9 => varnish 2 v0.2.10`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allenaivarnish201x--allenaivarnish202x"
        }}>{`varnish 2 v0.1.x => varnish 2 v0.2.x`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allenaivarnish30x--allenaivarnish201x"
        }}>{`varnish 1 v3.0.x => varnish 2 v0.1.x`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allenaivarnish2xx--allenaivarnish3xx"
        }}>{`varnish v2.x => varnish v3.x`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`@allenai/varnish2(0.2.9) => @allenai/varnish2(0.2.10)`}</h2>
    <h3>{`color2 to color`}</h3>
    <p>{`Varnish is sun-setting `}<inlineCode parentName="p">{`color2`}</inlineCode>{` with 5 values per color to `}<inlineCode parentName="p">{`color`}</inlineCode>{` with 10 values per color.
`}<inlineCode parentName="p">{`color2`}</inlineCode>{` is still backward compatible, so your program should still work if you update Varnish.
To update to `}<inlineCode parentName="p">{`color`}</inlineCode>{`, you can generally multiply your color value by 2.
For more details, check out the `}<a parentName="p" {...{
        "href": "https://varnish.apps.allenai.org/components/varnishmui/colors"
      }}>{`color docs`}</a></p>
    <p>{`e.g. `}<inlineCode parentName="p">{`color2.B3`}</inlineCode>{` -> `}<inlineCode parentName="p">{`color.B6`}</inlineCode>{`.`}</p>
    <h2>{`@allenai/varnish2(0.1.x) => @allenai/varnish2(0.2.x)`}</h2>
    <h3>{`Dependencies`}</h3>
    <p>{`Now using React 18, Emotion 11, and MUI 5.`}</p>
    <h3>{`React Router changes`}</h3>
    <p>{`In order to use React Router with Single Page Applications, we have to configure the links in the MUI library to use React Router.
This is done by calling `}<inlineCode parentName="p">{`getRouterOverriddenTheme()`}</inlineCode>{` in your VarnishApp theme.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { VarnishApp } from '@allenai/varnish2/components';
import { getRouterOverriddenTheme } from '@allenai/varnish2/utils';
<VarnishApp theme={getRouterOverriddenTheme(RouterLink)}>
    <Header.AI2Banner />
</VarnishApp>;
`}</code></pre>
    <h3>{`Style system change`}</h3>
    <p>{`For better integration with server side rendering, Varnish has replaced Styled components with `}<inlineCode parentName="p">{`@mui/material/styles`}</inlineCode>{`.
You should convert to use the new style system, or wrap you app in a styled component theme provider.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { ThemeProvider } from '@mui/material';
import { Content, Footer, Header, VarnishApp } from '@allenai/varnish2/components';
import { getTheme } from '@allenai/varnish2/theme';

const theme = getTheme(); // if you want to use ReactRouter, pass in getRouterOverriddenTheme(Link)

<ThemeProvider theme={theme}>
    <VarnishApp>
        <Header>{/* see: varnish.allenai.org/components/varnishmui/ai2_header/ */}</Header>
        <Content main>Hello World</Content>
        <Footer />
    </VarnishApp>
</ThemeProvider>;
`}</code></pre>
    <hr></hr>
    <h2>{`@allenai/varnish(3.0.x) => @allenai/varnish2(0.1.x)`}</h2>
    <p>{`There are several Varnish1 concepts that we have not mapped exactly as Varnish components in Varnish2, as there are different ways of doing them.
This guide will help you move from the old "Varnish1" of doing something, such as breakpoints or shadows, to the new "Varnish2" way of enabling that pattern.`}</p>
    <h3>{`Breakpoints`}</h3>
    <p>{`Now utilizing the Material UI System `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/customization/breakpoints/"
      }}>{`Breakpoints API`}</a>{`.`}</p>
    <h3>{`Link_CSS`}</h3>
    <p>{`Now you can use a MUI `}<inlineCode parentName="p">{`<Link>`}</inlineCode>{` component with whichever style variant you want applied directly to the link.`}</p>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { Link } from '@mui/material';
import muiStyles from '@mui/material/styles';

const Demo = () => {
    const theme = muiStyles.useTheme();
    return (
        <div style={{ width: '100%', backgroundColor: theme.color.B10.hex, padding: '10px' }}>
            <Link variant={'dark'} href="https://allenai.org">
                © The Allen Institute for Artificial Intelligence
            </Link>
        </div>
    );
};

render(<Demo />);
`}</code></pre>
    <h3>{`Shadows`}</h3>
    <p>{`Now utilizing the Material UI System `}<a parentName="p" {...{
        "href": "https://mui.com/system/shadows/"
      }}>{`Shadows API`}</a>{`.`}</p>
    <h3>{`Shape`}</h3>
    <p>{`Shape continues to be a variable in the Varnish `}<a parentName="p" {...{
        "href": "https://varnish.apps.allenai.org/components/varnishmui/theme/"
      }}>{`Theme`}</a>{`.`}</p>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled, css } from '@mui/material/styles';

const OuterDiv = styled('div')(
    ({ theme }) => css\`
        background-color: \${theme.color.B10.hex};
        width: 100%;
        height: 50px;
        padding: 10px;
    \`,
);

const InnerDiv = styled('div')(
    ({ theme }) => css\`
        background-color: white;
        width: 50%;
        border-radius: \${theme.shape.borderRadius}px;
        text-align: center;
    \`,
);

render(
    <div style={{ width: '100%' }}>
        <OuterDiv>
            <InnerDiv>Test</InnerDiv>
        </OuterDiv>
    </div>,
);
`}</code></pre>
    <h3>{`Spacing`}</h3>
    <p>{`Now utilizing the Material UI System `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/customization/spacing/"
      }}>{`Spacing API`}</a>{`.`}</p>
    <h3>{`Table Extensions`}</h3>
    <p>{`Now utilizing the Material UI System `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/react-table/"
      }}>{`Tables API`}</a>{`.`}</p>
    <p>{`Varnish does not provide a custom filtering API.
We encourage you to use various Material UI tools to work with MUI tables, such as the `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/api/table-sort-label/"
      }}>{`Table Sort Label API for column sorting`}</a>{`.`}</p>
    <h3>{`Text Styles`}</h3>
    <p>{`There is a typography variable in `}<a parentName="p" {...{
        "href": "https://varnish.apps.allenai.org/components/varnishmui/theme/"
      }}>{`theme`}</a>{` in Varnish to style text.`}</p>
    <p>{`More information about the Typography pattern in MUI can be found `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/customization/typography/"
      }}>{`here`}</a>{`.`}</p>
    <h4>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled, css } from '@mui/material/styles';

const OuterDiv = styled('div')(
    ({ theme }) => css\`
        width: 100%;
        height: 50px;
        border: 1px solid \${theme.palette.grey.A200};
    \`,
);

const StyledParagraph = styled('p')(
    ({ theme }) => css\`
        font-family: \${theme.typography.body1.fontFamily};
        font-size: \${theme.typography.body1.fontSize};
    \`,
);

render(
    <OuterDiv>
        <StyledParagraph>Test Paragraph Body 1 Size</StyledParagraph>
    </OuterDiv>,
);
`}</code></pre>
    <h3>{`Transitions`}</h3>
    <p>{`Now utilizing the Material UI System `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/customization/transitions/"
      }}>{`Transitions API`}</a>{`.`}</p>
    <h2>{`color to color2`}</h2>
    <p>{`AI2 colors have changed.
We are moving from `}<inlineCode parentName="p">{`color`}</inlineCode>{` with 10 values per color to `}<inlineCode parentName="p">{`color2`}</inlineCode>{` with 5 values per color.
In general, you should use `}<inlineCode parentName="p">{`color2`}</inlineCode>{`. Just divide your color value by 2 (round up).
e.g. `}<inlineCode parentName="p">{`color.B6`}</inlineCode>{` -> `}<inlineCode parentName="p">{`color2.B3`}</inlineCode>{`, `}<inlineCode parentName="p">{`color.R9`}</inlineCode>{` -> `}<inlineCode parentName="p">{`color2.R5`}</inlineCode>{`.`}</p>
    <h3>{`Nivo Charts`}</h3>
    <p>{`We still suggest using `}<a parentName="p" {...{
        "href": "https://nivo.rocks/"
      }}>{`Nivo Charts`}</a>{`.
However, there is currently no Varnish2 theme. So the colors may be off or need to be hand tuned.`}</p>
    <hr></hr>
    <h2>{`@allenai/varnish(2.x.x) => @allenai/varnish(3.x.x)`}</h2>
    <p>{`In version 3, we adjusted the package layout in a non-backwards compatible way. We now include both ESM and CommonJS compatible code, so that the consuming application can use either. The module defaults to using the CommonJS variation.`}</p>
    <ol>
      <li parentName="ol">
        <h3 parentName="li">{`Updating Imports`}</h3>
        <p parentName="li"><strong parentName="p">{`Change all imports of the form:`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ xxx }'} from '@allenai/varnish/x';
`}</code></pre>
        <p parentName="li">{`to:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ xxx }'} from '@allenai/varnish';
`}</code></pre>
        <p parentName="li">{`or, if you'd like to explicitly use a CommonJS module, do this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ xxx }'} from '@allenai/varnish/lib/x';
`}</code></pre>
        <p parentName="li">{`or, If you'd like to explicitly use the ESM variation, do this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ xxx }'} from '@allenai/varnish/es/x';
`}</code></pre>
        <p parentName="li"><strong parentName="p">{`Example:`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ VarnishApp }'} from '@allenai/varnish/components';
`}</code></pre>
        <p parentName="li">{`becomes:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ VarnishApp }'} from '@allenai/varnish';
`}</code></pre>
        <p parentName="li">{`or, if you'd like to explicitly use a CommonJS module, do this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ VarnishApp }'} from '@allenai/varnish/lib/components';
`}</code></pre>
        <p parentName="li">{`or, If you'd like to explicitly use the ESM variation, do this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import {'{ VarnishApp }'} from '@allenai/varnish/es/components';
`}</code></pre>
      </li>
      <li parentName="ol">
        <h3 parentName="li">{`CSS, LESS, and Shellac files have moved.`}</h3>
        <p parentName="li"><strong parentName="p">{`Change Varnish style imports:`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import '@allenai/varnish/theme/theme.css'; // or .less
`}</code></pre>
        <p parentName="li">{`becomes:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import '@allenai/varnish/theme.css'; // or .less
`}</code></pre>
        <p parentName="li">{`and/or:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import '@allenai/varnish/dist/theme.css'; // or .less
`}</code></pre>
        <p parentName="li">{`becomes:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import '@allenai/varnish/theme.css'; // or .less
`}</code></pre>
        <p parentName="li"><strong parentName="p">{`Change Shellac style imports:`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import '@allenai/varnish/dist/shellac.css'; // or .less
`}</code></pre>
        <p parentName="li">{`becomes:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import '@allenai/varnish/shellac/shellac.css'; // or .less
`}</code></pre>
        <p parentName="li">{`and/or:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`href = 'https://cdn.jsdelivr.net/npm/@allenai/varnish@2.0.22/dist/shellac.min.css';
`}</code></pre>
        <p parentName="li">{`becomes:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`href = 'https://cdn.jsdelivr.net/npm/@allenai/varnish@3.0.2/shellac/shellac.min.css';
`}</code></pre>
      </li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;