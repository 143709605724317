import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import type { DialogHeadingProps } from '@/components/dialog/DialogHeading';
import type { ModalProps } from '@/components/modal/Modal';
import { ModalCloseIconButton } from '@/components/modal/ModalActions';
import { DialogHeadingBase } from '@/components/dialog/DialogHeading';
import { modalStyles, type ModalRecipeProps } from '@/components/modal/modal.styles';
import { dialogStyles } from '@/components/dialog/dialog.styles';

type ModalHeadingProps = DialogHeadingProps &
    ModalRecipeProps &
    Pick<ModalProps, 'closeButton' | 'closeButtonClassName'>;

const ModalHeading = ({
    className,
    closeButton = true,
    closeButtonClassName,
    children,
    ...rest
}: ModalHeadingProps) => {
    const [variantProps, localProps] = modalStyles.splitVariantProps(rest);
    const modalVariantStyles = modalStyles.raw(variantProps);

    const [dialogVariantProps] = dialogStyles.splitVariantProps(rest);
    const dialogVariantStyles = dialogStyles.raw(dialogVariantProps);

    const closeButtonComponent =
        closeButton === true ? (
            <ModalCloseIconButton className={closeButtonClassName} {...variantProps} />
        ) : (
            closeButton
        );

    return (
        <DialogHeadingBase
            className={cx(css(dialogVariantStyles.heading, modalVariantStyles.heading), className)}
            {...localProps}>
            {children}
            {closeButtonComponent}
        </DialogHeadingBase>
    );
};

export type { ModalHeadingProps };
export { ModalHeading, ModalHeading as default };
