import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const footerRecipe = sva({
    slots: ['root', 'linksGroup', 'copyRight'],
    base: {
        root: {
            display: 'flex',
            width: '[100%]',
            padding: '[16px]',
        },
        linksGroup: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: '[16px]',
            lg: {
                gridTemplateColumns: '[repeat(4, 1fr)]',
                justifyContent: 'space-between',
                gap: '[16px]',
            },
            md: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '[16px]',
            },
            '& ul': {
                padding: '0',
                margin: '0',
                listStyleType: 'none',
            },
            '& a': {
                textDecoration: 'none',
            },
            '& label': {
                fontSize: '[20px]',
            },
        },
        copyRight: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: '[0px]',
            '& span': {
                marginRight: '[32px]',
            },
            '& ul': {
                display: 'flex',
                gap: '[32px]',
                padding: '0',
                '& li:first-child': {
                    listStyle: '[none]',
                },
            },
        },
    },
    variants: {
        variant: {
            full: {
                root: {
                    flexDirection: 'column',
                    gap: '[16px]',
                },
            },
            slimLeft: {
                root: {
                    justifyContent: 'flex-start',
                },
                copyRight: {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            slimCenter: {
                root: {
                    justifyContent: 'center',
                },
                copyRight: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
        },
        color: {
            default: {
                root: {
                    backgroundColor: 'extra-dark-teal.100',
                    color: 'cream.100',
                },
                linksGroup: {
                    '& label': {
                        color: '[inherit]',
                    },
                    '& a': {
                        color: 'green.100',
                    },
                },
                copyRight: {
                    '& li::marker': {
                        color: 'cream.10',
                    },
                    '& a': {
                        color: 'green.100',
                    },
                },
            },
            cream: {
                root: {
                    backgroundColor: 'cream.100',
                    color: 'dark-teal.100',
                },
                linksGroup: {
                    '& label': {
                        color: '[inherit]',
                    },
                    '& a': {
                        color: 'accent.primary',
                    },
                },
                copyRight: {
                    '& li::marker': {
                        color: 'extra-dark-teal.10',
                    },
                    '& a': {
                        color: 'accent.primary',
                    },
                },
            },
        },
    },
    defaultVariants: {
        color: 'default',
        variant: 'full',
    },
});

type FooterRecipeProps = RecipeVariantProps<typeof footerRecipe>;

export { footerRecipe, footerRecipe as default };
export type { FooterRecipeProps };
