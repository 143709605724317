import * as React from 'react';

import {
    ValidationResult,
    ButtonProps as AriaButtonProps,
    Button as AriaButton,
    FileTrigger as AriaFileTrigger,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { Upload } from '@/components/icons';

import fileDropperRecipe, { FileInputRecipeProps } from '@/components/fileInput/fileInput.styles';
import focusRing from '@/components/shared/focusRing.styles';

/**
 * FileInputButton allows user to click and browser files to upload.
 */

type FileInputButtonProps = {
    onSelect?: (files: FileList | null) => void;
    acceptedFileTypes?: string[];
    allowsMultiple?: boolean;
    icon?: React.ReactNode | false;
    buttonText?: string | React.ReactNode;
    className?: string;
    textClassName?: string;
    iconClassName?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    errorClassName?: string;
} & AriaButtonProps &
    FileInputRecipeProps;

const FileInputButton = ({
    onSelect,
    acceptedFileTypes,
    allowsMultiple = false,
    icon,
    buttonText,
    className,
    textClassName,
    iconClassName,
    children,
    ...rest
}: React.PropsWithChildren<FileInputButtonProps>) => {
    const [variantProps, localProps] = fileDropperRecipe.splitVariantProps(rest);
    const recipeClassNames = fileDropperRecipe(variantProps);

    return (
        <AriaFileTrigger
            {...localProps}
            allowsMultiple={allowsMultiple}
            acceptedFileTypes={acceptedFileTypes}
            onSelect={onSelect}>
            <AriaButton
                {...localProps}
                {...{ disabled: localProps.isDisabled }}
                className={cx('group', css(focusRing), recipeClassNames.button, className)}>
                <>
                    {icon ?? (
                        <Upload size="large" className={cx(recipeClassNames.icon, iconClassName)} />
                    )}
                    {buttonText ? (
                        <span className={cx(recipeClassNames.text, textClassName)}>
                            {buttonText}
                        </span>
                    ) : null}
                </>
            </AriaButton>
        </AriaFileTrigger>
    );
};

export { FileInputButton };
export type { FileInputButtonProps };
