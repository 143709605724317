import { PropsWithChildren } from 'react';
import {
    TabPanel as AriaTabPanel,
    TabPanelProps as AriaTabPanelProps,
} from 'react-aria-components';
import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import focusRing from '@/components/shared/focusRing.styles';

import TabsRecipeProps, { tabsRecipe } from './tabs.styles';

type TabPanelProps = {
    className?: string;
    children?: React.ReactNode;
} & AriaTabPanelProps &
    TabsRecipeProps;

const TabPanel = ({ className, children, ...rest }: PropsWithChildren<TabPanelProps>) => {
    const [variantProps, localProps] = tabsRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = tabsRecipe.raw(variantProps);

    return (
        <AriaTabPanel
            {...localProps}
            className={cx(css(focusRing, recipeClassNames.tabPanel), className)}>
            {children}
        </AriaTabPanel>
    );
};

export { TabPanel };
export type { TabPanelProps };
