import { Slider as AriaSlider, SliderProps as AriaSliderProps } from 'react-aria-components';

import { cx } from '@/utils/cx';

import sliderRecipe, { SliderRecipeProps } from '@/components/slider/slider.styles';

type SliderBaseProps<T> = {
    className?: string;
} & AriaSliderProps<T> &
    SliderRecipeProps;

const SliderBase = <T extends number | number[]>({
    className,
    children,
    ...rest
}: SliderBaseProps<T>) => {
    const [variantProps, localProps] = sliderRecipe.splitVariantProps(rest);
    const recipeClassNames = sliderRecipe(variantProps);
    // Slider needs variant prop for orientation also, so we pass it to the AriaSlider component
    const localPropsWithOrientation = { ...localProps, orientation: variantProps.orientation };
    return (
        <AriaSlider {...localPropsWithOrientation} className={cx(recipeClassNames.root, className)}>
            {children}
        </AriaSlider>
    );
};

export { SliderBase, SliderBase as default };
export type { SliderBaseProps };
