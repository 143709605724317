
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'How do I use a custom theme?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`First construct a JSON object and theme override for your theme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import ThemeMui from '@allenai/varnish-theme';
import { Color } from '@allenai/varnish2/theme';

// Name this whatever you want! We recommend something like \`<AppName>CustomColors\`
interface MyCustomColors {
    // We only need to include colors that aren't already in the theme!
    MyBlack: Color;
    MyOrange: Color;
}

// for more info on module augmentation in Typescript, see the TS docs: https://www.typescriptlang.org/docs/handbook/declaration-merging.html
declare module '@allenai/varnish2/theme' {
    interface VarnishColors extends MyCustomColors {}
}

export const myTheme = {
    // extend or override colors
    color: {
        MyBlack: new Color('MyBlack', '#333333', true),
        MyOrange: new Color('MyOrange', '#F4D35E', true),
        B6: new Color('B6', '#F4D3FF', true), // overriding an existing color in the theme
    },
    // styles individual components
    // see: https://mui.com/material-ui/customization/theme-components/
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }: any) => ({
                    '&.Mui-disabled': {
                        background: 'transparent',
                        color: ThemeMui.color.N3.value,
                    },
                }),
            },
        },
    },
};
`}</code></pre>
    <p>{`Then construct a merged theme using the `}<inlineCode parentName="p">{`getTheme`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { getTheme } from '@allenai/varnish2/theme';
import { Link } from 'react-router-dom'; // if needed

// if using react router:
const theme = getTheme(getRouterOverriddenTheme(Link, myTheme));

// if not:
const theme = getTheme(myTheme);
`}</code></pre>
    <p>{`Then pass that theme in the Varnish app (and styled components theme provided if needed)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { ThemeProvider } from '@mui/material'; // if needed
import { VarnishApp } from '@allenai/varnish2';

// if using styled components:
<ThemeProvider theme={theme}>
     <VarnishApp theme={theme}>
        {children}
    </VarnishApp>
</ThemeProvider>

// if not:
<VarnishApp theme={theme}>
    {children}
</VarnishApp>
`}</code></pre>
    <p>{`If you've passed in a custom color you'll be able to use it like any other color now!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`const DivWithCustomColor = styled('div')\`
  color: \${({theme}) => theme.color.MyBlack.toString()}
\`

const StackWithCustomColor = () => <Stack sx={{ color: (theme) => ThemeConsumer.ColorValues.MyBlack.toString()}}>
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;