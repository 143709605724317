
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { DrawerExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Drawer',
  cols: 2,
  iconSrc: './buttons-14px.svg',
  tag: {
    label: 'Alpha',
    color: 'secondary'
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Drawer`}</h2>
    <p>{`Navigation drawers provide quick access to other areas of an app without taking the user away from their current location.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={DrawerExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Content to render inside drawer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`backdrop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether to render the backdrop of the drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`header`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ReactNode?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Custom header to render inside drawer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether the drawer is open by default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional class names for the drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`placement`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Placement?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'left'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The placement of the drawer with respect to the body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`contentClassName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional class names for the content of the drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`headerClassName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional class names for the header of the drawer.`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;