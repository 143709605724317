import { Popover as AriaPopover, PopoverProps as AriaPopoverProps } from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { popoverStyles, type PopoverRecipeProps } from '@/components/popover/popover.styles';
import { tooltipRecipe } from '@/components/tooltip/tooltip.styles';
import {
    Dialog,
    DialogHeadingBase,
    type DialogHeadingProps,
    type DialogProps,
} from '@/components/dialog';
import { dialogStyles } from '@/components/dialog/dialog.styles';

import { OverlayArrow, type OverlayArrowProps } from '@/components/shared/OverlayArrow';

interface PopoverClassNames {
    className?: string;
    arrowClassName?: string;
    headingClassName?: string;
    contentClassName?: string;
}

type PopoverProps = Omit<AriaPopoverProps, 'className'> &
    Pick<DialogProps, 'heading'> &
    PopoverClassNames &
    PopoverRecipeProps &
    React.PropsWithChildren; // RAC allows children to be a render function, we can support this, but not now

// various classNames
const Popover = ({
    heading,
    className,
    arrowClassName,
    headingClassName,
    contentClassName,
    children,
    ...rest
}: PopoverProps) => {
    const [variantProps, localProps] = popoverStyles.splitVariantProps(rest);
    const popoverVariantStyles = popoverStyles.raw(variantProps);

    const [tooltipVariantProps] = tooltipRecipe.splitVariantProps(rest);
    const tooltipVariantStyles = tooltipRecipe.raw(tooltipVariantProps);

    const [dialogVariantProps] = dialogStyles.splitVariantProps(rest);

    const headingRender =
        typeof heading === 'string' ? (
            <PopoverHeading className={headingClassName} {...variantProps}>
                {heading}
            </PopoverHeading>
        ) : (
            heading
        );

    return (
        <AriaPopover
            className={cx(css(tooltipVariantStyles.root, popoverVariantStyles.root), className)}
            {...localProps}>
            <PopoverOverlayArrow className={arrowClassName} {...variantProps} />
            <Dialog heading={headingRender} className={contentClassName} {...dialogVariantProps}>
                {children}
            </Dialog>
        </AriaPopover>
    );
};

type PopoverHeadingProps = DialogHeadingProps & PopoverRecipeProps;

const PopoverHeading = ({ className, children, ...rest }: PopoverHeadingProps) => {
    const [variantProps, localProps] = popoverStyles.splitVariantProps(rest);
    const popoverVariantStyles = popoverStyles.raw(variantProps);

    const [dialogVariantProps] = dialogStyles.splitVariantProps(rest);
    const dialogVariantStyles = dialogStyles.raw(dialogVariantProps);

    return (
        <DialogHeadingBase
            className={cx(
                css(dialogVariantStyles.heading, popoverVariantStyles.heading),
                className
            )}
            {...localProps}>
            {children}
        </DialogHeadingBase>
    );
};

type PopoverOverlayArrowProps = OverlayArrowProps & PopoverRecipeProps;

const PopoverOverlayArrow = ({ className, ...rest }: PopoverOverlayArrowProps) => {
    const [variantProps, localProps] = popoverStyles.splitVariantProps(rest);
    const popoverVariantStyles = popoverStyles.raw(variantProps);

    const [tooltipVariantProps] = tooltipRecipe.splitVariantProps(rest);
    const tooltipVariantStyles = tooltipRecipe.raw(tooltipVariantProps);

    return (
        <OverlayArrow
            className={cx(css(tooltipVariantStyles.arrow, popoverVariantStyles.arrow), className)}
            {...localProps}
        />
    );
};

// For now, we will just re-export these
export {
    DialogTrigger as PopoverTrigger,
    type DialogTriggerProps as PopoverTriggerProps,
} from '@/components/dialog';

export { PopoverOverlayArrow, PopoverHeading, Popover, Popover as default };
export type { PopoverOverlayArrowProps, PopoverHeadingProps, PopoverProps };
