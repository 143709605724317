
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { MarkdownExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'Markdown',
  cols: 1,
  iconSrc: './text-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Markdown`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.markdownguide.org/getting-started/"
      }}>{`Markdown`}</a>{` is a markup language used for creating formatting in plain text. It is often used by blogs, forumns, and readme files. Here is Markdown `}<a parentName="p" {...{
        "href": "https://markdownlivepreview.com/"
      }}>{`sandbox`}</a>{` if you want to try it out. Also here is a `}<a parentName="p" {...{
        "href": "https://www.markdownguide.org/cheat-sheet/"
      }}>{`cheatsheet`}</a>{` of all Markdown syntax.`}</p>
    <p>{`For convenience, we created a Markdown component so Varnish users can create inline markdown.`}</p>
    <p><inlineCode parentName="p">{`<Markdown>{'## I am a Markdown example'}<Markdown/>`}</inlineCode></p>
    <h3>{`When To Use`}</h3>
    <p>{`It is best used when you need to write a lot of text for a bunch of different pages, blogs for example. It is also really useful for READMEs and Docs. Docs usually benefit from some text formatting, such as tables or blockquotes. This doc you are reading right now is made with markdown.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={MarkdownExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;