
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Code'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import varnishComponents from '@allenai/varnish2/components';
import mui from '@mui/material';

const Code = varnishComponents.Code;
const Box = mui.Box;
const Stack = mui.Stack;

render(
    <Stack spacing={5}>
        <div>
            Light
            <br />
            <Box sx={{ background: (theme) => theme.color.B10.hex, p: 5 }}>
                <Code>def get_metrics(self, reset: bool = False) -&gt; Dict[str, float]</Code>
            </Box>
        </div>
        <div>
            Dark
            <br />
            <Box sx={{ p: 5, border: 1 }}>
                <Code variant="dark">
                    def get_metrics(self, reset: bool = False) -&gt; Dict[str, float]
                </Code>
            </Box>
        </div>
    </Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;