
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'AI2 Banner'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This renders an AI2 banner on the top of your site. It's required for all AI2 sites. Link back to
the mothership, young skywalker.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

const Header = varnishComponents.Header;

render(<Header.AI2Banner />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;