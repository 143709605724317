import * as React from 'react';

import {
    ValidationResult,
    DropZone as AriaDropZone,
    DropZoneProps as AriaDropZoneProps,
    ButtonProps as AriaButtonProps,
    FileDropItem,
    DropItem,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import fileDropperRecipe, { FileInputRecipeProps } from '@/components/fileInput/fileInput.styles';
import { FileInputButton } from '@/components/fileInput/FileInputButton';

/**
 * FileInput is an area where a user can upload files via drag & drop.
 */

type FileInputProps = {
    onSelect?: (files: FileList | null) => void;
    acceptedFileTypes?: string[];
    allowsMultiple?: boolean;
    icon?: React.ReactNode;
    defaultText?: string | React.ReactNode;
    className?: string;
    buttonClassName?: string;
    textClassName?: string;
    iconClassName?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    errorClassName?: string;
} & AriaButtonProps &
    AriaDropZoneProps &
    FileInputRecipeProps;

const FileInput = ({
    onSelect,
    acceptedFileTypes,
    allowsMultiple = false,
    icon,
    defaultText,
    className,
    buttonClassName,
    textClassName,
    iconClassName,
    children,
    ...rest
}: React.PropsWithChildren<FileInputProps>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [files, setFiles] = React.useState<FileList | null>(null); // dont actually use this value yet, but its nice to have incase we do
    const [fileNames, setFileNames] = React.useState('');
    const [variantProps, localProps] = fileDropperRecipe.splitVariantProps(rest);
    const recipeClassNames = fileDropperRecipe(variantProps);

    return (
        <AriaDropZone
            {...localProps}
            className={cx(recipeClassNames.root, className)}
            // onDrop only gets called if user drags and drops a file
            onDrop={async (e) => {
                // make sure these are files. DropZone accepts other objects as well
                let myFiles = e.items.filter(
                    (item: DropItem): item is FileDropItem => item.kind === 'file'
                );

                // enforce acceptedFileTypes filter
                if (acceptedFileTypes) {
                    myFiles = myFiles.filter((file) =>
                        acceptedFileTypes.includes(String(file.type))
                    );
                }
                // enforce allowsMultiple
                if (!allowsMultiple && myFiles.length > 0) {
                    myFiles = myFiles.slice(0, 1);
                }

                // have to call getFile() to turn FileDropItem into files
                const resolvedFiles = await Promise.all(myFiles.map((f) => f.getFile()));
                const dataTransfer = new DataTransfer();
                resolvedFiles.forEach((file) => dataTransfer.items.add(file));

                setFiles(dataTransfer.files);
                setFileNames(resolvedFiles.map((file) => file.name).join(', '));

                if (onSelect) {
                    onSelect(dataTransfer.files);
                }
            }}>
            {children || (
                <FileInputButton
                    {...variantProps}
                    onSelect={onSelect}
                    buttonText={defaultText || fileNames || 'Drag & Drop or browse'}
                    icon={icon}
                    className={buttonClassName}
                    iconClassName={iconClassName}
                />
            )}
        </AriaDropZone>
    );
};

export { FileInput };
export type { FileInputProps };
