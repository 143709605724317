
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Color'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To customize slider colors, apply the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack spacing={2}>
        <varnishUi.Slider defaultValue={17} color="primary" label="Primary" />
        <varnishUi.Slider defaultValue={34} color="secondary" label="Secondary" />
        <varnishUi.Slider defaultValue={50} color="tertiary" label="Tertiary" />
        <varnishUi.Slider defaultValue={66} color="teal" label="Teal" />
        <varnishUi.Slider defaultValue={83} color="cream" label="Cream" />
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;