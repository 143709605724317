import { PropsWithChildren, ReactNode } from 'react';
import { Key, Row as AriaRow, RowProps as AriaRowProps } from 'react-aria-components';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';

type RowProps = {
    columns?: object[];
    children?: ReactNode;
    textValue?: string;
    isDisabled?: boolean;
    id?: Key;
    className?: string;
    href?: string;
} & AriaRowProps<object>;

const Row = ({
    columns,
    children,
    textValue,
    isDisabled,
    id,
    className,
    href,
    ...rest
}: PropsWithChildren<RowProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaRow
            {...localProps}
            id={id}
            columns={columns}
            textValue={textValue}
            isDisabled={isDisabled}
            className={cx(recipeClassNames.row, className)}
            href={href}>
            {children}
        </AriaRow>
    );
};

export { Row };
export type { RowProps };
