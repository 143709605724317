import { ColorKeys } from '@allenai/varnish-theme/varnish-tokens';
import { color } from '@allenai/varnish2/theme';

import varnishTokens from '@allenai/varnish-theme/panda-theme-beta';

import { ChartingGrid, ColorGroupDict, ColorValues } from './ColorValues';

type ColorKey = keyof typeof varnishTokens.tokens.colors;

const createColorShades = (colorName: string, indexes: string[]): ColorGroupDict => {
    const colorArray = indexes.map((num) => ({
        ...color[`${colorName}-${num}` as ColorKeys],
        displayName: `${colorName}-${num}`,
    }));
    return { [`${colorName} Shades`]: colorArray };
};

// Grab color shades from varnishPandaTheme color tokens
const colorsWithShades = (() => {
    const colorNames = Object.keys(varnishTokens.tokens.colors);
    return colorNames.map((name) => {
        const indexes = Object.keys(varnishTokens.tokens.colors[name as ColorKey]);
        return { name, shades: createColorShades(name, indexes) };
    });
})();

export function ColorShades() {
    return (
        <>
            {colorsWithShades.map(({ name, shades }) => (
                <ChartingGrid key={name}>
                    <ColorValues colorGroupDict={shades} themeProp={name} />
                </ChartingGrid>
            ))}
        </>
    );
}
