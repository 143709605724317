import { IconButton } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';

interface Props {
    url?: string;
    title?: string;
    summary?: string;
}

export function ShareOnLinkedInButton({ url, title, summary }: Props) {
    const myUrlWithParams = new URL('http://www.linkedin.com/shareArticle?mini=true');
    if (url) {
        myUrlWithParams.searchParams.append('url', url);
    }
    if (title) {
        myUrlWithParams.searchParams.append('title', title);
    }
    if (summary) {
        myUrlWithParams.searchParams.append('summary', summary);
    }

    return (
        <IconButton aria-label="LinkedIn Icon" color="primary" size="small">
            <a href={myUrlWithParams.href} target="_blank" rel="noopener noreferrer">
                <LinkedIn />
            </a>
        </IconButton>
    );
}

export default ShareOnLinkedInButton;
