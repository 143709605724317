
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Fields can be styled to override the default theme by passing new classNames (e.g., `}<inlineCode parentName="p">{`labelClassName`}</inlineCode>{` and `}<inlineCode parentName="p">{`descriptionClassName`}</inlineCode>{`). These classNames must have CSS pre-generated by a tool like @emotion/css.`}</p>
    <p>{`Fields are often used inside other components, and we allow passing a className to directly alter the style of the fields. For example, in the input component below, we can target the label and description fields.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from "@allenai/varnish-ui";
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const labelClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R6.value};
        text-transform: uppercase;
    \`;
};

const descriptionClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.G6.value};
        padding: 8px;
    \`;
};

render(
    <varnishUi.Stack spacing={4}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Input
                placeholder="Custom label and description css"
                label="Label text"
                labelClassName={labelClassName()}
                description="Description text"
                descriptionClassName={descriptionClassName()}
            />
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;