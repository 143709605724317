
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { AppProviderExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'App Provider',
  cols: 2,
  iconSrc: './header-footer-14px.svg',
  tag: {
    label: 'New',
    color: 'secondary'
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`App Provider`}</h2>
    <p><inlineCode parentName="p">{`AppProvider`}</inlineCode>{` provides the necessary Providers for varnish-ui to operate.
It includes a `}<inlineCode parentName="p">{`ThemeProviderBase`}</inlineCode>{` that handles changing and loading color mode.`}</p>
    <p>{`AppProvider can also accept a ThemeProvider component for an another component
library, such as Emotion or Mui.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppProvider } from '@allenai/varnish-ui';
import { OtherProvider } from 'somewhere';

import { MainApp } from './MainApp';

const App = () => (
    <AppProvider>
        <OtherProvider>
            <MainApp />
        </OtherProvider
    </AppProvider>
);

const container = document.getElementById('root');
if (!container) {
    throw new Error("No element with an id of 'root' was found.");
}
const root = createRoot(container);
root.render(<App />);
`}</code></pre>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={AppProviderExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <h4>{`AppProvider`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`themeProvider?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.ComponentType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is for an additional ThemeProvider as a convience, It should utilize `}<inlineCode parentName="td">{`useTheme()`}</inlineCode>{` from the ThemeProviderBase to get the user's colorMode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`themeDefaults?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ComponentProps<ThemeProviderBase>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Spread on to `}<inlineCode parentName="td">{`ThemeProviderBase`}</inlineCode>{`, so its defaults will apply if not overriden.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`ThemeProviderBase`}</h4>
    <h5>{`Types`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ColorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`system`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`light`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`These are actually derived from the theme, with `}<inlineCode parentName="td">{`system`}</inlineCode>{` added`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ExplicitColorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`light`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Is really `}<inlineCode parentName="td">{`Exclude<ColorMode, 'system'>`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h5>{`Props`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`localStorageKey`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'varnish-ui-theme'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`localStorage`}</inlineCode>{` key used to store user preference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`defaultColorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ColorMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`colorModes`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ColorMode[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`['system', 'light', 'dark']`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`forceClassForSystem`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`system`}</inlineCode>{` by default doesn't add className, you can force this behavoir if needed.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`useTheme hook / ThemeContext`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`colorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ColorMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`defaultColorMode`}</inlineCode>{` passed to `}<inlineCode parentName="td">{`ThemeProviderBase`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`this is what is stored by the user, defaulting to what ever was passed as the defaultColorMode into the context`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`systemPrefersColorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ExplicitColorMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is computed with a media query on page load`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`computedColorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ExplicitColorMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is the computed real value of the colorMode, taking into account system color mode when applicable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`setColorMode`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(_: ColorMode) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the `}<inlineCode parentName="td">{`colorMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is to be used by a Theme/ColorMode switcher. It does not verify that it is valid (i.e. against `}<inlineCode parentName="td">{`colorModes`}</inlineCode>{` prop on context)`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;