
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const exampleTextAreaClassName = 'exampleTextAreaClassName';
const exampleLabelClassName = 'exampleLabelClassName';
const exampleGrowContainer = 'exampleGrowContainer';

const CustomTextArea = mui.styled(varnishUi.TextArea)\`
    border: 2px dashed red;
    padding: 1;
    .\${exampleTextAreaClassName} {
        border: 2px dashed blue;
    }
    .\${exampleLabelClassName} {
        border: 2px dashed green;
    }
    .\${exampleGrowContainer} {
        border: 2px dashed yellow;
    }
\`;

render(
    <CustomTextArea
        label="Label"
        placeholder="textArea"
        textAreaClassName={exampleTextAreaClassName}
        labelClassName={exampleLabelClassName}
        growContainerClassName={exampleGrowContainer}
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;