import * as Default from './demo/default.mdx';
import * as Orientation from './demo/orientation.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StyledCss from './demo/stylingStyled.mdx';
import * as NonItems from './demo/nonItems.mdx';
import * as WithIcon from './demo/withIcon.mdx';

export const TabsExamples = [
    Default,
    Orientation,
    Disabled,
    NonItems,
    WithIcon,
    StylingCss,
    StyledCss,
];
