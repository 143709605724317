import { css } from '@allenai/varnish-panda-runtime/css';
import { useEffect } from 'react';

import { createPortal } from 'react-dom';

import { cx } from '@/utils/cx';

import drawerRecipe, { DrawerRecipeProps } from './drawer.style';
import Button from '../button/Button';
import { Close } from '../icons';

type DrawerProps = {
    className?: string;
    backdropClassName?: string;
    headerClassName?: string;
    contentClassName?: string;
    header: React.ReactNode;
    isOpen: boolean;
    placement?: 'left' | 'right' | 'top' | 'bottom';
    variant?: 'temporary' | 'persistent' | 'permanent';
    backdrop?: boolean;
    children: React.ReactNode;
    ariaLabel?: string;
    onClose?: () => void;
} & DrawerRecipeProps;

function Drawer({
    className,
    isOpen,
    placement = 'left',
    backdrop = true,
    children,
    variant = 'temporary',
    header,
    backdropClassName,
    headerClassName,
    contentClassName,
    ariaLabel = 'Drawer',
    onClose,
    ...rest
}: DrawerProps) {
    const [variantProps, localProps] = drawerRecipe.splitVariantProps(rest);
    const recipeClassNames = drawerRecipe.raw({
        ...variantProps,
        placement,
        isOpen,
        backdrop,
        variant,
    });

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && onClose !== undefined) {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]);

    const defaultHeader = () => (
        <div className={cx(css(recipeClassNames.defaultHeader))}>
            {variant !== 'permanent' && (
                <Button variant="text" onPress={onClose} startIcon={<Close size="large" />} />
            )}
        </div>
    );

    const content = isOpen ? (
        <>
            {backdrop && variant === 'temporary' && (
                <div
                    className={cx(css(recipeClassNames.backdrop), backdropClassName)}
                    aria-hidden="true"
                    onClick={onClose}
                />
            )}

            <div
                className={cx(css(recipeClassNames.root), className)}
                {...localProps}
                aria-label={ariaLabel}>
                {header !== undefined ? (
                    <div className={cx(css(recipeClassNames.header), headerClassName)}>
                        {header}
                        {variant !== 'permanent' && (
                            <Button
                                variant="text"
                                onPress={onClose}
                                startIcon={<Close size="large" />}
                            />
                        )}
                    </div>
                ) : (
                    defaultHeader()
                )}
                <div className={cx(css(recipeClassNames.content), contentClassName)}>
                    {children}
                </div>
            </div>
        </>
    ) : null;

    if ((variant === 'persistent' || variant === 'permanent') && isOpen) {
        return content;
    }

    return createPortal(content, document.body);
}

export { Drawer, Drawer as default };
export type { DrawerProps };
