import * as ExampleDefault from './demo/default.mdx';
import * as MsCustomAttributes from './demo/msCustomAttributes.mdx';
import * as MsDefault from './demo/msDefault.mdx';
import * as MsSelectComponentByName from './demo/msSelectComponentByName.mdx';
import * as ScCustomPadding from './demo/scCustomPadding.mdx';
import * as ScDefault from './demo/scDefault.mdx';
import * as Svgs from './demo/svgs.mdx';
import * as SxColor from './demo/sxColor.mdx';
import * as SxCustomPadding from './demo/sxCustomPadding.mdx';

export const HowDoIExamples = [
    ExampleDefault,
    MsCustomAttributes,
    MsDefault,
    MsSelectComponentByName,
    ScCustomPadding,
    ScDefault,
    Svgs,
    SxColor,
    SxCustomPadding,
];
