
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Dialog',
  cols: 2,
  iconSrc: './tab-14px.svg',
  tag: {
    label: 'New',
    color: 'secondary'
  }
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Dialog`}</h2>
    <p><inlineCode parentName="p">{`Dialog`}</inlineCode>{`s are an internal component to display content that Pops up. They are used with `}<inlineCode parentName="p">{`Popover`}</inlineCode>{` and `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`.`}</p>
    <p>{`Although being internal, they provide some helper components that are used inside other components.`}</p>
    <p><a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/Dialog.html"
      }}>{`React Aria Dialog docs`}</a></p>
    <h3>{`Examples`}</h3>
    <p>{`For examples, see `}<a parentName="p" {...{
        "href": "/components/varnishui/popover"
      }}>{`Popover`}</a>{` or `}<a parentName="p" {...{
        "href": "/components/varnishui/modal"
      }}>{`Modal`}</a></p>
    <h3>{`API`}</h3>
    <p>{`Most of these components, properties have analogies in either or both `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` and `}<inlineCode parentName="p">{`Popover`}</inlineCode>{`.`}</p>
    <h4>{`Dialog`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`heading`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`ReactElement?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A string will get wrapped in a `}<inlineCode parentName="td">{`DialogHeading`}</inlineCode>{` component, but a ReactNode can be passed in in place`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`buttons`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ReactNode?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Typically one or more `}<inlineCode parentName="td">{`Button`}</inlineCode>{`s (or `}<inlineCode parentName="td">{`DialogCloseButton`}</inlineCode>{`) to handle actions, the are wrapped in a `}<inlineCode parentName="td">{`DialogActions`}</inlineCode>{` component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className for the `}<inlineCode parentName="td">{`Dialog`}</inlineCode>{` component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`headingClassName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className for the `}<inlineCode parentName="td">{`DialogHeading`}</inlineCode>{` (assuming it was a `}<inlineCode parentName="td">{`string`}</inlineCode>{` and not a `}<inlineCode parentName="td">{`ReactNode`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`actionsClassName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className for the `}<inlineCode parentName="td">{`DialogActions`}</inlineCode>{` component (that wraps the `}<inlineCode parentName="td">{`buttons`}</inlineCode>{` property)`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`DialogHeading`}</h4>
    <p>{`Convenience component for the Heading (an AriaHeading slot="title"). See `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/Dialog.html#heading-1"
      }}>{`React Aria Dialog - Heading`}</a></p>
    <h4>{`DialogActions`}</h4>
    <p>{`Convenience component for wrapping the `}<inlineCode parentName="p">{`buttons`}</inlineCode>{` property, includes basic styling.`}</p>
    <h4>{`DialogCloseButton`}</h4>
    <p>{`Convenience component that will close a dialog (a `}<inlineCode parentName="p">{`<Button slot="close" />`}</inlineCode>{`). See `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/Dialog.html#button"
      }}>{`React Aria Dialog - Button`}</a></p>
    <h4>{`DialogTrigger`}</h4>
    <p>{`Convenience component to open a Dialog, takes a Button (and optionally a Dialog) as children (along with the trigger, i.e. a Button), or as a property. See `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/Dialog.html#dialogtrigger-1"
      }}>{`React Aria Dialog - Trigger`}</a></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ReactNode?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The Dialog to open`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The trigger element and optionally a Dialog`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;