
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Dismissable'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Dismissable`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Dismissable</varnishUi.Button>
            <varnishUi.Modal isDismissable heading="Modal Heading" color="reversed">
                <p>Modal Contents</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>No Keyboard Dismiss</varnishUi.Button>
            <varnishUi.Modal
                isDismissable
                isKeyboardDismissDisabled
                heading="Modal Heading"
                color="reversed">
                <p>Modal Contents</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Not dismissable</varnishUi.Button>
            <varnishUi.Modal isKeyboardDismissDisabled heading="Modal Heading" color="reversed">
                <p>You can still close with the close button</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;