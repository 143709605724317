
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { ColorsExamples } from '.';
export const frontMatter = {
  lib: 'varnish-theme',
  type: 'Components',
  title: 'Colors',
  cols: 2,
  iconSrc: './color-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ColorPicker = makeShortcode("ColorPicker");
const PrimaryColorValues = makeShortcode("PrimaryColorValues");
const LightCategoricalColorValues = makeShortcode("LightCategoricalColorValues");
const DarkCategoricalColorValues = makeShortcode("DarkCategoricalColorValues");
const ColorShades = makeShortcode("ColorShades");
const Examples = makeShortcode("Examples");
const DeprecatedColorValues = makeShortcode("DeprecatedColorValues");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Colors`}</h2>
    <p>{`Official AI2 Colors.`}</p>
    <p>{`To ensure your site looks polished and consistent with AI2's broader brand, try to stick using the colors
listed here.`}</p>
    <p>{`You can refer to these colors in two ways. Either explicitly via a reference like `}<inlineCode parentName="p">{`theme.color.N5`}</inlineCode>{`, or via
a semantic name attached to the `}<inlineCode parentName="p">{`palette`}</inlineCode>{` API, i.e. `}<inlineCode parentName="p">{`theme.palette.primary.main`}</inlineCode>{`. The latter is preferred, as
it means your UI will be updated if AI2's broader brand guidelines and change what the `}<inlineCode parentName="p">{`primary`}</inlineCode>{` cluster
is.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`Use these colors for all UI elements, including charts, graphs, and data visualizations.`}</p>
    <h2>{`Find a Varnish Color`}</h2>
    <ColorPicker mdxType="ColorPicker" />
    <h2>{`Main Colors`}</h2>
    <PrimaryColorValues mdxType="PrimaryColorValues" />
    <h2>{`Charting Colors`}</h2>
    <p>{`When designing charts on a `}<inlineCode parentName="p">{`light`}</inlineCode>{` background, use the following colors for data series.`}</p>
    <LightCategoricalColorValues mdxType="LightCategoricalColorValues" />
    <p>{`When designing charts on a `}<inlineCode parentName="p">{`dark`}</inlineCode>{` background, use the following colors for data series.`}</p>
    <DarkCategoricalColorValues mdxType="DarkCategoricalColorValues" />
    <h2>{`Color Shades`}</h2>
    <ColorShades mdxType="ColorShades" />
    <h2>{`Examples`}</h2>
    <Examples cols={frontMatter.cols} examples={ColorsExamples} mdxType="Examples" />
    <h2>{`Color API`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`contrastTextColor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The text color from our theme that has the best contrast with this color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`displayName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`hex`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hex value of color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`rgba`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`{`}{`r: number, g: number, b: number, a: number`}{`}`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`RGBA object of color`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Deprecated`}</h2>
    <h3>{`Colors from before the rebrand`}</h3>
    <p>{`AI2 has updated its brand colors. To maintain backwards-compatibility, Varnish has deprecated existing colors but still has them available. Expect them to go away at some point!`}</p>
    <DeprecatedColorValues mdxType="DeprecatedColorValues" />
    <h3>{`Colors2`}</h3>
    <p>{`Varnish used to use `}<inlineCode parentName="p">{`color2`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`color`}</inlineCode>{`, with their biggest difference being color2 had 5 colors per category while
color has 10. We decided to sunset color2 in favor of having more color values.`}</p>
    <p>{`Programs that still use `}<inlineCode parentName="p">{`color2`}</inlineCode>{` should be unaffected. However, it is recommended to eventually move back to `}<inlineCode parentName="p">{`color`}</inlineCode>{`.
All color2 values are listed in the color tables above, with the exception of `}<inlineCode parentName="p">{`color2.P3`}</inlineCode>{` which has a hard coded value of `}<inlineCode parentName="p">{`#7446F2`}</inlineCode>{`.`}</p>
    <p>{`Most `}<inlineCode parentName="p">{`color2`}</inlineCode>{` values can be updated by doubling the value, with some exceptions.`}</p>
    <p>{`e.g. `}<inlineCode parentName="p">{`color2.B3`}</inlineCode>{` -> `}<inlineCode parentName="p">{`color.B6`}</inlineCode>{`, `}<inlineCode parentName="p">{`color2.R5`}</inlineCode>{` -> `}<inlineCode parentName="p">{`color.R9`}</inlineCode>{`.`}</p>
    <h2>{`Additional APIs to work with Varnish Colors`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Function Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isVarnishColor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id: string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Checks if a color id is a valid Varnish color2 color`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;