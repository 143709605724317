import { Link, Typography, TypographyProps, styled } from '@mui/material';

const getAnchor = (text: string) => {
    return text
        .toLowerCase()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/[ ]/g, '-');
};

interface LinkedHeaderType extends TypographyProps {
    children: string;
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const LinkedHeader = ({ children, variant, ...typographyProps }: LinkedHeaderType) => {
    const anchor = getAnchor(children);
    const link = `#${anchor}`;

    return (
        <Typography
            component={variant}
            variant={variant}
            position="relative"
            id={anchor}
            {...typographyProps}>
            <StyledLink href={link}>{children}</StyledLink>
        </Typography>
    );
};

const StyledLink = styled(Link)`
    color: inherit;
    :hover {
        text-decoration: none;
        ::before {
            content: '§';
            position: absolute;
            transform: translate(-0.5em, -1px);
            color: ${({ theme }) => theme.color.B6.hex};
        }
    }
`;
