import { cva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps, SystemStyleObject } from '@allenai/varnish-panda-runtime/types';

/**
 *  The Stack component organizes its immediate children along either the vertical or horizontal
 *  axis, allowing for customizable spacing and optional dividers between each child.
 */

/*
returns: {
    0: { gap: "[0px]" },
    1: { gap: "[1px]" },
    ...,
    {max}: { gap: "[{max}px]" }
}
*/
const generateSpacing = (max: number): Record<number, SystemStyleObject> => {
    const spacing: Record<number, SystemStyleObject> = {};
    for (let i = 0; i <= max; i++) {
        spacing[i] = { gap: `[${i}px]` };
    }
    return spacing;
};

const stackRecipe = cva({
    base: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '0',
    },
    variants: {
        direction: {
            row: {
                flexDirection: 'row',
            },
            column: {
                flexDirection: 'column',
            },
        },
        align: {
            start: { alignItems: 'flex-start' },
            center: { alignItems: 'center' },
            end: { alignItems: 'flex-end' },
            stretch: { alignItems: 'stretch' },
        },
        justify: {
            start: { justifyContent: 'flex-start' },
            center: { justifyContent: 'center' },
            end: { justifyContent: 'flex-end' },
            between: { justifyContent: 'space-between' },
            around: { justifyContent: 'space-around' },
            evenly: { justifyContent: 'space-evenly' },
        },
        fullWidth: {
            true: {
                width: '[100%]',
            },
        },
        spacing: generateSpacing(100),
    },
    defaultVariants: {
        direction: 'column',
        align: 'start',
        justify: 'start',
        spacing: 0,
    },
});

type StackRecipeProps = RecipeVariantProps<typeof stackRecipe>;

export { stackRecipe, stackRecipe as default };
export type { StackRecipeProps };
