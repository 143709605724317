
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'ResponsiveBar with DarkTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A complex example of the Nivo ResponsiveBar component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { barDefaultsDark } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
    background: \${({ theme }) => theme.palette.background.reversed};
    padding: \${({ theme }) => theme.spacing['3']};
\`;

const data = [
    {
        country: 'AD',
        hotdog: 23,
        burger: 12,
        sandwich: 157,
        kebab: 163,
        fries: 158,
        donut: 129,
    },
    {
        country: 'AE',
        hotdog: 128,
        burger: 105,
        sandwich: 44,
        kebab: 99,
        fries: 56,
        donut: 50,
    },
    {
        country: 'AF',
        hotdog: 176,
        burger: 165,
        sandwich: 92,
        kebab: 140,
        fries: 53,
        donut: 114,
    },
    {
        country: 'AG',
        hotdog: 82,
        burger: 177,
        sandwich: 24,
        kebab: 20,
        fries: 127,
        donut: 19,
    },
    {
        country: 'AI',
        hotdog: 2,
        burger: 10,
        sandwich: 155,
        kebab: 170,
        fries: 46,
        donut: 19,
    },
    {
        country: 'AL',
        hotdog: 100,
        burger: 127,
        sandwich: 101,
        kebab: 59,
        fries: 85,
        donut: 193,
    },
    {
        country: 'AM',
        hotdog: 173,
        burger: 35,
        sandwich: 46,
        kebab: 55,
        fries: 135,
        donut: 21,
    },
];

render(
    <ChartWrapper>
        <ResponsiveBar
            // add in varnish theme at top of all charts
            {...barDefaultsDark}
            // add options specific to your chart
            // use varnish values when it makes sense
            keys={['hotdog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
            indexBy="country"
            margin={{
                left: 64,
                bottom: 64,
                right: 64,
                top: 32,
            }}
            axisLeft={{
                format: (d) => new Intl.NumberFormat().format(d),
                tickValues: 7,
                legend: 'Food',
                legendPosition: 'middle',
                legendOffset: -48,
            }}
            axisBottom={{
                legend: 'Country',
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    translateX: 0,
                    translateY: 64,
                    itemWidth: 48,
                    itemHeight: 24,
                    itemsSpacing: 40,
                    symbolSize: 14,
                },
            ]}
            // add data
            data={data}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;