import {
    FieldError as AriaFieldError,
    FieldErrorProps as AriaFieldErrorProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { fieldErrorRecipe, FieldErrorRecipeProps } from './fieldError.styles';

type FieldErrorProps = {
    className?: string;
} & AriaFieldErrorProps &
    FieldErrorRecipeProps;

const FieldError = ({ className, ...rest }: FieldErrorProps) => {
    const [variantProps, localProps] = fieldErrorRecipe.splitVariantProps(rest);
    return (
        <AriaFieldError {...localProps} className={cx(fieldErrorRecipe(variantProps), className)} />
    );
};

export { FieldError };
export type { FieldErrorProps };
