
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Overview',
  title: 'Getting Started',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const PeerDepsVarnishUi = makeShortcode("PeerDepsVarnishUi");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Getting Started`}</h1>
    <p><inlineCode parentName="p">{`Varnish-ui`}</inlineCode>{` is a RAC-based component library that represents the future of our branded UI.
Currently in an alpha stage, this library features a more streamlined set of components, with a
full brand-compliant design and plans for rapid expansion. Although it has fewer components right
now, it’s intended to become the go-to choice for new projects that value staying on the cutting
edge and can accommodate significant updates as the library matures. If you’re building a fresh
project and want the latest and most innovative UI components, this is the library to explore.`}</p>
    <p>{`To install, follow these steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`@allenai/varnish-ui`}</inlineCode>{` and all dependencies`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`// with npm
npm install @allenai/varnish-ui

// with yarn
yarn add @allenai/varnish-ui
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install Peer Dependencies`}</p>
        <p parentName="li"><em parentName="p">{`note`}</em>{` panda is optional, varnish-panda-runtime is provided as a required peer depenency,
which means it will work, but to get types, you will need to install the package.`}</p>
        <PeerDepsVarnishUi mdxType="PeerDepsVarnishUi" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a main.css with the following content`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`@import url('@allenai/varnish-ui/styles.css');
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add varnish-ui styles by importing them in your index.tsx`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import './main.css';
`}</code></pre>
      </li>
    </ol>
    <h2>{`React Aria UI Components`}</h2>
    <p><inlineCode parentName="p">{`varnish-ui`}</inlineCode>{` consists of a few components and many styles that are applied to
`}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/components.html"
      }}>{`React Aria Components`}</a>{`. We encourage you to explore their
documentation if you find our documentation lacking. Not all components are currently supported, but all will be eventually.`}</p>
    <h2>{`Varnish UI with Panda CSS`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "/components/varnishui/panda_css"
      }}>{`Varnish UI with Panda CSS documentation`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;