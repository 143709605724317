
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Several className props are provided by Select that will help with styling. For example, pass some css to the `}<inlineCode parentName="p">{`popoverClassName`}</inlineCode>{` to style the Select dropdown window. More info on these props in the API section.`}</p>
    <p>{`These classNames must have css inserted by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const exampleClassName = () => {
    return emotionCss.css\`
        border: 2px dashed red;
    \`;
};

const exampleButtonClassName = () => {
    return emotionCss.css\`
        border: 2px dashed orange;
    \`;
};

const exampleDescriptionClassName = () => {
    return emotionCss.css\`
         border: 2px dashed yellow;
    \`;
};

const exampleLabelClassName = () => {
    return emotionCss.css\`
        border: 2px dashed green;
    \`;
};

const examplePopoverClassName = () => {
    return emotionCss.css\`
         border: 2px dashed blue;
    \`;
};

const exampleListBoxItemClassName = () => {
    return emotionCss.css\`
         border: 2px dashed purple;
    \`;
};

const exampleItems = ["cat", "dog", "alligator", "llama", "lizard", "frog"];
render(
        <varnishUi.Select
            className={emotionCss.cx(exampleClassName())}
            buttonClassName={emotionCss.cx(exampleButtonClassName())}
            descriptionClassName={emotionCss.cx(exampleDescriptionClassName())}
            labelClassName={emotionCss.cx(exampleLabelClassName())}
            popoverClassName={emotionCss.cx(examplePopoverClassName())}
            listBoxItemClassName={emotionCss.cx(exampleListBoxItemClassName())}
            items={exampleItems}
            label="Label"
            description="I am the description."
            placeholder="label" />
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;