import * as Default from './demo/default.mdx';
import * as Placement from './demo/placement.mdx';
import * as Header from './demo/header.mdx';
import * as Content from './demo/content.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StyledCss from './demo/stylingStyled.mdx';
import * as Backdrop from './demo/backdrop.mdx';
import * as Persistent from './demo/persistentVariant.mdx';
import * as Permanent from './demo/permanentVariant.mdx';

export const DrawerExamples = [
    Default,
    Placement,
    Header,
    Content,
    StylingCss,
    StyledCss,
    Backdrop,
    Persistent,
    Permanent,
];
