
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Using the Semantic Scholar API'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`PaperRow functions well with the `}<a parentName="p" {...{
        "href": "https://www.semanticscholar.org/product/api"
      }}>{`Semantic Scholar API`}</a>{`.
Just spread the parsed response into the PaperRow.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`// Call your S2 api service to get a paper
const s2PaperResponse = s2PaperService.getPaper()

// Spread the paper object into PaperRow.
<PaperRow {...paper} />;

// OPTIONAl: Add some optional params to this result
<PaperRow {...paper} authorLimit={2} abstractCharLimit={100} shortenAuthorNames />;
`}</code></pre>
    <p>{`With the final result looking like this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

// pulled from \`https://api.semanticscholar.org/graph/v1/paper/13497bd108d4412d02050e646235f456568cf822?fields=url,year,authors.url,authors.name,title,url,publicationDate,abstract,fieldsOfStudy,venue\`;
const s2PaperResponse = {
    paperId: '13497bd108d4412d02050e646235f456568cf822',
    url: 'https://www.semanticscholar.org/paper/13497bd108d4412d02050e646235f456568cf822',
    title: 'Deep Speech 2 : End-to-End Speech Recognition in English and Mandarin',
    abstract:
        'We show that an end-to-end deep learning approach can be used to recognize either English or Mandarin Chinese speech-two vastly different languages. Because it replaces entire pipelines of hand-engineered components with neural networks, end-to-end learning allows us to handle a diverse variety of speech including noisy environments, accents and different languages. Key to our approach is our application of HPC techniques, enabling experiments that previously took weeks to now run in days. This allows us to iterate more quickly to identify superior architectures and algorithms. As a result, in several cases, our system is competitive with the transcription of human workers when benchmarked on standard datasets. Finally, using a technique called Batch Dispatch with GPUs in the data center, we show that our system can be inexpensively deployed in an online setting, delivering low latency when serving users at scale.',
    venue: 'International Conference on Machine Learning',
    year: 2015,
    fieldsOfStudy: ['Computer Science'],
    publicationDate: '2015-12-08',
    authors: [
        {
            authorId: '2698777',
            url: 'https://www.semanticscholar.org/author/2698777',
            name: 'Dario Amodei',
        },
        {
            authorId: '39436202',
            url: 'https://www.semanticscholar.org/author/39436202',
            name: 'S. Ananthanarayanan',
        },
        {
            authorId: '2432216',
            url: 'https://www.semanticscholar.org/author/2432216',
            name: 'Rishita Anubhai',
        },
        {
            authorId: '2113830293',
            url: 'https://www.semanticscholar.org/author/2113830293',
            name: 'Jin Bai',
        },
    ],
};
const PaperRow = varnishComponents.PaperRow;
render(
    <PaperRow {...s2PaperResponse} authorLimit={2} abstractCharLimit={100} shortenAuthorNames />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;