
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 10,
  title: 'ResponsiveBullet with LightTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A simple example of the Nivo ResponsiveBullet component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveBullet } from '@nivo/bullet';
import { bulletDefaults } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 70px;
    max-width: 100%;
\`;

render(
    <ChartWrapper>
        <ResponsiveBullet
            // add in varnish theme at top of all charts
            {...bulletDefaults}
            // add options specific to your chart
            // use varnish values when it makes sense
            margin={{
                top: 16,
                right: 24,
                bottom: 32,
                left: 72,
            }}
            // add data
            data={[
                {
                    id: 'volume',
                    ranges: [0, 25, 50, 75, 100],
                    measures: [22],
                    markers: [28, 55],
                },
            ]}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;