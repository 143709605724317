
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Sizes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To adjust button size, use the `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop for larger or smaller options.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="text" size="small">
                Small
            </varnishUi.Button>
            <varnishUi.Button variant="text" size="medium">
                Medium
            </varnishUi.Button>
            <varnishUi.Button variant="text" size="large">
                Large
            </varnishUi.Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="outlined" size="small">
                Small
            </varnishUi.Button>
            <varnishUi.Button variant="outlined" size="medium">
                Medium
            </varnishUi.Button>
            <varnishUi.Button variant="outlined" size="large">
                Large
            </varnishUi.Button>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="contained" size="small">
                Small
            </varnishUi.Button>
            <varnishUi.Button variant="contained" size="medium">
                Medium
            </varnishUi.Button>
            <varnishUi.Button variant="contained" size="large">
                Large
            </varnishUi.Button>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;