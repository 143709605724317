
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it
const markdownText = \`A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

##### Todo Checklist

* [ ] todo
* [x] done

##### Unordered List

* Milk
* Apples
* Eggs

##### Horizontal Rule

---

##### Table

| Product          | Estimated Price ($) | Estimated Weight (kg) |
|------------------|---------------------:|------------------------:|
| Widget X         |               $49.99 |                    0.5 |
| Gizmo Y          |               $79.95 |                    1.2 |
| Gadget Z         |              $129.99 |                    0.8 |
| Thingamajig A    |               $34.50 |                    0.3 |
| Doodad B         |               $89.75 |                    1.5 |

##### Ordered List

1. Use markdown
2. ???
3. Profit

##### Image

![Image](https://t4.ftcdn.net/jpg/00/53/45/31/360_F_53453175_hVgYVz0WmvOXPd9CNzaUcwcibiGao3CL.jpg)
\`;

const className = () => {
    return emotionCss.css\`
        text-decoration: none;
        color: red;
        border: 1px dashed \${theme.color.R6.value};
    \`;
};


const Demo = () => {
  return <varnishUi.Markdown className={emotionCss.cx(className())}>{markdownText}</varnishUi.Markdown>;
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;