import { css } from '@allenai/varnish-panda-runtime/css';
import React, { Component } from 'react';

import { cx } from '@/utils/cx';

import errorBoundaryRecipe, { ErrorBoundaryRecipeProps } from './errorBoundary.styles';

type ErrorBoundaryProps = {
    fallback?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    errorSelectionClassName?: string;
} & ErrorBoundaryRecipeProps;

type ErrorBoundaryState = {
    hasError: boolean;
    error?: Error;
    errorInfo?: React.ErrorInfo;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
        this.setState({ error, errorInfo });
    }

    render() {
        const { className, errorSelectionClassName, fallback, ...rest } = this.props;

        const { hasError, error, errorInfo } = this.state;

        if (!hasError) {
            return this.props.children;
        }
        const [variantProps, localProps] = errorBoundaryRecipe.splitVariantProps(rest);

        // `raw` receipe is required to be able to merge css objects (used in focus ring)
        const recipeClassNames = errorBoundaryRecipe.raw(variantProps);
        return (
            <div className={cx(css(recipeClassNames.root), className)} {...localProps}>
                <div className={cx(css(recipeClassNames.errorSelection), errorSelectionClassName)}>
                    <h1>Sorry, something went wrong</h1>
                    {fallback || <p>Please try again.</p>}
                    {process.env.NODE_ENV !== 'production' && error !== undefined && (
                        <div>
                            <h3>Error Details:</h3>
                            <p>
                                <strong>Message:</strong> {error.message}
                            </p>
                            <p>
                                <strong>Stack Trace:</strong>
                            </p>
                            <pre>{error.stack}</pre>
                            <p>
                                <strong>Component Stack:</strong>
                            </p>
                            {errorInfo !== undefined && <pre>{errorInfo.componentStack}</pre>}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export { ErrorBoundary, ErrorBoundary as default };
export type { ErrorBoundaryProps };
