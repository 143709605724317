import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const inputRecipe = sva({
    slots: ['root', 'input', 'label', 'description', 'error'],
    base: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'body',
        },
        input: {
            textStyle: 'inherit',
            boxSizing: 'border-box',
            border: '[1px solid]',
            flex: '1',
            minWidth: '[0]',
            outlineStyle: 'solid',
            ringWidth: '[0px]',
            _disabled: {
                cursor: 'not-allowed',
                backgroundColor: 'gray.20',
                borderColor: 'gray.40',
            },
            _invalid: {
                border: '[1px solid]',
                borderColor: 'error-red.100',
                _placeholder: {
                    color: 'error-red.100',
                },
                _dark: {
                    _placeholder: {
                        color: 'error-red.60',
                    },
                },
            },
            _hover: {
                _notDisabled: {
                    borderColor: 'green.100',
                },
            },
            // default light styles
            backgroundColor: '[white]',
            borderColor: '[white]',
            color: 'text.primary',
            _placeholder: {
                color: 'gray.50',
            },
            _dark: {
                backgroundColor: 'background',
                borderColor: 'background',
                color: 'text.primary',
                _placeholder: {
                    color: '[calc(text.primary/50)]',
                },
                _disabled: {
                    cursor: 'not-allowed',
                    backgroundColor: 'gray.20',
                    borderColor: 'gray.40',
                },
            },
        },
        label: {},
        description: {},
        error: {},
    },
    variants: {
        variant: {
            contained: {}, // default
            outlined: {
                input: {
                    borderColor: 'dark-teal.20',
                    _dark: {
                        borderColor:
                            '[color-mix(in srgb, {colors.cream.100} 20%, {colors.background})]',
                        color: 'text.primary',
                    },
                },
            },
        },
        size: {
            small: {
                input: {
                    fontSize: '[12px]',
                    padding: '[8px 12px]',
                    lineHeight: '[16px]',
                },
            },
            medium: {
                input: {
                    fontSize: 'sm',
                    padding: '[8px 12px]',
                    lineHeight: '[22px]',
                },
            },
            large: {
                input: {
                    fontSize: 'md',
                    padding: '[16px 16px]',
                    lineHeight: '[24px]',
                },
            },
        },
        shape: {
            box: {
                input: {
                    borderRadius: 'sm',
                },
            },
            rounded: {
                input: {
                    borderRadius: 'full',
                    borderWidth: '[2px]',
                },
            },
        },
        fullWidth: {
            false: {},
            true: {
                root: {
                    width: '[100%]',
                },
                input: {
                    width: '[100%]',
                },
            },
        },
    },
    defaultVariants: {
        variant: 'outlined',
        shape: 'box',
        size: 'medium',
        fullWidth: false,
    },
});

type InputRecipeProps = RecipeVariantProps<typeof inputRecipe>;

export { inputRecipe, inputRecipe as default };
export type { InputRecipeProps };
