
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Using Tabs, TabPanel, TabList, Tab separately'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can pass items and use Tabs component to render everything.
But you can use all these components separately as example below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
  const [tabSelected, setTabSelect] = React.useState<Key>("FoR");
  return (
    <varnishUi.Tabs onSelectionChange={setTabSelect} selectedKey={tabSelected}>
      <varnishUi.TabList aria-label="History of Ancient Rome">
        <varnishUi.Tab id="FoR">Founding of Rome</varnishUi.Tab>
        <varnishUi.Tab id="MaR">Monarchy and Republic</varnishUi.Tab>
        <varnishUi.Tab id="Emp">Empire</varnishUi.Tab>
      </varnishUi.TabList>
      <varnishUi.TabPanel id="FoR">
        Arma virumque cano, Troiae qui primus ab oris.
      </varnishUi.TabPanel>
      <varnishUi.TabPanel id="MaR">
        Senatus Populusque Romanus.
      </varnishUi.TabPanel>
      <varnishUi.TabPanel id="Emp">Alea jacta est.</varnishUi.TabPanel>
    </varnishUi.Tabs>
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;