import hexRgb from 'hex-rgb';
import varnishTheme, { ColorKeys } from '@allenai/varnish-theme/mui';

import { getContrastText } from './theme-utils';

type VarnishThemeColor = (typeof varnishTheme)['color'][ColorKeys];

export class RGBA {
    constructor(
        public r: number,
        public g: number,
        public b: number,
        public a: number
    ) {}

    toString() {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }
}

// convert a hex color string to a RGBA
export function hexToRgb(hex: string): RGBA {
    const rgba = hexRgb(hex);
    return new RGBA(rgba.red, rgba.green, rgba.blue, rgba.alpha);
}

export class Color {
    public readonly displayName: string;
    public readonly hex: string;
    public readonly rgba: RGBA;

    public readonly useContrastText: boolean;
    public readonly contrastTextColor: string;

    constructor(displayName: string, hex: string, rgba?: RGBA, useContrastText?: boolean) {
        this.displayName = displayName;
        this.hex = hex.toUpperCase();
        this.rgba = rgba || hexToRgb(hex);
        this.useContrastText = useContrastText ?? false;
        this.contrastTextColor = getContrastText(this.hex);
    }

    toString() {
        return this.hex;
    }
}

// get color from style token
const getColor = (color: VarnishThemeColor) => {
    const c = color.attributes;
    const rgba = new RGBA(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a);
    const useContrastText = 'useContrastText' in c ? c.useContrastText : false;

    const colorName = color.name.replace('color-', '');
    // Make color names formatted like B10 or R2 uppercase
    const displayName = colorName.match(/^\D\d+$/) ? colorName.toUpperCase() : colorName;
    return new Color(displayName, `#${c.hex.toUpperCase()}`, rgba, useContrastText);
};

// legacy: get legacy color2 from current colors
const getColor2 = (color2Type: string, c: Color) => {
    return new Color(color2Type, c.hex, c.rgba, c.useContrastText);
};

// colors grabbed from varnish-theme
export const color = Object.entries(varnishTheme.color).reduce<Record<ColorKeys, Color>>(
    (acc, [k, v]) => {
        acc[k as ColorKeys] = getColor(v);
        return acc;
    },
    // https://github.com/typescript-eslint/typescript-eslint/issues/3440
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as Record<ColorKeys, Color>
);

/**
 * @deprecated only looks for varnish2 colors
 * @param id string ID of a varnish2 color
 */
export const isVarnishColor = (id: string) => {
    return id in color2;
};

export const getUpdatedColor = (color2: Color): Color | null => {
    return Object.values(color).filter((c) => c.hex === color2.hex)[0] || null;
};

export const getLegacyColor = (color1: Color): Color | null => {
    return Object.values(color2).filter((c) => c.hex === color1.hex)[0] || null;
};

/**
 * @deprecated - colors2 is legacy. use this for backwards compatibility
 */
export const color2 = {
    R1: getColor2('R1', color.R2),
    R2: getColor2('R2', color.R4),
    R3: getColor2('R3', color.R6),
    R4: getColor2('R4', color.R8),
    R5: getColor2('R5', color.R10),
    O1: getColor2('O1', color.O2),
    O2: getColor2('O2', color.O4),
    O3: getColor2('O3', color.O6),
    O4: getColor2('O4', color.O8),
    O5: getColor2('O5', color.O9),
    G1: getColor2('G1', color.G2),
    G2: getColor2('G2', color.G4),
    G3: getColor2('G3', color.G6),
    G4: getColor2('G4', color.G8),
    G5: getColor2('G5', color.G10),
    T1: getColor2('T1', color.T1),
    T2: getColor2('T2', color.T3),
    T3: getColor2('T3', color.T6),
    T4: getColor2('T4', color.T8),
    T5: getColor2('T5', color.T10),
    A1: getColor2('A1', color.A1),
    A2: getColor2('A2', color.A3),
    A3: getColor2('A3', color.A5),
    A4: getColor2('A4', color.A8),
    A5: getColor2('A5', color.A10),
    B1: getColor2('B1', color.B1),
    B2: getColor2('B2', color.B3),
    B3: getColor2('B3', color.B6),
    B4: getColor2('B4', color.B8),
    B5: getColor2('B5', color.B10),
    P1: getColor2('P1', color.P2),
    P2: getColor2('P2', color.P4),
    P3: new Color('P3', '#7446F2', undefined, true), // there was no matching varnish1 color
    P4: getColor2('P4', color.P8),
    P5: getColor2('P5', color.P10),
    F1: getColor2('F1', color.M1),
    F2: getColor2('F2', color.M4),
    F3: getColor2('F3', color.M6),
    F4: getColor2('F4', color.M8),
    F5: getColor2('F5', color.M10),
    N1: getColor2('N1', color.N2),
    N2: getColor2('N2', color.N4),
    N3: getColor2('N3', color.N6),
    N4: getColor2('N4', color.N8),
    N5: getColor2('N5', color.N9),
    black: getColor2('black', color.black),
    white: getColor2('white', color.white),
    transparent: getColor2('transparent', color.transparent),
};

// TODO: these should pull from VarnishTheme. This is not currently done becasue the VarnishTheme
// does not expose hex nor a color id like 'R10'. And converting from string rgb is not ideal.
// this will likelt be reworked in the future due to new branding. (very likely, we wont expose
// charting colors from the mui theme at all)
export const lightCategoricalColor = {
    Red: { ...color.R10, displayName: 'Red' },
    Orange: { ...color.O9, displayName: 'Orange' },
    Aqua: { ...color.A10, displayName: 'Aqua' },
    Teal: { ...color.T8, displayName: 'Teal' },
    Blue: { ...color.B6, displayName: 'Blue' },
    Magenta: { ...color.M10, displayName: 'Magenta' },
    Purple: { ...color.P8, displayName: 'Purple' },
    Green: { ...color.G10, displayName: 'Green' },
} as const satisfies Record<string, Color>;

export const darkCategoricalColor = {
    Red: { ...color.R4, displayName: 'Red' },
    Orange: { ...color.O4, displayName: 'Orange' },
    Aqua: { ...color.A3, displayName: 'Aqua' },
    Teal: { ...color.T3, displayName: 'Teal' },
    Blue: { ...color.B3, displayName: 'Blue' },
    Magenta: { ...color.M4, displayName: 'Magenta' },
    Purple: { ...color.P4, displayName: 'Purple' },
    Green: { ...color.G4, displayName: 'Green' },
} as const satisfies Record<string, Color>;

// use to convert a set of color ids top their hex values
// e.g. SequenceColors = ColorsIdsToHexVals(['N1', 'N3', 'N5', 'N7', 'N9']);
export const colorsIdsToHexVals = (colorIds: Array<keyof typeof color>) =>
    colorIds.map((k) => color[k].hex);
export const colorMapToHexVals = (
    colorMap: typeof darkCategoricalColor | typeof lightCategoricalColor | typeof color
) => Object.values(colorMap).map((v) => v.hex);
