
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 15,
  title: 'Can I use styled-components?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`We recommend using MUI styled, but if you want to use Styled-Components.
See the `}<a parentName="p" {...{
        "href": "./app_layout#with-styledcomponents"
      }}>{`App Layout documentation`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import { styled, css } from '@mui/material/styles';

const BackgroundDiv = styled('div')(
    ({ theme }) => css\`
        width: 100%;
        text-align: center;
        margin: \${theme.spacing(0.5)};
        background-color: \${theme.color.B10.hex};
        color: \${theme.color.N4.hex};
        font-family: \${theme.typography.body1.fontFamily};
    \`,
);

render(
    <div style={{ width: '100%' }}>
        <BackgroundDiv>© The Allen Institute for Artificial Intelligence</BackgroundDiv>
    </div>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;