import { PropsWithChildren } from 'react';
import { Prism as SyntaxHighlighter, SyntaxHighlighterProps } from 'react-syntax-highlighter';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';
import codeBlockRecipe, { CodeBlockRecipeProps } from './codeblock.styles';

type CodeBlockProps = {
    value: string;
    language?: string;
    codeTagClassName?: string;
    style?: React.CSSProperties;
    inline?: boolean;
} & CodeBlockRecipeProps &
    SyntaxHighlighterProps;

const CodeBlock = ({
    value,
    language,
    className,
    codeTagClassName,
    style,
    inline = false,
    ...rest
}: PropsWithChildren<CodeBlockProps>) => {
    const [variantProps, localProps] = codeBlockRecipe.splitVariantProps(rest);
    const recipeClassNames = codeBlockRecipe.raw(variantProps);

    return (
        <SyntaxHighlighter
            style={style}
            language={language}
            PreTag="pre"
            CodeTag="code"
            {...localProps}
            codeTagProps={{
                className: cx(css(recipeClassNames.code), codeTagClassName),
            }}
            wrapLongLines={true}
            customStyle={
                inline ? { display: 'inline', padding: 0, whiteSpace: 'nowrap' } : undefined
            }>
            {value}
        </SyntaxHighlighter>
    );
};

export { CodeBlock };
export type { CodeBlockProps };
