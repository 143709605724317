
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Disabled'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The select component can be disabled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const exampleItems = ['Cat', 'Dog', 'Alligator', 'Llama', 'Lizard', 'Frog'];
render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Select items={exampleItems} placeholder="contained" isDisabled={true} />
        <varnishUi.Select items={exampleItems} placeholder="outlined" isDisabled={true} />
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;