
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Placement'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The placement of the tooltip with respect to the trigger. See react aria tooltip placement to see all the option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing="16">
        <varnishUi.Tooltip content="This is a tooltip">
            <varnishUi.Button variant="outlined">Top</varnishUi.Button>
        </varnishUi.Tooltip>
        <varnishUi.Tooltip content="This is a tooltip" placement="bottom">
            <varnishUi.Button variant="outlined">Bottom</varnishUi.Button>
        </varnishUi.Tooltip>
        <varnishUi.Tooltip content="This is a tooltip" placement="left">
            <varnishUi.Button variant="outlined">Left</varnishUi.Button>
        </varnishUi.Tooltip>
        <varnishUi.Tooltip content="This is a tooltip" placement="right">
            <varnishUi.Button variant="outlined">Right</varnishUi.Button>
        </varnishUi.Tooltip>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;