import { PropsWithChildren, ReactNode } from 'react';
import {
    TableHeader as AriaTableHeader,
    TableHeaderProps as AriaTableHeaderProps,
} from 'react-aria-components';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';

type TableHeaderProps = {
    columns: object[];
    children?: ReactNode;
    className?: string;
} & AriaTableHeaderProps<object>;

const TableHeader = ({
    columns,
    children,
    className,
    ...rest
}: PropsWithChildren<TableHeaderProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaTableHeader
            {...localProps}
            columns={columns}
            className={cx(recipeClassNames.tableHeader, className)}>
            {children}
        </AriaTableHeader>
    );
};

export { TableHeader };
export type { TableHeaderProps };
