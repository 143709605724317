import { PropsWithChildren, ReactNode } from 'react';
import {
    ResizableTableContainer as AriaResizableTableContainer,
    ResizableTableContainerProps as AriaResizableTableContainerProps,
} from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';
import focusRing from '../shared/focusRing.styles';

type ResizableTableContainerProps = {
    children?: ReactNode;
    className?: string;
} & AriaResizableTableContainerProps;

const ResizableTableContainer = ({
    children,
    className,
    ...rest
}: PropsWithChildren<ResizableTableContainerProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaResizableTableContainer
            {...localProps}
            className={cx(css(focusRing), recipeClassNames.resizableTableContainer, className)}>
            {children}
        </AriaResizableTableContainer>
    );
};

export { ResizableTableContainer };
export type { ResizableTableContainerProps };
