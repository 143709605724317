import * as Bar from './demo/darkBar.mdx';
import * as Bullet from './demo/darkBullet.mdx';
import * as HeatMap from './demo/darkHeatMap.mdx';
import * as Line from './demo/darkLine.mdx';
import * as Patterns from './demo/patterns.mdx';
import * as Pie from './demo/darkPie.mdx';
import * as ScatterPlot from './demo/darkScatterPlot.mdx';
import * as SimpleBar from './demo/simpleBar.mdx';
import * as SimpleBullet from './demo/simpleBullet.mdx';
import * as SimpleHeatMap from './demo/simpleHeatMap.mdx';
import * as SimpleLine from './demo/simpleLine.mdx';
import * as SimplePie from './demo/simplePie.mdx';
import * as SimpleScatterPlot from './demo/simpleScatterPlot.mdx';

export * from './NivoThemeValues';

export const NivoExamples = [
    Bar,
    Bullet,
    HeatMap,
    Line,
    Patterns,
    Pie,
    ScatterPlot,
    SimpleBar,
    SimpleBullet,
    SimpleHeatMap,
    SimpleLine,
    SimplePie,
    SimpleScatterPlot,
];
