
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Drag and drop with Directories'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A DirectoryDropItem references the contents of a directory on the user's device. It includes the name of the directory, as well as a method to iterate through the files and folders within the directory. The contents of any folders within the directory can be accessed recursively.
The getEntries method returns an async iterable object, which can be used in a for await...of loop. This provides each item in the directory as either a FileDropItem or DirectoryDropItem, and you can access the contents of each file as discussed above.`}</p>
    <p>{`This example accepts directory drops over the whole collection, and renders the contents as items in the list. DIRECTORY_DRAG_TYPE is imported from react-aria-components and included in the acceptedDragTypes prop to limit the accepted items to only directories.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import varnishUi from "@allenai/varnish-ui";
import FolderIcon from '@mui/icons-material/Folder';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const VarnishFolderIcon = varnishUi.varnishIcon(FolderIcon);
const VarnishFileIcon = varnishUi.varnishIcon(FileCopyIcon);

interface DirItem {
  name: string,
  kind: string,
  type: string
}

const Demo = () => {
    let [files, setFiles] = React.useState<DirItem[]>([]);
    let { dragAndDropHooks } = varnishUi.useDragAndDrop({
        acceptedDragTypes: [varnishUi.DIRECTORY_DRAG_TYPE],
        async onRootDrop(e) {
        // Read entries in directory and update state with relevant info.
        let dir = e.items.find(varnishUi.isDirectoryDropItem)!;
        let files = [];
        for await (let entry of dir.getEntries()) {
            files.push({
                name: entry.name,
                kind: entry.kind,
                type: entry.kind === 'directory' ? 'Directory' : entry.type
            });
        }
        setFiles(files);
        }
    });

    return (
        <varnishUi.Table
            aria-label="Droppable table"
            dragAndDropHooks={dragAndDropHooks}>
            <varnishUi.TableHeader>
                <varnishUi.Column>Kind</varnishUi.Column>
                <varnishUi.Column isRowHeader>Name</varnishUi.Column>
                <varnishUi.Column>Type</varnishUi.Column>
            </varnishUi.TableHeader>
            <varnishUi.TableBody items={files} renderEmptyState={() => 'Drop directory here'}>
                {item => (
                    <varnishUi.Row id={item.name}>
                        <varnishUi.Cell>{item.kind === 'directory' ? <VarnishFolderIcon size="small" color="primary" /> : <VarnishFileIcon size="small" color="primary" />}</varnishUi.Cell>
                        <varnishUi.Cell>{item.name}</varnishUi.Cell>
                        <varnishUi.Cell>{item.type}</varnishUi.Cell>
                    </varnishUi.Row>
                )}
            </varnishUi.TableBody>
        </varnishUi.Table>
  );
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;