
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'ResponsiveLine with DarkTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A complex example of the Nivo ResponsiveLine component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { lineDefaultsDark } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
    background: \${({ theme }) => theme.palette.background.reversed};
\`;

const data = [
    {
        id: 'japan',
        data: [
            {
                x: 'plane',
                y: 57,
            },
            {
                x: 'helicopter',
                y: 3,
            },
            {
                x: 'boat',
                y: 53,
            },
            {
                x: 'train',
                y: 57,
            },
            {
                x: 'subway',
                y: 104,
            },
            {
                x: 'bus',
                y: 112,
            },
            {
                x: 'car',
                y: 292,
            },
            {
                x: 'moto',
                y: 229,
            },
            {
                x: 'bicycle',
                y: 228,
            },
            {
                x: 'horse',
                y: 268,
            },
            {
                x: 'skateboard',
                y: 119,
            },
            {
                x: 'others',
                y: 4,
            },
        ],
    },
    {
        id: 'france',
        data: [
            {
                x: 'plane',
                y: 136,
            },
            {
                x: 'helicopter',
                y: 152,
            },
            {
                x: 'boat',
                y: 198,
            },
            {
                x: 'train',
                y: 157,
            },
            {
                x: 'subway',
                y: 77,
            },
            {
                x: 'bus',
                y: 33,
            },
            {
                x: 'car',
                y: 203,
            },
            {
                x: 'moto',
                y: 63,
            },
            {
                x: 'bicycle',
                y: 279,
            },
            {
                x: 'horse',
                y: 237,
            },
            {
                x: 'skateboard',
                y: 202,
            },
            {
                x: 'others',
                y: 71,
            },
        ],
    },
    {
        id: 'us',
        data: [
            {
                x: 'plane',
                y: 123,
            },
            {
                x: 'helicopter',
                y: 296,
            },
            {
                x: 'boat',
                y: 46,
            },
            {
                x: 'train',
                y: 282,
            },
            {
                x: 'subway',
                y: 107,
            },
            {
                x: 'bus',
                y: 260,
            },
            {
                x: 'car',
                y: 145,
            },
            {
                x: 'moto',
                y: 81,
            },
            {
                x: 'bicycle',
                y: 61,
            },
            {
                x: 'horse',
                y: 296,
            },
            {
                x: 'skateboard',
                y: 112,
            },
            {
                x: 'others',
                y: 202,
            },
        ],
    },
    {
        id: 'germany',
        data: [
            {
                x: 'plane',
                y: 154,
            },
            {
                x: 'helicopter',
                y: 143,
            },
            {
                x: 'boat',
                y: 74,
            },
            {
                x: 'train',
                y: 245,
            },
            {
                x: 'subway',
                y: 291,
            },
            {
                x: 'bus',
                y: 300,
            },
            {
                x: 'car',
                y: 288,
            },
            {
                x: 'moto',
                y: 161,
            },
            {
                x: 'bicycle',
                y: 270,
            },
            {
                x: 'horse',
                y: 247,
            },
            {
                x: 'skateboard',
                y: 114,
            },
            {
                x: 'others',
                y: 298,
            },
        ],
    },
    {
        id: 'norway',
        data: [
            {
                x: 'plane',
                y: 183,
            },
            {
                x: 'helicopter',
                y: 265,
            },
            {
                x: 'boat',
                y: 105,
            },
            {
                x: 'train',
                y: 221,
            },
            {
                x: 'subway',
                y: 31,
            },
            {
                x: 'bus',
                y: 135,
            },
            {
                x: 'car',
                y: 270,
            },
            {
                x: 'moto',
                y: 8,
            },
            {
                x: 'bicycle',
                y: 222,
            },
            {
                x: 'horse',
                y: 190,
            },
            {
                x: 'skateboard',
                y: 60,
            },
            {
                x: 'others',
                y: 6,
            },
        ],
    },
];

render(
    <ChartWrapper>
        <ResponsiveLine
            // add in varnish theme at top of all charts
            {...lineDefaultsDark}
            // add options specific to your chart
            // use varnish values when it makes sense
            // margin={{
            //     top: Theme.spacing['1'].value,
            //     right: Theme.spacing['4'].value,
            //     bottom: Theme.spacing.xl4.getValue(),
            //     left: Theme.spacing['6'].value,
            // }}
            margin={{
                left: 64,
                bottom: 72,
                right: 16,
                top: 32,
            }}
            axisLeft={{
                format: (d) => new Intl.NumberFormat().format(d),
                tickValues: 5,
                legend: 'Count',
                legendPosition: 'middle',
                legendOffset: -48,
            }}
            axisBottom={{
                tickRotation: -50,
                legend: 'Transportation',
                legendPosition: 'middle',
                legendOffset: 48,
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    itemWidth: 80,
                    itemHeight: 24,
                    translateY: -16,
                    symbolSize: 14,
                },
            ]}
            // add data
            data={data}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;