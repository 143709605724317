
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic usage'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Modal can be used inline, with a ModalTrigger that will trigger when clicked inside.
It can also be passed as a `}<inlineCode parentName="p">{`content`}</inlineCode>{` property to the trigger.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const MyModal = () => {
    return (
        <varnishUi.Modal isDismissable heading="Modal Heading" color="reversed">
            <p>Modal Contents</p>
        </varnishUi.Modal>
    );
};

render(
    <varnishUi.Stack direction="row" spacing={16}>
        <varnishUi.ModalTrigger>
            <varnishUi.Button>Open inline modal</varnishUi.Button>
            <varnishUi.Modal isDismissable heading="Modal Heading" color="reversed">
                <p>Modal Contents</p>
            </varnishUi.Modal>
        </varnishUi.ModalTrigger>
        <varnishUi.ModalTrigger content={<MyModal />}>
            <varnishUi.Button>Open modal as prop</varnishUi.Button>
        </varnishUi.ModalTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;