
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Usage pattern'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Here is an example of FileInput in action. Uploaded files can be redownloaded via the Download button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const Demo = () => {
    const [files, setFiles] = React.useState<File[]>([]);

    const downloadFiles = () => {
        files.forEach((file) => {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };
    return <varnishUi.Stack direction="column" spacing={8}>
            <varnishUi.FileInput onSelect={(f) => setFiles(f ? Array.from(f) : [])} />
            <varnishUi.Button onClick={downloadFiles} isDisabled={files.length === 0} color="primary" variant="contained" >
                Download
            </varnishUi.Button>
        </varnishUi.Stack>
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;