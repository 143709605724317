
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { CodeBlocksExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Components',
  title: 'CodeBlocks',
  cols: 2,
  iconSrc: './text-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`CodeBlocks`}</h2>
    <p>{`Display code blocks in a readable format.`}</p>
    <h3>{`When To Use`}</h3>
    <p>{`When you want to display a chunk of code in a readable format.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={CodeBlocksExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;