import { Label as AriaLabel, LabelProps as AriaLabelProps } from 'react-aria-components';

import { cx } from '@/utils/cx';

import { labelRecipe, LabelRecipeProps } from './label.styles';

type LabelProps = {
    className?: string;
} & AriaLabelProps &
    LabelRecipeProps;

const Label = ({ className, ...rest }: LabelProps) => {
    const [variantProps, localProps] = labelRecipe.splitVariantProps(rest);
    return <AriaLabel {...localProps} className={cx(labelRecipe(variantProps), className)} />;
};

export { Label };
export type { LabelProps };
