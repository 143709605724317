
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { StylingExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Overview',
  title: 'Styling',
  cols: 1,
  iconSrc: './palette-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Styling`}</h2>
    <p>{`Varnish UI can be styled to override the default theme.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={StylingExamples} mdxType="Examples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;