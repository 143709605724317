// see: https://github.com/Zenoo/mui-theme-creator

import { lazy } from 'react';

const AccordionExample = lazy(() => import('./Accordion'));
const AlertExample = lazy(() => import('./Alert'));
const AppBarExample = lazy(() => import('./AppBar'));
const AvatarExample = lazy(() => import('./Avatar'));
const BadgeExample = lazy(() => import('./Badge'));
const BottomNavigationExample = lazy(() => import('./BottomNavigation'));
const ButtonExample = lazy(() => import('./Button'));
const CardExample = lazy(() => import('./Card'));
const CheckboxesExample = lazy(() => import('./Checkboxes'));
const ChipExample = lazy(() => import('./Chip'));
const DialogExample = lazy(() => import('./Dialog'));
const FabExample = lazy(() => import('./FAB'));
const IconExample = lazy(() => import('./Icon'));
const ListExample = lazy(() => import('./List'));
const MenuExample = lazy(() => import('./Menu'));
const ProgressExample = lazy(() => import('./Progress'));
const RadioExample = lazy(() => import('./RadioGroup'));
const SelectExample = lazy(() => import('./Select'));
const SliderExample = lazy(() => import('./Slider'));
const SnackbarExample = lazy(() => import('./Snackbar'));
const StepperExample = lazy(() => import('./Stepper'));
const SwitchExample = lazy(() => import('./Switch'));
const TableExample = lazy(() => import('./Table'));
const TabsExample = lazy(() => import('./Tabs'));
const TextFieldExample = lazy(() => import('./TextField'));
const TooltipExample = lazy(() => import('./Tooltip'));
const TypographyExample = lazy(() => import('./Typography'));

// items skipped for now:
// Button Group
// Date/Time inputs
// Transfer List
// Drawer

// Alphabetically sorted list of all Material-UI component examples
export default [
    {
        id: 'Accordion',
        title: 'Accordion',
        component: <AccordionExample />,
        docs: 'https://material-ui.com/components/accordion/',
    },
    {
        id: 'Alert',
        title: 'Alert',
        component: <AlertExample />,
        docs: 'https://material-ui.com/components/alert/',
    },
    {
        id: 'Appbar',
        title: 'App Bar',
        component: <AppBarExample onDrawerButtonClick={() => {}} />,
        docs: 'https://material-ui.com/components/app-bar/',
    },
    {
        id: 'Avatar',
        title: 'Avatar',
        component: <AvatarExample />,
        docs: 'https://material-ui.com/components/avatars/',
    },
    {
        id: 'Badge',
        title: 'Badge',
        component: <BadgeExample />,
        docs: 'https://material-ui.com/components/badges/',
    },
    {
        id: 'BottomNavigation',
        title: 'Bottom Navigation',
        component: <BottomNavigationExample />,
        docs: 'https://material-ui.com/components/bottom-navigation/',
    },
    {
        id: 'Buttons',
        title: 'Buttons',
        component: <ButtonExample />,
        docs: 'https://material-ui.com/components/buttons/',
    },
    {
        id: 'Card',
        title: 'Card',
        component: <CardExample />,
        docs: 'https://material-ui.com/components/cards/',
    },
    {
        id: 'Checkboxes',
        title: 'Checkboxes',
        component: <CheckboxesExample />,
        docs: 'https://material-ui.com/components/checkboxes/',
    },
    {
        id: 'Chip',
        title: 'Chip',
        component: <ChipExample />,
        docs: 'https://material-ui.com/components/chips/',
    },
    {
        id: 'Dialog',
        title: 'Dialog',
        component: <DialogExample />,
        docs: 'https://material-ui.com/components/dialogs/',
    },
    {
        id: 'FloatingActionButton',
        title: 'Floating Action Button',
        component: <FabExample />,
        docs: 'https://material-ui.com/components/floating-action-button/',
    },
    {
        id: 'Icon',
        title: 'Icon',
        component: <IconExample />,
        docs: 'https://material-ui.com/components/icons/',
    },
    {
        id: 'List',
        title: 'List',
        component: <ListExample />,
        docs: 'https://material-ui.com/components/lists/',
    },
    {
        id: 'Menu',
        title: 'Menu',
        component: <MenuExample />,
        docs: 'https://material-ui.com/components/menus/',
    },
    {
        id: 'Progress',
        title: 'Progress',
        component: <ProgressExample />,
        docs: 'https://material-ui.com/components/progress/',
    },
    {
        id: 'Radio',
        title: 'Radio',
        component: <RadioExample />,
        docs: 'https://material-ui.com/components/radio-buttons/',
    },
    {
        id: 'Select',
        title: 'Select',
        component: <SelectExample />,
        docs: 'https://material-ui.com/components/selects/',
    },
    {
        id: 'Slider',
        title: 'Slider',
        component: <SliderExample />,
        docs: 'https://material-ui.com/components/slider/',
    },
    {
        id: 'Snackbar',
        title: 'Snackbar',
        component: <SnackbarExample />,
        docs: 'https://material-ui.com/components/snackbars/',
    },
    {
        id: 'Stepper',
        title: 'Stepper',
        component: <StepperExample />,
        docs: 'https://material-ui.com/components/steppers/',
    },
    {
        id: 'Switch',
        title: 'Switch',
        component: <SwitchExample />,
        docs: 'https://material-ui.com/components/switches/',
    },
    {
        id: 'Table',
        title: 'Table',
        component: <TableExample />,
        docs: 'https://material-ui.com/components/tables/',
    },
    {
        id: 'Tabs',
        title: 'Tabs',
        component: <TabsExample />,
        docs: 'https://material-ui.com/components/tabs/',
    },
    {
        id: 'TextField',
        title: 'TextField',
        component: <TextFieldExample />,
        docs: 'https://material-ui.com/components/text-fields/',
    },
    {
        id: 'Tooltip',
        title: 'Tooltip',
        component: <TooltipExample />,
        docs: 'https://material-ui.com/components/tooltips/',
    },
    {
        id: 'Typography',
        title: 'Typography',
        component: <TypographyExample />,
        docs: 'https://material-ui.com/components/typography/',
    },
];
