
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'Styling (MUI)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Styling with `}<inlineCode parentName="p">{`classNames`}</inlineCode>{` is the preferred approach. However, if you are currently using
`}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` and want to continue using a component-based solution, we recommend
transitioning to `}<inlineCode parentName="p">{`mui-styled`}</inlineCode>{` for a more consistent experience across Ai2 projects that use Varnish.
While `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` will still work with `}<inlineCode parentName="p">{`Varnish-UI`}</inlineCode>{` when properly wrapped, migrating to
`}<inlineCode parentName="p">{`mui-styled`}</inlineCode>{` ensures better alignment with our design standards.`}</p>
    <p>{`Preferred styling options, in order:`}</p>
    <ol>
      <li parentName="ol">{`pandaCSS`}</li>
      <li parentName="ol">{`classnames`}</li>
      <li parentName="ol">{`mui-styled`}</li>
      <li parentName="ol">{`styled-components`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider
const CustomButton = mui.styled(varnishUi.Button)\`
    background-color: \${({ theme }) => theme.color.B5.hex};
    padding: \${({ theme }) => theme.spacing(1)};
    font-size: \${({ theme }) => theme.typography.h5.fontSize};
    color: white;
    background-color: light-dark(\${({ theme }) => theme.color.B9.hex},  \${({ theme }) => theme.color.B5.hex});
\`;

render(<CustomButton>styled-component/mui.styled button</CustomButton>);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;