
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Basic'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Stack component serves as a versatile container that organizes the elements it wraps.`}</p>
    <p>{`Utilize the spacing prop to adjust the space between child elements. The `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` value can be a
number 1-16, providing flexibility in layout design.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack spacing={2}>
        <varnishUi.Button variant="outlined">Item 1</varnishUi.Button>
        <varnishUi.Button variant="outlined">Item 2</varnishUi.Button>
        <varnishUi.Button variant="outlined">Item 3</varnishUi.Button>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;