
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Overview',
  title: 'Material Design',
  cols: 2,
  iconSrc: './icons-2-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MuiComponentSamples = makeShortcode("MuiComponentSamples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Material Design (MUI)`}</h2>
    <p>{`Varnish has a peer dependency on `}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/all-components/"
      }}>{`Material Design`}</a>{`.`}</p>
    <p>{`To add mui run: `}<inlineCode parentName="p">{`yarn add @mui/material`}</inlineCode>{`.`}</p>
    <p>{`Then use Material Design Design components as described in their docs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`<Button variant="contained">Contained</Button>
`}</code></pre>
    <h3>{`Styling`}</h3>
    <p>{`You can override the css of a MUI component via styled components. Here is an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`const MyButton = styled(Button)\`
    color: #ff0044;
\`;
`}</code></pre>
    <h3>{`Examples`}</h3>
    <p>{`These are only a few of the many many components available from
`}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/all-components/"
      }}>{`Material Design`}</a>{`. Please see the full documentation for complete
examples.`}</p>
    <MuiComponentSamples mdxType="MuiComponentSamples" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;