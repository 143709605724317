
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 7,
  title: 'ResponsivePie with DarkTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A complex example of the Nivo ResponsivePie component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { pieDefaultsDark } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 300px;
    max-width: 100%;
    background: \${({ theme }) => theme.palette.background.reversed};
    padding: \${({ theme }) => theme.spacing['3']};
\`;

const data = [
    {
        id: 'go',
        label: 'go',
        value: 448,
    },
    {
        id: 'c',
        label: 'c',
        value: 134,
    },
    {
        id: 'make',
        label: 'make',
        value: 17,
    },
    {
        id: 'javascript',
        label: 'javascript',
        value: 361,
    },
    {
        id: 'css',
        label: 'css',
        value: 20,
    },
];

render(
    <ChartWrapper>
        <ResponsivePie
            // add in varnish theme at top of all charts
            {...pieDefaultsDark}
            // add options specific to your chart
            // use varnish values when it makes sense
            margin={{
                top: 32,
                bottom: 32,
                left: 32,
                right: 96,
            }}
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    translateX: 40,
                    itemWidth: 32,
                    itemHeight: 24,
                    symbolSize: 14,
                },
            ]}
            // add data
            data={data}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;