import { SliderTrackRenderProps as AriaSliderTrackRenderProps } from 'react-aria-components';

import { cx } from '@/utils/cx';

import { type SliderRecipeProps, sliderRecipe } from './slider.styles';

type SliderTrackIndicatorProps = {
    className?: string;
} & Pick<AriaSliderTrackRenderProps, 'orientation' | 'state'> &
    SliderRecipeProps;

const SliderTrackIndicator = ({ state, className, ...variantProps }: SliderTrackIndicatorProps) => {
    const { values } = state;
    const { orientation } = variantProps;
    const recipeClassNames = sliderRecipe(variantProps);

    const style: React.CSSProperties = {
        position: 'absolute',
    };
    let minValue = 0;
    const maxValue = state.getThumbPercent(state.values.length - 1);

    if (values.length > 1) {
        minValue = state.getThumbPercent(0);
    }

    if (orientation === 'vertical') {
        style.top = `${(1 - maxValue) * 100}%`;
        style.bottom = `${minValue * 100}%`;
    } else {
        style.left = `${minValue * 100}%`;
        style.right = `${(1 - maxValue) * 100}%`;
    }

    return <div style={style} className={cx(recipeClassNames.indicator, className)} />;
};

export { SliderTrackIndicator, SliderTrackIndicator as default };
export type { SliderTrackIndicatorProps };
