
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Projects'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Canonical logos for AI2's projects.`}</p>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import mui from '@mui/material';

const logos = varnishComponents.logos;
const ImageListItemBar = mui.ImageListItemBar;

const Item = mui.styled(mui.ImageListItem)\`
    background: white;
    width: 160px;
    margin: \${({ theme }) => theme.spacing(0.5)};
    border: 1px solid \${({ theme }) => theme.color.N4.hex};
\`;
const Center = mui.styled('div')\`
    margin: 60px auto 10px auto;
    text-align: center;
\`;
const List = mui.styled('div')\`
    display: flex;
    flex-wrap: wrap;
\`;

render(
    <List>
        <Item>
            <Center>
                <ImageListItemBar title="AllenNLP" position="top" />
                <logos.AllenNLP />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Aristo" position="top" />
                <logos.Aristo />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Mosaic" position="top" />
                <logos.Mosaic />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Prior" position="top" />
                <logos.Prior />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Semantic Scholar" position="top" />
                <logos.SemanticScholar />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Fairness" position="top" />
                <logos.Fairness />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="OLMo" position="top" />
                <logos.OLMo />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Incubator" position="top" />
                <logos.Incubator />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="EarthRanger" position="top" />
                <logos.EarthRanger className="earthranger_logo" />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Skylight" position="top" />
                <logos.Skylight className="skylight_logo" />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Climate" position="top" />
                <logos.Climate />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="Wildlands" position="top" />
                <logos.Wildlands />
            </Center>
        </Item>

        <Item>
            <Center>
                <ImageListItemBar title="ReViz" position="top" />
                <logos.Reviz polychrome={false} />
            </Center>
        </Item>
    </List>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;