
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 6,
  title: 'Composition'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can reorder elements of the slider by writing your own composed slider using the `}<inlineCode parentName="p">{`SliderBase`}</inlineCode>{`,
`}<inlineCode parentName="p">{`SliderOutput`}</inlineCode>{`, `}<inlineCode parentName="p">{`SliderTrack`}</inlineCode>{`, and `}<inlineCode parentName="p">{`SliderThumb`}</inlineCode>{` components. This allows you to create a custom
slider with your own styles and layout.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import emotionStyled from '@emotion/styled';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';

const BorderedDiv = emotionStyled('div')\`
    border: 1px dashed \${ThemeArch.color.B6.value};
    padding: 2px;
    margin: 2px;
    width: 100%;
\`;

const sliderClassName = () => {
    return emotionCss.css\`
       grid-template-columns: auto 1fr;
    \`;
};

const numberToEmoji = (num: number) => {
    if (num >= 0 && num <= 20) {
        return '😢';
    } else if (num <= 40) {
        return '😞';
    } else if (num <= 60) {
        return '😐';
    } else if (num <= 80) {
        return '🙂';
    } else {
        return '😄';
    }
}

render(
    <varnishUi.SliderBase className={emotionCss.cx(sliderClassName())} defaultValue={[37, 55]} color="primary">
        <BorderedDiv>
            <varnishUi.Label>Label</varnishUi.Label>
        </BorderedDiv>
        <BorderedDiv>
            <varnishUi.SliderTrack>
                {({ state }) => (
                    <>
                        <varnishUi.SliderTrackIndicator state={state} />
                        {state.values.map((_, i) => {
                            return (
                                <varnishUi.SliderThumb
                                    key={i}
                                    index={i}
                                />
                            );
                        })}
                    </>
                )}
            </varnishUi.SliderTrack>
        </BorderedDiv>
        <BorderedDiv>
            {/* output at the bottom - with emojis */}
            <varnishUi.SliderOutput>
                {({ state }) => state.values.map((_, i) => \`Output \${state.getThumbValueLabel(i)} \${numberToEmoji(state.getThumbValueLabel(i))}\`).join(' – ')}
            </varnishUi.SliderOutput>
        </BorderedDiv>
    </varnishUi.SliderBase>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;