import { cva, RecipeVariantProps } from '@allenai/varnish-panda-runtime/css';

const descriptionRecipe = cva({
    base: {
        color: 'text.primary',
        _groupDisabled: {
            color: '[#343434]', // gray.40 -- https://github.com/allenai/varnish/issues/1026
        },
    },
    variants: {
        size: {
            small: {
                fontSize: '[12px]',
            },
            medium: {
                fontSize: 'sm',
            },
            large: {
                fontSize: 'md',
            },
        },
    },
    defaultVariants: {
        size: 'medium',
    },
});

type DescriptionRecipeProps = RecipeVariantProps<typeof descriptionRecipe>;

export { descriptionRecipe, descriptionRecipe as default };
export type { DescriptionRecipeProps };
