
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <p><inlineCode parentName="p">{`color`}</inlineCode>{` controls the fill color and `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` controls the size.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const VarnishCopyIcon = varnishUi.varnishIcon(ContentCopyIcon);

const RedStyledSpark = styled(varnishUi.Icons.Spark)\`
    color: red;
\`;

const RedStyledCopyIcon = styled(VarnishCopyIcon)\`
    color: red;
\`;

const RedStyledIconWrapped = styled(varnishUi.Icon)\`
    color: red;
\`;

const LargeStyledSpark = styled(varnishUi.Icons.Spark)\`
    font-size: 32px;
\`;

const LargeStyledCopyIcon = styled(VarnishCopyIcon)\`
    font-size: 32px;
\`;

const LargeStyledIconWrapped = styled(varnishUi.Icon)\`
    font-size: 32px;
\`;

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Stack>
                <RedStyledSpark size="large" />
                <varnishUi.Label>styled vUI overriding color</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <RedStyledCopyIcon size="large" />
                <varnishUi.Label>styled varnish(ed)Icon overriding color</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <RedStyledIconWrapped size="large">
                    <ContentCopyIcon />
                </StyledIconWrapped>
                <varnishUi.Label>MUI + varnish Icon styled overriding color</varnishUi.Label>
            </varnishUi.Stack>
        </varnishUi.Stack>
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Stack>
                <LargeStyledSpark color="tertiary" />
                <varnishUi.Label>styled vUI sprite overriding size</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <LargeStyledCopyIcon color="tertiary" />
                <varnishUi.Label>styled varnish(ed)Icon overriding size</varnishUi.Label>
            </varnishUi.Stack>
            <varnishUi.Stack>
                <LargeStyledIconWrapped color="tertiary">
                    <ContentCopyIcon />
                </StyledIconWrapped>
                <varnishUi.Label>MUI + varnish Icon styled overriding size</varnishUi.Label>
            </varnishUi.Stack>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;