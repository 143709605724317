import React, { DetailedHTMLProps, useState, useEffect } from 'react';
import useEyeDropper from 'use-eye-dropper';
import Button from '@mui/material/Button';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { styled } from '@mui/material';
import { Color, RGBA, color as varnishColors, hexToRgb } from '@allenai/varnish2/theme';
import varnishTheme from '@allenai/varnish-theme';

interface ColorSimilarity {
    color: Color;
    value: number;
}

interface PaletteItem {
    value: string;
    path: readonly string[];
}

interface PaletteGroup {
    [key: string]: PaletteItem | PaletteGroup;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPaletteItem(item: any): item is PaletteItem {
    return typeof item === 'object' && 'value' in item && 'path' in item;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPaletteGroup(item: any): item is PaletteGroup {
    return typeof item === 'object' && !('value' in item);
}

interface ColorKeyAndPath {
    path: string;
    color: Color;
}

// find the color in varnish based on the rgb string
const rgbaToColor = (key: string) => {
    const found = Object.values(varnishColors).filter((v) => {
        return v.rgba.toString().replace(/\s/g, '') === key.replace(/\s/g, '');
    });
    if (!found.length) {
        throw new Error(`Could not find color for key: ${key}`);
    }
    return found[0];
};

// walk varnish theme palette and return colors for each value
const getColorKeyPaths = (obj: PaletteGroup): ColorKeyAndPath[] => {
    let colorKeyPaths: ColorKeyAndPath[] = [];
    for (const key in obj) {
        const item = obj[key];
        if (isPaletteItem(item)) {
            colorKeyPaths.push({
                path: item.path.slice(1, 99).join('.'),
                color: rgbaToColor(item.value),
            });
        } else if (isPaletteGroup(item)) {
            colorKeyPaths = colorKeyPaths.concat(getColorKeyPaths(item));
        }
    }
    return colorKeyPaths;
};

// returns similarity of colors based on eye
// proportions for RGB are defined as 0.299, 0.587, 0.114 from the idea of converting to grayscale,
// as our eye notices blue change less. see: https://linux.die.net/man/1/ppmtopgm
const compareColors = (v1: RGBA, v2: RGBA) => {
    return (
        0.299 * Math.pow(v1.r - v2.r, 2) +
        0.587 * Math.pow(v1.g - v2.g, 2) +
        0.114 * Math.pow(v1.b - v2.b, 2)
    );
};

const compareAllColors = (inputColor: RGBA, colorArray: Color[]): ColorSimilarity[] => {
    const scores = colorArray.map((v) => {
        return { color: v, value: compareColors(inputColor, v.rgba) };
    });
    scores.sort((a, b) => a.value - b.value);
    return scores;
};

export const ColorPicker = () => {
    const { open, isSupported } = useEyeDropper();
    const [colorsToRender, setColorsToRender] = useState<ColorSimilarity[]>([]);
    const [selectedColor, setSelectedColor] = useState<string>();
    const [paletteColors, setPaletteColors] = useState<ColorKeyAndPath[]>([]);

    useEffect(() => {
        setPaletteColors(getColorKeyPaths(varnishTheme.palette));
    }, []);

    const pickColor = () => {
        open().then((colorP) => {
            setSelectedColor(colorP.sRGBHex);
            const filteredVarnishColors = Object.values(varnishColors).filter(
                (v) => v !== varnishColors.transparent && v !== varnishColors.white
            );
            const filteredPaletteColors = paletteColors.map(
                (v) => new Color(v.path, v.color.hex, v.color.rgba, v.color.useContrastText)
            );
            // get combbined scores for colors and palette colors
            const scoredColors = compareAllColors(hexToRgb(colorP.sRGBHex), [
                ...filteredVarnishColors,
                ...filteredPaletteColors,
            ]);
            const medianVal = scoredColors[Math.round(scoredColors.length / 2)].value;
            setColorsToRender(
                scoredColors.slice(0, 15).map((v) => {
                    return { color: v.color, value: 1 - v.value / medianVal };
                })
            );
        });
    };

    if (!isSupported()) {
        return null;
    }
    return (
        <React.Fragment>
            <Button variant="contained" startIcon={<ColorizeIcon />} onClick={pickColor}>
                Pick color
            </Button>
            {colorsToRender.length ? (
                <div>
                    <div>These are the most similar colors to: {selectedColor}</div>
                    <ColorsContainer background={selectedColor || 'white'}>
                        {colorsToRender.map(({ color, value }) => (
                            <Swatch key={color.displayName} varnishColor={color}>
                                <div>{color.displayName}</div>
                                <div>{Math.round(value * 1000) / 10}%</div>
                            </Swatch>
                        ))}
                    </ColorsContainer>
                </div>
            ) : null}
        </React.Fragment>
    );
};

interface ColorsContainerProps
    extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    background: string;
}

const ColorsContainer = styled(({ background, ...rest }: ColorsContainerProps) => (
    <div {...rest} />
))`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: ${({ background }) => background};
    border: 1px solid ${({ theme }) => theme.color.N5.hex};
`;

interface SwatchProps
    extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    varnishColor: Color;
}

const Swatch = styled(({ varnishColor, ...rest }: SwatchProps) => <div {...rest} />)`
    color: ${({ varnishColor }) => varnishColor.contrastTextColor};
    background: ${({ varnishColor }) => varnishColor.hex};
    padding: ${({ theme }) => theme.spacing(1.5)};
    margin: ${({ theme }) => theme.spacing(1.5)};
`;
