import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const snackbarListRecipe = sva({
    slots: ['root'],
    base: {
        root: {
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: '[1rem]',
            zIndex: '[snackbar]',
        },
    },
    variants: {
        anchorOrigin: {
            topLeft: {
                root: {
                    top: '[1rem]',
                    left: '[1rem]',
                },
            },
            topCenter: {
                root: {
                    top: '[1rem]',
                    left: '[50%]',
                    transform: 'translateX(-50%)',
                },
            },
            topRight: {
                root: {
                    top: '[1rem]',
                    right: '[1rem]',
                },
            },
            bottomLeft: {
                root: {
                    bottom: '[1rem]',
                    left: '[1rem]',
                },
            },
            bottomCenter: {
                root: {
                    bottom: '[1rem]',
                    left: '[50%]',
                    transform: 'translateX(-50%)',
                },
            },
            bottomRight: {
                root: {
                    bottom: '[1rem]',
                    right: '[1rem]',
                },
            },
        },
    },
    defaultVariants: {
        anchorOrigin: 'bottomLeft',
    },
});

type SnackbarListRecipeProps = RecipeVariantProps<typeof snackbarListRecipe>;

export { snackbarListRecipe, snackbarListRecipe as default };
export type { SnackbarListRecipeProps };
