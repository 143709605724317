'use client';

import { Theme, Typography, TypographyProps } from '@mui/material';

interface CodeProps extends Omit<TypographyProps, 'variant'> {
    variant?: TypographyProps['variant'] | 'dark';
}

export const Code = ({ children, variant, ...typographyProps }: CodeProps) => {
    return (
        <Typography
            component="pre"
            variant="monospace"
            sx={{
                maxHeight: '500px',
                maxWidth: '100%',
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                marginY: (theme) => theme.spacing(2),
                padding: (theme) => theme.spacing(3),
                backgroundColor: (theme: Theme) =>
                    variant === 'dark'
                        ? theme.palette.background.reversed
                        : theme.palette.background.paper,
                color: (theme: Theme) =>
                    variant === 'dark' ? theme.palette.text.reversed : theme.palette.text.primary,
            }}
            {...typographyProps}>
            {children}
        </Typography>
    );
};

export const InlineCode = ({ children, variant, ...typographyProps }: CodeProps) => {
    return (
        <Typography
            component="code"
            variant="monospace"
            sx={{
                display: 'inline-block',
                whiteSpace: 'pre-wrap',
                paddingX: (theme) => theme.spacing(0.5),
                marginX: (theme) => theme.spacing(0.5),
                backgroundColor: (theme: Theme) =>
                    variant === 'dark'
                        ? theme.palette.background.reversed
                        : theme.palette.background.paper,
                color: (theme: Theme) =>
                    variant === 'dark' ? theme.palette.text.reversed : theme.palette.text.primary,
            }}
            {...typographyProps}>
            {children}
        </Typography>
    );
};

export default { Code, InlineCode };
