import { Tab as AriaTab, TabProps as AriaTabProps, Key } from 'react-aria-components';
import { css } from '@allenai/varnish-panda-runtime/css';

import { PropsWithChildren } from 'react';

import { cx } from '@/utils/cx';

import focusRing from '@/components/shared/focusRing.styles';

import TabsRecipeProps, { tabsRecipe } from './tabs.styles';

type TabProps = {
    id: Key;
    isDisabled?: boolean;
    children?: React.ReactNode;
    className?: string;
} & AriaTabProps &
    TabsRecipeProps;

const Tab = ({ id, isDisabled, className, children, ...rest }: PropsWithChildren<TabProps>) => {
    const [variantProps, localProps] = tabsRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = tabsRecipe.raw(variantProps);

    return (
        <AriaTab
            {...localProps}
            className={cx(css(focusRing, recipeClassNames.tab), className)}
            id={id}
            isDisabled={isDisabled}>
            {children}
        </AriaTab>
    );
};

export { Tab };
export type { TabProps };
