
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Controlled'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Modal can be controlled programatically (i.e. with state) by using the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

const ModalDemo = () => {
    const [isOpen, setOpen] = React.useState(false);

    const handleClick = (e) => {
        setTimeout(() => {
            setOpen(!isOpen);
        }, Math.random() * 1000);
    };

    return (
        <>
            <varnishUi.Button onClick={handleClick}>Open soonish</varnishUi.Button>
            <varnishUi.Modal
                isDismissable
                heading="Modal Heading"
                color="reversed"
                isOpen={isOpen}
                onOpenChange={setOpen}>
                <p>Modal Contents</p>
            </varnishUi.Modal>
        </>
    );
};

render(<ModalDemo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;