
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Required Validation'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`To require that specific checkboxes are checked, set the isRequired prop at the Checkbox level instead of the CheckboxGroup. The following example shows how to require that all items are selected.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <form>
        <varnishUi.CheckboxGroup label="Favorite sports" errorMessage="Please select all sports">
            <varnishUi.Checkbox isRequired value="soccer">
                Soccer
            </varnishUi.Checkbox>
            <varnishUi.Checkbox isRequired value="baseball">
                Baseball
            </varnishUi.Checkbox>
            <varnishUi.Checkbox isRequired value="basketball">
                Basketball
            </varnishUi.Checkbox>
        </varnishUi.CheckboxGroup>
        <br />
        <varnishUi.Button variant="outlined" type="submit">
            Submit
        </varnishUi.Button>
    </form>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;