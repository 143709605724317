
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Overview',
  title: 'Getting Started',
  cols: 1,
  iconSrc: './tutorials-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const PeerDepsVarnishMui = makeShortcode("PeerDepsVarnishMui");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Getting Started`}</h1>
    <h2>{`Note About Versions`}</h2>
    <p>{`All new applications should be using `}<inlineCode parentName="p">{`Varnish2`}</inlineCode>{`. However, many older applications will still be using `}<inlineCode parentName="p">{`Varnish`}</inlineCode>{`. This older version is deprecated and no longer actively supported by ReViz.
New applications can also choose to use `}<inlineCode parentName="p">{`varnish-ui`}</inlineCode>{` which is currently in alpha.`}</p>
    <h2>{`Upgrading from Varnish to Varnish2`}</h2>
    <p>{`Upgrading older applications is non trivial. contact REVIZ if you feel this should happen.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`This MUI-based component library is a well-established, reliable choice with robust branding support
across a wide range of components. It’s ideal for applications that require a consistent, branded
look and need a variety of UI elements ready for use. While it’s the primary option available at the
moment, it will eventually be phased out for `}<inlineCode parentName="p">{`varnish-ui`}</inlineCode>{`. For projects needing comprehensive
branded components and stable performance, this library provides everything needed for a cohesive
user experience.`}</p>
    <p>{`If you started your application from `}<a parentName="p" {...{
        "href": "https://github.com/allenai/skiff-template"
      }}>{`Skiff Template`}</a>{`,
then you're already using `}<inlineCode parentName="p">{`Varnish`}</inlineCode>{`, as it comes installed by default.`}</p>
    <p>{`However, if you didn't, follow these steps to use `}<inlineCode parentName="p">{`Varnish`}</inlineCode>{`:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`@allenai/varnish2`}</inlineCode>{` and all dependencies`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`// with npm
npm install @allenai/varnish2

// with yarn
yarn add @allenai/varnish2
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install Peer Dependencies`}</p>
        <PeerDepsVarnishMui mdxType="PeerDepsVarnishMui" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add Varnish fonts by importing them in your index.html.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`<head>
    ...
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap" rel="stylesheet">
</head>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wrap your `}<inlineCode parentName="p">{`<YourApp />`}</inlineCode>{` in the `}<inlineCode parentName="p">{`<VarnishApp />`}</inlineCode>{` component:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import { VarnishApp } from '@allenai/varnish2/components';

const App = () => (
    <VarnishApp>
        <YourApp />
    </VarnishApp>
);
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now that you have Varnish installed, you can start using it to style your application.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "dark",
            "dark": true
          }}>{`import { Footer } from '@allenai/varnish2/components';

// use as any other React component
const MyComponent = () => (
    <div>
        <h1>👋 Hello World</h1>
        <Footer />
    </div>
);
`}</code></pre>
      </li>
    </ol>
    <h2>{`Material UI Components`}</h2>
    <p><inlineCode parentName="p">{`Varnish`}</inlineCode>{` consists of a few components and many styles that are automatically applied to all
`}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/getting-started/supported-components/"
      }}>{`Material UI components`}</a>{`. We encourage you to explore their
documentation and use any component that suits you.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;