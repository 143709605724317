import { BrowserRouter } from 'react-router-dom';
import { TableWrapper, VarnishApp, VarnishContext } from '@allenai/varnish2/components';
import { MDXProvider } from '@mdx-js/react';
import { getTheme } from '@allenai/varnish2/theme';
import { getRouterOverriddenTheme } from '@allenai/varnish2/utils';
import { SpriteProvider, SVGSpriteLink } from '@allenai/varnish-ui';
import varnishUiIconSprite from '@allenai/varnish-ui/varnish-ui-sprite.svg';

import {
    GlobalStyles,
    Link,
    styled,
    ThemeProvider,
    Typography,
    type TypographyProps,
} from '@mui/material';

import React, { ComponentProps } from 'react';

import { HashLink } from 'react-router-hash-link';

import { AppChrome } from './AppChrome';
import { LiveProvider, Examples } from './Examples';
import { WarningBox, AlphaMsg } from './shared/WarningBox';
import { LinkedHeader } from './shared/LinkedHeaders';

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// Dynamically imports all symbols from files with the name `index` in the `pages` directory.
// We use this so that the MDX components can use Components that's defined there.
const loadPageComponents = (): { [symbolName: string]: unknown } => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const moduleLoaderByPath = (require as any).context('./pages', true, /index\.(ts)$/);
    const includeComponents = moduleLoaderByPath.keys().map(moduleLoaderByPath);
    const importedSymbols: { [symbolName: string]: unknown } = {};
    includeComponents.forEach((module: Record<string, unknown>) => {
        Object.keys(module).forEach((name: string) => {
            if (name in importedSymbols) {
                throw Error(`The ${name} symbol is exported by multiple modules.`);
            }
            importedSymbols[name] = module[name];
        });
    });
    return importedSymbols;
};

/* eslint-enable @typescript-eslint/no-unsafe-member-access */
/* eslint-enable @typescript-eslint/no-unsafe-call */
/* eslint-enable @typescript-eslint/no-unsafe-assignment */

export const ApiPropTableWrapper = styled(TableWrapper)`
    table {
        td {
            word-break: auto-phrase;

            /* Our demo tables are mosty used for API props info
               Make the second column pink because its the Type row */
            &:nth-of-type(2):not(:last-child) {
                color: ${({ theme }) => theme.color.M6.hex};
            }
        }
    }
`;

// a mapping of components overrides used by MDX
// Note: We map `<pre />` tags to a `<span />` since we use the `<LiveProvider />` to highlight code
// samples. If we don't do that things don't work quite right.
// See: https://mdxjs.com/guides/live-code
const components = {
    ...loadPageComponents(),
    h1: (props: Omit<ComponentProps<typeof LinkedHeader>, 'variant'>) => (
        <LinkedHeader gutterBottom variant="h1" {...props} />
    ),
    h2: (props: Omit<ComponentProps<typeof LinkedHeader>, 'variant'>) => (
        <LinkedHeader gutterBottom variant="h2" {...props} />
    ),
    h3: (props: Omit<ComponentProps<typeof LinkedHeader>, 'variant'>) => (
        <LinkedHeader gutterBottom variant="h3" {...props} />
    ),
    h4: (props: Omit<ComponentProps<typeof LinkedHeader>, 'variant'>) => (
        <LinkedHeader gutterBottom variant="h4" {...props} />
    ),
    h5: (props: Omit<TypographyProps, 'variant'>) => (
        <Typography gutterBottom variant="h5" {...props} />
    ),
    h6: (props: Omit<TypographyProps, 'variant'>) => (
        <Typography gutterBottom variant="h6" {...props} />
    ),
    pre: (props: ComponentProps<'span'>) => <span {...props} />,
    code: (props: ComponentProps<typeof LiveProvider>) => <LiveProvider {...props} />,
    table: (props: ComponentProps<'table'>) => (
        <ApiPropTableWrapper>
            <table {...props} />
        </ApiPropTableWrapper>
    ),
    a: Link,
    Examples,
    AlphaMsg,
    WarningBox,
};
const GlobalStyle = ({ headerHeight }: { headerHeight: number }) => (
    <GlobalStyles
        styles={{
            html: {
                scrollPaddingTop: `${headerHeight}px`, // offset header height when scrolling to anchor
            },
        }}
    />
);
export const App = () => {
    const theme = getTheme(getRouterOverriddenTheme(HashLink));
    const { currentHeaderHeight } = React.useContext(VarnishContext);

    React.useEffect(() => {
        // scroll to anchor in URI on pageload for Safari
        if (
            document.location.hash !== '' &&
            // eslint-disable-next-line @typescript-eslint/prefer-includes
            navigator.userAgent.indexOf('Safari') > -1
        ) {
            document.location = document.location.hash;
        }
    }, []);

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <VarnishApp layout="left-aligned" theme={theme}>
                    <SpriteProvider sprite={varnishUiIconSprite}>
                        {/* This should be in head (we aren't using react-helmet in the demo) somewhere */}
                        <SVGSpriteLink />
                        <GlobalStyle headerHeight={currentHeaderHeight} />
                        <MDXProvider components={components}>
                            <AppChrome />
                        </MDXProvider>
                    </SpriteProvider>
                </VarnishApp>
            </ThemeProvider>
        </BrowserRouter>
    );
};
