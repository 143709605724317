
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Sizing the component can be done with css, by default it will fill its container.`}</p>
    <p><inlineCode parentName="p">{`color`}</inlineCode>{` controls the fill color.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';

const heightClass = emotionCss.css\`
    height: 82px;
    width: auto;
\`;

const widthClass = emotionCss.css\`
    width: 205px;
\`;

render(
    <varnishUi.Stack spacing={16}>
        <varnishUi.Logos.Skiff className={heightClass} />
        <varnishUi.Logos.Varnish className={widthClass} />
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;