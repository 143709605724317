
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { ReactRouterExamples } from '.';
export const frontMatter = {
  lib: 'varnish-mui',
  type: 'Patterns',
  title: 'React Router',
  cols: 1,
  iconSrc: './links-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Varnish With React Router`}</h2>
    <p>{`AI2 Varnish With React Router`}</p>
    <p>{`This is documentation for how you can use React Router in a Varnish App and inherit Varnish theming for links.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactrouter.com/en/main"
      }}>{`React Router`}</a>{` is a tool commonly used for client-side routing. You can pull this into your Varnish App and by default get the Varnish theming for react-router links. Below is an example of how this can look when routing to pages and getting a scroll-to-top experience upon navigating to a page.`}</p>
    <p>{`The 'To Getting Started' example link below shows how to employ the contrast colors of Varnish links via the linkContrast class if you are using a dark theme.`}</p>
    <h3>{`Example`}</h3>
    <Examples cols={frontMatter.cols} examples={ReactRouterExamples} mdxType="Examples" />
    <h3>{`Global theme Link`}</h3>
    <p>{`In order to use React Router with Single Page Applications, we have to configure the links in the MUI library to use React Router.
This is done by calling `}<inlineCode parentName="p">{`getRouterOverriddenTheme()`}</inlineCode>{` in your VarnishApp theme.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { Link as RouterLink } from 'react-router-dom';
import { VarnishApp } from '@allenai/varnish2/components';
import { getRouterOverriddenTheme } from '@allenai/varnish2/utils';
<VarnishApp theme={getRouterOverriddenTheme(RouterLink)}>
    <Header.AI2Banner />
</VarnishApp>;
`}</code></pre>
    <h3>{`Anchor Links and react-router`}</h3>
    <p>{`If you want to use react-router and have anchor links, then import `}<inlineCode parentName="p">{`HashLink`}</inlineCode>{` from `}<inlineCode parentName="p">{`react-router-hash-link`}</inlineCode>{` and pass it in instead`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { HashLink } from 'react-router-hash-link';
import { VarnishApp } from '@allenai/varnish2/components';
import { getRouterOverriddenTheme } from '@allenai/varnish2/utils';
<VarnishApp theme={getRouterOverriddenTheme(HashLink)}>
    <Header.AI2Banner />
</VarnishApp>;
`}</code></pre>
    <h3>{`ScrollToTopOnPageChange`}</h3>
    <p>{`A common function you may want to add to you React Router Application to auto scroll the user to the top of a page when they navigate to a new page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTopOnPageChange = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return null;
};
`}</code></pre>
    <h4>{`Usage`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import { VarnishApp } from '@allenai/varnish2/components';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

<BrowserRouter>
    <ScrollToTopOnPageChange />
    <VarnishApp>
        <App />
    </VarnishApp>
</BrowserRouter>;
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;