import * as Default from './demo/default.mdx';
import * as Size from './demo/size.mdx';
import * as Disable from './demo/disable.mdx';
import * as Validate from './demo/validate.mdx';
import * as MultipleFiles from './demo/multipleFiles.mdx';
import * as ExampleUsage from './demo/exampleUsage.mdx';
import * as FileInputButton from './demo/button.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const FileInputExamples = [
    Default,
    Size,
    Disable,
    Validate,
    MultipleFiles,
    ExampleUsage,
    FileInputButton,
    StylingCss,
    StylingStyled,
];
