import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const markdownRecipe = sva({
    slots: [
        'root',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'ul',
        'li',
        'img',
        'em',
        'del',
        'ol',
        'hr',
        'table',
    ],
    base: {
        root: {
            color: 'text.primary',
        },
        h1: {
            fontSize: '[2.25rem]',
            fontFamily: '[PP Telegraf]',
            fontWeight: '[500]',
            fontStyle: 'normal',
            letterSpacing: '0',
            lineHeight: '[43.2px]',
        },
        h2: {
            fontSize: '[2rem]',
            fontFamily: '[PP Telegraf]',
            fontStyle: 'normal',
            fontWeight: '[500]',
            lineHeight: '[38.4px]',
            letterSpacing: '0',
        },
        h3: {
            fontSize: '[1.5rem]',
            fontFamily: '[PP Telegraf]',

            fontStyle: 'normal',
            fontWeight: '[500]',
            lineHeight: '[28.8px]',
            letterSpacing: '0',
        },
        h4: {
            fontSize: '[1.25rem]',
            fontFamily: '[PP Telegraf]',
            fontStyle: 'normal',
            fontWeight: '[500]',
            lineHeight: '[24px]',
            letterSpacing: '0',
        },
        h5: {
            fontSize: '[1.125rem]',
            fontFamily: '[PP Telegraf]',

            fontStyle: 'normal',
            fontWeight: '[500]',
            lineHeight: '[21.6px]',
            letterSpacing: '0',
        },
        h6: {
            fontSize: '[1rem]',
            fontFamily: '[PP Telegraf]',
            fontStyle: 'normal',
            fontWeight: '[500]',
            lineHeight: '[20.8px]',
            letterSpacing: '0',
        },
        p: {
            fontSize: '[0.875rem]',
            fontFamily: '[Manrope]',
            fontStyle: 'normal',
            fontWeight: '[400]',
            lineHeight: '[21px]',
            letterSpacing: '0',
        },
        ul: {
            listStyleType: 'revert',
            marginBottom: '[16px]',
        },
        li: {
            marginBottom: '0',
        },
        img: {
            maxWidth: '[100%]',
            '&[src*="#thumbnail"]': {
                width: '[64px]',
            },
        },
        em: {
            fontStyle: 'italic',
        },
        del: {
            textDecoration: 'line-through',
        },
        ol: {
            marginBottom: '[16px]',
        },
        hr: {
            borderColor: 'gray.60',
        },
        table: {
            borderCollapse: 'collapse',
            '& th, & td': {
                border: '1px solid',
                borderColor: 'gray.60',
                textAlign: 'left',
                padding: '[8px]',
            },
        },
    },
});

type MarkdownRecipeProps = RecipeVariantProps<typeof markdownRecipe>;

export { markdownRecipe, MarkdownRecipeProps as default };
export type { MarkdownRecipeProps };
