
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 11,
  title: 'ResponsiveBullet with DarkTheme'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`A complex example of the Nivo ResponsiveBullet component styled for Varnish.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import { styled } from '@mui/material';
import { ResponsiveBullet } from '@nivo/bullet';
import { bulletDefaultsDark } from '@allenai/varnish-nivo';

const ChartWrapper = styled('div')\`
    height: 350px;
    max-width: 100%;
    background: \${({ theme }) => theme.palette.background.reversed};
    padding: \${({ theme }) => theme.spacing['3']};
\`;

const data = [
    {
        id: 'temp',
        ranges: [127, 8, 132, 0, 140],
        measures: [106],
        markers: [118],
    },
    {
        id: 'power',
        ranges: [1.15, 0.28, 1.46, 0, 2],
        measures: [0.8, 1.21],
        markers: [1.95],
    },
    {
        id: 'volume',
        ranges: [32, 6, 0, 12, 21, 10, 40],
        measures: [22],
        markers: [28],
    },
    {
        id: 'cost',
        ranges: [225, 3716, 39977, 0, 50000],
        measures: [2597, 33530],
        markers: [9422],
    },
    {
        id: 'revenue',
        ranges: [3, 3, 8, 0, 11],
        measures: [5],
        markers: [8.37, 10.31],
    },
];

render(
    <ChartWrapper>
        <ResponsiveBullet
            // add in varnish theme at top of all charts
            {...bulletDefaultsDark}
            // add options specific to your chart
            // use varnish values when it makes sense
            spacing={40}
            margin={{
                top: 16,
                right: 24,
                bottom: 32,
                left: 80,
            }}
            // add data
            data={data}
        />
    </ChartWrapper>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;