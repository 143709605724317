
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Action'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You may provide either your own custom action, or provide an onClose handler to be used with a default Close Icon button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="column" spacing={25}>
        <varnishUi.Alert severity="info" variant="contained">
            No action
        </varnishUi.Alert>
        <varnishUi.Alert severity="info" variant="contained" onClose={() => {}}>
            onClose provided
        </varnishUi.Alert>
        <varnishUi.Alert
            severity="info"
            variant="contained"
            action={<varnishUi.Link>Undo</varnishUi.Link>}>
            Custom action
        </varnishUi.Alert>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;