import { Popover as AriaPopover, PopoverProps as AriaPopoverProps } from 'react-aria-components';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import selectRecipe, { SelectRecipeProps } from '@/components/select/select.styles';
import focusRing from '@/components/shared/focusRing.styles';

type SelectPopoverProps = {
    label?: string;
    className?: string;
} & AriaPopoverProps &
    SelectRecipeProps;

const SelectPopover = ({ className, ...rest }: SelectPopoverProps) => {
    const [variantProps, localProps] = selectRecipe.splitVariantProps(rest);
    // `raw` receipe is required to be able to merge css objects (used in focus ring)
    const recipeClassNames = selectRecipe.raw(variantProps);
    return (
        <AriaPopover
            {...localProps}
            // we need to wrap the `raw` focusRing and `raw` recipeClassNames.thumb in `css()` to merge them
            // there is no garauntee which class will take precidence
            className={cx(css(recipeClassNames.popover, focusRing), className)}
        />
    );
};

export { SelectPopover, SelectPopover as default };
export type { SelectPopoverProps };
