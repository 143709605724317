import * as Default from './demo/default.mdx';
import * as Label from './demo/label.mdx';
import * as Color from './demo/color.mdx';
import * as Size from './demo/size.mdx';
import * as Validation from './demo/validation.mdx';
import * as Description from './demo/description.mdx';
import * as Disabled from './demo/disabled.mdx';
import * as Readonly from './demo/readonly.mdx';
import * as GroupValues from './demo/groupValues.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StylingStyled from './demo/stylingStyled.mdx';

export const CheckboxGroupExamples = [
    Default,
    Label,
    Color,
    Size,
    Validation,
    Description,
    Disabled,
    Readonly,
    GroupValues,
    StylingCss,
    StylingStyled,
];
