import { css } from '@allenai/varnish-panda-runtime/css';

/**
 *  focusRing styles can be used to apply a CSS class when an element has keyboard focus. This helps
 *  keyboard users determine which element on a page or in an application has keyboard focus as they
 *  navigate around. Focus rings are only visible when interacting with a keyboard so as not to
 *  distract mouse and touch screen users. When we are unable to detect if the user is using a mouse
 *  or touch screen, such as switching in from a different tab, we show the focus ring.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/FocusRing.html
 */

const focusRingGenericStyles = css.raw({
    outlineStyle: 'solid',
    ringColor: {
        base: 'accent.tertiary',
    },
    ringOffset: '[0]',
    ringWidth: {
        base: '0',
    },
});

const focusRing = css.raw(focusRingGenericStyles, {
    ringWidth: {
        _focusVisible: '2px',
    },
});

const groupFocusRing = css.raw(focusRingGenericStyles, {
    ringWidth: {
        _groupFocusVisible: '2px',
    },
});

const peerFocusRing = css.raw(focusRingGenericStyles, {
    ringWidth: {
        _peerFocusVisible: '2px',
    },
});

const childFocusRing = css.raw(focusRingGenericStyles, {
    ringWidth: {
        _groupFocusWithin: '2px',
    },
});

export { focusRing as default, focusRing, groupFocusRing, peerFocusRing, childFocusRing };
