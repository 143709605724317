
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Twitter'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can promote users to share something on Twitter as just text, or text and a url linking back to your website.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

const ShareOnTwitterButton = varnishComponents.ShareOnTwitterButton;

render(<ShareOnTwitterButton text={'The best AI company'} url={'https://allenai.org/'} />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;