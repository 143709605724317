import { sva } from '@allenai/varnish-panda-runtime/css';

import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const chipGroupRecipe = sva({
    slots: ['root', 'chipList', 'label', 'description', 'label', 'error'],
    base: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1',
        },
        chipList: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1',
        },
        label: {
            color: 'text.primary',
        },
        description: {
            color: 'text.primary',
        },
        error: {},
    },
});

type ChipGroupRecipeProps = RecipeVariantProps<typeof chipGroupRecipe>;

export { chipGroupRecipe, ChipGroupRecipeProps as default };
export type { ChipGroupRecipeProps };
