
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Snackbar Anchor Position'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You need a `}<inlineCode parentName="p">{`SnackbarNotificationProvider`}</inlineCode>{` and a `}<inlineCode parentName="p">{`SnackbarList`}</inlineCode>{` in order to display a `}<inlineCode parentName="p">{`snackbar`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from "@allenai/varnish-ui";
const { logos } = varnishUi;

const anchorOrigins = [
  "topLeft",
  "topRight",
  "topCenter",
  "bottomLeft",
  "bottomRight",
  "bottomCenter",
];

const Demo = ({ anchorOrigin }: { anchorOrigin: string }) => {
  const { addSnackbarNotification } = varnishUi.useSnackbarNotification();

  const handleClick = () => {
    addSnackbarNotification(anchorOrigin, {
      message: "Hello from Varnish UI Snackbar!",
      autoHideDuration: 3000,
      variant: "default",
    });
  };

  return (
    <div>
      <varnishUi.Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        {anchorOrigin}
      </varnishUi.Button>
      <varnishUi.SnackbarList anchorOrigin={anchorOrigin} />
    </div>
  );
};

// Wrap the whole app or component tree with SnackbarNotificationProvider
const App = () => (
  <>
    <varnishUi.SnackbarNotificationProvider>
      {anchorOrigins.map((anchorOrigin) => (
        <Demo key={anchorOrigin} anchorOrigin={anchorOrigin} />
      ))}
    </varnishUi.SnackbarNotificationProvider>
  </>
);

render(<App />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;