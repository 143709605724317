
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Group Property Overrides'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The RadioGroup accepts all the variant props that Radio does, which provides defaults for the Radio and can be overriden on the Radio.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.RadioGroup size="small" defaultValue="baseball">
        <varnishUi.Radio value="soccer">Soccer</varnishUi.Radio>
        <varnishUi.Radio value="baseball" color="secondary">
            Baseball (secondary color override)
        </varnishUi.Radio>
        <varnishUi.Radio value="basketball" size="large">
            Basketball (large size override)
        </varnishUi.Radio>
    </varnishUi.RadioGroup>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;