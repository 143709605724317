
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { StackExamples } from '.';
export const frontMatter = {
  lib: 'varnish-ui',
  type: 'Components',
  title: 'Stack',
  cols: 1,
  iconSrc: './header-footer-14px.svg'
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Examples = makeShortcode("Examples");
const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Stack`}</h2>
    <p>{`The Stack component organizes its immediate children along either the vertical or horizontal axis,
allowing for customizable spacing and optional dividers between each child.`}</p>
    <h3>{`Examples`}</h3>
    <Examples cols={frontMatter.cols} examples={StackExamples} mdxType="Examples" />
    <h3>{`API`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`direction`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`row`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`column`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`column`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Defines the flex direction of the stack.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`start`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`center`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`end`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`stretch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls the alignment of stack children along the cross axis.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`justify`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`start`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`center`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`end`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`between`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`around`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`evenly`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Controls the justification of stack children along the main axis.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`spacing`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`0`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`1`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`2`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`3`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`4`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`5`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`6`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`7`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`8`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`9`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`10`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`...`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`100`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Defines the gap between stack children. Options correspond to pixel values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The elements to be rendered inside the Stack.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional class names for the stack container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`...other`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.HTMLProps<HTMLDivElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Any additional HTML attributes for the stack container element.`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;