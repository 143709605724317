
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 8,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles and the mui theme provider

const exampleButtonClassName = 'mySelectButton';
const exampleDescriptionClassName = 'mySelectDescription';
const exampleLabelClassName = 'mySelectLabel';
const examplePopoverClassName = 'mySelectPopover';
const exampleListBoxItemClassName = 'mySelectListBoxItem';

const CustomSelect = mui.styled(varnishUi.Select)\`

    border: 2px dashed red;
    padding: 1;
    .\${exampleButtonClassName} {
        border: 2px dashed orange;
    }
    .\${exampleDescriptionClassName} {
        border: 2px dashed yellow;
    }
    .\${exampleLabelClassName} {
        border: 2px dashed green;
    }
    .\${examplePopoverClassName} {
        border: 2px dashed blue;
    }
    .\${exampleListBoxItemClassName} {
        border: 2px dashed purple;
    }
\`;

const exampleItems = ['cat', 'dog', 'alligator', 'llama', 'lizard', 'frog'];

render(
    <CustomSelect
        buttonClassName={exampleButtonClassName}
        descriptionClassName={exampleDescriptionClassName}
        labelClassName={exampleLabelClassName}
        popoverClassName={examplePopoverClassName}
        listBoxItemClassName={exampleListBoxItemClassName}
        items={exampleItems}
        label="Label"
        description="I am the description."
        placeholder="label"
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;