import {
    SliderOutput as AriaSliderOutput,
    SliderOutputProps as AriaSliderOutputProps,
    SliderRenderProps as AriaSliderRenderProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import sliderRecipe, { SliderRecipeProps } from '@/components/slider/slider.styles';

type SliderOutputProps = {
    className?: string;
    children: (d: AriaSliderRenderProps) => React.ReactNode;
} & AriaSliderOutputProps &
    SliderRecipeProps;

const SliderOutput = ({ className, children, ...rest }: SliderOutputProps) => {
    const [variantProps, localProps] = sliderRecipe.splitVariantProps(rest);
    const recipeClassNames = sliderRecipe(variantProps);
    return (
        <AriaSliderOutput {...localProps} className={cx(recipeClassNames.output, className)}>
            {children}
        </AriaSliderOutput>
    );
};

export { SliderOutput, SliderOutput as default };
export type { SliderOutputProps };
