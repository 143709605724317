import React from 'react';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import { ThemeProviderBase } from './ThemeProviderBase';

interface AppProviderProps extends React.PropsWithChildren {
    themeProvider?: React.ComponentType;
    themeDefaults?: React.ComponentPropsWithoutRef<typeof ThemeProviderBase>;
}

const AppProvider = ({ themeProvider, themeDefaults, children }: AppProviderProps) => {
    const ThemeProvider = themeProvider ?? React.Fragment;
    return (
        <ErrorBoundary>
            <ThemeProviderBase {...themeDefaults}>
                <ThemeProvider>{children}</ThemeProvider>
            </ThemeProviderBase>
        </ErrorBoundary>
    );
};

export { AppProvider, AppProvider as default };
