import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const errorBoundaryRecipe = sva({
    slots: ['root', 'errorSelection'],
    base: {
        root: {
            height: '[100vh]',
            backgroundColor: 'extra-dark-teal.100',
            display: 'grid',
            placeItems: 'center',
            color: 'text.primary.reversed',
            _dark: {
                color: 'text.primary',
            },
        },
        errorSelection: {
            width: '[750px]',
            margin: 'auto',
            padding: '[16px]',
            minHeight: '[50%]',
            maxHeight: '[90%]',
            overflowY: 'auto',
            border: '2px solid red',
        },
    },
});

type ErrorBoundaryRecipeProps = RecipeVariantProps<typeof errorBoundaryRecipe>;

export { errorBoundaryRecipe, errorBoundaryRecipe as default };
export type { ErrorBoundaryRecipeProps };
