import { PropsWithChildren, ReactNode } from 'react';
import { Key, Column as AriaColumn, ColumnProps as AriaColumnProps } from 'react-aria-components';

import { tableRecipe } from './table.styles';
import { cx } from '@/utils/cx';

type ColumnProps = {
    id?: Key;
    allowsSorting?: boolean;
    isRowHeader?: boolean;
    textValue?: string;
    children?: ReactNode;
    className?: string;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    defaultWidth?: number;
    sortDirection?: 'ascending' | 'descending';
} & AriaColumnProps;

const Column = ({
    id,
    allowsSorting,
    isRowHeader,
    textValue,
    children,
    className,
    width,
    minWidth,
    maxWidth,
    defaultWidth,
    sortDirection,
    ...rest
}: PropsWithChildren<ColumnProps>) => {
    const [variantProps, localProps] = tableRecipe.splitVariantProps(rest);
    const recipeClassNames = tableRecipe(variantProps);

    return (
        <AriaColumn
            {...localProps}
            id={id}
            allowsSorting={allowsSorting}
            isRowHeader={isRowHeader}
            textValue={textValue}
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
            defaultWidth={defaultWidth}
            className={cx(recipeClassNames.column, className)}
            data-sort-direction={sortDirection}>
            <>
                {children}
                {allowsSorting && (
                    <span aria-hidden="true">{sortDirection === 'ascending' ? '▲' : '▼'}</span>
                )}
            </>
        </AriaColumn>
    );
};

export { Column };
export type { ColumnProps };
