import React, { RefObject } from 'react';

import { useScrollTrigger } from '@mui/material';

import { VarnishContext } from '../VarnishContext';

type SmartBannerAttrs = number;
interface useSmartAI2BannerProps {
    containerRef?: RefObject<HTMLElement>;
    appBarRef: RefObject<HTMLElement>;
    bannerRef: RefObject<HTMLElement>;
    bannerAlwaysVisible?: boolean;
}

/**
 * Sets up the logic required to toggle the visibility of the `<Header.AI2Banner />`
 * component as the user scrolls the page.
 *
 * @arg props
 * @arg props.containerRef An optional ref if you want to use this in a different scrolling context
 * @arg props.appBarRef A required ref for the AppBar
 * @arg bannerAlwaysVisible If we want the banner to stay visible, set this to true
 *
 * @returns {number} the top offset that should be set as the CSS `top` property of the `<AppBar />` component.
 */
export const useSmartAI2Banner = ({
    containerRef,
    appBarRef,
    bannerRef,
    bannerAlwaysVisible = false,
}: useSmartAI2BannerProps): SmartBannerAttrs => {
    const ctx = React.useContext(VarnishContext);

    const appBarHeight =
        appBarRef.current == null ? 0 : appBarRef.current.getBoundingClientRect().height;

    const bannerHeight =
        bannerRef.current == null ? 0 : bannerRef.current.getBoundingClientRect().height;
    const paddingBottom =
        bannerRef.current == null
            ? 0
            : parseFloat(window.getComputedStyle(bannerRef.current).paddingBottom || '0');
    const offset = -1 * (bannerHeight - paddingBottom);

    const shouldHideBanner = useScrollTrigger({ target: containerRef?.current ?? undefined });
    const topOffset = shouldHideBanner ? offset : 0;

    const totalOffset = bannerAlwaysVisible ? 0 : topOffset;

    const headerHeight = appBarHeight + totalOffset;

    React.useEffect(() => {
        ctx.setHeaderHeight(headerHeight);
    }, [ctx, headerHeight]);

    return totalOffset;
};

export default useSmartAI2Banner;
