import { Dialog as AriaDialog, type DialogProps as AriaDialogProps } from 'react-aria-components';

import { cx } from '@/utils/cx';

import { dialogStyles, type DialogRecipeProps } from '@/components/dialog/dialog.styles';
import { DialogHeading } from '@/components/dialog/DialogHeading';
import { DialogActions } from '@/components/dialog/DialogActions';

interface DialogClassNames {
    headingClassName?: string;
    actionsClassName?: string;
}

interface DialogProps extends AriaDialogProps, DialogRecipeProps, DialogClassNames {
    heading?: React.ReactNode;
    buttons?: React.ReactNode;
    children: React.ReactNode; // Force ReactNode Override RAC children render functionality - at least for now.
}

const Dialog = ({
    heading,
    buttons,
    className,
    headingClassName,
    actionsClassName,
    children,
    ...rest
}: DialogProps) => {
    const [variantProps, localProps] = dialogStyles.splitVariantProps(rest);
    const variantClassNames = dialogStyles(variantProps);

    const headingNode =
        typeof heading === 'string' ? (
            <DialogHeading className={headingClassName} {...variantProps}>
                {heading}
            </DialogHeading>
        ) : (
            heading
        );

    return (
        <AriaDialog className={cx(variantClassNames.root, className)} {...localProps}>
            {headingNode}
            {children}
            {buttons ? (
                <DialogActions className={actionsClassName} {...variantProps}>
                    {buttons}
                </DialogActions>
            ) : null}
        </AriaDialog>
    );
};

export type { DialogProps };
export { Dialog, Dialog as default };
