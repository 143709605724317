import { merge } from 'lodash-es';

import varnishTokens from '@allenai/varnish-theme/simple-hex-unitless';

import type { CommonPieProps } from '@nivo/pie';

import { themeLight, themeDark } from '../nivo-theme';
import { DarkColorHexes, LightColorHexes, globalDefaults } from './global';

const { spacing } = varnishTokens;

export const pieDefaults: Partial<CommonPieProps<unknown>> = {
    theme: themeLight,
    innerRadius: 0.5,
    padAngle: 1.5,
    // cornerRadius: parseFloat(shape.borderRadius.default),
    activeOuterRadiusOffset: spacing[0], // previously spacing.xs.getValue(),
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsThickness: 2,
    arcLabelsSkipAngle: 25,
    arcLinkLabelsColor: {
        from: 'color',
    },
    arcLabelsTextColor: globalDefaults.lightValueLabelColor,
    colors: LightColorHexes,
};

export const pieDefaultsDark = merge({}, pieDefaults, {
    theme: themeDark,
    colors: DarkColorHexes,
    arcLabelsTextColor: globalDefaults.darkValueLabelColor,
} as Partial<CommonPieProps<unknown>>);
