import { css } from '@allenai/varnish-panda-runtime/css';
import React, { useEffect } from 'react';

import { Button as AriaButton } from 'react-aria-components';

import { cx } from '@/utils/cx';

import snackbarRecipe, { SnackbarRecipeProps } from './snackbar.styles';
import { Icons } from '@/index';

type SnackbarProps = {
    action?: React.ReactNode;
    actionClassName?: string;
    ariaLabel?: string;
    autoHideDuration?: number;
    className?: string;
    exitClassName?: string;
    index?: number;
    id?: string;
    messageClassName?: string;
    onClose?: () => void;
    variant?: 'default' | 'confirmation' | 'error';
    message: string;
    open: boolean;
} & SnackbarRecipeProps;

function Snackbar({
    message,
    variant = 'default',
    autoHideDuration = 5000,
    className,
    action,
    actionClassName,
    messageClassName,
    open,
    onClose,
    index,
    id,
    exitClassName,
    ariaLabel,
    ...rest
}: SnackbarProps) {
    const [variantProps, localProps] = snackbarRecipe.splitVariantProps(rest);

    const recipeClassNames = snackbarRecipe.raw({
        ...variantProps,
        variant,
    });

    useEffect(() => {
        if (open && autoHideDuration && onClose !== undefined) {
            const timer = setTimeout(() => {
                onClose();
            }, autoHideDuration);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [open, autoHideDuration, onClose]);

    return open ? (
        <div
            className={cx(css(recipeClassNames.root), className)}
            {...localProps}
            aria-label={ariaLabel}
            id={id}>
            <span className={cx(css(recipeClassNames.message), messageClassName)}>{message}</span>
            {action !== undefined ? (
                <div className={cx(css(recipeClassNames.action), actionClassName)}>
                    {action}
                    <AriaButton
                        slot="remove"
                        className={cx(css(recipeClassNames.exit), exitClassName)}>
                        <Icons.Close size="medium" />
                    </AriaButton>
                </div>
            ) : (
                <>
                    <AriaButton
                        slot="remove"
                        className={cx(css(recipeClassNames.exit), exitClassName)}>
                        <Icons.Close size="medium" />
                    </AriaButton>
                </>
            )}
        </div>
    ) : null;
}

export { Snackbar, Snackbar as default };
export type { SnackbarProps };
