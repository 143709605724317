/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { ComponentProps, ReactNode } from 'react';
import { Icon } from '@mui/material';
import slug from 'slug';
import Apps from '@mui/icons-material/AppsOutlined';
import BarChart from '@mui/icons-material/BarChartOutlined';
import Start from '@mui/icons-material/StartOutlined';
import PermDataSetting from '@mui/icons-material/PermDataSettingOutlined';
import Palette from '@mui/icons-material/PaletteOutlined';

import { LibAppRouteGroup, RouteTag } from './AppRoute';

const componentRoot = '/components';

// index md files follow the Example interface
interface Example {
    frontMatter: {
        lib: string;
        type: string;
        title: string;
        cols: number;
        iconSrc: string;
        tag: RouteTag;
    };
    default: (props: ComponentProps<any>) => React.ReactElement;
}

// find all index mdx pages to show in menu
const mdxs = (require as any).context('./pages', true, /page\.(mdx)$/);
const examples: Example[] = mdxs.keys().map(mdxs);

// load all icons
const icons = (require as any).context('./icons', true, /\.(svg)$/);

const ImgIcon = (props: { src: string; alt?: string; ariaHidden?: boolean }) => {
    const { src, alt = '', ariaHidden, ...other } = props;
    return (
        <Icon
            {...other}
            component={() => (
                <img src={src} alt={alt} aria-hidden={ariaHidden ? 'true' : 'false'} />
            )}
        />
    );
};

// predefined order of known libs
const orderedLibs = [
    'varnish',
    'varnish-theme',
    'varnish-ui',
    'varnish-mui',
    'varnish-nivo',
    'eslint-config-varnish',
];
const mdGroupLibs = orderedLibs.concat(
    Array.from(new Set<string>(examples.map((ex) => ex.frontMatter.lib)))
        .filter((a) => !orderedLibs.includes(a))
        .sort((a, b) => a.localeCompare(b))
);
const libIcons: { [index: string]: ReactNode } = {
    varnish: <Start />,
    'varnish-theme': <Palette />,
    'varnish-mui': <Apps />,
    'varnish-ui': <Apps />,
    'varnish-nivo': <BarChart />,
    'eslint-config-varnish': <PermDataSetting />,
};

// predefined order of known types
const orderedTypes = ['Overview', 'Patterns', 'Components'];
const mdGroupTypes = orderedTypes.concat(
    Array.from(new Set<string>(examples.map((ex) => ex.frontMatter.type)))
        .filter((a) => !orderedTypes.includes(a))
        .sort((a, b) => a.localeCompare(b))
);

export const libComponentGroups: LibAppRouteGroup[] = mdGroupLibs.map((lib) => {
    return {
        label: lib,
        icon: libIcons[lib],
        startOpen: lib === orderedLibs[0],
        typeGroups: mdGroupTypes
            .map((t) => {
                return {
                    label: t,
                    routes: examples
                        .filter((ex) => ex.frontMatter.lib === lib && ex.frontMatter.type === t)
                        .sort((a, b) => a.frontMatter.title.localeCompare(b.frontMatter.title))
                        .map((ex) => {
                            return {
                                path: `${componentRoot}/${slug(ex.frontMatter.lib, '_')}/${slug(ex.frontMatter.title, '_')}`,
                                label: ex.frontMatter.title,
                                icon: <ImgIcon src={icons(ex.frontMatter.iconSrc).default} />,
                                component: ex.default,
                                tag: ex.frontMatter.tag,
                            };
                        }),
                };
            })
            .filter((t) => t.routes.length > 0),
    };
});
