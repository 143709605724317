import * as AI2Logo from './AI2Logo';
import * as AI2Projects from './AI2Projects';
import { AI2Mark } from './AI2Mark';
import { AI2MarkText } from './AI2MarkText';
import { NewAI2Mark } from './NewAI2Mark';

export const logos = {
    ...AI2Logo,
    ...AI2Projects,
    AI2Mark,
    AI2MarkText,
    NewAI2Mark,
};

export default logos;
