import * as Default from './demo/default.mdx';
import * as Shapes from './demo/shapes.mdx';
import * as Colors from './demo/colors.mdx';
import * as FullWidth from './demo/fullWidth.mdx';
import * as StylingCss from './demo/stylingCss.mdx';
import * as StyledCss from './demo/stylingStyled.mdx';
import * as Position from './demo/position.mdx';
import * as Nav from './demo/nav.mdx';
import * as Search from './demo/search.mdx';
import * as Action from './demo/action.mdx';
import * as Content from './demo/content.mdx';

export const HeaderExamples = [
    Default,
    Shapes,
    Colors,
    FullWidth,
    StylingCss,
    StyledCss,
    Position,
    Content,
    Nav,
    Search,
    Action,
];
