
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Typography Variants'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`MUI has a number of built in Typography elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';
import ThemeMui from '@allenai/varnish2/theme';
import mui from '@mui/material';

const Box = mui.Box;
const Typography = mui.Typography;
const MaxWidthText = varnishComponents.MaxWidthText;
const varnishDesignTokens = varnishTheme.getTheme();

render(
    <MaxWidthText>
        <Typography variant="h1" gutterBottom>
            [h1] Example text ({varnishDesignTokens.typography.h1.fontSize})
        </Typography>
        <Typography variant="h2" gutterBottom>
            [h2] Example text ({varnishDesignTokens.typography.h2.fontSize})
        </Typography>
        <Typography variant="h3" gutterBottom>
            [h3] Example text ({varnishDesignTokens.typography.h3.fontSize})
        </Typography>
        <Typography variant="h4" gutterBottom>
            [h4] Example text ({varnishDesignTokens.typography.h4.fontSize})
        </Typography>
        <Typography variant="h5" gutterBottom>
            [h5] Example text ({varnishDesignTokens.typography.h5.fontSize})
        </Typography>
        <Typography variant="h6" gutterBottom>
            [h6] Example text ({varnishDesignTokens.typography.h6.fontSize})
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
            [subtitle1] Example text ({varnishDesignTokens.typography.subtitle1.fontSize})
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
            [subtitle2] Example text ({varnishDesignTokens.typography.subtitle2.fontSize})
        </Typography>
        <Typography variant="body1" gutterBottom>
            [body1] Example text ({varnishDesignTokens.typography.body1.fontSize})
        </Typography>
        <Typography variant="body2" gutterBottom>
            [body2] Example text ({varnishDesignTokens.typography.body2.fontSize})
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
            [button] Example text ({varnishDesignTokens.typography.button.fontSize})
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
            [caption] Example text ({varnishDesignTokens.typography.caption.fontSize})
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
            [overline] Example text ({varnishDesignTokens.typography.overline.fontSize})
        </Typography>
        <Typography variant="monospace" display="block" gutterBottom>
            [monospace] Example text ({varnishDesignTokens.typography.monospace.fontSize})
        </Typography>
    </MaxWidthText>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;