
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 11,
  title: 'Styling (via css)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by passing new classNames.
These classNames must have css pregenerated by a tool like `}<inlineCode parentName="p">{`@emotion/css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';
import * as emotionCss from '@emotion/css';
import ThemeArch from '@allenai/varnish-theme/tokens-archetype-beta';
import Theme from \`@allenai/varnish-theme\`;

// This is an example of using Emotion CSS directly

const theme = {spacing:Theme.spacing, ...ThemeArch}; // or useTheme() if you have it

const className = () => {
    return emotionCss.css\`
        text-decoration: none;
        color: red;
        border: 1px dashed \${theme.color.R6.value};
    \`;
};

const tableRowClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.B2.value};
    \`;
}

const tableHeaderClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R5.value};
    \`;
}

const columnClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R4.value};
    \`;
}

const rowClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R4.value};
    \`;
}

const cellClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R4.value};
    \`;
}

const tableBodyClassName = () => {
    return emotionCss.css\`
        border: 1px dashed \${theme.color.R4.value};
    \`;
}

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
        { id: 'dateModified', name: 'Date Modified' },
    ];

    const dataItems = [
        { id: '1', name: 'Games', type: 'File folder', dateModified: '6/7/2020' },
        { id: '2', name: 'Program Files', type: 'File folder', dateModified: '4/7/2021' },
        { id: '3', name: 'bootmgr', type: 'System file', dateModified: '11/20/2010' },
        { id: '4', name: 'log.txt', type: 'Text Document', dateModified: '1/18/2016' },
    ];
    return (
        <varnishUi.Table aria-label="Files" headers={headers} dataItems={dataItems}
            className={emotionCss.cx(className())}
            tableRowClassName={emotionCss.cx(tableRowClassName())}
            tableHeaderClassName={emotionCss.cx(tableHeaderClassName())}
            columnClassName={emotionCss.cx(columnClassName())}
            rowClassName={emotionCss.cx(rowClassName())}
            cellClassName={emotionCss.cx(cellClassName())}
            tableBodyClassName={emotionCss.cx(tableBodyClassName())}
        />
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;