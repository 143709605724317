
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 2,
  title: 'Spacing'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Alter the gap between items via the `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="col" spacing={2}>
        spacing: 4
        <varnishUi.Stack direction="row" spacing={4}>
            <varnishUi.Button variant="outlined">Item 1</varnishUi.Button>
            <varnishUi.Button variant="outlined">Item 2</varnishUi.Button>
            <varnishUi.Button variant="outlined">Item 3</varnishUi.Button>
        </varnishUi.Stack>
        spacing: 8<varnishUi.Stack direction="row" spacing={8}>
            <varnishUi.Button variant="outlined">Item 1</varnishUi.Button>
            <varnishUi.Button variant="outlined">Item 2</varnishUi.Button>
            <varnishUi.Button variant="outlined">Item 3</varnishUi.Button>
        </varnishUi.Stack>
        spacing: 16
        <varnishUi.Stack direction="row" spacing={16}>
            <varnishUi.Button variant="outlined">Item 1</varnishUi.Button>
            <varnishUi.Button variant="outlined">Item 2</varnishUi.Button>
            <varnishUi.Button variant="outlined">Item 3</varnishUi.Button>
        </varnishUi.Stack>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;