import { ReactNode, createContext } from 'react';
import {
    RadioGroup as AriaRadioGroup,
    RadioGroupProps as AriaRadioGroupProps,
    type ValidationResult,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import { Label } from '@/components/field/label/Label';
import { FieldError } from '@/components/field/fieldError/FieldError';
import { Description } from '@/components/field/description/Description';
import { radioGroupRecipe } from '@/components/radioGroup/radioGroup.styles';
import { radioRecipe, type RadioRecipeProps } from '../radio/radio.styles';

export const RadioVariantContext = createContext<RadioRecipeProps>(undefined);

/**
 * Radio groups can be built in HTML with the <fieldset> and <input> elements, however these
 * can be difficult to style. RadioGroup helps achieve accessible radio groups that can be
 * styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/RadioGroup.html
 */
type RadioGroupProps = Omit<AriaRadioGroupProps, 'children'> &
    RadioRecipeProps & {
        label?: string;
        children?: ReactNode;
        description?: string;
        errorMessage?: string | ((validation: ValidationResult) => string);
        className?: string;
        labelClassName?: string;
        descriptionClassName?: string;
        errorClassName?: string;
    };

function RadioGroup({
    label,
    children,
    description,
    errorMessage,
    className,
    labelClassName,
    descriptionClassName,
    errorClassName,
    ...props
}: RadioGroupProps) {
    const [groupVariantProps, rest] = radioGroupRecipe.splitVariantProps(props);
    const [radioVariantProps, localProps] = radioRecipe.splitVariantProps(rest);
    const recipeClassNames = radioGroupRecipe(groupVariantProps);
    const { size } = props;

    return (
        <AriaRadioGroup {...localProps} className={cx(recipeClassNames.root, className)}>
            <RadioVariantContext.Provider value={radioVariantProps}>
                <Label size={size} className={cx(recipeClassNames.label, labelClassName)}>
                    {label}
                </Label>
                {children}
                <Description
                    size={size}
                    className={cx(recipeClassNames.description, descriptionClassName)}>
                    {description}
                </Description>
                <FieldError size={size} className={cx(recipeClassNames.error, errorClassName)}>
                    {errorMessage}
                </FieldError>
            </RadioVariantContext.Provider>
        </AriaRadioGroup>
    );
}

export { RadioGroup, RadioGroup as default };
export type { RadioGroupProps };
