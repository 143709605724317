
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 0,
  title: 'Paper With Links'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The title will be a link if the `}<inlineCode parentName="p">{`url`}</inlineCode>{` prop is passed.`}</p>
    <p>{`Author names can also be links, if their `}<inlineCode parentName="p">{`url`}</inlineCode>{` field is filled. Like this `}<inlineCode parentName="p">{`"authors": [{"name": "Eric", "url": "www.google.com"}]`}</inlineCode>{`;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import varnishComponents from '@allenai/varnish2/components';

const PaperRow = varnishComponents.PaperRow;
const title = 'Example Title: How A Fake Paper Title Showed Us An Example';
const url = '/';
const authors = Array(6).fill({ name: 'Jane Doe', url: '/' });
const venue = 'arXiv.org';
const fieldsOfStudy = ['Computer Science', 'Math'];
const publicationDate = '2024-02-21';
const abstract =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

render(
    <PaperRow
        title={title}
        url={url}
        authors={authors}
        venue={venue}
        fieldsOfStudy={fieldsOfStudy}
        publicationDate={publicationDate}
        abstract={abstract}
    />,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;