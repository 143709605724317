
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 3,
  title: 'Sizes'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The select component has 3 sizes: small, medium(default), large.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const ImageIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-image">
        <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
        <circle cx="9" cy="9" r="2" />
        <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
    </svg>
);

const defaultItems = [
    { id: '1', text: 'Mario' },
    { id: '2', icon: <ImageIcon />, text: 'Luigi' },
];

const Demo = () => {
    return (
        <varnishUi.Stack spacing={16}>
            <varnishUi.Stack direction="row" spacing={16}>
                <varnishUi.ChipGroup
                    size="small"
                    onRemove={() => {}}
                    items={defaultItems.map((i) => ({ ...i, text: 'small' }))}
                />
                <varnishUi.ChipGroup
                    size="small"
                    shape="box"
                    onRemove={() => {}}
                    items={defaultItems.map((i) => ({ ...i, text: 'small' }))}
                />
            </varnishUi.Stack>
            <varnishUi.Stack direction="row" spacing={16}>
                <varnishUi.ChipGroup
                    size="medium"
                    onRemove={() => {}}
                    items={defaultItems.map((i) => ({ ...i, text: 'medium' }))}
                />
                <varnishUi.ChipGroup
                    size="medium"
                    shape="box"
                    onRemove={() => {}}
                    items={defaultItems.map((i) => ({ ...i, text: 'medium' }))}
                />
            </varnishUi.Stack>
            <varnishUi.Stack direction="row" spacing={16}>
                <varnishUi.ChipGroup
                    size="large"
                    onRemove={() => {}}
                    items={defaultItems.map((i) => ({ ...i, text: 'large' }))}
                />
                <varnishUi.ChipGroup
                    size="large"
                    shape="box"
                    onRemove={() => {}}
                    items={defaultItems.map((i) => ({ ...i, text: 'large' }))}
                />
            </varnishUi.Stack>
        </varnishUi.Stack>
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;