const capitalize = (s: string) => (!s.length ? '' : s[0].toUpperCase() + s.slice(1));
const initialize = (s: string) => (!s.length ? '' : s[0] + '.');

export function shortenName(name: string) {
    return name
        .split(' ')
        .filter((a) => a.length > 0)
        .map((w, i, words) => (i === words.length - 1 ? capitalize(w) : initialize(capitalize(w))))
        .join(' ');
}
