import varnishTokens, { PaletteModes } from '@allenai/varnish-theme/mui';
import { type Theme } from '@mui/material';

// importing from @mui/system directly seems to get us around some client component issues in Next
import { getContrastRatio } from '@mui/system/colorManipulator';

import type { Variant } from '@mui/material/styles/createTypography';

// Used to convert style object to css for typographic elements like h1...h6
export const getTypographyStyle = (
    theme: Omit<Theme, 'components'>,
    key: Variant,
    includeKey: boolean = true
) => {
    return `
    ${includeKey ? `${key} {` : ''}
      color: ${theme.typography[key].color};
      font-family: ${theme.typography[key].fontFamily};
      font-weight:  ${theme.typography[key].fontWeight};
      font-size:  ${theme.typography[key].fontSize};
      line-height:  ${theme.typography[key].lineHeight};
      margin:  ${theme.typography[key].margin};
    ${includeKey ? '}' : ''}
  `;
};

export const getThemeObjectTokens = <T extends { [index: string]: { value: string | number } }>(
    themeObject: T,
    properties: (keyof T)[] = Object.keys(themeObject)
) => {
    type RecordOfT = Record<keyof T, string>;

    return properties.reduce<RecordOfT>((acc: RecordOfT, attribute: keyof T) => {
        acc[attribute] = themeObject[attribute].value.toString();
        return acc;
        // https://github.com/typescript-eslint/typescript-eslint/issues/3440
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    }, {} as RecordOfT);
};

interface ColorWithContrastRatio {
    color: string;
    contrastRatio: number;
}
const getColorWithContrastRatio = (baseColor: string, color: string): ColorWithContrastRatio => {
    return {
        color,
        contrastRatio: getContrastRatio(baseColor, color),
    };
};

/**
 *
 * @param backgroundColor The color you want to get the contrast color for
 * @param mode 'light' or 'dark', this determines which palette we pull text colors from
 * @returns The primary or reversed value for the palette mode selected, chosen based on whichever has the better contrast ratio
 */
export const getContrastText = (
    backgroundColor: string,
    mode: PaletteModes = 'light',
    desiredContrastRatio = 4.5
) => {
    const primaryText = getColorWithContrastRatio(
        backgroundColor,
        varnishTokens.palette[mode].text.primary.default.value
    );

    const reversedText = getColorWithContrastRatio(
        backgroundColor,
        varnishTokens.palette[mode].text.primary.reversed.value
    );

    const secondaryText = getColorWithContrastRatio(
        backgroundColor,
        varnishTokens.palette[mode].text.secondary.default.value
    );

    // HACK: dark-green will always have better contrast than ai2-green. This lets us keep using ai2-green if it's above the contrast ratio we're looking for
    if (primaryText.contrastRatio >= desiredContrastRatio) {
        return primaryText.color;
    }

    // We want the color with the highest contrast ratio at the first index
    const colorsOrderedByContrastRatio = [primaryText, reversedText, secondaryText].sort(
        (a, b) => b.contrastRatio - a.contrastRatio
    );

    return colorsOrderedByContrastRatio[0].color;
};

export const getBetaPaletteTokens = <TColor extends { value: string }>(
    color: TColor,
    mode: PaletteModes = 'light'
): {
    main: string;
    contrastText: string;
} => {
    return {
        main: color.value,
        contrastText: getContrastText(color.value, mode),
    };
};
