import {
    SliderTrack as AriaSliderTrack,
    SliderTrackProps as AriaSliderTrackProps,
    SliderTrackRenderProps as AriaSliderTrackRenderProps,
} from 'react-aria-components';

import { cx } from '@/utils/cx';

import sliderRecipe, { SliderRecipeProps } from '@/components/slider/slider.styles';

type SliderTrackProps = {
    className?: string;
    trackClassName?: string;
    indicatorClassName?: string;
    children: (d: AriaSliderTrackRenderProps) => React.ReactNode;
} & AriaSliderTrackProps &
    SliderRecipeProps;

const SliderTrack = ({
    className,
    trackClassName,
    indicatorClassName,
    children,
    ...rest
}: SliderTrackProps) => {
    const [variantProps, localProps] = sliderRecipe.splitVariantProps(rest);
    const recipeClassNames = sliderRecipe(variantProps);
    return (
        <AriaSliderTrack
            {...localProps}
            className={cx('group', recipeClassNames.control, className)}>
            {(renderProps: AriaSliderTrackRenderProps) => (
                <div className={cx(recipeClassNames.track, trackClassName)}>
                    {children(renderProps)}
                </div>
            )}
        </AriaSliderTrack>
    );
};

export { SliderTrack, SliderTrack as default };
export type { SliderTrackProps };
