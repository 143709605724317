import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 * Radio groups can be built in HTML with the <fieldset> and <input> elements, however these
 * can be difficult to style. RadioGroup helps achieve accessible radio groups that can be
 * styled as needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/RadioGroup.html
 */

const radioGroupRecipe = sva({
    slots: ['root', 'label', 'description', 'error'],
    base: {
        root: {
            display: 'flex',
            flexDir: 'column',
            alignItems: 'start',
            gap: '[2px]',
            color: 'text.primary',
        },
        label: {},
        description: {},
        error: {},
    },
});

type RadioGroupRecipeProps = RecipeVariantProps<typeof radioGroupRecipe>;

export { radioGroupRecipe, radioGroupRecipe as default };
export type { RadioGroupRecipeProps };
