import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

/**
 *  The <input type="range"> HTML element can be used to build a slider, however it is very
 *  difficult to style cross browser. Slider helps achieve accessible sliders that can be styled as
 *  needed.
 *
 *  See: https://react-spectrum.adobe.com/react-aria/Slider.html
 */

const sliderRecipe = sva({
    slots: [
        'root',
        'label',
        'output',
        'control',
        'track',
        'indicator',
        'thumb',
        'description',
        'error',
    ],
    base: {
        root: {
            display: 'grid',
            gridTemplateColumns: '[1fr auto]',
        },
        // This is the <label> for the slider
        label: {},
        // this is the value (for the slider)
        output: {
            color: '[currentColor]',
        },
        control: {
            gridColumn: '[1 / -1]',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            _disabled: {
                cursor: 'not-allowed',
                color: 'gray.40',
            },
        },
        track: {
            position: 'relative',
            borderRadius: 'full',
            backgroundColor: 'teal.20',
            _groupDisabled: {
                // this prevents hover state
                pointerEvents: 'none',
                backgroundColor: 'gray.20',
            },
        },
        indicator: {
            borderRadius: 'full',
            backgroundColor: '[currentColor]',
        },
        thumb: {
            boxSizing: 'border-box',
            borderRadius: 'full',
            width: 'var(--slider-control-size)',
            aspectRatio: '1',
            backgroundColor: '[currentColor]',

            _disabled: {
                color: '[inherit]',
            },
            _dragging: {
                color: '[inherit]',
            },
        },
        description: {},
        error: {},
    },
    variants: {
        color: {
            primary: {
                control: {
                    color: 'accent.primary',
                },
                thumb: {
                    color: 'pink.80',
                    _hover: {
                        color: 'accent.primary',
                    },
                },
            },
            secondary: {
                control: {
                    color: 'accent.secondary',
                },
                thumb: {
                    color: 'green.80',
                    _hover: {
                        color: 'accent.secondary',
                    },
                },
            },
            tertiary: {
                control: {
                    color: 'accent.tertiary',
                },
                thumb: {
                    color: 'purple.80',
                    _hover: {
                        color: 'accent.tertiary',
                    },
                    _dragging: {
                        color: 'accent.tertiary',
                    },
                },
            },
            teal: {
                control: {
                    color: 'teal.100',
                },
                thumb: {
                    color: 'teal.80',
                    _hover: {
                        color: 'teal.100',
                    },
                },
            },
            cream: {
                control: {
                    color: 'cream.100',
                },
                track: {
                    backgroundColor: '[white]',
                },
                thumb: {
                    color: '[white]',
                    _hover: {
                        color: 'cream.100',
                    },
                },
            },
        },
        size: {
            small: {
                root: {
                    '--slider-control-size': '[0.75rem]',
                    '--slider-track-size': '[0.25rem]',
                },
            },
            medium: {
                root: {
                    '--slider-control-size': '[0.875rem]',
                    '--slider-track-size': '[0.25rem]',
                },
            },
            large: {
                root: {
                    '--slider-control-size': '[1.25rem]',
                    '--slider-track-size': '[0.375rem]',
                },
            },
        },
        orientation: {
            horizontal: {
                root: {
                    width: '[100%]',
                },
                output: {},
                control: {
                    height: 'var(--slider-control-size)',
                    width: '[100%]',
                },
                track: {
                    height: 'var(--slider-track-size)',
                    width: '[100%]',
                },
                indicator: {
                    top: '[0]',
                    height: '[100%]',
                },
                thumb: {
                    top: '[50%]',
                },
            },
            vertical: {
                root: {
                    height: '[100%]',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                },
                output: {
                    marginBottom: '[calc(var(--slider-control-size) / 2)]',
                },
                control: {
                    width: 'var(--slider-control-size)',
                    height: '[100%]',
                    justifyContent: 'center',
                },
                track: {
                    width: 'var(--slider-track-size)',
                    height: '[100%]',
                },
                indicator: {
                    left: '[0]',
                    width: '[100%]',
                },
                thumb: {
                    left: '[50%]',
                },
            },
        },
    },
    defaultVariants: {
        color: 'primary',
        size: 'medium',
        orientation: 'horizontal',
    },
});

type SliderRecipeProps = RecipeVariantProps<typeof sliderRecipe>;

export { sliderRecipe, sliderRecipe as default };
export type { SliderRecipeProps };
