
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 12,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <p>{`Snackbar should be styled when calling the context to add one. The rendering example
you see on VarnishUI is for demo purpose only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import * as varnishUi from '@allenai/varnish-ui';
import * as mui from '@mui/material';

// This is an example of using mui styles ant the mui theme provider

const exampleTableRowClassName = 'myTableRow';
const exampleTableHeaderClassName = 'myTableHeader';
const exampleColumnClassName = 'myColumn';
const exampleRowClassName = 'myRow';
const exampleCellClassName = 'myCell';
const exampleTableBodyClassName = 'myTableBody';

const CustomTable = mui.styled(varnishUi.Table)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};

    .\${exampleTableRowClassName} {
        border: 2px dashed orange;
    }
    .\${exampleTableHeaderClassName} {
        border: 2px dashed yellow;
    }
    .\${exampleColumnClassName} {
        border: 2px dashed blue;
    }
    .\${exampleRowClassName} {
        border: 2px dashed pink;
    }
    .\${exampleCellClassName} {
        border: 2px dashed green;
    }
    .\${exampleTableBodyClassName} {
        border: 2px dashed white;
    }
\`;

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
        { id: 'dateModified', name: 'Date Modified' },
    ];

    const dataItems = [
        { id: '1', name: 'Games', type: 'File folder', dateModified: '6/7/2020' },
        {
            id: '2',
            name: 'Program Files',
            type: 'File folder',
            dateModified: '4/7/2021',
        },
        {
            id: '3',
            name: 'bootmgr',
            type: 'System file',
            dateModified: '11/20/2010',
        },
        {
            id: '4',
            name: 'log.txt',
            type: 'Text Document',
            dateModified: '1/18/2016',
        },
    ];
    return (
        <CustomTable
            aria-label="Files"
            headers={headers}
            dataItems={dataItems}
            tableRowClassName={exampleTableRowClassName}
            tableHeaderClassName={exampleTableHeaderClassName}
            columnClassName={exampleColumnClassName}
            rowClassName={exampleRowClassName}
            cellClassName={exampleCellClassName}
            tableBodyClassName={exampleTableBodyClassName}
        />
    );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;