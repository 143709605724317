import * as React from 'react';

import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { iconStyles } from './icon.styles';
import type { IconRecipeProps } from './icon.styles';
import { colorProp } from '@/components/svg/SVGBase';
import { type SVGColorProp } from '@/components/svg/svg.styles';

// Need this to force children exist, not optional
type ChildrenAsElement = {
    children: React.ReactElement;
};

type IconProps = Omit<React.HTMLAttributes<SVGElement>, 'color' | 'children'> &
    ChildrenAsElement &
    SVGColorProp &
    IconRecipeProps;

type IconPropsImpl = Omit<IconProps, 'children'>;

// Wraps external Icon with our styles
const Icon = ({ children, color, className: origClassName, ...rest }: IconProps) => {
    const colorStyles = colorProp(color);
    const [variantProps, localProps] = iconStyles.splitVariantProps(rest);

    return React.cloneElement(children, {
        className: cx(origClassName, css(colorStyles, iconStyles.raw(variantProps))),
        ...localProps,
        fontSize: 'none', // hack Mui icons =/ -- `none` is meaningless ...
    });
};

// TODO: Make this type generic
const varnishIcon = (IconComponent: React.ComponentType): React.ComponentType<IconPropsImpl> => {
    const VarnishedIcon = (props: IconPropsImpl) => (
        <Icon {...props}>
            <IconComponent />
        </Icon>
    );
    return VarnishedIcon;
};

const createVarnishIcon = (svgIcon: React.ReactElement): React.ComponentType<IconPropsImpl> => {
    const VarnishedIcon = (props: IconPropsImpl) => <Icon {...props}>{svgIcon}</Icon>;
    return VarnishedIcon;
};

export { varnishIcon, createVarnishIcon, Icon, Icon as default };
export type { IconProps };
