
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'How do I use SVGs in as Components?'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`There are 2 ways.`}</p>
    <p>{`First for simple SVGs and icons, simply import the svg and use it as a src:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "dark",
        "dark": true
      }}>{`import mySvgSrc from './myScg.svg';

<img src={mySvgSrc} alt="My SVG" />;
`}</code></pre>
    <p>{`For more complex svgs that you want to be able to styled in code, make a component for your svg:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`import React from 'react';
import mui from '@mui/material';

interface Props extends React.SVGProps<SVGSVGElement> {
    someCustomFill: string;
    someCustomFill2: string;
}

const Video = (props: Props) => {
    const defaults = {
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
    };
    return (
        <svg {...Object.assign({}, defaults, props)}>
            <circle cx="12" cy="12" r="12" fill={props.someCustomFill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 7C6.89543 7 6 7.89543 6 9V15C6 16.1046 6.89543 17 8 17H16C17.1046 17 18 16.1046 18 15V9C18 7.89543 17.1046 7 16 7H8ZM10 15L15 12L10 9L10 15Z"
                fill={props.someCustomFill2}
            />
        </svg>
    )
};

render(
    <mui.Stack spacing={2}>
        <Video someCustomFill="black" someCustomFill2="white" />
        <Video someCustomFill="grey" someCustomFill2="black" />
        <Video someCustomFill="blue" someCustomFill2="pink" />
    </>
)
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` There are `}<a parentName="p" {...{
        "href": "https://svg2jsx.com/"
      }}>{`online tools`}</a>{` that can help you convert SVGs to JSX.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;