
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Placement'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The placement of the tooltip with respect to the trigger. See react aria tooltip placement to see all the option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import varnishUi from '@allenai/varnish-ui';

render(
    <varnishUi.Stack direction="row" spacing="16">
        <varnishUi.DialogTrigger>
            <varnishUi.Button>Top</varnishUi.Button>
            <varnishUi.Popover placement="top">
                <p>Top placement</p>
            </varnishUi.Modal>
        </varnishUi.DialogTrigger>
        <varnishUi.DialogTrigger>
            <varnishUi.Button>Bottom</varnishUi.Button>
            <varnishUi.Popover placement="bottom">
                <p>Bottom placement</p>
            </varnishUi.Modal>
        </varnishUi.DialogTrigger>
        <varnishUi.DialogTrigger>
            <varnishUi.Button>Left</varnishUi.Button>
            <varnishUi.Popover placement="left">
                <p>Left placement</p>
            </varnishUi.Modal>
        </varnishUi.DialogTrigger>
        <varnishUi.DialogTrigger>
            <varnishUi.Button>Right</varnishUi.Button>
            <varnishUi.Popover placement="right">
                <p>Right placement</p>
            </varnishUi.Modal>
        </varnishUi.DialogTrigger>
    </varnishUi.Stack>,
);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;