
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 5,
  title: 'Tab with icon'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from "react";
import type {Key} from 'react-aria-components';
import varnishUi from "@allenai/varnish-ui";

const Demo = () => {
  const [tabSelected, setTabSelect] = React.useState<Key>("FoR");

  const items: Item[] = [
    {
      id: 'FoR',
      header: (props) => <varnishUi.Tab {...props}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <varnishUi.Icons.Spark size="large" color="primary" />
            Founding of Rome
        </div>
    </varnishUi.Tab>,
      content: (props) => <varnishUi.TabPanel {...props}>Arma virumque cano, Troiae qui primus ab oris.</varnishUi.TabPanel>,
    },
    {
      id: 'MaR',
      header: (props) => <varnishUi.Tab {...props}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <varnishUi.Icons.Spark size="large" color="primary" />
             Monarchy and Republic
        </div>
      </varnishUi.Tab>,
      content: (props) => <varnishUi.TabPanel {...props}>Senatus Populusque Romanus.</varnishUi.TabPanel>,
    },
    {
      id: 'Emp',
      header: (props) => <varnishUi.Tab {...props}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <varnishUi.Icons.Spark size="large" color="primary" />
            Empire
        </div>
      </varnishUi.Tab>,
      content: (props) => <varnishUi.TabPanel {...props}>Alea jacta est.</varnishUi.TabPanel>,
      isDisabled: true,
    },
  ];

  return (
    <varnishUi.Tabs onSelectionChange={setTabSelect} selectedKey={tabSelected} items={items} />
  );
};

render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;