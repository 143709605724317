
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 1,
  title: 'Empty State'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Use the renderEmptyState prop to customize what the TableBody will display if there are no items.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import React from 'react';
import varnishUi from '@allenai/varnish-ui';

const Demo = () => {
    const headers = [
        { id: 'name', name: 'Name', isRowHeader: true },
        { id: 'type', name: 'Type' },
        { id: 'dateModified', name: 'Date Modified' },
    ];

    return (
        <varnishUi.Table
            headers={headers}
            renderEmptyState={() => 'No results found.'}
            dataItems={[]}
        />
    );
};
render(<Demo />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;