
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const frontMatter = {
  order: 4,
  title: 'Styling (via mui.styled/styled-components)'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Component can be styled to override the default theme by using `}<inlineCode parentName="p">{`mui.styled`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` or other similar systems.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live lightAndDark",
        "live": true,
        "lightAndDark": true
      }}>{`import * as varnishUi from "@allenai/varnish-ui";
import * as mui from "@mui/material";

// This is an example of using mui styles ant the mui theme provider

const exampleLinksGroupClassName = 'myLinks';
const exampleCopyRightClassName = 'myCopyRight';

const CustomFooter = mui.styled(varnishUi.Footer)\`
    border: 1px dashed \${({ theme }) => theme.color.R5.hex};
     .\${exampleLinksGroupClassName} {
        border: 2px dashed orange;
    }
    .\${exampleCopyRightClassName} {
        border: 2px dashed yellow;
    }
\`;

const linksGroup: ReactNode[] = [
    <div>
        <varnishUi.Label>Link Header 1</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Privacy Policy</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Terms of Service</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Contact Us</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">About Us</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Help Center</varnishUi.Link></li>
        </ul>
    </div>,
    <div>
        <varnishUi.Label>Link Header 2</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Data Protection Policy</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">User Agreement</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Get in Touch</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Our Story</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Support Center</varnishUi.Link></li>
        </ul>
    </div>,
    <div>
        <varnishUi.Label>Link Header 3</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Confidentiality Agreement</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Service Terms</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Reach Out</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Who We Are</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Assistance Hub</varnishUi.Link></li>
        </ul>
    </div>,
    <div>
        <varnishUi.Label>Link Header 4</varnishUi.Label>
        <ul>
            <li><varnishUi.Link color="primary" href="#">Privacy Commitment</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Terms and Conditions</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Contact Support</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">Learn About Us</varnishUi.Link></li>
            <li><varnishUi.Link color="primary" href="#">FAQ Section</varnishUi.Link></li>
        </ul>
    </div>
];


render(<CustomFooter linksGroup={linksGroup}
    linksGroupClassName={exampleLinksGroupClassName}
    copyRightClassName={exampleCopyRightClassName}
 />);
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;