import { css } from '@allenai/varnish-panda-runtime/css';

import { cx } from '@/utils/cx';

import { useSpriteConfig } from './SpriteProvider';
import { svgStyles } from './svg.styles';
import type {
    SVGRecipeProps,
    SVGRecipeColorProp,
    SVGColorPropType,
    SystemColorProp,
} from './svg.styles';

interface SVGBaseProps extends Omit<React.SVGAttributes<SVGElement>, 'color'>, SVGRecipeProps {
    aspectRatio?: string;
    title?: string;
}

const SVGBase = ({
    viewBox,
    title,
    color,
    aspectRatio,
    className,
    role = 'img',
    children,
    ...rest
}: SVGBaseProps) => {
    const colorStyles = colorProp(color);
    // we have no other variants, so for now this will do nothing but give a type errors
    // const [variantProps, localProps] = svgStyles.splitVariantProps(rest);

    return (
        <svg
            viewBox={viewBox}
            className={cx(css(colorStyles, { aspectRatio }), svgStyles(), className)}
            role={role}
            {...rest}>
            {title ? <title>{title}</title> : null}
            {children}
        </svg>
    );
};

interface SVGSpriteProps extends SVGBaseProps {
    forceInternal?: boolean;
}

type SVGSpritePropsInternal<T extends React.ComponentType> = {
    SVGComponent?: T;
    use: string;
} & Pick<SVGSpriteProps, 'forceInternal'> &
    React.ComponentProps<T>;

const SVGSprite = <T extends React.ComponentType = React.ComponentType<SVGBaseProps>>({
    SVGComponent,
    use,
    forceInternal = false,
    ...rest
}: SVGSpritePropsInternal<T>) => {
    const SVGComponentRender = SVGComponent || SVGBase;
    const { idPrefix, external, sprite } = useSpriteConfig();

    const href = !forceInternal && external ? sprite : '';
    return (
        <SVGComponentRender {...rest}>
            <use href={`${href}#${idPrefix}${use}`} />
        </SVGComponentRender>
    );
};

export const colorProp = (color: SVGColorPropType) => {
    if (color) {
        const colorMerged = css.raw(
            { color: color as SystemColorProp },
            svgStyles.raw({ color: color as SVGRecipeColorProp })
        );
        return colorMerged;
    }
    return {};
};

export { SVGBase as default, SVGBase, SVGSprite };
export type { SVGBaseProps, SVGSpriteProps };
