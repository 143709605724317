import packageJson from '@allenai/varnish-ui/package.json';
import { Code } from '@allenai/varnish2/components';

export const PeerDepsVarnishUi = () => {
    const deps = Object.entries(packageJson.peerDependencies)
        .map(([k, v]) => `${k}@${v}`)
        .join(' ');

    return (
        <Code variant="dark">
            {/* with npm */}
            <br />
            npm install {deps}
            <br />
            <br />
            {/* with yarn */}
            <br />
            yarn add {deps}
            <br />
        </Code>
    );
};
