import varnishTokens from '@allenai/varnish-theme/mui';

import { getThemeObjectTokens } from './theme-utils';

// pass font info from varnish-theme to mui theme
/**
 * @deprecated
 */
export const font = {
    family: getThemeObjectTokens(varnishTokens.font.family),
    size: getThemeObjectTokens(varnishTokens.font.size),
    weight: getThemeObjectTokens(varnishTokens.font.weight),
} as const;

export type FontType = typeof font;
